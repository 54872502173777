import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const initialState = Immutable({
  translations: {},
  isFetchedTranslationsAvailable: false,
});

export default function TranslationsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_TRANSLATIONS_SUCCESS:
      return { translations: action.updatedTranslations };
    case types.SET_IS_FETCHED_TRANSLATIONS_AVAILABLE:
      return { isFetchedTranslationsAvailable: action.isAvailable };
    default:
      return state;
  }
}
