import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Icon, Typography } from '@material-ui/core';
import LinkText from '../core/components/LinkText';

import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import '../../css/subscriptionsPage/SubscriptionFeaturesList.scss';

import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import ClickShowMoreSubscriptionFeaturesEvent from '../../events/Subscription/ClickShowMoreSubscriptionFeaturesEvent';
import getClickEventClientXY from '../../events/utils/getClickEventClientXY';
import { useSelector } from 'react-redux';
import { getUserToken } from '../../selectors';

const SubscriptionFeaturesList = ({
  features, translation, subscriptionId, getRowSlot,
}) => {
  const cloudEventsSubjectParams = useCloudEventsBaseParams();

  const [showExtras, setShowExtras] = useState(false);
  if (isEmpty(features)) return null;

  // We only want to show the first 3 features, before the user clicks on 'show more'
  const shownFeatures = features.slice(0, 3);
  // These are the rest of the features, after the first three.
  const hiddenFeatures = features.slice(3);

  const toggleText = showExtras ? translation('showLess') : translation('showMore');
  const toggleIcon = showExtras ? 'keyboard_arrow_up' : 'keyboard_arrow_down';

  const userToken = useSelector(getUserToken);

  const onToggleShowMore = (clickEvent = {}) => {
    if (!showExtras) {
      const cloudEvent = new ClickShowMoreSubscriptionFeaturesEvent({
        ...cloudEventsSubjectParams,
        subscriptionId,
        slot: getRowSlot(),
        ...getClickEventClientXY(clickEvent),
      });

      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }
    setShowExtras(!showExtras);
  };

  return (
    <div className="SubscriptionFeaturesList-featureList">
      <Typography className="SubscriptionFeaturesList-featureTitle">{translation('SubscriptionsPage.featureTitle')}</Typography>
      {
        shownFeatures.map(feature =>
          (
            <div key={feature} className="SubscriptionFeaturesList-feature">
              <Icon className="SubscriptionFeaturesList-featureIcon">check_circle</Icon>
              <Typography className="SubscriptionFeaturesList-featureTitle">{feature}</Typography>
            </div>
          ))
        }
      {
        showExtras && (
          <Fragment>
            {
              hiddenFeatures.map(feature =>
              (
                <div key={feature} className="SubscriptionFeaturesList-feature">
                  <Icon className="SubscriptionFeaturesList-featureIcon">check_circle</Icon>
                  <Typography className="SubscriptionFeaturesList-featureTitle">{feature}</Typography>
                </div>
              ))
            }
          </Fragment>
        )
      }
      {
        (hiddenFeatures.length > 0) && (
          <div className="SubscriptionFeaturesList-toggleContainer">
            <Icon className="SubscriptionFeaturesList-toggleIcon">{toggleIcon}</Icon>
            <LinkText
              onClick={onToggleShowMore}
              text={toggleText}
            />
          </div>
        )
      }
    </div>
  );
};

SubscriptionFeaturesList.propTypes = {
  translation: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  subscriptionId: PropTypes.number.isRequired,
  getRowSlot: PropTypes.func.isRequired,
};

SubscriptionFeaturesList.defaultProps = {
  features: null,
};

export default SubscriptionFeaturesList;
