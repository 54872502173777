import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getImageUrl } from '../../../services/functions/Functions';
import '../../../css/core/components/CircleFill.scss';

const CircleFill = (props) => {
  const {
    fillHeight,
  } = props;
  const fillHeightPercentage = `${fillHeight}%`;

  return (
    <div className="circleFill-container">
      <div className="circle">
        <div className="circleFill" style={{ height: fillHeightPercentage }} />
        <img
          alt="CircleFill-logo"
          className="circleFillLogo"
          src={getImageUrl('circleFillLogo.png')}
        />
      </div>
    </div>
  );
};

CircleFill.propTypes = {
  fillHeight: PropTypes.number.isRequired,
};

export default (withRouter(CircleFill));
