import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import withWidth from '@material-ui/core/withWidth';
import {
  Hidden,
  Typography,
  AppBar,
} from '@material-ui/core';

import * as Routes from '../../../services/routes/Routes.json';
import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import ShoppingCart from './ShoppingCart';
import NotificationIconComponent from './NotificationIconComponent';
import AnnouncementBanner from './AnnouncementBanner';
import AppDrawer from '../components/AppDrawer';
import * as Functions from '../../../services/functions/Functions';
import CheckoutDrawer from '../../checkout/CheckoutDrawer';
import PaymentDrawer from '../../core/components/PaymentDrawer';
import { getDocumentStyleVariables } from '../../../services/functions/Functions';
import '../../../css/core/components/HeaderComponent.scss';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';

/* eslint-env browser */

const HeaderComponent = ({
  actions,
  currentOrder,
  handleClickOptions,
  handleClickAuthentication,
  options,
  externalOptions,
  user,
  selectedPage,
  translation,
  appDrawerOpen,
  history,
  handleDrawerOpen,
  handleDrawerClose,
  width,
}) => {
  const getLogoString = (isDesktop) => {
    const desktopLogoString = FeatureFlags.CoreView.HeaderComponent.usePrimaryLogo
      ? 'primary_logo.svg'
      : 'logoContrast.svg';
    const mobileLogoString = 'mobile_header_logo.svg';
    const useMobileLogo = !isDesktop && FeatureFlags.CoreView.HeaderComponent.useMobileHeaderLogo;
    if (useMobileLogo) return mobileLogoString;
    return desktopLogoString;
  };

  const isDesktop = Functions.isDesktopMode(width);
  const useAltLink = FeatureFlags.CoreView.HeaderComponent.useAlternateLogoLink;
  let allOptions = (FeatureFlags.CoreView.HeaderComponent.showExtraLinks)
    ? options.concat(externalOptions) : options;
  if (user && user.guestToken) {
    allOptions = Functions.filterGuestOptions(allOptions);
  }

  const documentStyleVariables = getDocumentStyleVariables();
  const getPageSelectedClass = (option) => {
    if (selectedPage === translation(option.key)) return 'optionButton-selected';
    return 'optionButton';
  };

  const onClickHeaderOption = option => (event = {}) => {
    const clientPosition = getClickEventClientXY(event);
    handleClickOptions(translation(option.key), clientPosition);
  };

  return (
    <div>
      <AppDrawer
        appDrawerOpen={appDrawerOpen}
        translation={translation}
        history={history}
        externalOptions={externalOptions}
        handleClickAuthAppDrawer={handleClickAuthentication}
        handleClickDrawerOptions={handleClickOptions}
        user={user}
        onHandleDrawerClose={handleDrawerClose}
        actions={actions}
      />
      <AppBar
        position="absolute"
        className="muiAppBar"
      >
        {
          !isDesktop
          && (
            <IconButton
              className="menuButton"
              color="primary"
              aria-label="Menu"
              onClick={handleDrawerOpen}
            >
              <Icon>menu</Icon>
            </IconButton>
          )
        }
        <Link to={useAltLink ? Routes.path.altLogoLink : Routes.path.menuPage}>
          <img
            src={Functions.getImageUrl(getLogoString(isDesktop))}
            alt={`${window.companyName} logo`}
            className={isDesktop ? 'logo' : 'logo_mobile'}
          />
        </Link>
        <Hidden smDown>
          <div className="optionsContainer">
            {
              allOptions.map(option => (
                option.isExternal
                ? (
                  <div
                    key={option.id}
                    className="optionButton"
                  >
                    <Link
                      style={{ textDecoration: 'none', color: documentStyleVariables['header-text-color'] }}
                      to={{ pathname: option.href }}
                      target={option.target}
                    >
                      {translation(option.title)}
                    </Link>
                  </div>
                ) : (
                  <Typography
                    key={option.id}
                    className={getPageSelectedClass(option)}
                    onClick={onClickHeaderOption(option)}
                  >
                    {
                      option.src
                      ? <img src={option.src} alt={translation(option.alt)} />
                      : null
                    }
                    {translation(option.title)}
                  </Typography>
                )
              ))
            }
          </div>
        </Hidden>
        <div className="accountContainer">
          <div className="accountButtonContainer">
            {
              user
                && (
                  <Fragment>
                    <div className="loggedInUserContainer">
                      <Typography className="loggedInUserWelcomeText">
                        {translation('HeaderComponent.welcomeText')}
                      </Typography>
                      <Typography className="loggedInUserText" >
                        {user.name}
                      </Typography>
                    </div>
                    <div className="loggedInUserContainer">
                      <Typography className="loggedInUserWelcomeText">
                        {translation('HeaderComponent.notYou')}
                      </Typography>
                      <div
                        className="SignOutButton"
                        id="signOut"
                      >
                        <Typography
                          onClick={() => handleClickAuthentication(user)}
                          className="SignOutText"
                        >
                          {translation('HeaderComponent.signOut')}
                        </Typography>
                      </div>
                    </div>
                  </Fragment>
                )
              }
            {
              !user && !Functions.isMobileMode(width)
              && (
                <Typography
                  onClick={() => handleClickAuthentication(user)}
                  className="createAccountText"
                >
                  {translation('HeaderComponent.signIn')}
                </Typography>
              )
            }
          </div>
          {
            user && !user.guestToken && !Functions.isMobileMode(width)
            && (
              <NotificationIconComponent
                iconStyleProps="notificationIconStyle"
              />
            )
          }
          <ShoppingCart
            onHandleClick={handleClickAuthentication}
            onHandleOpenCheckoutDrawer={() => actions.toggleComponent('CheckoutDrawer')}
            user={user}
          />
        </div>
        <CheckoutDrawer
          handleClickAuthentication={handleClickAuthentication}
          history={history}
          actions={actions}
          user={user}
          isDesktop={isDesktop}
          translation={translation}
        />
        <PaymentDrawer
          history={history}
          actions={actions}
          user={user}
          isDesktop={isDesktop}
          translation={translation}
        />
      </AppBar >
    </div>
  );
};

HeaderComponent.propTypes = {
  handleClickOptions: PropTypes.func.isRequired,
  handleClickAuthentication: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  externalOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedPage: PropTypes.string,
  translation: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  appDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.string,
  handleDrawerClose: PropTypes.func.isRequired,
};

HeaderComponent.defaultProps = {
  user: null,
  selectedPage: '',
  currentOrder: {},
  width: null,
};

export default withWidth()(HeaderComponent);
