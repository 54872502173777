import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { CONTROLS, PAGES, PLACEMENTS, SLOTS, TYPES } from './constants';

export default class IncrementQuantityEvent extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    itemId,
    idSeam,
    sessionId,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.INCREMENT_ITEM_QUANTITY,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: PAGES.PRODUCT_DIALOG,
        slot: SLOTS.FOOTER,
        placement: PLACEMENTS.PRODUCT_CALL_TO_ACTION_PLACEMENT,
        control: CONTROLS.INCREMENT_QUANTITY_BUTTON,
      },
      position: getPositionObject({ clientX, clientY }),
      itemId,
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
    };
    this.setData(data);
  }
}
