import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import '../../../css/locationsPage/subComponents/HoursComponent.scss';
import { PICKUP, DELIVERY, BUSINESS } from '../../../services/constants/Constants';
import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import { getGroupedHoursForDeliveryType } from '../../../services/helpers/HoursUtils';


class HoursComponent extends Component {
  generateKey = index => (`key${index}`);

  showDeliveryOptionHours = (locationHours, type) => {
    let bool = true;
    if (type === DELIVERY) {
      bool = locationHours.some(hour => hour.deliveryOpen || hour.deliveryClose);
    } else if (type === PICKUP) {
      bool = locationHours.some(hour => hour.pickupOpen || hour.pickupClose);
    }
    return bool;
  }

  getLocationHourOptions = () => ([
    {
      type: BUSINESS,
      shouldShow: FeatureFlags.HoursComponent.showBusinessHours,
      headingText: 'HoursComponent.businessHours',
    },
    {
      type: DELIVERY,
      shouldShow: FeatureFlags.HoursComponent.showDeliveryHours,
      headingText: 'HoursComponent.deliveryHours',
    },
    {
      type: PICKUP,
      shouldShow: FeatureFlags.HoursComponent.showPickUpHours,
      headingText: 'HoursComponent.pickupHours',
    },
  ]
  );

  renderNoHours = () => {
    const { translation, textcolorOverride } = this.props;
    return (
      <div className="businessHours">
        <Typography
          className="locationHoursHeading"
          style={{ color: `${textcolorOverride}` }}
        >
          {translation('HoursComponent.locationUnavailableHours')}
        </Typography>
      </div>
    );
  };

  renderHours = ({
    hours, className, hoursKey, textcolorOverride,
  }) => (
    hours && (
      <Typography
        className={className}
        style={{ color: `${textcolorOverride}` }}
        key={hoursKey}
      >
        {hours}
      </Typography>
    )
  );

  renderFormattedHours = (text, optionType, index = 0) => {
    const { translation, location, textcolorOverride } = this.props;
    const deliveryTypeTimes = getGroupedHoursForDeliveryType(location, optionType);
    return (
      <div key={this.generateKey(index)}>
        <Typography
          className="hoursComponent-locationHoursHeading "
          style={{ color: `${textcolorOverride}` }}
        >
          {`${translation(text)}:`}
        </Typography>
        <div className="businessHours">
          {
            deliveryTypeTimes.map((time, i) => (
              <div>
                { this.renderHours({
                    hours: time.hours, className: 'regularHours', hoursKey: i, textcolorOverride,
                  }) }
                {
                  time.holidays.length > 0 && (
                    time.holidays.map((holiday, j) => (
                      this.renderHours({
                        hours: holiday, className: 'holidayHours', hoursKey: j, textcolorOverride,
                      })
                    ))
                  )
                }
              </div>
            ))
          }
        </div>
      </div>
    );
  };

  render() {
    const {
      hours,
      location,
      wrapperStyle,
      deliveryOption,
    } = this.props;
    const locationHoursOptions = this.getLocationHourOptions();
    let locationHoursOptionsIndex = locationHoursOptions.findIndex(option => option.type === deliveryOption);
    locationHoursOptionsIndex = locationHoursOptionsIndex >= 0 ? locationHoursOptionsIndex : 0;

    // There should be hours set for all days. If there are less than 7, the store location hours were not set up properly.
    if (hours.length === 0 || hours.length < 7) return this.renderNoHours();

    return (
      deliveryOption && this.showDeliveryOptionHours(location.hours, deliveryOption)
        ? this.renderFormattedHours(locationHoursOptions[locationHoursOptionsIndex].headingText, deliveryOption)
        : (
          <div className={wrapperStyle || 'hoursComponent-hours'}>
            {
              locationHoursOptions.map((option, index) => (
                option.shouldShow
                && this.showDeliveryOptionHours(location.hours, option.type)
                && (
                  this.renderFormattedHours(option.headingText, option.type, index)
                )
              ))
            }
          </div>
        )
    );
  }
}

HoursComponent.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.any).isRequired,
  translation: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  wrapperStyle: PropTypes.string,
  deliveryOption: PropTypes.string,
  textcolorOverride: PropTypes.string,
};

HoursComponent.defaultProps = {
  wrapperStyle: '',
  deliveryOption: null,
  textcolorOverride: null,
};

export default (HoursComponent);
