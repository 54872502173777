import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { CONTROLS, PAGES, PLACEMENTS, SLOTS, TYPES } from './constants';

export default class ClickCheckoutUpsellCarouselAddButton extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    idSeam,
    sessionId,
    upsellId,
    itemId,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.CLICK_CHECKOUT_UPSELL_CAROUSEL_ADD_BUTTON,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: PAGES.CHECKOUT_DRAWER,
        slot: SLOTS.L2,
        placement: PLACEMENTS.UPSELL_PRODUCT_CAROUSEL,
        control: CONTROLS.UPSELL_ORDER_NOW_BUTTON,
      },
      position: getPositionObject({ clientX, clientY }),
      upsellId,
      itemId,
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
    };
    this.setData(data);
  }
}
