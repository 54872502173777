import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { GIFT_CARD } from '../../../services/constants/Constants';
import Button from '../../core/components/Button';
import * as Config from '../../../../dist/config.json';
import { getDocumentStyleVariables } from '../../../services/functions/Functions';

import '../../../css/paymentPage/subComponents/SquareGiftCardComponent.scss';

const resetGiftCardForm = async (giftCardObject) => {
  await giftCardObject.detach();
  await giftCardObject.attach('#gift-card-container');
};

class SquareGiftCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      squareGiftCard: null,
      cardButtonDisabled: false,
    };
  }

  componentDidMount = async () => {
    const { company, actions } = this.props;
    // eslint-disable-next-line no-undef
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    if (isEmpty(company)) {
      try {
        actions.getAllResources(null, ['companies']);
      } catch (error) {
        console.log('API call error while getting companies', error);
      }
    }
    let squarePaymentObject;
    try {
      squarePaymentObject = await this.initializeSquare();
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

    this.setState({ squareGiftCard: squarePaymentObject.giftCard });
  }

  getSquarePaymentStyles = () => {
    const styleVariables = getDocumentStyleVariables();
    const squarePaymentStyles = {
      messageText:
      {
        color: styleVariables.text,
      },
      messageIcon:
      {
        color: styleVariables.text,
      },
      input:
      {
        backgroundColor: styleVariables['input-background-color'],
        color: styleVariables['input-text-color'],
      },
      inputPlaceholder:
      {
        color: styleVariables['input-text-color'],
      },
      inputContainer:
      {
        borderColor: styleVariables['input-border-color'],
      },
    };
    return squarePaymentStyles;
  }

  // Need to use Square's class selectors with the dots in front. Otherwise, the form crashes
  getCardStyleSelectors = () => {
    const squarePaymentStyles = this.getSquarePaymentStyles();
    return ({
      input: squarePaymentStyles.input,
      'input.is-error': {},
      'input.is-error::placeholder': {},
      'input.is-focus': {},
      'input.is-focus::placeholder': {},
      'input::placeholder': squarePaymentStyles.inputPlaceholder,
      '.message-text': squarePaymentStyles.messageText,
      '.message-text.is-error': {},
      '.message-icon': squarePaymentStyles.messageIcon,
      '.message-icon.is-error': {},
      '.input-container': squarePaymentStyles.inputContainer,
      '.input-container.is-error': {},
      '.input-container.is-focus': {},
    });
  }

  getCardOptions = () => ({ style: this.getCardStyleSelectors() })

  // Helper method for displaying the Payment Status on the screen.
  // status is either SUCCESS or FAILURE;
  displayPaymentResults = (status) => {
    // eslint-disable-next-line no-undef
    const statusContainer = document.getElementById('giftcard-payment-status-container');
    if (!statusContainer) return;
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }
    statusContainer.style.visibility = 'visible';
  };

  initializeSquare = async () => {
    const { currentOrder } = this.props;
    const appId = Config.general.squareAppId;
    const locationId = get(currentOrder, 'location.squareId');
    // eslint-disable-next-line no-undef
    const payments = window.Square.payments(appId, locationId);
    const cardOptions = this.getCardOptions();
    const giftCard = await payments.giftCard(cardOptions);
    await giftCard.attach('#gift-card-container');
    return { giftCard };
  }

  tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') return tokenResult.token;
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }
    throw new Error(errorMessage);
  };

  handlePaymentMethodSubmission = async (squareGiftCard) => {
    try {
      // disable the submit button as we await tokenization and make a payment request.
      this.setState({ cardButtonDisabled: true });
      const result = await this.tokenize(squareGiftCard);
      const addCardResult = await this.addCard(result);
      this.displayPaymentResults('SUCCESS');
      console.debug('Payment Success', addCardResult);
    } catch (e) {
      this.setState({ cardButtonDisabled: false });
      this.displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  }

  addCard = async (tokenizeResult) => {
    const { user, actions } = this.props;
    const { squareGiftCard } = this.state;
    const cardToken = tokenizeResult;
    const cardInfo = {
      name: '',
      cardNumber: cardToken,
      cardType: GIFT_CARD,
    };

    const newCardResponse = await actions
      .addResource(
        user.token, cardInfo,
        'users', user.id,
        'payment-options',
      );
    if (!newCardResponse || newCardResponse.error) {
      console.log('There was an error adding your giftcard', newCardResponse.error);
    } else {
      resetGiftCardForm(squareGiftCard);
    }
    this.setState({ cardButtonDisabled: false });
  }

  render() {
    const { cardButtonDisabled, squareGiftCard } = this.state;
    const { translation } = this.props;
    return (
      <div>
        <form id="payment-form">
          <div id="gift-card-container" />
          <Button
            disabled={cardButtonDisabled}
            onClick={() => this.handlePaymentMethodSubmission(squareGiftCard, false, false)}
            id="gift-card-button"
            text={translation('SquareGiftCardComponent.addCardButton')}
            type="primary"
          />
        </form>
        <div id="giftcard-payment-status-container" />
      </div>
    );
  }
}

SquareGiftCardComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SquareGiftCardComponent;
