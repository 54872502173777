import Immutable from 'seamless-immutable';
import ResourcesAPI from '../../../services/api/ResourcesApi';

export const getActiveSubscription = (userSubscriptions, subscriptionOffer) => {
  if (!userSubscriptions || !subscriptionOffer) return null;
  const activeSubscription = userSubscriptions.find(userSub => userSub.subscriptionId === subscriptionOffer.subscriptionId);
  if (!activeSubscription) return null;
  return activeSubscription;
};

export const getSubscriptionFrequency = (subscription, translation) => {
  // THE API RETURNS VALUES SUCH AS 'WEEKLY, MONTHLY, YEARLY' WE WANT TO GET THE TIME UNIT FOR THIS
  if (!subscription) return '';
  let translationKey = '';
  if (subscription.billingFrequency === 'DAILY') translationKey = 'day';
  translationKey = subscription.billingFrequency.slice(0, -2).toLowerCase();
  return translation(translationKey);
};

export const getRenewalDate = (endDate) => {
  if (!endDate) return '';
  const endDateObject = new Date(endDate);
  return endDateObject.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
};

export const getAvailableSubscriptionOffers = subscriptionOffers => subscriptionOffers.filter(offer => offer.disabled === false);

export const getSortedSubscriptionOffers = (subscriptionOffers, userSubscriptions) => {
  const mutableOffers = Immutable.asMutable(subscriptionOffers);
  const activeSubscriptions = mutableOffers.filter(offer => getActiveSubscription(userSubscriptions, offer));
  const inactiveSubscriptions = mutableOffers.filter(offer => !getActiveSubscription(userSubscriptions, offer));

  const sortedActiveSubscriptions = activeSubscriptions.sort((a, b) => a.price - b.price);
  const sortedInactiveSubscriptions = inactiveSubscriptions.sort((a, b) => a.price - b.price);

  return [...sortedActiveSubscriptions, ...sortedInactiveSubscriptions];
};

export const manageSubscription = (user, callBack = null) => {
  const billingPortalSession = ['subscriptions', 'billing-portal-session'];
  ResourcesAPI.redirectResourceToNewTab(user.token, billingPortalSession, callBack);
};

export const subscribe = async (subscriptionOffer, user, callBack = null) => {
  const currentWindowLocationHref = window.location.href;
  const checkoutSession = ['subscriptions', subscriptionOffer.subscriptionId, `checkout-session?=successUrl=${currentWindowLocationHref}`];
  ResourcesAPI.redirectResourceInPlace(user.token, checkoutSession, callBack);
};
