const TYPES = {
  CLICK_SQUARE_APPLE_PAY_BUTTON: 'ca.craver.web.click_square_apple_pay_button.v1',
  CLICK_SQUARE_GOOGLE_PAY_BUTTON: 'ca.craver.web.click_square_google_pay_button.v1',
  CLICK_PLACE_ORDER_BUTTON: 'ca.craver.web.click_place_order_button.v1',
};

const PAGES = {
  PAYMENT_DRAWER: 'payment-drawer',
};

const SLOTS = {
  L2: 'L2',
  FOOTER: 'footer',
};

const PLACEMENTS = {
  SQUARE_PAYMENT_CARD_INFO_ENTRY_PLACEMENT: 'square-payment-card-info-entry-placement',
  PAYMENT_DRAWER_CALL_TO_ACTION_PLACEMENT: 'payment-drawer-call-to-action-placement',
};

const CONTROLS = {
  SQUARE_APPLE_PAY_BUTTON: 'square-apple-pay-button',
  SQUARE_GOOGLE_PAY_BUTTON: 'square-google-pay-button',
  PLACE_ORDER_BUTTON: 'place-order-button',
};

export {
  TYPES,
  PAGES,
  SLOTS,
  PLACEMENTS,
  CONTROLS,
};
