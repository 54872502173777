import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Immutable from 'seamless-immutable';
import { LinearProgress } from '@material-ui/core';

import { getLocations } from '../../selectors';
import LocationComponent from './subComponents/LocationComponent';
import '../../css/locationsPage/LocationsPage.scss';

class LocationsPage extends Component {
  async componentDidMount() {
    const {
      actions, locations, upliftHistory, history,
    } = this.props;
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);

    if (!locations) {
      try {
        // eslint-disable-next-line no-undef
        await actions.getResource(window.companyApiKey, ['locations']);
      } catch (error) {
        console.log('There is an API error', error);
      }
    }
  }

  render() {
    const {
      locations,
      loading,
      translation,
      history,
      actions,
    } = this.props;
    const sortedLocations = Immutable.asMutable(locations, { deep: true });
    if (locations) {
      sortedLocations.sort((l1, l2) => l1.name.localeCompare(l2.name));
    }

    return (
      loading !== 0
        ? <LinearProgress />
        : (
          <div className="locationsPage">
            {
              locations && sortedLocations.map(location =>
              (
                <LocationComponent
                  key={location.id}
                  location={location}
                  translation={translation}
                  history={history}
                  actions={actions}
                />

              ))
            }
          </div>
        )
    );
  }
}

LocationsPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  upliftHistory: PropTypes.func.isRequired,
};

LocationsPage.defaultProps = {
  locations: null,
};

const mapStateToProps = state => ({
  locations: getLocations(state),
});

export default (withRouter(connect(mapStateToProps)(LocationsPage)));
