import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { Typography, withWidth, Hidden } from '@material-ui/core';
import {
  get,
  cloneDeep,
  isEmpty,
  defaultTo,
} from 'lodash';

import Button from '../core/components/Button';
import AddSubtractToggle from '../core/components/AddSubtractToggle';
import InstructionsComponent from './subComponents/InstructionsComponent';
import CustomizationComponent from './subComponents/CustomizationComponent';
import DialogView from '../core/components/DialogView';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import * as Functions from '../../services/functions/Functions';
import {
  getCompany,
  getCurrentOrder,
  getPortions,
  getProducts,
  getDialogLoading,
  getDialogProductById,
  getCategories,
  getLoading,
  getUser,
  getCloudEventSessionId,
} from '../../selectors';
import * as Actions from '../../actions/Actions';
import { LEFT, RIGHT, WHOLE } from '../../services/constants/Constants';
import { FeatureFlags } from '../../services/functions/FeatureFlag';
import '../../css/product/ProductFlowDialog.scss';
import TimeLimitErrorComponent from './subComponents/TimeLimitErrorComponent';
import ProductDialogATCEvent from '../../events/ATC/ProductDialogATCEvent';
import getClickEventClientXY from '../../events/utils/getClickEventClientXY';
import { DIALOG_NAMES } from '../../services/api/CloudEvents/constants';

class ProductFlowDialog extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      note: '',
      item: null,
      isEdit: false,
      customizations: [],
      customizationOpenStates: {},
      validCustomizations: [],
      currentItem: {},
      editQuantity: null,
      setPreset: true,
      timeLimitErrorVisible: true,
    };
    this.state = initialState;
  }

  async componentDidMount() {
    const {
      actions,
      portions,
      product,
      categories,
      company,
      isEditFromCheckout,
      currentItemFromCheckout,
    } = this.props;
    // eslint-disable-next-line no-undef
    const companyApiKey = window.companyApiKey;
    const customizationObj = {};

    if (product) {
      const productItems = defaultTo(product.items, []);
      // Check if there is more than 1 product item
      if (productItems.length > 1) {
        // Set all to false as default.
        // We set this as true if the customization selection requirements
        // have been met. Such as meeting the min/max requirements.
        customizationObj[`item${product.id}`] = true;
      }
      let selectedItem = productItems.find(item => item.isDefault === true);
      if (!selectedItem) selectedItem = productItems[0];
      this.setState({
        item: {
          ...selectedItem,
          quantity: 1,
        },
      });
    }

    if (this.state.item || product) {
      const positionIds = product.items != null ? product.items.map(i => i.position) : [];
      const defaultProductItem = product.items != null ? product.items.find(pItem => pItem.position === Math.min(...positionIds)) : {};
      if (defaultProductItem) {
        defaultProductItem.customizations.forEach((customization) => {
          if (customization.minOptions > 0) {
            // Check if the customization has includedOption
            customizationObj[customization.id] = this.customizationHasOption(defaultProductItem, customization);
          }
        });
      }
    }
    this.setState({
      validCustomizations: customizationObj,
    });

    if (!portions || portions.length === 0) {
      try {
        await actions.getAllResources(companyApiKey, ['portions']);
      } catch (error) {
        console.log('API call error', error);
      }
    }
    if (!categories || categories.length === 0) {
      try {
        await actions.getAllResources(companyApiKey, ['categories']);
      } catch (error) {
        console.log('API call error', error);
      }
    }
    // If product is not being edited from checkout page, preset customizations with included options
    if (!isEditFromCheckout) {
      this.setState({ customizations: Functions.getIncludedCustomizations(product.items.find(item => item.isDefault === true)) });
    } else {
      const currentItemCustomizations = currentItemFromCheckout.productItem.customizations;
      this.setState({
        isEdit: isEditFromCheckout,
        editQuantity: currentItemFromCheckout.quantity,
        currentItem: currentItemFromCheckout,
        note: currentItemFromCheckout.note,
      });
      if (currentItemCustomizations.length > 0) {
        customizationObj[currentItemCustomizations[0].id] = true;
        // Set preset customizations as valid
        currentItemCustomizations.forEach((cust) => {
          customizationObj[cust.id] = true;
        });
      }
      // Check for more than 1 product item
      if (product.items && product.items.length > 1) {
        // Set preset productItem as valid
        customizationObj[`item${product.id}`] = true;
      }
      this.setState({
        validCustomizations: customizationObj,
      });
    }

    if (isEmpty(company)) {
      try {
        actions.getAllResources(null, ['companies']);
      } catch (error) {
        console.log('API call error while getting companies', error);
      }
    }
    this.updateCustomizationOpenState();
  }

  componentDidUpdate(prevState, prevProps) {
    const { isEdit, editQuantity, currentItem } = this.state;
    const { product } = this.props;

    const nextItem = this.state.item;
    if (prevProps.item && nextItem && prevProps.item !== nextItem) {
      const customizationObj = {};
      // Create a new all-invalid customization object.
      // Since we want to test all validations first, we assume they're invalid at first.
      nextItem.customizations.forEach((cust) => {
        if (cust.minOptions > 0) customizationObj[cust.id] = false;
      });
      if (this.state.customizations.length > 0) {
        customizationObj[this.state.customizations[0].id] = true;

        // Set included customization options as valid
        this.state.customizations.forEach((cust) => {
          customizationObj[cust.id] = true;
        });
      }
      // Check if there is more than 1 product item
      if (product.items && product.items.length > 1) {
        // Set default productItem as valid
        customizationObj[`item${product.id}`] = true;
      }
      this.setState({
        validCustomizations: customizationObj,
      });
    }

    if (isEdit && editQuantity) {
      this.setPresetQuantityCustomizations(editQuantity, currentItem);
    }
  }

  onHandleClickList = (customizationId) => {
    const { customizationOpenStates } = this.state;
    const mutableObj = Immutable.asMutable(customizationOpenStates, { deep: true });
    // Only open 1 list at a time
    Object.keys(mutableObj).forEach((key) => {
      mutableObj[key] = Number(key) === customizationId && !customizationOpenStates[key];
    });

    this.setState({
      customizationOpenStates: mutableObj,
    });
  }

  /**
   * Sets the quantity with preset value if users is coming through edit item
   * link on checkout page
   * @param {*} editQuantity the pre-edit quantity of the item per checkout page
   * @param {*} currentItem the order item being edited
   */
  setPresetQuantityCustomizations = (editQuantity, currentItem) => {
    const { product } = this.props;
    const { item, setPreset } = this.state;
    const presetOptions = currentItem.options;
    const presetSubItems = defaultTo(currentItem.subItems, []);

    if (item && setPreset && presetOptions) {
      if (currentItem.productItem.customizations.length > 0) {
        this.handleChangeCustomization(presetOptions[0], currentItem.productItem.customizations[0].id);
        if (presetOptions[1] > 1) {
          this.handleChangeCustomization(presetOptions[1], currentItem.productItem.customizations[1].id);
        }
      }
      // Find 'original' product item -> currentOrder productItem (currentItem) doesn't have out of stock details
      const productItem = product.items.find(pItem => pItem.id === currentItem.productItem.id);
      this.setState({
        item: {
          ...productItem,
          quantity: editQuantity,
        },
        setPreset: false,
        currentItem,
        note: currentItem.note,
      });

      const customizationArr = [];
      const productCustomizations = productItem.customizations;

      productCustomizations.forEach((customization) => {
        const optionIds = customization.options.map(c => c.id); // array of available option ids
        // Get subItem options
        const sideOneSubOptions = presetSubItems.length > 0
          ? Functions.setSubOptionSection(presetSubItems[0], optionIds)
          : [];
        const sideTwoSubOptions = presetSubItems.length === 2
          ? Functions.setSubOptionSection(presetSubItems[1], optionIds)
          : [];
        const combinedOptions = presetOptions.concat(sideOneSubOptions, sideTwoSubOptions);
        const selectedOptionsForCustom = combinedOptions
          .filter(selected => optionIds.includes(selected.id));
        customizationArr.push({
          id: customization.id,
          options: selectedOptionsForCustom,
        });
      });
      this.setState({ customizations: customizationArr });
    }
  }

  getResourceByName = (resourceName, resources) => {
    if (!resources) return null;
    return resources.find(item => item.name === resourceName);
  }

  /**
   * Get order item with associated options, subItems, quantity, notes
   * @param {*} customizations
   * @param {*} item
   * @param {*} quantity
   * @param {*} note
   */
  getOrderItem = (customizations, item, quantity, note) => {
    const { currentItem } = this.state;
    const { portions } = this.props;

    let optionsList = [];
    customizations.forEach((customization) => {
      optionsList = optionsList.concat(customization.options);
    });

    let leftSubItem;
    let rightSubItem;
    const leftPortionOptions = Functions.sortOptionsBySection(optionsList, LEFT);
    const rightPortionOptions = Functions.sortOptionsBySection(optionsList, RIGHT);
    // Get left and right subItems
    if (leftPortionOptions || rightPortionOptions || (item && item.subItems)) {
      leftSubItem = {
        id: Functions.getSubItemIdBySide(currentItem, 'Left'),
        portion: this.getResourceByName('Left', portions),
        options: leftPortionOptions || [],
      };
      rightSubItem = {
        id: Functions.getSubItemIdBySide(currentItem, 'Right'),
        portion: this.getResourceByName('Right', portions),
        options: rightPortionOptions || [],
      };
    }

    const productItem = Object.assign(
      {},
      currentItem ? currentItem && item : item,
      { quantity: undefined },
    );
    const orderItem = {
      options: Functions.sortOptionsBySection(optionsList, WHOLE),
      productItem,
      quantity,
      note,
    };

    if (FeatureFlags.CustomizationComponent.allowSectionSelection
      && (leftPortionOptions.length > 0
        || rightPortionOptions.length > 0
        || (item && item.subItems))
    ) {
      if (leftPortionOptions.length === 0 && rightPortionOptions.length === 0) return orderItem;
      orderItem.subItems = [];
      if (leftPortionOptions.length > 0) orderItem.subItems.push(leftSubItem);
      if (rightPortionOptions.length > 0) orderItem.subItems.push(rightSubItem);
    }
    return orderItem;
  }

  getOrderItemObj = (customizations, item, quantity, note) => {
    if (!item) {
      return null;
    }

    const orderItem = this.getOrderItem(customizations, item, quantity, note);
    const { products, company } = this.props;
    // Calculate the per unit price
    // i.e. orderItem.price + all customizations, with quantity equal to 1
    const price = quantity
      ? Functions.calculatePrice([orderItem], products, null, company) / quantity
      : 0;

    return { ...orderItem, price };
  };

  /**
   * Create the updated order item object with new customizations
   * @param {*} customizations the most recent customizations store locally in state
   * @param {*} item the order item
   * @param {*} quantity the quantity of the item selected
   * @param {*} note order item instructions
   */
  getUpdateOrderItemObj = (customizations, item, quantity, note) => {
    const { currentItem } = this.state;

    if (!item) {
      return null;
    }

    const orderItem = this.getOrderItem(customizations, item, quantity, note);
    const productItem = Object.assign(
      {},
      currentItem ? currentItem && item : item,
      { quantity: undefined },
    );

    const { products, company } = this.props;
    // Calculate the per unit price
    // i.e. orderItem.price + all customizations, with quantity equal to 1
    let price;
    if (orderItem.productItem.id) {
      price = quantity
        ? Functions.calculatePrice([orderItem], products, null, company) / quantity
        : 0;
    }

    if (currentItem) {
      return {
        options: orderItem.options,
        productItem,
        quantity,
        price,
        id: currentItem.id,
        redeemedPoints: currentItem.redeemedPoints,
        subItems: orderItem.subItems,
        tax: currentItem.tax,
        note: orderItem.note,
      };
    }
  };

  getButtonText = (price, isEdit) => {
    const { translation } = this.props;
    if (isEdit) return `${translation('ProductFlowDialog.updateCartText')} - ${Functions.getCurrencySymbol()}${price.toFixed(2)}`;
    return `${translation('ProductFlowDialog.addToCartText')} - ${Functions.getCurrencySymbol()}${price.toFixed(2)}`;
  };

  getDialogContent = () => {
    const {
      translation,
      product,
      products,
      currentOrder,
      company,
      loading,
    } = this.props;
    const {
      isEdit, item, customizations, note, validCustomizations, timeLimitErrorVisible,
    } = this.state;
    const showProductImage = FeatureFlags.ProductFlowDialog && FeatureFlags.ProductFlowDialog.showProductImage;
    const showNote = product && product.showNote;
    const itemCustomizations = item ? item.customizations : [];
    const orderItemObj = this.getOrderItemObj(customizations, item, item && item.quantity, note);
    const updateOrderItemObj
      = this.getUpdateOrderItemObj(customizations, item, item && item.quantity, note);
    const price = orderItemObj
      ? Functions.calculatePrice([orderItemObj], products, null, company)
      : 0;
    const orderId = currentOrder.id;
    const timeLimits = this.getCategoryTimeLimit();
    const invalidTimeLimit = !this.validateTimeLimits();
    const productOutOfStock = Functions.productIsOutOfStock(product, currentOrder.location.id);

    return (
      <div className="productFlowDialog-dialogContentContainer">
        {
          showProductImage && product
          && (
            <div className="imageContainer">
              <img
                className="imageStyles"
                src={
                  product.images && product.images.length > 0
                    ? product.images[0].src
                    : Functions.getImageUrl('image-placeholder.png')
                }
                alt={translation('ProductFlowDialog.imageAlt')}
              />
            </div>
          )
        }
        {product && this.renderProductDetails()}
        {
          productOutOfStock
          && (
            <div className="timeLimitContainer">
              <Typography className="productFlowDialog-outOfStockText">
                {translation('ProductFlowDialog.outOfStock')}
              </Typography>
            </div>
          )
        }
        {product && product.items && product.items.length > 1 && this.renderProductItemSelector(item)}
        {itemCustomizations && itemCustomizations.length > 0 && this.renderCustomizations(item, itemCustomizations)}
        {
          showNote
          && (
            <div className="instructionsContainer">
              <InstructionsComponent
                handleOnChange={event => this.handleNoteChange(event)}
                translation={translation}
                dialogView
                note={note}
              />
            </div>
          )
        }
        {
          <div className="productFlowDialog-footer">
            {
              (invalidTimeLimit && !productOutOfStock) && timeLimitErrorVisible
              && (
                <TimeLimitErrorComponent
                  timeLimits={timeLimits}
                  translation={translation}
                  onClickClose={() => this.setState({ timeLimitErrorVisible: false })}
                />
              )
            }
            <div className="productFlowDialog-buttonContainerStyles">
              {this.renderQuantityToggle()}
              <Button
                testId="addToCart"
                id="addToCart"
                type="primary"
                overrideClass
                className="addToCartButton"
                keepclickeventonclick
                onClick={
                  isEdit
                    ? () => this.handleClickUpdate(updateOrderItemObj)
                    : event => this.handleClickAdd(orderItemObj, orderId, event)
                }
                disabled={this.disableButton(validCustomizations) || loading !== 0}
                text={
                  this.getButtonText(price, isEdit)
                }
              />
            </div>
          </div>

        }
      </div>
    );
  }

  getAttributeBoxSubtitle = (translation) => {
    if (!translation) return '';
    return get(this.state, 'item.attribute_value', `(${translation('CustomContainerComponent.required')})`);
  }

  getCategoryTimeLimit = () => {
    const category = this.getCurrentCategory();
    if (!category) return undefined;
    return category.timeLimits;
  }

  getCurrentCategory = () => {
    const { categories } = this.props;
    const { item } = this.state;
    if (!item || !categories) return undefined;
    // We want to get the category depending on the item. If the item is a product,
    // it has it's category on it's JSON representation. If it's a productItem it has the categoryId field.
    const category = (item.categoryId) ? categories.find(cat => cat.id === item.categoryId) : item.category;
    return category;
  }

  getDefaultSelectedOptions(item) {
    let singleSelectCustomizations = [];
    if (item.customizations) {
      const filteredCustomizations = item.customizations.filter(customization => customization.maxOptions === 1 && customization.minOptions === 1);
      singleSelectCustomizations = this.sortCustomizations(filteredCustomizations, 'position');
    }

    if (singleSelectCustomizations.length === 0) return item.includedOptions;

    let result = item.includedOptions;

    // Filter single select customizations that need to have a default value set initially.
    const singleSelectDefaultCustomizations = singleSelectCustomizations.filter(
      customization => customization.isSingleSelectDefault,
    );

    if (singleSelectDefaultCustomizations.length === 0) return result;

    // By default, we want to select the first option for each mandatoryCustomization.
    // but ONLY if the corresponding customization doesn't have an included option from that group already.

    // First we filter all customization with no options appearing in includedOption.
    let notIncludedCustomizations = [];
    singleSelectDefaultCustomizations.forEach((customization) => {
      const mandatoryCustomizationOptionsIds = customization.options.map(opt => opt.id);
      let shouldAddToNotIncludedCustomizations = true;
      item.includedOptions.forEach((opt) => {
        if (mandatoryCustomizationOptionsIds.includes(opt.id)) shouldAddToNotIncludedCustomizations = false;
      });
      if (shouldAddToNotIncludedCustomizations) notIncludedCustomizations = notIncludedCustomizations.concat(customization);
    });
    // In some case the customizations we filtered in notIncludedCustomizations don't have any of their options
    // included, so we can now select the first options from each.
    const firstMandatoryOptions = notIncludedCustomizations.map(customization => customization.options[0]);
    if (firstMandatoryOptions.length > 0) result = result.concat(firstMandatoryOptions);
    return result;
  }

  customizationHasOption(productItem, customization) {
    const selectedOptions = this.getDefaultSelectedOptions(productItem);
    const customizationOptionIds = customization.options.map(cus => cus.id);
    const selectedFromCustomization = selectedOptions.filter(opt => customizationOptionIds.includes(opt.id));
    if (selectedFromCustomization && selectedFromCustomization.length > 0) return true;
    return false;
  }

  validateTimeLimits = () => {
    const { currentOrder } = this.props;

    if (!currentOrder) return true;
    const category = this.getCurrentCategory();

    if (!category) return true;
    // No need to continue evaluating if category doesn't have any time limits
    if (!category.timeLimits || category.timeLimits.length === 0) return true;

    // Evaluate multiple time limits
    const timeLimitEvaluations = [];
    category.timeLimits.forEach((timeLimit) => {
      timeLimitEvaluations.push(Functions.evaluateTimeLimit(timeLimit, currentOrder));
    });

    return timeLimitEvaluations.includes(true);
  }

  validateCustomizations = (validCustomizations) => {
    const invalid = Object.keys(validCustomizations)
      .find(customization => !validCustomizations[customization]);
    return invalid !== undefined;
  }

  disableButton = (validCustomizations) => {
    const { product, currentOrder } = this.props;
    // Disable button if customizations or time limits are not valid or product is out of stock
    const productOutOfStock = Functions.productIsOutOfStock(product, currentOrder.location.id);
    const invalidCustomizations = this.validateCustomizations(validCustomizations);
    const invalidTimeLimit = !this.validateTimeLimits();
    return (invalidCustomizations || invalidTimeLimit || productOutOfStock);
  }

  /**
   * Sort customizations according to param
   * @param {*} customizations
   * @param {*} param
   */
  sortCustomizations = (customizations, param) => {
    const mutableObj = Immutable.asMutable(customizations, { deep: true });
    return mutableObj.slice().sort(Functions.compareValues(param, 'asc'));
  };

  handleNoteChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  };

  handleClose = () => {
    this.props.handleClose();
  }

  handleChangeItemQuantity = (id, quantity) => {
    const { item } = this.state;
    this.setState({
      item: {
        ...item,
        quantity,
      },
    });
  }

  handleChangeCustomization = (selectedOptions, id, onChangeSize = false) => {
    const { product } = this.props;
    // If the product item is changed, reset customizations
    if ((selectedOptions && selectedOptions.length > 0) && (onChangeSize || selectedOptions[0].attribute_value)) {
      const productItem = product.items.find(item => item.id === selectedOptions[0].id);
      this.setState({
        item: {
          ...productItem,
          quantity: 1,
        },
        customizations: Functions.getIncludedCustomizations(productItem),
      });
      return;
    }
    const { customizations } = this.state;
    const customization = {
      id,
      options: selectedOptions,
    };
    const customizationIndex = customizations.findIndex(cust => cust.id === id);

    if (customizationIndex < 0) {
      // If customization does not exist in the state, add it
      this.setState({
        customizations: [...customizations, customization],
      });
    } else {
      // Update existing customization
      const updatedCustomizations = [...customizations];
      updatedCustomizations[customizationIndex] = customization;
      this.setState({
        customizations: updatedCustomizations,
      });
    }
  };

  updateCustomizationOpenState() {
    const { product } = this.props;
    const { item } = this.state;
    const openStateObj = {};

    const itemCustomizations = item ? item.customizations : [];
    itemCustomizations.forEach((customization) => {
      if (!customization.isDisabled) {
        openStateObj[customization.id] = false;
      }
    });

    // If product has multiple product items (i.e. attributes) add the product
    // id to the openStateObj
    if (product.items && product.items.length > 1) {
      openStateObj[product.id] = false;
    }

    this.setState({ customizationOpenStates: openStateObj });
  }

  disableCartButtonItem = (id, isValid, isItem) => {
    const { validCustomizations } = this.state;
    let itemID;
    isItem ? itemID = `item${id}` : itemID = id;
    this.setState({
      validCustomizations: {
        ...validCustomizations,
        [itemID]: isValid,
      },
    });
  }

  handleChangeOptionQuantity = (quantity, customizationId, optionId) => {
    const { customizations } = this.state;
    const newCustomizations = cloneDeep(customizations);
    const indexOfCustomization = newCustomizations
      .findIndex(customization => customization.id === customizationId);
    const indexOfOption = newCustomizations[indexOfCustomization].options
      .findIndex(option => option.id === optionId);

    newCustomizations[indexOfCustomization].options[indexOfOption].quantity
      = quantity;
    this.setState({
      customizations: newCustomizations,
    });
  }

  handleChangeSideSection = (section, customizationId, optionId) => {
    const { customizations } = this.state;
    const newCustomizations = cloneDeep(customizations);
    const indexOfCustomization = newCustomizations
      .findIndex(customization => customization.id === customizationId);
    const indexOfOption = newCustomizations[indexOfCustomization].options
      .findIndex(option => option.id === optionId);

    newCustomizations[indexOfCustomization].options[indexOfOption].section
      = section;
    this.setState({
      customizations: newCustomizations,
    });
  }

  handleClickAdd = async (orderItem, orderId, clickEvent = {}) => {
    const {
      actions, user, currentOrder, history, upsellId, company, sessionId,
    } = this.props;
    const orderItemPayload = upsellId ? { ...orderItem, upsellSourceId: upsellId } : orderItem;
    try {
      const cloudEvent = new ProductDialogATCEvent({
        userId: user && user.id,
        orderId,
        itemId: orderItemPayload && orderItemPayload.productItem && orderItemPayload.productItem.id,
        merchantId: company && company.id,
        sessionId,
        upsellId,
        ...getClickEventClientXY(clickEvent),
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken: user && user.token });
      await Functions.addToCart(actions, user, currentOrder, [orderItemPayload], orderId, history);
    } catch (error) {
      console.log('Error adding to cart', error);
    }
    this.handleClose();
  }

  getCloudEventParams = () => {
    const { item } = this.state;
    const {
      company, user, currentOrder, sessionId,
    } = this.props;

    return {
      userId: user && user.id,
      orderId: currentOrder && currentOrder.id,
      itemId: item && item.id,
      merchantId: company && company.id,
      sessionId,
    };
  }

  /**
  * Update the current order when "UPDATE CART" clicked
  * @param {*} orderItem the product item with customizations
  */
  handleClickUpdate = async (orderItem) => {
    const {
      actions, user, currentOrder,
    } = this.props;

    try {
      const orderItemResponse = await actions
        .updateOrderItem(user, orderItem, currentOrder.id);
      if (orderItemResponse && orderItemResponse.error) {
        return;
      }
    } catch (error) {
      console.log('API error', error);
    }
    this.handleClose();
  }

  renderProductDetails = () => {
    const {
      product, company, products,
    } = this.props;
    const { customizations, item, note } = this.state;
    const uppercaseProductName = FeatureFlags.ProductFlowDialog.useUppercaseProductName;
    const orderItemObj
    = this.getOrderItemObj(customizations, item, item && item.quantity, note);
    const price = orderItemObj
      ? Functions.calculatePrice([orderItemObj], products, null, company)
      : 0;

    return (
      <div className="productDetails">
        <div className="productNameAndPrice">
          <Typography className="productName">
            {uppercaseProductName ? product.name.toUpperCase() : product.name}
          </Typography>
          <Typography className="price">
            {`${Functions.getCurrencySymbol()}${price.toFixed(2)}`}
          </Typography>
        </div>
        <Typography className="productDescription">
          {product.description}
        </Typography>
        {this.renderCustomizationList()}
      </div>
    );
  }

  renderQuantityToggle = () => {
    const { item } = this.state;
    const { user } = this.props;
    const eventParams = this.getCloudEventParams();

    return (
      <div className="quantityContainer">
        <AddSubtractToggle
          id={1}
          quantity={item && item.quantity}
          containerStyle="addSubtractContainer"
          textStyle="addSubtractTypography"
          iconStyle="productFlowDialog-iconStyle"
          iconDisabledStyle="iconDisabledStyle"
          handleChange={this.handleChangeItemQuantity}
          useCircleIcons={false}
          trackEvents
          eventParams={eventParams}
          userToken={user && user.token}
        />
      </div>
    );
  }

  /**
   * Render list of currently selected customization options
   */
  renderCustomizationList = () => {
    const { customizations } = this.state;
    let formattedList;

    customizations.forEach((cust) => {
      const { options } = cust;
      options.map((option) => {
        formattedList = (formattedList !== undefined)
          ? `${formattedList}, ${option.name} x${option.quantity}`
          : `${option.name} x${option.quantity}`;
      });
    });

    if (customizations.length > 0) {
      return (
        <Typography className="customizationListText">
          {formattedList}
        </Typography>
      );
    }
  }

  renderProductItemSelector = (item) => {
    const { product, translation, currentOrder } = this.props;
    const {
      customizationOpenStates, isEdit, currentItem, customizations,
    } = this.state;
    return (
      <div>
        <CustomizationComponent
          id={product.id}
          title={item ? item.attribute_name : ''}
          description={this.getAttributeBoxSubtitle(translation)}
          options={product.items}
          maxOptions={1}
          minOptions={1}
          position={1}
          onCustomHandleChange={(selectedOptions, id, isItem) =>
            this.handleChangeCustomization(selectedOptions, id, isItem)}
          onCustomDisableCartButton={this.disableCartButtonItem}
          onHandleClickList={customizationId =>
            this.onHandleClickList(customizationId)}
          isEdit={isEdit}
          currentItem={currentItem}
          open={customizationOpenStates[product.id]}
          translation={translation}
          currentCustomizations={customizations}
          product={product}
          item={item}
          currentOrderLocationId={currentOrder && currentOrder.location ? currentOrder.location.id : null}
          productDialogAttribute
        />
      </div>
    );
  }

  renderCustomizations = (item, itemCustomizations) => {
    const { product, translation, currentOrder } = this.props;
    const { isEdit, currentItem, customizations } = this.state;
    return (
      this.sortCustomizations(itemCustomizations, 'position').map((customization, i) => {
        if (customization.isDisabled) {
          return false;
        }
        return (
          <CustomizationComponent
            key={customization.id}
            id={customization.id}
            title={customization.title || customization.name}
            description={customization.description}
            options={customization.options}
            freeOptions={customization.freeOptions}
            maxOptions={customization.maxOptions}
            minOptions={customization.minOptions}
            position={i + 1}
            onCustomHandleChange={(selectedOptions, id, isItem) =>
              this.handleChangeCustomization(selectedOptions, id, isItem)}
            onCustomDisableCartButton={this.disableCartButtonItem}
            onChangeOptionQuantity={(quantity, customizationId, optionId) =>
              this.handleChangeOptionQuantity(quantity, customizationId, optionId)}
            onHandleClickList={customizationId =>
              this.onHandleClickList(customizationId)}
            isEdit={isEdit}
            currentItem={currentItem}
            open
            translation={translation}
            useLeftRightOptions={customization.useLeftRightOptions}
            onChangeSideSection={(id, section, optionId) =>
              this.handleChangeSideSection(id, section, optionId)}
            currentCustomizations={customizations}
            product={product}
            item={item}
            currentOrderLocationId={currentOrder && currentOrder.location ? currentOrder.location.id : null}
            useOpenStyle
          />
        );
      })
    );
  }

  render() {
    const {
      open, dialogLoading,
    } = this.props;
    return (
      <DialogView
        dialogName={DIALOG_NAMES.PRODUCT_DIALOG}
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableEscapeKeyDown={false}
        dialogTitleStyle="productFlowDialog-dialogTitleStyle"
        subHeaderImageContainerStyle="subHeaderImageContainerStyle"
        subHeaderImageStyle="subHeaderImageStyle"
        titleHasCloseBtn
        hasHeaderImage
        hasDialogContent
        hasDialogContent2={false}
        hasDialogErrorContent={false}
        renderDialogContent={() => this.getDialogContent()}
        hasDialogActions={false}
        // TO-DO: remove inline styles
        dialogCloseIconColor="#000"
        dialogBodyContainerStyle="productFlowDialog-dialogBodyContainerStyle"
        dialogContentStyle="productFlowDialog-dialogContentStyle"
        loading={!!dialogLoading}
      />
    );
  }
}

ProductFlowDialog.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.object),
  product: PropTypes.objectOf(PropTypes.any),
  categories: PropTypes.arrayOf(PropTypes.object),
  currentOrder: PropTypes.objectOf(PropTypes.any),
  dialogLoading: PropTypes.number,
  portions: PropTypes.arrayOf(PropTypes.object),
  company: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.number,
  upsellId: PropTypes.string,
  isEditFromCheckout: PropTypes.bool,
  currentItemFromCheckout: PropTypes.objectOf(PropTypes.any),
  sessionId: PropTypes.string,
};

ProductFlowDialog.defaultProps = {
  user: null,
  products: null,
  product: null,
  categories: null,
  currentOrder: {},
  dialogLoading: 0,
  portions: [],
  company: {},
  loading: 0,
  upsellId: null,
  isEditFromCheckout: false,
  currentItemFromCheckout: {},
  sessionId: null,
};

const mapStateToProps = (state, props) => ({
  company: getCompany(state),
  currentOrder: getCurrentOrder(state),
  portions: getPortions(state),
  dialogLoading: getDialogLoading(state),
  products: getProducts(state),
  product: getDialogProductById(state, props),
  categories: getCategories(state),
  loading: getLoading(state),
  user: getUser(state).user,
  sessionId: getCloudEventSessionId(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withWidth()(ProductFlowDialog)));
