import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { number } from 'prop-types';

import '../../../css/core/components/ScrollToTop.scss';

function ScrollToTop(props) {
  const { showBelow } = props;
  const [show, setShow] = useState(!showBelow);

  const handleScroll = () => {
    // eslint-disable-next-line no-undef
    if (window.scrollY > showBelow) {
      if (!show) setShow(true);
    } else setShow(false);
  };

  useEffect(() => {
    if (showBelow) {
      // eslint-disable-next-line no-undef
      window.addEventListener('scroll', handleScroll);
      // eslint-disable-next-line no-undef
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return undefined;
  });

  const handleClick = () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    show
    && (
      <IconButton
        onClick={handleClick}
        className="scrollToTop-iconButton"
      >
        <ExpandLessIcon />
      </IconButton>
    )
  );
}

ScrollToTop.propTypes = {
  showBelow: number.isRequired,
};

export default ScrollToTop;
