import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import PageLoadEvent from '../Bounce/PageLoadEvent';
import PageExitEvent from '../Bounce/PageExitEvent';
import { PAGES_BY_ROUTES } from '../../services/api/CloudEvents/constants';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import { getUserToken } from '../../selectors';

export const getHistoryListenerCb = ({ prevPathnameRef, cloudEventBaseParamsRef, userTokenRef }) => (location) => {
  const newPathname = location.pathname;

  if (PAGES_BY_ROUTES[prevPathnameRef.current] === PAGES_BY_ROUTES[newPathname]) return;

  const userToken = userTokenRef.current;

  const pageExitEvent = new PageExitEvent({
    ...cloudEventBaseParamsRef.current,
    route: prevPathnameRef.current,
  });
  CloudEventsApi.sendCloudEvent({ cloudEvent: pageExitEvent, userToken });

  const nextPageLoadEvent = new PageLoadEvent({
    ...cloudEventBaseParamsRef.current,
    route: newPathname,
  });
  CloudEventsApi.sendCloudEvent({ cloudEvent: nextPageLoadEvent, userToken });
};


const usePageLoadAndExitEvents = () => {
  const [history, setHistory] = useState(null);

  const upliftHistory = useCallback((newHistory) => {
    if (!history) setHistory(newHistory);
  }, [history]);

  const cloudEventBaseParams = useCloudEventsBaseParams();
  const cloudEventBaseParamsRef = useRef(cloudEventBaseParams);

  const currentPathname = history && history.location && history.location.pathname;
  const prevPathnameRef = useRef(currentPathname);

  const userToken = useSelector(getUserToken);
  // Give a ref to the history.listen callback function, as it serves as a stable reference
  // to the latest userToken value without needing to set up a new callback in history.listen.
  // Just remember to also update the ref.current value when the userToken changes.
  const userTokenRef = useRef(userToken);

  useEffect(() => {
    if (!history) return;

    // The first time history starts existing is on first page load
    const pageLoadEvent = new PageLoadEvent({
      ...cloudEventBaseParamsRef.current,
      route: history && history.location && history.location.pathname,
    });
    CloudEventsApi.sendCloudEvent({ cloudEvent: pageLoadEvent });

    const unlisten = history.listen(getHistoryListenerCb({ prevPathnameRef, cloudEventBaseParamsRef, userTokenRef }));

    // Needs to return early if history does not exist
    // eslint-disable-next-line consistent-return
    return () => { unlisten(); };
  }, [history]);

  // Update this value once history starts existing, and afterward on page navs
  useEffect(() => {
    prevPathnameRef.current = currentPathname;
  }, [currentPathname]);

  useEffect(() => {
    userTokenRef.current = userToken;
  }, [userToken]);

  useEffect(() => {
    cloudEventBaseParamsRef.current = cloudEventBaseParams;
  }, [cloudEventBaseParams]);

  return { upliftHistory };
};

export default usePageLoadAndExitEvents;
