import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import CoreView from '../CoreView';

const CoreRoute = (props) => {
  const { coreview, render, ...rest } = props;
  const [componentToRender, setComponentToRender] = useState(render());

  useEffect(() => {
    setComponentToRender(render());
  }, [render]);

  return (
    <Route
      {...rest}
      render={() => (
        <CoreView
          actions={coreview.actions}
          user={coreview.user}
          translation={coreview.translation}
          rememberUser={coreview.rememberUser}
          currentOrder={coreview.currentOrder}
          locations={coreview.locations}
        >
          {componentToRender}
        </CoreView>
      )}
    />
  );
};

CoreRoute.propTypes = {
  user: PropTypes.object,
  render: PropTypes.func.isRequired,
  coreview: PropTypes.object,
};

CoreRoute.defaultProps = {
  user: null,
  coreview: {
    actions: null,
    user: null,
    resources: null,
    users: null,
    rememberUser: false,
    currentOrder: null,
  },
};

export default CoreRoute;
