import handleResponse from '../ResponseHandler';

// eslint-disable-next-line no-undef
const companyApiKey = window.companyApiKey;
// eslint-disable-next-line no-undef
const baseUrl = window.api_host;
const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${companyApiKey}`,
};

export function generateHeader(token) {
  const apiHeader = {};
  apiHeader.Authorization = `Bearer ${token}`;
  apiHeader['Content-Type'] = 'application/json';
  return apiHeader;
}

export function generatePath(resourcePath) {
  return resourcePath.join('/');
}

export default class UsersApi {
  static loginUser(userData) {
    const path = `${baseUrl}users/login`;
    const requestBody = JSON.stringify(userData);
    const request = (
      new Request(path, {
        method: 'POST',
        headers,
        body: requestBody,
      })
    );
    return handleResponse(request, requestBody);
  }
  static signUpUser(userData) {
    const path = `${baseUrl}users`;
    userData['source'] = 'WEB';
    const requestBody = JSON.stringify(userData);
    const request = (
      new Request(path, {
        method: 'POST',
        headers,
        body: requestBody,
      })
    );
    return handleResponse(request, requestBody);
  }

  static getUser(apiToken, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  }
}
