/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getLoading } from '../../../selectors';
import Button from '../../core/components/Button';
import '../../../css/paymentPage/subComponents/BamboraPaymentComponent.scss';

// Listen to 'brand' events
const brandEventListener = (customCheckout) => {
  customCheckout.on('brand', (event) => {
    let cardLogo = 'none';
    if (event.brand && event.brand !== 'unknown') {
      const filePath = `https://cdn.na.bambora.com/downloads/images/cards/${event.brand}.svg`;
      cardLogo = `url(${filePath})`;
    }
    document.getElementById('card-number').style.backgroundImage = cardLogo;
    document.getElementById('card-type').value = event.brand;
  });
};

const initializeCustomCheckout = (customCheckout) => {
  if (!customCheckout) return;
  const options = {
    classes: {
      error: 'bambora-input-error',
      base: 'bambora-input-base',
      complete: 'bambora-input-complete',
    },
  };
  customCheckout.create('card-number', { ...options, placeholder: 'Card Number' }).mount('#card-number');
  customCheckout.create('cvv', { ...options, placeholder: 'CCV' }).mount('#card-cvv');
  customCheckout.create('expiry', { ...options, placeholder: 'MM/YY' }).mount('#card-expiry');
  brandEventListener(customCheckout);
};


const updateStateReducer = (prevState, nextState) => {
  const newState = { ...prevState, ...nextState };
  return newState;
};

const initialState = {
  customCheckout: null,
  cardHolderName: null,
  validationFeedback: null,
  validCardNumber: false,
  validExpiry: false,
  validCvv: false,
};

const BamboraPaymentComponent = ({
  user, translation, actions, loading,
}) => {
  const [state, updateState] = useReducer(updateStateReducer, initialState);

  useEffect(() => {
    if (window.customcheckout) updateState({ customCheckout: window.customcheckout() });
  }, []);

  const validationListener = () => {
    state.customCheckout.on('error', (event) => {
      switch (event.field) {
        case 'card-number':
          updateState({ validCardNumber: false });
          break;
        case 'cvv':
          updateState({ validCvv: false });
          break;
        case 'expiry':
          updateState({ validExpiry: false });
          break;
        default:
          break;
      }
      updateState({ validationFeedback: event.message });
    });
  };

  const completionListener = () => {
    state.customCheckout.on('complete', (event) => {
      switch (event.field) {
        case 'card-number':
          updateState({ validCardNumber: true });
          break;
        case 'cvv':
          updateState({ validCvv: true });
          break;
        case 'expiry':
          updateState({ validExpiry: true });
          break;
        default:
          break;
      }
      updateState({ validationFeedback: null });
    });
  };

  const areAllFieldsValid = () => {
    const { validCardNumber, validCvv, validExpiry } = state;
    return validCardNumber && validCvv && validExpiry;
  };

  useEffect(() => {
    initializeCustomCheckout(state.customCheckout);
    if (state.customCheckout) validationListener();
    if (state.customCheckout) completionListener();
  }, [state.customCheckout]);

  // When the token is successfully processed, the card will be created in database
  const processTokenSuccess = async (result) => {
    const {
      token, last4, expiryMonth, expiryYear,
    } = result;

    const cardType = document.getElementById('card-type').value;
    const paymentMethod = {
      cardNumber: last4,
      expiryMonth,
      expiryYear,
      name: state.cardHolderName,
      cardType,
      keyId: token,
    };

    await actions.addResource(
      user.token, paymentMethod,
      'users', user.id,
      'payment_options',
    );
  };

  const processTokenError = (error) => {
    console.log(JSON.stringify(error, undefined, 2));
  };

  const onSubmit = () => {
    if (!state.customCheckout) return;

    if (state.cardHolderName) {
      const callback = (result) => {
        if (result.error) {
          processTokenError(result.error);
        } else {
          processTokenSuccess(result);
        }
      };
      state.customCheckout.createToken(callback);
    }
  };

  const feedbackClass = state.validationFeedback ? 'error' : 'no-feedback';

  return (
    <div className="bambora-container">
      <form id="checkout-form">
        <div id="billing-name">
          <input
            id="name-input"
            onChange={event => updateState({ cardHolderName: event.target.value })}
            className="bambora-name-input"
            type="text"
            placeholder="Name"
          />
        </div>
        <label htmlFor="billing-name" id="name-input-error" />

        <div id="card-number" />
        <label htmlFor="card-number" id="card-number-error" />

        <div className="craverCreditCardForm-dividedColumn">
          <div id="card-expiry" />
          <label htmlFor="card-expiry" id="card-expiry-error" />
          <div id="card-cvv" />
          <label htmlFor="card-cvv" id="card-cvv-error" />
        </div>

        <div id="feedback" className={feedbackClass} >
          {state.validationFeedback}
        </div>
        <Button
          id="add-button"
          disabled={!areAllFieldsValid() && loading !== 0}
          type="primary"
          onClick={onSubmit}
          text={translation('save')}
        />
        <input id="card-type" type="hidden" />
      </form>
    </div>
  );
};

BamboraPaymentComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  loading: getLoading(state),
});

export default connect(mapStateToProps)(BamboraPaymentComponent);
