import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import '../../../css/product/subComponents/InstructionsComponent.scss';

const InstructionsComponent = (props) => {
  const {
    handleOnChange,
    translation,
    dialogView,
    note,
  } = props;


  return (
    <div className={dialogView ? 'instructionsComponent-dialogContent' : 'instructionsComponent-content'}>
      <div className={dialogView ? 'dialogInstructionContainer' : 'instructionContainer'}>
        <Typography className="instructionTitle">
          {translation('InstructionsComponent.instructionTitle')}
        </Typography>
        <Typography className="instructionDescription">
          {translation('InstructionsComponent.instructionDescription')}
        </Typography>
      </div>
      <div className={dialogView ? 'dialogTextFieldContainer' : 'instructionsComponent-textFieldContainer'}>
        <TextField
          onChange={handleOnChange}
          multiline
          minRows={4}
          className="instructionsComponent-textFieldStyle"
          InputProps={{ className: 'instructionsComponent-inputMultilineStyle', disableUnderline: true }}
          value={note}
        />
      </div>
    </div>
  );
};

InstructionsComponent.propTypes = {
  dialogView: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  note: PropTypes.string,
};

InstructionsComponent.defaultProps = {
  note: '',
};

export default (InstructionsComponent);
