import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import '../../../css/core/components/PageTitle.scss';

const PageTitle = (props) => {
  const { containerWidth, title } = props;

  return (
    <div className="pageTitle-container" style={{ width: containerWidth }}>
      <div className="rectangular" />
      <Typography className="headerText">
        {title}
      </Typography>
      <div className="rectangular" />
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  containerWidth: PropTypes.string,
};

PageTitle.defaultProps = {
  containerWidth: null,
};

export default (withWidth()(PageTitle));
