import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import '../../../css/core/components/RedirectLinkComponent.scss';

const RedirectLinkComponent = (props) => {
  const {
    href,
    linkText,
    textClassName,
  } = props;

  const linkClassName = textClassName || 'redirectLinkComponent-linkText';

  return (
    <Link href={href} to={href} className="redirectLinkComponent-redirectLink">
      <Typography className={linkClassName}>
        &#8592; {linkText}
      </Typography>
    </Link>
  );
};

RedirectLinkComponent.propTypes = {
  textClassName: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

RedirectLinkComponent.defaultProps = {
  textClassName: null,
};

export default RedirectLinkComponent;

