/**
 * See https://javascript.info/size-and-scroll-window#summary
 * https://javascript.info/size-and-scroll-window#width-height-of-the-document
 */

const isPositiveNumberNotInfinity = height => Number.isFinite(height) && height > 0;

const getScrollHeight = () => {
  const possibleScrollHeights = [
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight,
  ];

  const filteredForValidNumbers = possibleScrollHeights.filter(isPositiveNumberNotInfinity);
  return Math.max(...filteredForValidNumbers);
};

const getScrollWidth = () => {
  const possibleScrollWidths = [
    document.body.scrollWidth, document.documentElement.scrollWidth,
    document.body.offsetWidth, document.documentElement.offsetWidth,
    document.body.clientWidth, document.documentElement.clientWidth,
  ];

  const filteredForValidNumbers = possibleScrollWidths.filter(isPositiveNumberNotInfinity);
  return Math.max(...filteredForValidNumbers);
};

const getPageHeightWidth = () => ({
  totalPageX: getScrollWidth(),
  totalPageY: getScrollHeight(),
});

export default getPageHeightWidth;
