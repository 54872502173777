import cloneDeep from 'lodash/cloneDeep';
import { DEVICE_TYPES } from './constants';

export default class CloudEvent {
  id;
  source;
  type;
  specversion;
  time;
  subject;
  data = {};

  constructor(source, type, subject, idSeam) {
    this.id = idSeam || self.crypto.randomUUID();
    this.source = source;
    this.type = type;
    this.specversion = '1.0';
    this.subject = subject;
  }

  // eslint-disable-next-line class-methods-use-this
  getDeviceType() {
    if (!window || !window.navigator || !window.navigator.userAgent) return '';
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
    if (isMobile) return DEVICE_TYPES.MOBILEWEB;
    return DEVICE_TYPES.DESKTOP;
  }

  setData(data = {}) {
    const newData = cloneDeep(data);
    newData.deviceType = this.getDeviceType();
    this.data = newData;
  }

  stamp(dateSeam = new Date()) {
    this.time = dateSeam.toISOString();
  }
}
