export const DIALOG_CONSTANTS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  VERIFY: 'verify',
  RESET_PASSWORD: 'reset',
  GUEST_SIGN_IN: 'guestSignIn',
  REWARD: 'rewards',
};

export const DIALOG_EVENT_SOURCES = {
  CLOSE_ICON: 'closeIcon',
  SIGNUP_SUCCESS: 'signUpSuccess',
};
