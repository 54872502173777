import { PAGES_BY_ROUTES } from './constants';

export default function getPageByRoute(route) {
  if (!route) return '';
  const notificationRegExp = /\/notifications\//g;
  const singleNotificationRoute = '/notifications/';
  const routeArr = [...route.matchAll(notificationRegExp)];

  if (routeArr.join('') === singleNotificationRoute) return route.substring(1);
  return PAGES_BY_ROUTES[route] || '';
}

export const getPage = ({ route, componentNameAsPage }) => componentNameAsPage || getPageByRoute(route);
