export default function openInNewTab(url) {
  const linkToOpenNewTab = document.createElement('a');
  linkToOpenNewTab.href = url;
  // open Manage Subscription session on a new tab
  linkToOpenNewTab.target = '_blank';
  document.body.appendChild(linkToOpenNewTab);
  // setTimeout is required here to allow iOS Safari to open link in new tab
  setTimeout(() => {
    linkToOpenNewTab.click();
  }, 1);
  document.body.removeChild(linkToOpenNewTab);
}
