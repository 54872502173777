import localStyleVariablesJSON from '../css/styleVariables.json';

export const mergeLocalAndRemote = (remoteStyles) => {
  Object.keys(localStyleVariablesJSON).forEach((k) => {
    if (remoteStyles[k]) {
      localStyleVariablesJSON[k] = remoteStyles[k];
    }
  });
  return localStyleVariablesJSON;
};

const combinedMUIStyles = (remoteStyles) => {
  const styleVariablesJSON = mergeLocalAndRemote(remoteStyles);
  return {
    palette: {
      type: styleVariablesJSON['palette-mode'],
      primary: {
        light: '#323232',
        main: styleVariablesJSON['primary-color'],
        dark: '#191919',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#e8484e',
        main: '#90A022',
        dark: '#cc181f',
        contrastText: '#fff',
      },
      tertiary: {
        light: '#eeeeee',
        main: '#e7e7e7',
        dark: '#cfcfcf',
      },
    },
    typography: {
      fontFamily: `${styleVariablesJSON['regular-font-family']}, sans-serif`,
      md: {
        fontSize: '1rem',
      },
      body1: {
        md: {
          fontSize: '0.9rem',
        },
      },
      title: {
        fontSize: '40px',
        fontWeight: 300,
      },
      caption: {
        md: {
          fontSize: '0.65rem',
        },
      },
    },
    overrides: {
      MuiButton: {
        root: {
          fontFamily: `${styleVariablesJSON['regular-font-family']}, sans-serif`,
          height: '40px',
          width: 'auto',
          fontSize: '14px',
          borderRadius: '2px',
          md: {
            fontSize: '0.9rem',
            height: '5vh',
          },
          color: styleVariablesJSON['primary-button-text-color'],
          disabled: {
            fontFamily: styleVariablesJSON['disabled-button-font-family'],
            borderColor: styleVariablesJSON['disabled-button-background-color'],
            backgroundColor: styleVariablesJSON['disabled-button-background-color'],
            color: styleVariablesJSON['disabled-button-text-color'],
          },
        },
        contained: {
          boxShadow: '0px 1px 2px -2px rgb(0 0 0 / 20%)',
        },
        textPrimary: {
          color: styleVariablesJSON.text,
        },
      },
      MuiDialogContent: {
        root: {
          flex: '1 1 auto',
          overflowY: 'visible',
        },
      },
      MuiTabs: {
        indicator: {
          display: 'none',
        },
      },
      MuiTab: {
        root: {
          minWidth: 'unset !important',
          fontSize: '0.8rem',
          fontFamily: styleVariablesJSON['header-unselected-font-family'],
        },
        textColorPrimary: {
          color: styleVariablesJSON['unselected-tab-line'],
        },
        textColorSecondary: {
          color: styleVariablesJSON['selected-tab-line'],
        },
      },
      MuiTabScrollButton: {
        root: {
          display: 'flex !important',
          color: styleVariablesJSON['unselected-tab-line'],
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none',
        },
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          backgroundColor: 'transparent',
        },
      },
      MuiMenu: {
        paper: {
          backgroundColor: styleVariablesJSON['paper-background-color'],
        },
      },
      MuiNotchedOutline: {
        root: {
          borderStyle: 'none',
          borderBottom: '1px solid #666666',
          borderRadius: '0',
        },
      },
      MuiDialog: {
        paperScrollPaper: {
          backgroundColor: styleVariablesJSON['modal-background'],
        },
        paper: {
          margin: '16px',
          'overflow-x': 'hidden',
        },
      },
      MuiList: {
        root: {
          backgroundColor: styleVariablesJSON['modal-background'],
        },
        padding: {
          paddingLeft: '1vw',
        },
      },
      MuiListItem: {
        root: {
          margin: '2vh 0',
          color: styleVariablesJSON['input-text-color'],
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '36px',
        },
      },
      MuiListItemText: {
        primary: {
          color: styleVariablesJSON.text,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: styleVariablesJSON['box-background'],
        },
      },
      MuiTypography: {
        body1: {
          color: styleVariablesJSON.text,
        },
      },
      MuiIcon: {
        root: {
          md: {
            fontSize: '1.5rem',
          },
          color: 'inherit',
        },
      },
      MuiIconButton: {
        root: {
          color: styleVariablesJSON.text,
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: styleVariablesJSON['icon-button-hover-color'],
          },
        },
        label: {
          color: 'unset',
        },
      },
      MuiRadio: {
        root: {
          color: styleVariablesJSON['unselected-checkbox'],
        },
        colorSecondary: {
          color: styleVariablesJSON['unselected-checkbox'],
          '&$checked': {
            color: styleVariablesJSON['selected-checkbox'],
          },
        },
      },
      MuiSvgIcon: {
        root: {
          md: {
            fontSize: '1rem',
          },
        },
      },
      MuiCardHeader: {
        root: {
          padding: '10px',
        },
        md: {
          padding: '4px',
        },
      },
      MuiCheckbox: {
        root: {
          color: styleVariablesJSON['unselected-checkbox'],
        },
        colorSecondary: {
          color: styleVariablesJSON['unselected-checkbox'],
          '&$checked': {
            color: styleVariablesJSON['selected-checkbox'],
          },
          '&.Mui-disabled': {
            color: styleVariablesJSON['unselected-checkbox'],
          },
        },
      },
      MuiInputLabel: {
        formControl: {
          color: styleVariablesJSON['input-text-color'],
        },
        outlined: {
          transform: 'translate(10px, 20px) scale(0.8)',
        },
      },
      MuiInputBase: {
        root: {
          color: styleVariablesJSON['input-text-color'],
          backgroundColor: styleVariablesJSON['input-background-color'],
          'Mui-disabled': {
            color: styleVariablesJSON['disabled-text-color'],
          },
        },
        input: {
          fontFamily: styleVariablesJSON['input-font-family'],
          fontSize: '16px',
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: styleVariablesJSON['input-background-color'],
          color: styleVariablesJSON['input-text-color'],
          '& fieldset': { borderColor: styleVariablesJSON['input-border-color'] },
          borderRadius: styleVariablesJSON['input-border-radius'],
        },
      },
      MuiTableCell: {
        body: {
          borderColor: styleVariablesJSON.text,
          color: styleVariablesJSON.text,
        },
      },
      MuiBadge: {
        colorSecondary: {
          fontFamily: styleVariablesJSON['badge-font-family'],
          color: styleVariablesJSON['badge-text'],
          backgroundColor: styleVariablesJSON['badge-bg'],
        },
        anchorOriginTopRightRectangle: {
          right: '8px',
        },
      },
      MuiFormHelperText: {
        contained: {
          color: styleVariablesJSON.text,
        },
      },
      MuiFormControlLabel: {
        label: {
          'Mui-disabled': {
            color: styleVariablesJSON['unselected-checkbox'],
          },
        },
      },
      PrivateTabScrollButton: {
        root: {
          color: styleVariablesJSON.text,
        },
      },
      PrivateTabIndicator: {
        colorSecondary: {
          backgroundColor: styleVariablesJSON['primary-color'],
        },
      },
      MuiStepLabel: {
        completed: {
          color: styleVariablesJSON['primary-color'],
        },
        active: {
          color: styleVariablesJSON['primary-color'],
        },
        color: styleVariablesJSON['unselected-checkbox'],
        fontSize: '0.5rem',
      },
      MuiStepConnector: {
        completed: {
          backgroundColor: styleVariablesJSON['primary-color'],
          height: '1.5px',
        },
        active: {
          backgroundColor: styleVariablesJSON['primary-color'],
          height: '1.5px',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#d0d0d0',
        },
      },
      MuiSelect: {
        icon: {
          color: styleVariablesJSON['input-text-color'],
        },
      },
    },
  };
};

const muiTheme = remoteStyles => combinedMUIStyles(remoteStyles);

export default muiTheme;
