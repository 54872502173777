import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Grid, Typography, Card, CardContent, Icon } from '@material-ui/core';

import Button from '../../core/components/Button';
import MapComponent from './MapComponent';
import HoursComponent from './HoursComponent';
import * as Functions from '../../../services/functions/Functions';
import * as Routes from '../../../services/routes/Routes.json';
import { getCurrentOrder } from '../../../selectors';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import '../../../css/locationsPage/subComponents/LocationComponent.scss';

// Temp styles, will be removed in the future.
const mapContainerStyle = {
  height: '250px',
  position: 'relative',
};

const mapStyle = {
  width: 'auto',
  height: '100%',
};

class LocationComponent extends Component {
  handleClickOrderHere = () => {
    const { actions, currentOrder, location } = this.props;
    const orderObj = {};
    // Set location
    orderObj.location = location;
    // Create Order
    if (!isEmpty(orderObj)) {
      actions.createOrder(null, {
        ...currentOrder,
        ...orderObj,
      });
    }
    // Redirect to menu page
    this.props.history.push(Routes.path.menuPage);
  }

  render() {
    const {
      location,
      translation,
      history,
    } = this.props;
    const locationName = FeatureFlags.LocationsComponent.useUppercaseLocationName ? location.name.toUpperCase() : location.name;
    const infoGrid = [
      { icon: null, info: locationName, infoFormat: 'locationComponent-locationName' },
      { icon: 'place_icon', info: Functions.parseAddress(location.address), infoFormat: 'locationComponent-locationText' },
      { icon: 'local_phone', info: location.phone, infoFormat: 'locationComponent-locationText' },
    ];
    // Inclusion of the radix "10" per ESLint rule for ES6
    // TO-DO: Remove inline styles
    return (
      <Card className="locationComponent-card" id={`locationComponent${location.id}`}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MapComponent
                location={location}
                translation={translation}
                history={history}
                containerStyle={mapContainerStyle}
                mapStyle={mapStyle}
              />
            </Grid>
            {
              infoGrid.map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid container spacing={0} key={i}>
                  {
                    x.icon
                      && (
                        <Grid className="iconGrid" item xs={2}>
                          <Icon className="locationComponent-icon" color="disabled">{x.icon}</Icon>
                        </Grid>
                      )
                  }
                  <Grid item xs={x.icon ? 10 : 12}>
                    <Typography className={x.infoFormat}>{x.info}</Typography>
                  </Grid>
                </Grid>
              ))
            }
            <Grid container spacing={0} key="hoursGrid">
              <Grid className="iconGrid" item xs={2}>
                <Icon className="locationComponent-icon" color="disabled">access_alarm</Icon>
              </Grid>
              <Grid item xs={10}>
                <HoursComponent
                  hours={location.hours}
                  translation={translation}
                  textcolorOverride="var(--text)"
                  location={location}
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="primary"
            onClick={this.handleClickOrderHere}
            fullWidth
            text={translation('LocationComponent.orderHere')}
          />
        </CardContent>
      </Card>
    );
  }
}

LocationComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
};

LocationComponent.defaultProps = {
  currentOrder: {},
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default (connect(mapStateToProps)(LocationComponent));
