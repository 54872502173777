import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import withWidth from '@material-ui/core/withWidth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as Functions from '../../../services/functions/Functions';
import DialogView from '../../core/components/DialogView';
import Logo from '../../../assets/images/primary_logo.svg';
import * as Selectors from '../../../selectors/index';

import '../../../css/checkout/subComponents/InvalidOrderItemsDialog.scss';
import { DIALOG_NAMES } from '../../../services/api/CloudEvents/constants';

const renderDialogContent = dialogContentProps => () => {
  const {
    invalidatedOrderItems,
    translation,
    deliveryOption,
    itemIdToWithProductNameMap,
  } = dialogContentProps;

  const invalidatedItemNames = Object.keys(invalidatedOrderItems).map((orderItemId) => {
    const { itemId, name } = invalidatedOrderItems[orderItemId];
    const descriptiveName = Functions.getDescriptiveItemName(itemId, itemIdToWithProductNameMap, name);
    return <Typography className="invalidOrderDialog-itemName">{descriptiveName}</Typography>;
  });

  const mainBody = translation('MenuPage.deliveryOptionInvalidProduct');
  const errorMessage = `${mainBody} ${deliveryOption}.`;


  return (
    <div className="invalidOrderDialog-dialogContentContainer">
      <div className="invalidOrderDialog-topMessageContaine">
        <Typography className="invalidOrderDialog-deliveryOptionInvalidProductTitle">
          {errorMessage}
        </Typography>
      </div>
      <div className="invalidOrderDialog-outerCenteringFixedHeightDiv">
        <div className="invalidOrderDialog-itemNameScrollMaxHeightDiv">
          {invalidatedItemNames}
        </div>
      </div>
    </div>
  );
};

const renderActionButtons = actionButtonProps => () => {
  const {
    translation,
    navigateBack,
    handleChange,
  } = actionButtonProps;

  return (
    <div className="invalidOrderDialog-actionButtonContainer">
      <Button
        className="invalidOrderDialog-backButton"
        onClick={navigateBack}
      >
        <ArrowBackIcon className="invalidOrderDialog-buttonIcon" />
        {translation('BACK')}
      </Button>
      <Button
        className="invalidOrderDialog-nextButton"
        onClick={handleChange}
      >
        {translation('REMOVE')}
        <ArrowForwardIcon className="invalidOrderDialog-buttonIcon" />
      </Button>
    </div>
  );
};

const InvalidOrderItemsDialog = (props) => {
  const {
    invalidatedOrderItems,
    deliveryOption,
    open,
    onCloseDialog,
    translation,
    handleChange,
    dialogLoading,
    navigateBack,
    width,
    itemIdToWithProductNameMap,
  } = props;

  const commonProps = { translation, width };
  const dialogContentProps = {
    ...commonProps,
    invalidatedOrderItems,
    deliveryOption,
    itemIdToWithProductNameMap,
  };
  const actionButtonProps = { ...commonProps, navigateBack, handleChange };

  return (
    <DialogView
      dialogName={DIALOG_NAMES.INVALID_ORDER_ITEMS}
      open={open}
      titleAlignClose={false}
      handleClose={onCloseDialog}
      disableEscapeKeyDown={false}
      subHeaderImageContainerStyle="invalidOrderDialog-subHeaderImageContainerStyle"
      subHeaderImageStyle="invalidOrderDialog-subHeaderImageStyle"
      titleHasCloseBtn
      dialogTitleSubheaderImage={Logo}
      dialogTitleSubheaderImageAlt="invalidOrderDialog-dialogTitleSubheaderImageAlt"
      hasDialogContent
      hasDialogContent2={false}
      hasDialogErrorContent={false}
      renderDialogContent={renderDialogContent(dialogContentProps)}
      hasDialogActions
      renderActionBtn={renderActionButtons(actionButtonProps)}
      dialogCloseIconColor="var(--text)"
      dialogBodyContainerStyle="invalidOrderDialog-bodyContainer"
      dialogContentStyle="invalidOrderDialog-dialogContentStyle"
      dialogTitleStyleVariant="h2"
      loading={!!dialogLoading}
    />
  );
};

InvalidOrderItemsDialog.propTypes = {
  invalidatedOrderItems: PropTypes.objectOf(PropTypes.object),
  deliveryOption: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
  translation: PropTypes.func,
  handleChange: PropTypes.func,
  dialogLoading: PropTypes.number,
  navigateBack: PropTypes.func,
  width: PropTypes.string.isRequired,
  itemIdToWithProductNameMap: PropTypes.objectOf(PropTypes.string),
};

InvalidOrderItemsDialog.defaultProps = {
  invalidatedOrderItems: [],
  onCloseDialog: null,
  translation: null,
  dialogLoading: 0,
  navigateBack: null,
  handleChange: null,
  itemIdToWithProductNameMap: null,
};

const mapStateToProps = state => ({ itemIdToWithProductNameMap: Selectors.getitemIdToWithProductNameMap(state) });

export default connect(mapStateToProps)(withWidth()(InvalidOrderItemsDialog));

