import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import '../../../css/core/components/TextBoxComponent.scss';

const TextBoxComponent = ({
  selectedValue, handleChange, field, title, fieldKey, fieldType, editable, multiline, rows, fieldAutoFocus,
}) => {
  const [textInput, setTextInput] = useState(selectedValue);

  const onTextFieldChange = (event) => {
    setTextInput(event.target.value);
  };

  useEffect(() => {
    handleChange(field, textInput);
  }, [textInput]);

  return (
    <TextField
      key={fieldKey}
      value={textInput}
      label={title}
      placeholder={title}
      onChange={onTextFieldChange}
      className="textBoxComponent-textFieldStyle"
      variant="outlined"
      FormHelperTextProps={{ className: 'textBoxComponent-textFieldHelperText' }}
      id={fieldKey}
      autoFocus={fieldAutoFocus}
      multiline={multiline}
      minRows={rows}
      type={fieldType}
      disabled={!editable}
    />
  );
};

TextBoxComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  fieldType: PropTypes.string,
  selectedValue: PropTypes.string,
  editable: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  fieldAutoFocus: PropTypes.bool,
};

TextBoxComponent.defaultProps = {
  fieldType: 'string',
  selectedValue: '',
  editable: true,
  multiline: false,
  rows: '5',
  fieldAutoFocus: true,
};

export default (TextBoxComponent);
