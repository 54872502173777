/* eslint-disable max-len */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinearProgress, Typography, TextField } from '@material-ui/core';
import get from 'lodash/get';

import { getUser } from '../../selectors';
import Button from '../core/components/Button';
import '../../css/settingsPage/SettingsPage.scss';
import * as Routes from '../../services/routes/Routes.json';

const userFormFields = [
  {
    key: 'userFirstName',
  },
  {
    key: 'userLastName',
  },
  {
    key: 'userPhone',
    disabled: true,
  },
  {
    key: 'userEmail',
  },
];

const initialState = user => ({
  userFirstName: user ? user.name.split(' ')[0] : '',
  userLastName: user ? user.name.split(' ')[1] : '',
  userEmail: user ? user.email : '',
  userPhone: user ? user.telephone : '',
  userPasswordConfirm: '',
  passwordConfirmError: false,
});


const updateStateReducer = (prevState, nextState) => {
  const newState = { ...prevState, ...nextState };
  // Run some validations here ...
  return newState;
};

const SettingsPage = ({
  loading, translation, actions, history, upliftHistory,
}) => {
  const user = get(useSelector(getUser), 'user');
  const [state, updateState] = useReducer(updateStateReducer, initialState(user));

  useEffect(() => {
    if (!user || user.guestToken) {
      // Redirect not logged in or guest users to menu page
      updateState(initialState(null));
      history.push(Routes.path.menuPage);
    }
  }, [user]);

  useEffect(() => {
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);
  }, []);

  const handleChange = (event, key) => {
    updateState({ [key]: event.target.value });
  };

  const handleUpdateUser = async (newUserObj) => {
    if (user && newUserObj) {
      try {
        await actions.updateUser(user.token, newUserObj, ['users', user.id]);
      } catch (error) {
        console.log('Api call error', error);
      }
    }
  };

  const handleUserSubmit = () => {
    const {
      userFirstName,
      userLastName,
      userEmail,
      userPhone,
    } = state;
    const newUserObj = {
      name: `${userFirstName} ${userLastName}`,
      email: userEmail,
      // We have to send the password - in the backend we have a NotNull constraint which we might want to remove
      password: '',
      telephone: userPhone,
    };
    handleUpdateUser(newUserObj);
  };

  const handlePasswordSubmit = () => {
    const {
      userPassword,
      userPasswordConfirm,
    } = state;
    if (userPassword !== userPasswordConfirm) {
      updateState({ passwordConfirmError: true });
    } else {
      const newUserObj = {
        name: user.name,
        email: user.email,
        password: userPassword,
        telephone: user.telephone,
      };
      handleUpdateUser(newUserObj);
      updateState({ passwordConfirmError: false });
    }
  };

  return (
    loading !== 0
      ? <LinearProgress />
      : (
        <div className="settingsPage-pageContent">
          <div className="settingsPage-body">
            <div className="profileSection">
              <Typography className="settingsPage-sectionTitle">
                {translation('SettingsPage.componentTitles.userProfile')}
              </Typography>
              <div className="settingsPage-formContainer">
                <form className="userProfileForm" noValidate autoComplete="off">
                  {
                    userFormFields.map(field =>
                      (<TextField
                        key={field.key}
                        id={`outlined-${field.key}`}
                        placeholder={translation(`SettingsPage.formFields.${field.key}`)} // COMMENT this line and un-comment `label` line to use label instead of placeholder
                        className="settingsPage-textField"
                        value={state[field.key]}
                        onChange={event => handleChange(event, field.key)}
                        margin="normal"
                        variant="outlined"
                        disabled={field.disabled}
                      />))
                  }
                  <div className="settingsPage-buttonContainer">
                    <Button
                      onClick={handleUserSubmit}
                      type="primary"
                      text={translation('SettingsPage.formFields.submitProfile')}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="passwordSection">
              <Typography className="settingsPage-sectionTitle">
                {translation('SettingsPage.componentTitles.changePassword')}
              </Typography>
              <div className="settingsPage-formContainer">
                <form className="userProfileForm" noValidate autoComplete="off">
                  <TextField
                    id="outlined-password-input"
                    placeholder={translation('SettingsPage.formFields.newPassword')}
                    className="settingsPage-textField"
                    value={state.userPassword}
                    onChange={event => handleChange(event, 'userPassword')}
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-name"
                    placeholder={state.passwordConfirmError ? translation('SettingsPage.formFields.confirmPasswordError') : translation('SettingsPage.formFields.confirmPassword')}
                    className="settingsPage-textField"
                    value={state.userPasswordConfirm}
                    type="password"
                    onChange={event => handleChange(event, 'userPasswordConfirm')}
                    error={state.passwordConfirmError}
                    margin="normal"
                    variant="outlined"
                  />
                  <div className="settingsPage-buttonContainer">
                    <Button
                      onClick={handlePasswordSubmit}
                      type="primary"
                      text={translation('SettingsPage.formFields.submitPassword')}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )
  );
};

SettingsPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  upliftHistory: PropTypes.func.isRequired,
};

export default (withRouter(SettingsPage));
