import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Routes from '../../services/routes/Routes.json';
import TabBar from '../core/components/TabBar';
import PageTitle from '../core/components/PageTitle';
import '../../css/accountPage/AccountPage.scss';
import SettingsPage from '../settingsPage/SettingsPage';
import { getUser, getLoading } from '../../selectors';
import PaymentMethodPage from '../paymentMethodPage/PaymentMethodPage';
import AddNewCardPage from '../addNewCardPage/AddNewCardPage';

const AccountPage = ({
  history, translation, actions,
}) => {
  const user = get(useSelector(getUser), 'user');
  const loading = useSelector(getLoading);
  const [selectedTab, setSelectedTab] = useState('PAYMENT_METHODS');
  const [shouldRenderAddCard, setSHoudlRenderAddCard] = useState(false);

  useEffect(() => {
    if (!user) history.push(Routes.path.menuPage);
  }, [user]);

  const onPressAddCard = () => setSHoudlRenderAddCard(true);

  const onPressCancel = () => setSHoudlRenderAddCard(false);

  const getTabs = () => ([{
    id: 'PAYMENT_METHODS',
    label: translation('AccountPage.paymentMethods'),
  },
  {
    id: 'PROFILE',
    label: translation('AccountPage.profile'),
  }]);

  const renderPaymentMethods = () => (shouldRenderAddCard ? (
    <AddNewCardPage
      translation={translation}
      actions={actions}
      onPressCancel={onPressCancel}
    />
  ) : (
    <PaymentMethodPage
      user={user}
      actions={actions}
      loading={loading}
      translation={translation}
      onPressAddCard={onPressAddCard}
    />
  ));

  const renderProfile = () => <SettingsPage user={user} loading={loading} translation={translation} actions={actions} />;

  const renderTab = () => {
    if (selectedTab === 'PAYMENT_METHODS') return renderPaymentMethods();
    return renderProfile();
  };

  const onClickSelected = (value) => {
    setSelectedTab(value);
    setSHoudlRenderAddCard(false);
  };

  return (
    <div className="accountPage-main">
      <div className="accountPage-titleContainer">
        <PageTitle title={translation('AccountPage.title')} />
      </div>
      <TabBar
        tabs={getTabs()}
        selected={selectedTab}
        onClickSelected={onClickSelected}
      />
      {renderTab()}
    </div>
  );
};

AccountPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(AccountPage);
