import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography } from '@material-ui/core';
import {
  objectOf,
  string,
  bool,
  func,
  any,
} from 'prop-types';

import {
  getCurrentOrder,
  getCurrentOrderItems,
} from '../../../selectors';
import { priceAndTaxSummary } from '../../../services/constants/Constants';
import * as Functions from '../../../services/functions/Functions';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';

import '../../../css/core/components/SubTotalSummary.scss';

const PriceItem = (props) => {
  const {
    item, currentOrder, translation, id,
  } = props;
  let itemAmount = item.getValueAmount(currentOrder);
  if (Number.isNaN(itemAmount)) itemAmount = 0;
  const discountSign = item.isDiscount ? '-' : '';
  if (item.optional && !itemAmount) return null;
  const priceText = `${discountSign}${Functions.getFormattedPrice(itemAmount)}`;
  return (
    <div id={id} className="subTotalSummary-priceItem" >
      <Typography className={item.isTotal && 'subTotalSummary-totalPriceText'}>{translation(item.title)}</Typography>
      <Typography className={item.isTotal && 'subTotalSummary-totalPriceText'}>{priceText}</Typography>
    </div>
  );
};

const PriceAndTaxItems = (props) => {
  const { showTotal, paymentDrawer } = props;
  const { tipAmount, ...priceAndTaxSummaryNoTip } = priceAndTaxSummary;
  const priceAndTaxSummaryItems = FeatureFlags.CoreView.PaymentDrawer.enableTipping && paymentDrawer ? priceAndTaxSummary : priceAndTaxSummaryNoTip;
  if (showTotal) {
    priceAndTaxSummaryItems.total = {
      isTotal: true,
      title: 'CheckoutDrawer.summary.total',
      getValueAmount: order => order.totalPrice,
    };
  } else {
    delete priceAndTaxSummaryItems.total;
  }
  return Object.keys(priceAndTaxSummaryItems).map(key => <PriceItem {...props} id={key} item={priceAndTaxSummaryItems[key]} key={key} />);
};

const SubTotalSummary = (props) => {
  return (
    <div className="subTotalSummary-priceSummary">
      <PriceAndTaxItems {...props} />
    </div>
  );
};

PriceItem.propTypes = {
  item: objectOf(any).isRequired,
  currentOrder: objectOf(any).isRequired,
  translation: func.isRequired,
  id: string.isRequired,
};

PriceItem.defaultProps = {
};

SubTotalSummary.propTypes = {
  currentOrder: objectOf(any).isRequired,
  translation: func.isRequired,
  showTotal: bool,
};

SubTotalSummary.defaultProps = {
  showTotal: false,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  currentOrderItems: getCurrentOrderItems(state),
});

const EnhancedSubTotalSummary = compose(connect(mapStateToProps))(SubTotalSummary);
export default EnhancedSubTotalSummary;
