import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const INITIAL_STATE = Immutable({ sessionId: null });

export default function cloudEventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SESSION_ID:
      return Immutable.set(state, 'sessionId', action.sessionId);
    case types.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
