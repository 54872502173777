import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const initialState = Immutable({
  user: null,
  rememberUser: false,
});

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return Immutable.merge(state, { user: action.loggedInUser });
    case types.SIGNUP_USER_SUCCESS:
      return Immutable.merge(state, { user: action.newUser });
    case types.LOGIN_USER_FAILURE:
    case types.LOGOUT_USER:
      return Immutable.merge(state, { ...initialState });
    case types.SET_USER:
      return Immutable.merge(state, { user: action.user });
    case types.SET_REMEMBER_USER:
      return Immutable.merge(state, { rememberUser: action.rememberUser });
    case types.GET_USER_SUCCESS:
      return Immutable.merge(state, { user: state.user });
    default:
      return state;
  }
}
