import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { CONTROLS, PAGES, PLACEMENTS, SLOTS, TYPES } from './constants';

export default class ClickShowMoreSubscriptionFeaturesEvent extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    subscriptionId,
    slot,
    idSeam,
    sessionId,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.CLICK_SHOW_MORE,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: PAGES.SUBSCRIPTIONS,
        slot: slot || SLOTS.L1,
        placement: PLACEMENTS.SUBSCRIPTIONS_CAROUSEL,
        control: CONTROLS.SHOW_MORE_TEXT,
      },
      position: getPositionObject({ clientX, clientY }),
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
      subscriptionId,
    };
    this.setData(data);
  }
}
