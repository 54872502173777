import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from '../../actions/Actions';

// eslint-disable-next-line import/prefer-default-export
export const checkForNotifications = (user) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Actions.getUserNotifications(user.token, ['push_notifications', 'users', user.id]));
  }, [dispatch]);
};
