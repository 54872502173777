import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography, Icon } from '@material-ui/core';
import { DINE_IN } from '../../../services/constants/Constants';
import * as Functions from '../../../services/functions/Functions';
import '../../../css/orderStatusPage/subComponents/StatusComponent.scss';

const showTableNumber = (orderPlaced, translation) => {
  const tableNumberNAMessage = translation('OrderStatusPage.tableNumberNotAvailable');
  const tableNumber = orderPlaced.tableNumber || tableNumberNAMessage;
  const tableNumberTitle = translation('OrderStatusPage.tableNumber');
  return (
    <TableRow>
      <TableCell className="statusComponent-placeHolderTableCellStyle" />
      <TableCell className="tableCellTableNumberTitle">{tableNumberTitle}</TableCell>
      <TableCell className="tableCellTableNumber">{tableNumber}</TableCell>
      <TableCell className="statusComponent-placeHolderTableCellStyle" />
    </TableRow>
  );
};

const StatusComponent = (props) => {
  const {
    translation,
    orderPlaced,
  } = props;
  const { deliveryOption } = orderPlaced;
  const formattedDeliveryOption = deliveryOption === DINE_IN
    ? 'DINE IN'
    : deliveryOption;

  return (
    <div className="statusInfo">
      <Table className="statusComponent-tableStyle">
        <TableHead>
          <TableRow>
            <TableCell className="statusComponent-placeHolderTableCellStyle" />
            <TableCell className="statusComponent-headerTableCellStyle">
              {translation('OrderStatusPage.statusHeading')}
            </TableCell>
            <TableCell className="statusComponent-headerTableEstimated">
              {`${translation('OrderStatusPage.estimatedTime')}: `}
              {!isEmpty(orderPlaced) && Functions.formatDesiredTime(orderPlaced.desiredTime)}
            </TableCell>
            <TableCell className="statusComponent-placeHolderTableCellStyle" />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="statusComponent-placeHolderTableCellStyle" />
            <TableCell className="statusComponent-tableCellDeliveryOption">
              {formattedDeliveryOption}
            </TableCell>
            <TableCell className="statusComponent-tableCellAddress">
              <div className="deliveryAddressIcon">
                {
                  orderPlaced.address
                  ? (
                    <div className="deliveryDetails">
                      <div>
                        <Icon className="statusComponent-icon">directions_car</Icon>
                      </div>
                      <div>
                        <Typography className="statusComponent-locationAddress">
                          {orderPlaced.address.streetAddress},
                          {orderPlaced.address.unitNumber}
                        </Typography>
                        <Typography className="statusComponent-locationAddress">
                          {orderPlaced.address.city},
                          {orderPlaced.address.postal_code}
                        </Typography>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </TableCell>
            <TableCell className="statusComponent-placeHolderTableCellStyle" />
          </TableRow>
          {deliveryOption === DINE_IN && showTableNumber(orderPlaced, translation)}
        </TableBody>
      </Table>
    </div>
  );
};

StatusComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  orderPlaced: PropTypes.objectOf(PropTypes.any),
};

StatusComponent.defaultProps = {
  orderPlaced: null,
};

export default (StatusComponent);

