/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, FormGroup, ListItem, Collapse, Typography } from '@material-ui/core';
import { ExpandMore, CheckCircle, ChevronRight } from '@material-ui/icons';
import Immutable from 'seamless-immutable';
import { cloneDeep, isEmpty, range, get } from 'lodash';

import {
  compareValues,
  setSubOptionSection,
  getDefaultSelectedOptions,
  getCurrencySymbol,
  getIsDefaultOptions,
} from '../../../services/functions/Functions';
import { LEFT, RIGHT } from '../../../services/constants/Constants';
import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import AddSubtractToggle from '../../core/components/AddSubtractToggle';
import RadioGroupComponent from '../../core/components/RadioGroupComponent';
import CheckBoxComponent from '../../core/components/CheckBoxComponent';
import HalfWholePizzaToggle from '../../core/components/HalfWholePizzaToggle';
import '../../../css/product/subComponents/CustomizationComponent.scss';
import { getCompany } from '../../../selectors';

// TODO: Implement 2x/3x option
class CustomizationComponent extends Component {
  constructor(props) {
    super(props);

    const selectObject = this.getMultiSelectObject();
    this.state = {
      selectedValue: '',
      multipleSelections: selectObject,
      selectedOptions: [],
      isValid: false,
      setPresets: true,
      sectionSelection: FeatureFlags.CustomizationComponent
        ? FeatureFlags.CustomizationComponent.allowSectionSelection
        : false,
    };
  }

  componentDidMount() {
    const {
      isEdit, options, maxOptions, minOptions, item,
    } = this.props;
    if (isEdit === false) {
      // Pre-select the default productItem attribute & radio options unless it is out of stock
      const includedOptionsIds = this.getIncludedOptionsIds(item);
      const defaultOptionIds = getIsDefaultOptions(item).map(option => option.id);
      const isAttribute = options[0] && options[0].attribute_value;

      const allSelected = [...includedOptionsIds, ...defaultOptionIds];
      if (maxOptions === 1 && minOptions === 1) {
        this.setSelectedValue(allSelected, isAttribute);
      }
    } else {
      this.setState({ setPresets: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { item, options } = this.props;
    let isValidIncludedOption = false;
    const isAttribute = nextProps.options[0] && nextProps.options[0].attribute_value;
    const includedOptionsIds = this.getIncludedOptionsIds(nextProps.item);

    // If customizations have been reset due to size change, reset multiple selections object
    if (item && nextProps.item && (item.id !== nextProps.item.id)) {
      const selectObject = this.getMultiSelectObject(nextProps);
      options.forEach((opt) => {
        if (includedOptionsIds.includes(opt.id)) {
          isValidIncludedOption = true;
        }
      });
      this.setState({ multipleSelections: selectObject, isValid: isValidIncludedOption });
      if (nextProps.maxOptions === 1 && nextProps.minOptions === 1) {
        this.setSelectedValue(includedOptionsIds, isAttribute, nextProps.item);
      }
    }
  }

  componentDidUpdate(prevState, prevProps) {
    const {
      options, maxOptions, minOptions, currentItem,
    } = this.props;
    if (this.isEditingFromCheckout(prevProps.isEdit, prevState.setPresets)) {
      if (maxOptions === 1 && minOptions === 1) {
        // Get selected radio options
        const customizationOptionIds = options.map(c => c.id); // array of available option ids
        const isAttribute = options[0].attribute_value;
        const selectedOptions = currentItem.options;
        const selectedOptionForCustomization = selectedOptions.filter(selected => customizationOptionIds.includes(selected.id));
        // If options is a size attribute, selectedValue is productItem.id
        this.setState({
          selectedValue: isAttribute
            ? currentItem.productItem.id.toString()
            : selectedOptionForCustomization[0] && selectedOptionForCustomization[0].id.toString(),
          isValid: true,
        });
      }
      this.setPresetCheckBoxOptions();
      this.setState({ setPresets: false });
    }
  }

  getIncludedOptionsIds = (item) => {
    const includedOptionForCust = item ? getDefaultSelectedOptions(item) : [];
    return includedOptionForCust.map(includedOption => includedOption.id);
  }

  setSelectedValue = (includedOptionsIds, isAttribute, nextPropsItem) => {
    const { options, product } = this.props;
    const defaultProductItem = product.items.find(i => i.isDefault === true);
    let isValidIncludedOption = false;
    const productItem = nextPropsItem || defaultProductItem;

    if (productItem && productItem.outOfStockLocations.length === 0) {
      // Check if there are any included options in this customization and set this as selectedValue
      let selectedOptionId = '';
      options.forEach((opt) => {
        if (includedOptionsIds.includes(opt.id)) {
          selectedOptionId = opt.id;
          isValidIncludedOption = true;
        }
      });
      this.setState({
        selectedValue: isAttribute
          ? productItem.id.toString()
          : selectedOptionId.toString(),
        isValid: isValidIncludedOption,
      });
    }
  }

  getMultiSelectObject = (props = this.props) => {
    const {
      options, maxOptions, minOptions, item,
    } = props;
    const selectObject = {};
    let includedOptionIds = item ? item.includedOptions.map(includedOption => includedOption.id) : [];
    const defaultOptionIds = getIsDefaultOptions(item).map(option => option.id);

    if (defaultOptionIds.length > 0) {
      includedOptionIds = [...includedOptionIds, ...defaultOptionIds];
    }
    if (maxOptions > 1 || this.hasUnlimitedOptionSelections(maxOptions, minOptions)) {
      // If option is an included option, selected is true
      options.forEach((option) => {
        selectObject[option.id] = {
          selected: !!includedOptionIds.includes(option.id),
          quantity: 1,
        };
      });
    }
    return selectObject;
  }


  /**
   * Parse selected multi-options from currentItem to
   * set multipleSelections state
   */
  setPresetCheckBoxOptions = () => {
    const { currentItem, options } = this.props;
    const multiSelectObj = this.state.multipleSelections;
    // Get preselected options
    const optionArr = currentItem.options;
    const customizationOptionIds = options.map(c => c.id); // array of available option ids
    // Get preselected subItem options
    const presetSubItems = get(currentItem, 'subItems', []);
    const sideOneSubOptions = presetSubItems.length > 0
      ? setSubOptionSection(presetSubItems[0], customizationOptionIds)
      : [];
    const sideTwoSubOptions = presetSubItems.length === 2
      ? setSubOptionSection(presetSubItems[1], customizationOptionIds)
      : [];
    const combinedOptionArr = optionArr.concat(sideOneSubOptions, sideTwoSubOptions);

    // Set state of multipleSelections object based on preset combinedOptionArr
    if (combinedOptionArr) {
      combinedOptionArr.forEach((option) => {
        multiSelectObj[option.id]
          ? multiSelectObj[option.id] = {
              selected: true,
              quantity: option.quantity,
              section: option.section,
            }
          : false;
      });
      this.setState({
        multipleSelections: multiSelectObj,
      });
    }
  }

  getRadioOptions = (options) => {
    const { currentOrderLocationId, translation } = this.props;
    const isSamePriceAddOn = this.isSamePriceAddOn(options);
    const selectedRadioOption = this.getSelectedRadioOption();
    return options.reduce((acc, cur) => {
      // Check if the option is disabled
      if (cur.isDisabled) {
        return acc;
      }
      let label;
      let style;
      let outOfStockOption = false;
      if (cur.outOfStockLocationIds && cur.outOfStockLocationIds.includes(currentOrderLocationId)) {
        label = cur.attribute_value
          ? `${cur.attribute_value} - ${translation('CustomizationComponent.outOfStock')}`
          : `${cur.name} - ${translation('CustomizationComponent.outOfStock')}`;
        outOfStockOption = true;
      } else {
        label = cur.attribute_value
          ? `${cur.attribute_value} - ${getCurrencySymbol()}${cur.price.toFixed(2)}`
          : cur.name;
      }

      const isRadioLabel = true;
      const description = cur.description ? cur.description : null;
      const descriptionStyle = 'optionDescriptionStyle';

      // Display add on price if there is a price add on
      if (cur.priceAddOn && cur.priceAddOn > 0) {
        label = this.getLabel(isSamePriceAddOn, cur, null);
      }

      // If option selected is current option being rendered
      // get radio label with selected style
      if (cur.id === parseInt(selectedRadioOption, 10)) {
        label = this.getLabel(isSamePriceAddOn, cur, isRadioLabel);
        style = 'formControlSelectedRadio';
      } else {
        style = 'formControlRadioStyle';
      }

      acc.push({
        key: cur.id,
        value: cur.id.toString(),
        label,
        style,
        description,
        descriptionStyle,
        outOfStockOption,
      });

      return acc;
    }, []);
  };

  getDescription = (minOptions, freeOptions, options) => {
    let requiredText = '';
    let additionalText = '';
    let description = '';

    if (minOptions > 0) {
      requiredText = 'Required';
    }

    const optionWithPrice = options.find(option => !option.name.match(/^(no )/i));

    if (freeOptions > 0 && optionWithPrice) {
      // TO-DO: Need to translate these
      additionalText = `Up to ${freeOptions} free, Extra ${getCurrencySymbol()}${optionWithPrice.priceAddOn.toFixed(2)} each`;
    } else if (this.isSamePriceAddOn(options) && optionWithPrice) {
      additionalText = `${getCurrencySymbol()}${optionWithPrice.priceAddOn.toFixed(2)} each`;
    }

    if (requiredText && additionalText) {
      description = `${additionalText} - ${requiredText}`;
    } else if (requiredText && !additionalText) {
      description = requiredText;
    } else if (!requiredText && additionalText) {
      description = additionalText;
    }

    return (
      <Typography className="descriptionStyle">
        {description ? `(${description})` : ''}
      </Typography>
    );
  };

  getOptionAddOnPrice = (option) => {
    const {
      company: {
        sidePriceStrategy,
        surcharge,
      } = {},
    } = this.props;
    const portionCoefficient = this.props.company.portionCoefficient || 0.5;
    const { selectedOptions, multipleSelections } = this.state;
    let selectedOption = selectedOptions.find(opt => opt.id === option.id);
    // try another way to find the selected option
    if (!selectedOption) {
      selectedOption = multipleSelections[option.id] ? option : null;
    }
    if (selectedOption
        && sidePriceStrategy === 'PORTION'
        && (multipleSelections[option.id].section === RIGHT || multipleSelections[option.id].section === LEFT)) {
      return ((selectedOption.priceAddOn * portionCoefficient) + surcharge).toFixed(2);
    }
    return get(option, 'priceAddOn', 0).toFixed(2);
  }

  getLabelStyle = (option, isRadioLabel) => {
    const { currentOrderLocationId } = this.props;
    let labelStyle;
    if (option.outOfStockLocationIds && option.outOfStockLocationIds.includes(currentOrderLocationId)) {
      labelStyle = isRadioLabel ? 'outOfStockCheckedLabel' : 'outOfStockCheckedLabel';
    } else {
      labelStyle = isRadioLabel ? 'checked' : 'optionNameStyle';
    }
    return labelStyle;
  }

  // Returns the option name along with the price add on if
  // all option price add ons are not the same.
  getLabel = (isSamePriceAddOn, option, isRadioLabel) => {
    const { currentOrderLocationId, translation } = this.props;
    let labelValue;

    if (option.outOfStockLocationIds && option.outOfStockLocationIds.includes(currentOrderLocationId)) {
      if (option.attribute_value) {
        labelValue = `${option.attribute_value} - ${translation('CustomizationComponent.outOfStock')}`;
      } else {
        labelValue = `${option.name} - ${translation('CustomizationComponent.outOfStock')}`;
      }
    } else if (option.attribute_value) {
      labelValue = `${option.attribute_value} - ${getCurrencySymbol()}${option.price.toFixed(2)}`;
    } else {
      labelValue = `${option.name}`;
    }

    const addOnPrice = this.getOptionAddOnPrice(option);
    const addOnSymbol = addOnPrice > 0 ? '+' : '-';

    return (
      <div className={isRadioLabel ? 'checkedBackground' : 'checkBoxLabelStyle'}>
        <Typography className={this.getLabelStyle(option, isRadioLabel)}>
          {labelValue}
        </Typography>
        {
          !!option.priceAddOn && !isSamePriceAddOn
          && (
            <Typography className={isRadioLabel ? 'addOnChecked' : 'priceAddOnStyle'}>
              {`${addOnSymbol} ${getCurrencySymbol()}${Math.abs(addOnPrice).toFixed(2)}`}
            </Typography>
          )
        }
      </div>
    );
  }

  /**
   * Sort customization options in ascending order
   * @param {*} options the customization options
   */
  getSortedOptions = (options) => {
    const mutableOptions = Immutable.asMutable(options, { deep: true });
    return mutableOptions.sort(compareValues('position', 'asc'));
  }
  /**
   * Find the selected option by id, set the quantity
   * and return the object
   * @param {*} event the selection event
   */
  getOptionObject = (event) => {
    const { options } = this.props;
    // Object is not extensible, we need to clone it
    const optionObject = cloneDeep(options.find(option =>
      option.id === Number(event.target.value)));
    optionObject.quantity = 1;
    return optionObject;
  }

  getCurrentSelections = () => {
    const { currentCustomizations } = this.props;
    const { multipleSelections } = this.state;
    const customizationArr
      = currentCustomizations.reduce((acc, cur) => acc.concat(cur.options), []);
    let multiSelectObj = {
      ...multipleSelections,
    };

    Object.keys(multiSelectObj).forEach((selection) => {
      // if selection in multiSelectObj is not in customizationArr, reset
      if (!customizationArr.find(cust => cust.id === Number(selection))) {
        multiSelectObj = {
          ...multiSelectObj,
          [selection]: {
            ...multiSelectObj[selection],
            selected: false,
          },
        };
      }
    });
    return multiSelectObj;
  }

  /**
  * Reset selected radio option if no longer included in currentCustomizations
  */
  getSelectedRadioOption = () => {
    const {
      minOptions, maxOptions, options, currentCustomizations,
    } = this.props;

    const { selectedValue } = this.state;
    const customizationArr
      = currentCustomizations.reduce((acc, cur) => acc.concat(cur.options), []);
    let selectedRadioOption = selectedValue;

    if ((options[0] && options[0].attribute_name === undefined)
      && (maxOptions === 1 && minOptions === 1)) {
      selectedRadioOption = customizationArr.find(cust => cust.id === Number(selectedValue))
        ? selectedValue
        : '';
    }
    return selectedRadioOption;
  }

  hasUnlimitedOptionSelections = (maxOptions, minOptions) => maxOptions === 0 && minOptions === 0;

  isEditingFromCheckout = (prevIsEditProp, prevSetPresetState) => {
    const { isEdit } = this.props;
    const { setPresets } = this.state;
    if ((!prevIsEditProp && !prevSetPresetState) && (isEdit && setPresets)) return true;
    return false;
  }

  /**
   * Return true if customization option has been selected
   * @param {*} option - the option being rendered
   */
  isOptionSelected = (option) => {
    const { multipleSelections } = this.state;
    if (multipleSelections[option.id] && multipleSelections[option.id].selected) return true;
    return false;
  }

  createSelectedOptionsObject = (multipleSelections) => {
    const { selectedOptions } = this.state;
    const mutableOptions = Immutable.asMutable(selectedOptions, { deep: true });
    mutableOptions.forEach((option) => {
      // eslint-disable-next-line no-param-reassign
      option.quantity = multipleSelections[option.id].quantity;
    });
    return mutableOptions;
  }

  handleChangeQuantity = (optionId, quantity) => {
    const { id, onChangeOptionQuantity, onCustomDisableCartButton } = this.props;
    const { multipleSelections } = this.state;
    this.setState({
      multipleSelections: {
        ...multipleSelections,
        [optionId]: {
          ...multipleSelections[optionId],
          quantity,
        },
      },
    }, () => {
      onChangeOptionQuantity(quantity, id, optionId);
      // We also check if the selected options
      // have enough quantity to enable/disable addToCart button
      const mutableOptions = this.createSelectedOptionsObject(this.state.multipleSelections);
      const validSelection = this.isSelectionValid(mutableOptions);
      onCustomDisableCartButton(id, validSelection);
    });
  }

  /**
   * Return true if only one option is select-able or selection is for
   * attribute/size
   */
  isRadioGroupOption = () => {
    const { minOptions, maxOptions, title, translation } = this.props;
    if ((minOptions === 1 && maxOptions === 1)
      || title === translation('CustomContainerComponent.size')) return true;

    return false;
  }

  // Returns true if priceAddOn is the same for all options
  isSamePriceAddOn = (options) => {
    if (FeatureFlags.CustomizationComponent.ignoreSamePriceAddOn == true) return false;
    for (let i = 0; i < options.length - 1; i += 1) {
      if (!options[i].name.match(/^(no )/i) && !options[i + 1].name.match(/^(no )/i)) {
        if (options[i].priceAddOn !== options[i + 1].priceAddOn || !options[i].priceAddOn) {
          return false;
        }
      }
    }

    return true;
  };

  isSelectionValid = (selectedOptions) => {
    const { minOptions, maxOptions } = this.props;
    let numberOfOptions = 0;
    selectedOptions.forEach((option) => {
      numberOfOptions += option.quantity;
    });
    if (this.hasUnlimitedOptionSelections(maxOptions, minOptions)) {
      return true;
    }
    // Display expand icon if there is no mandatory option
    if (minOptions === 0) {
      return true;
    }
    return numberOfOptions >= minOptions && numberOfOptions <= maxOptions;
  };

  handleChange = (event, checkBoxOption = null) => {
    const {
      options, onCustomHandleChange, id, onCustomDisableCartButton, minOptions, freeOptions,
    } = this.props;
    const { multipleSelections } = this.state;
    let selectedOptions = [];
    const isItem = options[0] && options[0].attribute_name;


    // checkBoxOption consists of the option object that is used to render the checkbox
    if (!checkBoxOption) {
      const optionObject = this.getOptionObject(event);
      selectedOptions.push(optionObject);
      this.setState(
        {
          selectedValue: event.target.value,
          isValid: true,
        },
        onCustomHandleChange(selectedOptions, id, !!isItem),
        isItem
          ? onCustomDisableCartButton(id, true, true)
          : onCustomDisableCartButton(id, true),
      );
    } else {
      // Deselect option should have an option name starting with "No " (No Options, No Cheese, etc).
      // Find the deselect option.
      const noOption = cloneDeep(options.find(option => option.name.match(/^(no )/i)));

      // If no options is selected, deselect all other options
      if ((noOption && checkBoxOption.id === noOption.id) && FeatureFlags.CustomizationComponent.deSelectOnNoOption == true) {
        const selectObject = {};
        noOption.quantity = 1;
        options.forEach((option) => {
          selectObject[option.id] = {
            selected: false,
            quantity: 1,
          };
        });

        const optionObject = this.getOptionObject(event);
        if (event.target.checked) selectedOptions.push(optionObject);
        selectedOptions.push(optionObject);
        this.setState(
          {
            multipleSelections: {
              ...selectObject,
              [checkBoxOption.id]: {
                selected: event.target.checked,
                quantity: 1,
              },
            },
          },
          onCustomHandleChange(selectedOptions, id, !!isItem),
          onCustomDisableCartButton(id, true),
        );
        return;
      }

      const multiSelectObj = {
        ...multipleSelections,
        [checkBoxOption.id]: {
          selected: event.target.checked,
          quantity: 1,
        },
      };

      // Deselect no options
      if (noOption && FeatureFlags.CustomizationComponent.deSelectOnNoOption == true) {
        multiSelectObj[noOption.id] = {
          selected: false,
        };
      }

      // The keys consist of option IDs for multipleSelections
      // Return an array of selected option IDs
      let freeOptionsCounter = freeOptions;
      selectedOptions = Object.keys(multiSelectObj).reduce((acc, cur) => {
        const optionId = Number(cur);
        // Remove/don't add the No Options
        if (noOption && optionId === noOption.id && FeatureFlags.CustomizationComponent.deSelectOnNoOption == true) {
          return acc;
        }

        if ((optionId === checkBoxOption.id && !event.target.checked)
          || (optionId !== checkBoxOption.id && !multipleSelections[optionId].selected)) {
          return acc;
        }
        // Find the selected option and append it to the array along with the quantity
        const selected = cloneDeep(options.find(option => option.id === optionId));

        selected.quantity = multiSelectObj[optionId].quantity;
        selected.section = multiSelectObj[optionId].section;
        // Option add on price is 0 if freeOptions is greater than selected option quantities
        if (freeOptionsCounter >= selected.quantity) {
          freeOptionsCounter -= selected.quantity;
          selected.priceAddOn = 0;
        }
        acc.push(selected);
        return acc;
      }, []);

      const validSelection = this.isSelectionValid(selectedOptions);

      this.setState({
        multipleSelections: multiSelectObj,
        isValid: validSelection,
      });

      // Don't need to check if customization requirements are met if the min
      // option is greater than 0.
      if (minOptions > 0) {
        // Check to disable/enable add to cart button
        onCustomDisableCartButton(id, validSelection);
      }
      this.setState({ selectedOptions });

      onCustomHandleChange(selectedOptions, id, !!isItem);
    }
  };

  /**
   * Handle change of option between left and right
   * @param {*} optionId
   * @param {*} section (left or right)
   */
  handleChangeSection = (optionId, section) => {
    const { id, options, onCustomHandleChange, onChangeSideSection } = this.props;
    const { sectionSelection, multipleSelections } = this.state;
    let selectedOptions;

    if (sectionSelection) {
      const multiSelectObj = {
        ...multipleSelections,
        [optionId]: {
          ...multipleSelections[optionId],
          section,
        },
      };
      this.setState({
        multipleSelections: multiSelectObj,
      });

      selectedOptions = Object.keys(multiSelectObj).reduce((acc, cur) => {
        const theOptionId = Number(cur);

        if (multiSelectObj[theOptionId].selected !== true) return acc;
        // Find the selected option and append it to the array along with the quantity
        const selected = cloneDeep(options.find(option => option.id === theOptionId));
        selected.section = multiSelectObj[theOptionId].section;
        acc.push(selected);
        return acc;
      }, []);
      this.setState({ selectedOptions });
      onCustomHandleChange(selectedOptions, id);
      onChangeSideSection(section, id, optionId);
    }
  }

  renderAddSubtractToggle = (option) => {
    const { useLeftRightOptions } = this.props;
    if (!option.name.match(/^(no )/i) && !useLeftRightOptions) {
      return true;
    }
    return false;
  }

  renderPizzaToggle = (option) => {
    const {
      translation,
      useLeftRightOptions,
    } = this.props;
    const { sectionSelection } = this.state;
    const multiSelectObj = this.getCurrentSelections();

    const shouldRenderPizzaToggle = this.isOptionSelected(option)
      && !option.name.match(/^(no )/i)
      && FeatureFlags.CustomizationComponent.showPizzaToggle
      && useLeftRightOptions;

    return (
      shouldRenderPizzaToggle
        ? (
          <HalfWholePizzaToggle
            id={option.id}
            handleChange={(id, section) => this.handleChangeSection(id, section)}
            selectedSection={multiSelectObj[option.id].section}
            enableSectionSelection={multiSelectObj[option.id].selected === true ? sectionSelection : false}
            translation={translation}
          />
        )
        : null
    );
  }

  isCheckboxDisabled = (option, count) => {
    const { currentOrderLocationId, maxOptions } = this.props;

    if (option.outOfStockLocationIds && option.outOfStockLocationIds.includes(currentOrderLocationId)) return true;
    if (this.isOptionSelected(option)) return false;

    return (maxOptions > 0 && count >= maxOptions);
  }

  shouldShowMultipleQuantityButtons = (option) => {
    const { multipleSelections } = this.state;
    if (!option || !multipleSelections) return false;
    const optionName = get(option, 'name');
    const optionId = get(option, 'id');
    if (!optionName || !optionId || !multipleSelections[optionId]) return false;
    return (
      FeatureFlags.CustomizationComponent.showMultipleQuantityButtons
      && !(optionName.toLowerCase().match(/^no/))
      && multipleSelections[optionId].selected
    );
  }

  getQuantityButtonStyle = (optionId, quantity, multiSelectObj, styleType) => {
    if (!multiSelectObj) return null;
    const multiSelectQuantity = get(multiSelectObj, `${optionId}`, 'quantity');
    const areQuantitiesEqual = multiSelectQuantity && multiSelectQuantity.quantity === quantity;

    const styleKeyPrefix = areQuantitiesEqual
      ? 'multipleQuantityButtonSelected'
      : 'multipleQuantityButtonUnselected';
    const styleKey = styleType === 'text'
      ? `${styleKeyPrefix}Text`
      : styleKeyPrefix;
    return styleKey;
  }

  renderQuantityButton = (text, optionId, quantity, multiSelectObj) => {
    return (
      <Button
        className={this.getQuantityButtonStyle(optionId, quantity, multiSelectObj)}
        onClick={() => {
          this.handleChangeQuantity(optionId, quantity);
        }}
      >
        <Typography className={this.getQuantityButtonStyle(optionId, quantity, multiSelectObj, 'text')}>
          {text}
        </Typography>
      </Button>
    );
  }

  renderMultipleQuantityButtons = (option, multiSelectObj) => {
    const { currentCustomizations, id } = this.props;
    const customization = currentCustomizations.find(cus => cus.id === id);
    const upperQuantity = option.maxQuantity > 3 ? 3 : option.maxQuantity;
    return (
      <div className="multipleQuantityButtonsView">
        {
          range(1, upperQuantity + 1).map(quantityVal => (
            <div key={quantityVal} className="quantityButtonView">
              { this.renderQuantityButton(`x${quantityVal}`, option.id, quantityVal, multiSelectObj) }
            </div>
          ))
        }
      </div>
    );
  }

  renderCheckBoxHelper = (option, multiSelectObj, count) => {
    const { maxOptions } = this.props;
    const isOpen = !isEmpty(multiSelectObj) && this.isOptionSelected(option);

    const showToggle = isOpen && this.renderAddSubtractToggle(option) && FeatureFlags.CustomizationComponent.showOptionAddSubtractToggle;
    if (showToggle) {
      return (
        <AddSubtractToggle
          id={option.id}
          max={maxOptions}
          forceDisable={count >= maxOptions}
          containerStyle="addSubtractToggleContainer"
          iconStyle="iconStyle"
          handleChange={(id, quantity) => this.handleChangeQuantity(id, quantity)}
          quantity={multiSelectObj[option.id].quantity}
        />
      );
    } else if (this.shouldShowMultipleQuantityButtons(option)) {
      return this.renderMultipleQuantityButtons(option, multiSelectObj);
    }
    return this.renderPizzaToggle(option);
  }

  renderCheckBox = (options) => {
    const isSamePriceAddOn = this.isSamePriceAddOn(options);
    let count = 0;

    const multiSelectObj = this.getCurrentSelections();
    // Count the number of options selected, by quantity
    Object.keys(multiSelectObj).forEach((selection) => {
      if (multiSelectObj[selection].selected) {
        count += multiSelectObj[selection].quantity;
      }
    });

    return (
      <FormGroup
        row={false}
        className="customizationComponent-formGroupStyle"
      >
        {
          options.map((option) => {
            const label = this.getLabel(isSamePriceAddOn, option, null);
            const isSelected = multiSelectObj[option.id] && multiSelectObj[option.id].selected;
            return (
              !option.isDisabled
              && <CheckBoxComponent
                id={`checkBoxOption${option.id}`}
                key={option.id}
                field={{ ...option, title: label }}
                selectedValue={isSelected}
                handleChange={(event, checkBoxOption) => this.handleChange(event, checkBoxOption)}
                formControlLabelStyle="formControlLabelStyle"
                labelStyle="customizationComponent-labelTextStyle"
                disabled={this.isCheckboxDisabled(option, count)}
                containerStyle={isSelected ? 'checkedContainerStyle' : 'containerStyle'}
                checkboxContentStyle="customizationComponent-checkboxContent"
                optionDescriptionStyle="optionDescriptionStyle"
                component={this.renderCheckBoxHelper(option, multiSelectObj, count)}
              />
            );
          })
        }
      </FormGroup>
    );
  };

  getCustomizationContainerStyle() {
    const {
      useOpenStyle, productDialogAttribute,
    } = this.props;

    if (productDialogAttribute) return 'dialogAttributeContainer';
    if (useOpenStyle) return 'dialogCustomizationContainer';
    return 'customizationContainer';
  }

  renderOpenIcons() {
    const { open, useOpenStyle } = this.props;
    return !useOpenStyle && (
      open
        ? <ExpandMore className="expandIconStyle" />
        : <ChevronRight className="expandIconStyle" />
    );
  }

  render() {
    const {
      title,
      options,
      freeOptions,
      minOptions,
      position,
      onHandleClickList,
      open,
      id,
      description,
      useOpenStyle,
      translation,
      productDialogAttribute,
    } = this.props;
    const { isValid } = this.state;
    const sortedOptions = this.getSortedOptions(options);
    const positionValue = FeatureFlags.CustomizationComponent.showPositionInTitle
      ? `${position} - `
      : '';

    const selectedRadioOption = this.getSelectedRadioOption();

    let subTitle = minOptions === 1
      ? translation('CustomizationComponent.pleaseSelectOne')
      : `${translation('CustomizationComponent.pleaseSelectMinimum')} ${minOptions} ${translation('CustomizationComponent.options')}`;
    // Don't need to show a warning if we can have 0 or more options
    // Or if we are showing the productItem selector.
    if (minOptions === 0 || productDialogAttribute) subTitle = null;

    return (
      <div className={this.getCustomizationContainerStyle()}>
        <ListItem
          button
          onClick={() => onHandleClickList(id)}
          className={useOpenStyle ? 'dialogListItemStyle' : 'customizationComponent-listItemStyle'}
        >
          <div className="headingContainer">
            <Typography className="listItemTitle">
              {`${positionValue}${title}`}
            </Typography>
            {
              description
              && (
                <Typography className="customizationDescription">
                  {`${description}`}
                </Typography>
              )
            }
            {
              subTitle
              && (
                <Typography className="customizationDescription">
                  {`${subTitle}`}
                </Typography>
              )
            }
          </div>
          <div className="listItemDescription">
            {this.getDescription(minOptions, freeOptions, options)}
            {/* Only show CheckCircle icon if option is valid and option tab is closed */}
            {isValid && !open
              ? <CheckCircle className="expandIconStyle" />
              : this.renderOpenIcons()}
          </div>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit className="customizationComponent-contentContainer">
          <div className={useOpenStyle ? 'customizationComponent-dialogContent' : 'customizationComponent-content'}>
            {
              this.isRadioGroupOption()
                ? (
                  <RadioGroupComponent
                    options={this.getRadioOptions(sortedOptions)}
                    selectedValue={selectedRadioOption}
                    handleChange={event => this.handleChange(event)}
                    formControlStyle="formControlStyle"
                    radioGroupStyle="customizationComponent-formGroupStyle"
                    labelStyle="customizationComponent-labelTextStyle"
                    disabledLabelStyle="disabledLabelTextStyle"
                  />
                )
                : this.renderCheckBox(sortedOptions)
            }
          </div>
        </Collapse>
      </div>
    );
  }
}

CustomizationComponent.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  maxOptions: PropTypes.number.isRequired,
  minOptions: PropTypes.number.isRequired,
  position: PropTypes.number,
  onCustomHandleChange: PropTypes.func.isRequired,
  onCustomDisableCartButton: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  onChangeOptionQuantity: PropTypes.func,
  freeOptions: PropTypes.number,
  currentItem: PropTypes.objectOf(PropTypes.any),
  isEdit: PropTypes.bool,
  open: PropTypes.bool,
  onHandleClickList: PropTypes.func,
  useLeftRightOptions: PropTypes.bool,
  onChangeSideSection: PropTypes.func,
  currentCustomizations: PropTypes.arrayOf(PropTypes.object),
  product: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  description: PropTypes.string,
  currentOrderLocationId: PropTypes.number,
  company: PropTypes.objectOf(PropTypes.any),
  useOpenStyle: PropTypes.bool,
  productDialogAttribute: PropTypes.bool,
};

CustomizationComponent.defaultProps = {
  position: null,
  freeOptions: 0,
  onChangeOptionQuantity: null,
  currentItem: {},
  isEdit: false,
  open: false,
  onHandleClickList: null,
  useLeftRightOptions: false,
  onChangeSideSection: null,
  currentCustomizations: [],
  product: null,
  item: null,
  description: null,
  currentOrderLocationId: null,
  company: {},
  useOpenStyle: false,
  productDialogAttribute: false,
};

const mapStateToProps = state => ({
  company: getCompany(state),
});

export default connect(mapStateToProps)(CustomizationComponent);
