import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withRouter } from 'react-router-dom';
import { Typography, withWidth, Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core';
import { get } from 'lodash';
import Immutable from 'seamless-immutable';
import {
  func,
  objectOf,
  bool,
  any,
  arrayOf,
  number,
} from 'prop-types';

import { getResources } from '../../../selectors';
import * as Functions from '../../../services/functions/Functions';
import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import DialogView from '../../core/components/DialogView';
import CircleFill from './CircleFill';

import '../../../css/core/components/RewardDialog.scss';
import { DIALOG_NAMES } from '../../../services/api/CloudEvents/constants';
import { DIALOG_CONSTANTS } from '../DialogConstants';

const RewardTiers = (props) => {
  const {
    translation,
    userPoints,
    sortedTiers,
  } = props;

  return (
    <div className="rewardDialog-pointsRewards">
      {
        sortedTiers.map(reward => (
          <Card
            square
            key={reward.points}
            className="rewardDialog-rewardCard"
          >
            <CardActionArea
              className="rewardDialog-rewardCardActionArea"
              disabled
            >
              <CardMedia
                component="img"
                className={
                  userPoints >= reward.points
                    ? 'rewardDialog-rewardCardPicture'
                    : 'rewardDialog-rewardCardPictureDisabled'
                }
                image={reward.image}
              />
              <CardContent className="rewardDialog-rewardCardContent">
                <Typography
                  className={
                    userPoints >= reward.points
                      ? 'rewardDialog-rewardPointsText'
                      : 'rewardDialog-rewardPointsTextDisabled'
                  }
                >
                  {`${reward.points} ${translation('RewardDialog.points').toUpperCase()}`}
                </Typography>
                <Typography
                  className={
                    userPoints >= reward.points
                      ? 'rewardDialog-rewardDescriptionText'
                      : 'rewardDialog-rewardDescriptionDisabledText'
                  }
                >
                  {reward.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      }
    </div>
  );
};

const CirclePointsComponent = (props) => {
  const {
    translation,
    userPoints,
    sortedTiers,
  } = props;

  const calcFill = () => {
    const fillByMaxReward
      = FeatureFlags.RewardDialog
        ? FeatureFlags.RewardDialog.fillByMaxReward
        : false;

    const fillHeight = fillByMaxReward
      ? Functions.calculateNormalFillHeight(sortedTiers, userPoints)
      : Functions.calculateProportionalFillHeight(sortedTiers, userPoints);

    return fillHeight;
  };

  return (
    <div className="rewardDialog-userPoints">
      <CircleFill fillHeight={calcFill()} />
      <Typography className="rewardDialog-pointsAmountText">
        {`${userPoints} ${translation('RewardDialog.points')}`}
      </Typography>
      <Typography className="rewardDialog-pointsSubtitle">
        {translation('RewardDialog.pointsSubtitle')}
      </Typography>
    </div>
  );
};

const RewardComponent = (props) => {
  const {
    open,
    actions,
    user,
  } = props;
  const { users } = props.resources;

  const userPoints = users ? users.points : 0;

  const handleClose = () => {
    const dialogObj = { dialog: DIALOG_CONSTANTS.REWARD };
    props.handleClose(dialogObj);
  };

  const renderActionButtons = () => {
    // Placeholder for required function: will use in future for dialog action buttons
  };

  if (user) {
    useEffect(() => {
      actions.getAllResources(user.token, ['tiers']);
    }, []);
  }

  const getSortedTiers = (tiers = []) => {
    const mutableTiers = Immutable.asMutable(tiers, { deep: true });
    return mutableTiers.sort(Functions.compareValues('points', 'asc'));
  };

  const allTiers = get(props.resources, 'tiers', []);
  const sortedTiers = getSortedTiers(allTiers);

  const getDialogContent = () => {
    return (
      <div className="rewardDialog-dialogContentContainer">
        <CirclePointsComponent
          {...props}
          userPoints={userPoints}
          sortedTiers={sortedTiers}
        />
        <div className="rewardDialog-pointsRewards">
          {
            FeatureFlags.RewardDialog.showRewardsTiers
            && (
              <RewardTiers
                {...props}
                userPoints={userPoints}
                sortedTiers={sortedTiers}
              />
            )
          }
        </div>
      </div>
    );
  };

  return (
    <DialogView
      dialogName={DIALOG_NAMES.REWARD}
      open={open}
      handleClose={() => handleClose()}
      titleAlignClose={false}
      disableEscapeKeyDown={false}
      dialogTitleStyle="rewardDialog-dialogTitleStyle"
      subHeaderImageContainerStyle="rewardDialog-subHeaderImageContainerStyle"
      subHeaderImageStyle="rewardDialog-subHeaderImageStyle"
      titleHasCloseBtn
      hasHeaderImage
      hasDialogContent
      hasDialogContent2={false}
      hasDialogErrorContent={false}
      renderDialogContent={() => getDialogContent()}
      hasDialogActions={false}
      actionBtnStyle="rewardDialog-actionBtnStyle"
      renderActionBtn={() => renderActionButtons()}
      dialogCloseIconColor="#C7F147"
      dialogBodyContainerStyle="rewardDialog-bodyContainer"
      dialogContentStyle="rewardDialog-dialogContentStyle"
    />
  );
};


RewardComponent.propTypes = {
  open: bool.isRequired,
  actions: objectOf(func).isRequired,
  resources: objectOf(any).isRequired,
  handleClose: func.isRequired,
  user: objectOf(any),
};

RewardComponent.defaultProps = {
  user: null,
};

CirclePointsComponent.propTypes = {
  translation: func.isRequired,
  userPoints: number,
  sortedTiers: arrayOf(objectOf(any)),
};

CirclePointsComponent.defaultProps = {
  userPoints: 0,
  sortedTiers: [],
};

RewardTiers.propTypes = {
  translation: func.isRequired,
  userPoints: number,
  sortedTiers: arrayOf(objectOf(any)),
};

RewardTiers.defaultProps = {
  userPoints: 0,
  sortedTiers: [],
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

const RewardDialog = compose(connect(mapStateToProps))(withRouter(withWidth()(RewardComponent)));

export default RewardDialog;
