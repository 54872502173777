import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { LEFT, RIGHT, WHOLE } from '../../../services/constants/Constants';

import '../../../css/core/components/HalfWholePizzaToggle.scss';

class HalfWholePizzaToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: (props.selectedSection)
        ? props.selectedSection
        : WHOLE,
    };
  }

  sectionSelected = (selectedSection) => {
    const { enableSectionSelection, handleChange, id } = this.props;
    if (enableSectionSelection) {
      this.setState({ selectedSection });
      handleChange(id, selectedSection);
    }
  }

  renderButton = () => {
    const { selectedSection } = this.state;
    const { translation, enableSectionSelection, id } = this.props;

    const leftStyle = (selectedSection === LEFT && enableSectionSelection)
      ? 'halfWholePizzaToggle-selectedLeft'
      : 'halfWholePizzaToggle-unSelectedLeft';
    const rightStyle = (selectedSection === RIGHT && enableSectionSelection)
      ? 'halfWholePizzaToggle-selectedRight'
      : 'halfWholePizzaToggle-unSelectedRight';
    const wholeStyle = (selectedSection === WHOLE)
      ? 'halfWholePizzaToggle-selectedWhole'
      : 'halfWholePizzaToggle-unSelectedWhole';

    const toggleButtons = [
      {
        side: LEFT,
        outerStyle: 'halfWholePizzaToggle-halfCircleLeft',
        innerStyle: leftStyle,
        buttonText: 'HalfWholePizzaToggle.left',
      },
      {
        side: WHOLE,
        outerStyle: 'halfWholePizzaToggle-fullCircle',
        innerStyle: wholeStyle,
        buttonText: 'HalfWholePizzaToggle.whole',
      },
      {
        side: RIGHT,
        outerStyle: 'halfWholePizzaToggle-halfCircleRight',
        innerStyle: rightStyle,
        buttonText: 'HalfWholePizzaToggle.right',
      },
    ];

    return (
      toggleButtons.map((button) => {
        const testId = `${button.side}-side-${id}`;
        return (
          <div data-testid={testId} className={button.outerStyle} key={button.side}>
            <button className={button.innerStyle} onClick={() => this.sectionSelected(button.side)}>
              <Typography
                className={selectedSection === button.side
                  ? 'halfWholePizzaToggle-enabledText'
                  : 'halfWholePizzaToggle-disabledText'}
              >
                {translation(button.buttonText)}
              </Typography>
            </button>
          </div>
        );
      })
    );
  }

  render() {
    const { enableSectionSelection } = this.props;
    return (
      enableSectionSelection
      && (
        <div className="halfWholePizzaToggle-container">
          {this.renderButton()}
        </div>
      )
    );
  }
}

HalfWholePizzaToggle.propTypes = {
  translation: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  enableSectionSelection: PropTypes.bool.isRequired,
  selectedSection: PropTypes.string,
  id: PropTypes.number.isRequired,
};

HalfWholePizzaToggle.defaultProps = {
  selectedSection: '',
};

export default HalfWholePizzaToggle;

