import * as bundleFlags from '../../../feature-flags.json';
import ResourcesAPI from '../../services/api/ResourcesApi';
import versionInfo from '../../config/version.json';
import { store } from '../../config/configureStore';
import * as Actions from '../../actions/Actions';
import { customAssign } from '../functions/Functions';

const setIsAvailable = isAvailable => store.dispatch(Actions.setIsFetchedFeatureFlagsAvailable(isAvailable));
class FeatureFlag {
  values = undefined;
  isReady = false;

  static instance = null;

  static getInstance() {
    if (FeatureFlag.instance == null) {
      FeatureFlag.instance = new FeatureFlag();
      this.instance.init();
    }
    return this.instance;
  }

  init() {
    this.values = bundleFlags;
    this.downloadFile(0);
  }

  // eslint-disable-next-line class-methods-use-this
  readFile(remoteFlags, sideEffectFunc) {
    if (bundleFlags.functionality.enableUpdateFeatureFlag) {
      const combinedFlags = customAssign(bundleFlags, remoteFlags);
      this.values = combinedFlags;
    }
    if (sideEffectFunc && typeof sideEffectFunc === 'function') sideEffectFunc(true);
  }

  downloadFile(attempts) {
    if (bundleFlags.functionality.enableUpdateFeatureFlag) {
      ResourcesAPI.getResource(null, ['web/featureflag', versionInfo.version]).then((res) => {
        if (res && res.data && res.data.featureFlags) {
          console.log('feature flag is downloded successfully');
          this.readFile(res.data.featureFlags, setIsAvailable);
        } else {
          console.log('Cannot parse feature flag data');
          if (attempts >= 3) {
            setIsAvailable(false);
          } else {
            this.downloadFile(attempts + 1);
          }
        }
      }).catch((error) => {
        console.log('Error fetching feature flags', error);
        if (attempts >= 3) {
          setIsAvailable(false);
        } else {
          this.downloadFile(attempts + 1);
        }
      });
    }
  }
}

export const FeatureFlags = FeatureFlag.getInstance().values;
export const FeatureFlagClass = FeatureFlag;
