/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Button from '../../core/components/Button';

import * as Config from '../../../../dist/config.json';
import { getDocumentStyleVariables } from '../../../services/functions/Functions';
import { shouldUseApplePay, shouldUseGooglePay } from '../../../services/functions/PaymentFunctions';
import '../../../css/paymentPage/subComponents/SquarePaymentComponent.scss';
import ClickSquareApplePayButtonEvent from '../../../events/Payment/ClickSquareApplePayButtonEvent';
import ClickSquareGooglePayButtonEvent from '../../../events/Payment/ClickSquareGooglePayButtonEvent';
import withCloudEventBaseParams from '../../../events/HOCs/withCloudEventBaseParams';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';


class SquarePaymentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardButtonDisabled: false,
      squareCard: null,
      squareApplePay: null,
      squareGooglePay: null,
    };
  }

  componentDidMount = async () => {
    const { company, actions, isAddNewCardPage } = this.props;
    // eslint-disable-next-line no-undef
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    if (isEmpty(company)) {
      try {
        actions.getAllResources(null, ['companies']);
      } catch (error) {
        console.log('API call error while getting companies', error);
      }
    }
    let squarePaymentObject;
    try {
      squarePaymentObject = await this.initializeSquare();
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
    this.setState({ squareCard: squarePaymentObject.card });
    if (shouldUseApplePay(company, isAddNewCardPage)) this.setState({ squareApplePay: squarePaymentObject.applePay });
    if (shouldUseGooglePay(company, isAddNewCardPage)) {
      this.setState({ squareGooglePay: squarePaymentObject.googlePay });
    }
  }

  getSquarePaymentStyles = () => {
    const styleVariables = getDocumentStyleVariables();

    const squarePaymentStyles = {
      messageText:
        {
          color: styleVariables.text,
        },
      messageIcon:
        {
          color: styleVariables.text,
        },
      input:
        {
          backgroundColor: styleVariables['input-background-color'],
          color: styleVariables['input-text-color'],
        },
      inputPlaceholder:
        {
          color: styleVariables['input-text-color'],
        },
      inputContainer:
        {
          borderColor: styleVariables['input-border-color'],
        },
    };
    return squarePaymentStyles;
  }

  // Need to use Square's class selectors with the dots in front. Otherwise, the form crashes
  getCardStyleSelectors = () => {
    const squarePaymentStyles = this.getSquarePaymentStyles();
    return ({
      input: squarePaymentStyles.input,
      'input.is-error': {},
      'input.is-error::placeholder': {},
      'input.is-focus': {},
      'input.is-focus::placeholder': {},
      'input::placeholder': squarePaymentStyles.inputPlaceholder,
      '.message-text': squarePaymentStyles.messageText,
      '.message-text.is-error': {},
      '.message-icon': squarePaymentStyles.messageIcon,
      '.message-icon.is-error': {},
      '.input-container': squarePaymentStyles.inputContainer,
      '.input-container.is-error': {},
      '.input-container.is-focus': {},
    });
  }

  getCardOptions = () => ({ style: this.getCardStyleSelectors() })

  initializeSquare = async (resetCardForm) => {
    const { currentOrder, company, isAddNewCardPage } = this.props;
    const appId = Config.general.squareAppId;
    const locationId = get(currentOrder, 'location.squareId');
    const useApplePay = shouldUseApplePay(company, isAddNewCardPage);
    const useGooglePay = shouldUseGooglePay(company, isAddNewCardPage);
    // eslint-disable-next-line no-undef
    const payments = window.Square.payments(appId, locationId);
    const cardOptions = this.getCardOptions();
    const card = await payments.card(cardOptions);
    let googlePay;
    let applePay;

    if (isAddNewCardPage) {
      await card.attach('#card-container-add-card');
    } else {
      await card.attach('#card-container');
    }
    if (useApplePay && !resetCardForm) {
      try {
        applePay = await this.initializeDigitalPay(payments, 'Apple Pay');
      } catch (error) {
        applePay = null;
      }
    }
    if (useGooglePay && !resetCardForm) {
      try {
        googlePay = await this.initializeDigitalPay(payments, 'Google Pay');
      } catch (error) {
        googlePay = null;
      }
    }
    return { card, googlePay, applePay };
  }

  initializeDigitalPay = async (squarePayments, digitalWalletType) => {
    const { company, currentOrder } = this.props;
    const isApplePay = digitalWalletType === 'Apple Pay';
    const isGooglePay = digitalWalletType === 'Google Pay';
    const countryCode = get(company, 'countryCode') || 'US';
    const currencyCode = get(currentOrder, 'location.currencyCode') || 'USD';
    const orderTotal = get(currentOrder, 'totalPrice') || 0;
    const paymentRequest = squarePayments.paymentRequest({
      countryCode,
      currencyCode,
      total: {
        amount: orderTotal.toString(),
        label: 'Total',
      },
    });

    let digitalPay;
    if (isApplePay) {
      digitalPay = await squarePayments.applePay(paymentRequest);
    } else if (isGooglePay) {
      digitalPay = await squarePayments.googlePay(paymentRequest);
      await digitalPay.attach('#google-pay-button');
    }

    return digitalPay;
  }

  resetCardForm = async () => {
    const { squareCard } = this.state;
    await squareCard.destroy();
    let squarePaymentObject;
    const resetCardForm = true;
    try {
      squarePaymentObject = await this.initializeSquare(resetCardForm);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
    this.setState({ squareCard: squarePaymentObject.card });
  }

  handlePaymentMethodSubmission = async (paymentMethod, isApplePay, isGooglePay, clickEvent) => {
    const { cloudEventsBaseParams, user } = this.props;
    if (clickEvent) {
      let CloudEventClass = null;
      if (isApplePay) CloudEventClass = ClickSquareApplePayButtonEvent;
      if (isGooglePay) CloudEventClass = ClickSquareGooglePayButtonEvent;
      const cloudEvent = new CloudEventClass({
        ...cloudEventsBaseParams,
        ...getClickEventClientXY(clickEvent),
      });
      if (CloudEventClass) {
        CloudEventsApi.sendCloudEvent({
          cloudEvent,
          userToken: user && user.token,
        });
      }
    }

    try {
      // disable the submit button as we await tokenization and make a
      // payment request.
      this.setState({ cardButtonDisabled: true });
      const result = await this.tokenize(paymentMethod);
      if (isApplePay) {
        const applePayResult = await this.handleDigitalPayment(result, isApplePay);
        this.displayPaymentResults('SUCCESS');
        console.debug('Payment Success', applePayResult);
      } else if (isGooglePay) {
        const googlePayResult = await this.handleDigitalPayment(result);
        this.displayPaymentResults('SUCCESS');
        console.debug('Payment Success', googlePayResult);
      } else {
        const addCardResult = await this.addCard(result);
        this.displayPaymentResults('SUCCESS');
        console.debug('Payment Success', addCardResult);
      }
    } catch (e) {
      this.setState({ cardButtonDisabled: false });
      this.displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  }

  handleDigitalPayment = async (tokenizeResult, isApplePay = false) => {
    const { handleProcessPayment } = this.props;
    const cardToken = tokenizeResult.token;
    // -1 is the marker for digital wallet and the id the API is looking for
    const paymentMethod = {
      id: -1,
      name: isApplePay ? 'Apple Pay' : 'Google Pay',
      nonce: cardToken,
    };
    handleProcessPayment(paymentMethod);
  }

  addCard = async (tokenizeResult) => {
    const { user, actions, handleAddCard } = this.props;
    const card = tokenizeResult.details;
    const cardToken = tokenizeResult.token;

    const paymentMethod = {
      name: user.name,
      cardNumber: cardToken,
      expiryMonth: card.expMonth,
      expiryYear: card.expYear,
    };

    const newCardResponse = await actions
      .addResource(
        user.token, paymentMethod,
        'users', user.id,
        'payment_options',
      );
    this.resetCardForm();
    this.setState({ cardButtonDisabled: false });
    if (newCardResponse.id) {
      handleAddCard(newCardResponse);
    }
    if (!newCardResponse || newCardResponse.error) {
      console.log('There was an error', newCardResponse.error);
    }
  }

  // This function tokenizes a payment method.
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult;
    }
    let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }
    throw new Error(errorMessage);
  }

  // Helper method for displaying the Payment Status on the screen.
  // status is either SUCCESS or FAILURE;
  displayPaymentResults = (status) => {
    // eslint-disable-next-line no-undef
    const statusContainer = document.getElementById('payment-status-container');
    if (!statusContainer) return;
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  };

  renderApplePayButton = () => {
    const { squareApplePay } = this.state;
    // Need to use inline style so vendor specific attributes work
    const appleButtonStyle = {
      display: 'inline-block',
      WebkitAppearance: '-apple-pay-button',
      ApplePayButtonType: 'plain',
      ApplePayButtonStyle: 'black',
      width: '100%',
      margin: '8px 0 0 0',
    };
    return (
      <button
        style={appleButtonStyle}
        onClick={(clickEvent) => this.handlePaymentMethodSubmission(squareApplePay, true, false, clickEvent)}
        id="apple-pay-button"
      />
    );
  }

  renderAddCardButton = () => {
    const { cardButtonDisabled, squareCard } = this.state;
    const { translation } = this.props;
    return (
      <Button
        disabled={cardButtonDisabled}
        onClick={() => this.handlePaymentMethodSubmission(squareCard, false, false)}
        id="card-button"
        text={translation('SquarePaymentComponent.addCardButton')}
        type="primary"
      />
    );
  }

  renderAddNewCardPageButtons = () => {
    const {
      isAddNewCardPage, onPressCancel, translation,
    } = this.props;
    return (
      <div className="squarePaymentComponent-buttonContainer">
        {
          isAddNewCardPage
          && (
            <Button
              className="squarePaymentComponent-cancelButton"
              overrideClass
              onClick={onPressCancel}
              text={translation('CANCEL')}
              type="secondary"
            />
          )
        }
        {this.renderAddCardButton()}
      </div>
    );
  }

  render() {
    const { squareGooglePay } = this.state;
    const {
      company, isAddNewCardPage,
    } = this.props;

    const cardContainerId = isAddNewCardPage ? 'card-container-add-card' : 'card-container';

    return (
      <div>
        <form id="payment-form">
          <div id={cardContainerId} />
          {
            isAddNewCardPage ? this.renderAddNewCardPageButtons() : this.renderAddCardButton()
          }
        </form>
        {
          shouldUseApplePay(company, isAddNewCardPage)
          && (
            this.renderApplePayButton()
          )
        }
        {
          shouldUseGooglePay(company, isAddNewCardPage)
          && (
            <div className="squarePaymentComponent-googlePayContainer">
              <div
                id="google-pay-button"
                onClick={(clickEvent) => this.handlePaymentMethodSubmission(squareGooglePay, false, true, clickEvent)}
                role="button"
                tabIndex={0}
                aria-label="google pay button"
              />
            </div>
          )
        }
        <div id="payment-status-container" />
      </div>
    );
  }
}

SquarePaymentComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  isAddNewCardPage: PropTypes.bool,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  onPressCancel: PropTypes.func,
  cloudEventsBaseParams: PropTypes.objectOf(PropTypes.any).isRequired,
};

SquarePaymentComponent.defaultProps = {
  isAddNewCardPage: false,
  onPressCancel: () => { },
};

export default withCloudEventBaseParams(SquarePaymentComponent);
