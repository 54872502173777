import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { LinearProgress, Typography } from '@material-ui/core';

import { getUser, getLoading, getCurrentOrder, getCompany } from '../../selectors';
import Routes from '../../services/routes/Routes.json';
import SquarePaymentComponent from '../paymentPage/subComponents/SquarePaymentComponent';
import ToastPaymentComponent from '../paymentPage/subComponents/ToastPaymentComponent';
import CraverCreditCardForm from '../core/components/CraverCreditCardForm';

import '../../css/addNewCardPage/AddNewCardPage.scss';

const AddNewCardPage = ({
  history, actions, onPressCancel, translation,
}) => {
  const user = get(useSelector(getUser), 'user');
  const loading = useSelector(getLoading);
  const currentOrder = useSelector(getCurrentOrder);
  const company = useSelector(getCompany);
  useEffect(() => {
    if (!user) history.push(Routes.path.menuPage);
  }, [user]);

  const handleAddCard = () => {
    // No need to do anything after the card is added. So the user is taken back to the Payment Methods Page
    // By calling the onPressCancel function
    onPressCancel();
  };

  const renderCreditCardForm = () => {
    const paymentIntegration = get(company, 'paymentIntegration', 'NONE');
    const commonProps = {
      user, actions, translation, currentOrder,
    };
    switch (paymentIntegration) {
      case 'SQUARE':
        return <SquarePaymentComponent {...commonProps} handleAddCard={handleAddCard} isAddNewCardPage onPressCancel={onPressCancel} />;
      case 'TOAST':
        return <ToastPaymentComponent {...commonProps} handleAddCard={handleAddCard} />;
      default:
        return <CraverCreditCardForm {...commonProps} handleAddCard={handleAddCard} onPressCancel={onPressCancel} />;
    }
  };

  return (
    <div>
      {loading !== 0 && <LinearProgress />}
      <div className="addNewCardPage-pageContent">
        <div className="addNewCardPage-inputSection">
          <Typography>{translation('AddNewCardPage.title')}</Typography>
          {renderCreditCardForm()}
        </div>
      </div>
    </div>
  );
};

AddNewCardPage.propTypes = {
  onPressCancel: PropTypes.func,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

AddNewCardPage.defaultProps = {
  user: null,
  onPressCancel: null,
};


export default withRouter(AddNewCardPage);
