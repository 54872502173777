import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import versionInfo from '../../../config/version.json';
import { addCurrentCopyrightYear } from '../../../services/functions/Functions';

import '../../../css/core/components/FooterComponent.scss';

/* eslint-env browser */

const FooterComponent = (props) => {
  const { options, translation } = props;
  const optionLength = options.length;

  // TODO: Assign appropriate links to terms of use, privacy statement, and legal disclaimer
  return (
    <footer className="footerComponent-footer">
      <div className="footerComponent-footerContent">
        <div className="footerComponent-optionsStyle">
          {
            options.map((item, index) => (
              <Typography variant="caption" key={item.id} className="footerComponent-optionsText">
                <a href={item.ref} className="footerComponent-optionsText">{translation(item.title)}</a>
                {index !== optionLength - 1 ? '\u00A0|\u00A0' : ''}
              </Typography>
             ))
          }
        </div>
        <Typography variant="caption" className="footerComponent-copyrightText">
          {addCurrentCopyrightYear(`${translation('FooterComponent.copyright')} ${window.companyName}`)}
          {` - ${translation('FooterComponent.version')} ${versionInfo.version}  ${translation('FooterComponent.build')} ${versionInfo.build}`}
        </Typography>
      </div>
    </footer>
  );
};

FooterComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  translation: PropTypes.func.isRequired,
};

export default FooterComponent;
