import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';
import { compareValues } from '../services/functions/Functions';

const initialState = Immutable({
  notifications: [],
});

export default function NotificationReducer(state = initialState, action) {
  let updatedNotifications;
  switch (action.type) {
    case types.GET_USER_NOTIFICATIONS_SUCCESS:
      return Immutable.merge(state, { notifications: action.notifications.sort(compareValues('id', 'desc')) });
    case types.UPDATE_NOTIFICATION_SUCCESS:
      updatedNotifications = state.notifications.filter(note => note.id !== action.notification.id).concat(action.notification);
      return { notifications: updatedNotifications };
    default:
      return state;
  }
}
