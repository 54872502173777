/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import get from 'lodash/get';

import * as Functions from '../../../services/functions/Functions';
import { getUserOrders } from '../../../selectors';
import '../../../css/ordersPage/subComponents/UnavailableItem.scss';

const getUnavaliablePictureSrc = (reorderId, reOrderItemId, orders) => {
  // If at any point a required object is not found, we'll return the placeholder image.
  const placeholderImageSrc = Functions.getImageUrl('image-placeholder.png');
  const reOrder = orders.find(order => order.id === reorderId);
  if (!reOrder) return placeholderImageSrc;

  const reOrderItem = reOrder.items.find(item => item.id === reOrderItemId);
  if (!reOrderItem) return placeholderImageSrc;

  const reOrderProductItem = reOrderItem.productItem;
  if (!reOrderProductItem || !reOrderProductItem.images[0]) return placeholderImageSrc;

  return reOrderProductItem.images[0].src;
};

const getReasonString = (unavailablilityReason, translation, orders, reorderId) => {
  const reasonString = translation(`OrdersPage.ReOrderDialog.unavailableReasons.${unavailablilityReason}`);
  if (!['UNAVAILABLE_FOR_DELIVERY_TYPE', 'UNAVAILABLE_FOR_LOCATION'].includes(unavailablilityReason)) {
    return reasonString;
  }
  const reOrder = orders.find(order => order.id === reorderId);
  if (!reOrder) return '';
  if (unavailablilityReason === 'UNAVAILABLE_FOR_DELIVERY_TYPE') {
    const deliveryTypeString = translation(reOrder.deliveryOption);
    return `${reasonString} ${deliveryTypeString}`;
  }
  if (unavailablilityReason === 'UNAVAILABLE_FOR_LOCATION' && reOrder.location) {
    const locationString = translation(reOrder.location.name);
    return `${reasonString} ${locationString}`;
  }
  return reasonString;
};

const getUnavailableOrderItem = (orders, reorderId, unavailableItem) => {
  const reOrder = orders.find(o => o.id === reorderId);
  if (!reOrder) return null;

  return reOrder.items.find(orderItem => orderItem.id === unavailableItem.orderItemId);
};

const getUnavailableReasons = (unavailableItem, translation, orders, reorderId, mainEntityType) => (
  <Fragment>
    {
        unavailableItem.unavailabilityDetail.map((unavailableDetail, index) => {
          if (unavailableDetail.entityType === mainEntityType) {
            return (
              <Typography
                key={Functions.generateKey(index)}
                className="unavailableReason"
              >
                {getReasonString(unavailableDetail.reason, translation, orders, reorderId)}
              </Typography>);
            }
          return null;
         })
      }
  </Fragment>
);

const UnavailableItem = (props) => {
  const {
    unavailableItem, orders, reorderId, translation,
  } = props;
  if (!unavailableItem) return null;
  // We get a list of unavailable item details,
  const unavailabilityDetailArray = unavailableItem.unavailabilityDetail;
  const firstUnavailableReason = unavailabilityDetailArray[0];

  const getOptionName = (unavailableOption) => {
    const unavailableOrderItem = getUnavailableOrderItem(orders, reorderId, unavailableItem);
    if (!unavailableOrderItem) return '';

    const productItemCustomizations = get(unavailableOrderItem, 'productItem.customizations');
    if (!productItemCustomizations) return '';

    const allOptions = productItemCustomizations.map(customization => customization.options).flat();
    const optionWithFullName = allOptions.find(option => option.id === unavailableOption.entityId);
    if (!optionWithFullName) return '';

    return optionWithFullName.name;
  };

  const getProductItemName = () => {
    const unavailableOrderItem = getUnavailableOrderItem(orders, reorderId, unavailableItem);
    if (!unavailableOrderItem) return '';

    return get(unavailableOrderItem, 'productItem.name', '');
  };

  const unavailableEntityName = firstUnavailableReason.entityType === 'OPTION' ? getOptionName(firstUnavailableReason) : firstUnavailableReason.name;
  const unavailableItemImageSrc = getUnavaliablePictureSrc(reorderId, unavailableItem.orderItemId, orders);
  return (
    <div className="unavailableItem">
      <img src={unavailableItemImageSrc} className="unavailableItemPicture" alt={unavailableEntityName} />
      <div className="unavailableItemDetails">
        <Typography className="unavailableItemTitle">{unavailableEntityName}</Typography>
        {
          firstUnavailableReason.entityType === 'OPTION'
          && <Typography className="unavailableItemAttached">{`${translation('OrdersPage.ReOrderDialog.attachedTo')} ${getProductItemName()}`}</Typography>
        }
        {getUnavailableReasons(unavailableItem, translation, orders, reorderId, firstUnavailableReason.entityType)}
      </div>
    </div>
  );
};

UnavailableItem.propTypes = {
  reorderId: PropTypes.number,
  unavailableItem: PropTypes.objectOf(PropTypes.any).isRequired,
  orders: PropTypes.arrayOf(PropTypes.any),
  translation: PropTypes.func.isRequired,
};

UnavailableItem.defaultProps = {
  reorderId: null,
  orders: null,
};

const mapStateToProps = state => ({
  orders: getUserOrders(state),
});

export default connect(mapStateToProps)(UnavailableItem);
