import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { ChevronRight } from '@material-ui/icons';
import { arrayOf, objectOf, string, any, func, number } from 'prop-types';
import {
  Typography,
  List,
  ListItem,
  Card,
  withWidth,
} from '@material-ui/core';

import * as Routes from '../../services/routes/Routes.json';
import * as Actions from '../../actions/Actions';
import { getNotifications } from '../../selectors';
import {
  isMobileMode,
  isDesktopMode,
  compareValues,
} from '../../services/functions/Functions';
import { checkForNotifications } from '../../services/helpers/CustomHooks';
import DateHelper from '../../services/helpers/DateHelper';
import '../../css/notificationsPage/NotificationsPage.scss';
import PageTitle from '../core/components/PageTitle';

const MESSAGE_READ = true;
const MESSAGE_UNREAD = false;

const SelectedMessage = (props) => {
  const { translation, message } = props;

  return (
    <Card className="notificationsPage-messageContainer">
      <Typography className="notificationTitleMsgView">
        {message.title}
      </Typography>
      <Typography className="notificationDateTimeMsgView">
        {DateHelper.getSuggestedTimeIntervalString(message.createdAt, translation('NotificationsPage.at'))}
      </Typography>
      <Typography className="notificationBodyMsgView">
        {message.body}
      </Typography>
    </Card>
  );
};

const Notification = (props) => {
  const {
    translation,
    user,
    history,
    notifications,
    notification,
    setNotificationId,
    updateNotification,
    width,
    selectedNoteId,
    getUserNotifications,
  } = props;

  const getListItemStyle = (note) => {
    if (note.id === selectedNoteId && notification.isRead === MESSAGE_READ) {
      return 'listItemSelected';
    } else if (note.isRead === MESSAGE_UNREAD) {
      return 'listItemUnread';
    }
    return 'notificationsPage-listItemStyle';
  };

  const handleClickNotification = async (noteId) => {
    setNotificationId(noteId);

    const selectedNotification = notifications.find(note => note.id === noteId);
    const mutableNotification = Immutable.asMutable(selectedNotification, { deep: true });

    if (selectedNotification.isRead === false) {
      mutableNotification.isRead = true;
      try {
        await updateNotification(user.token, mutableNotification, ['push_notifications', noteId, 'users', user.id]);
      } catch (error) {
        console.log('Error updating message status', error);
      }
    }
    if (isMobileMode(width)) history.push(`${Routes.path.notificationsPage}/${selectedNotification.id}`);
    getUserNotifications(user);
  };

  return (
    <ListItem
      className={getListItemStyle(notification)}
      onClick={() => handleClickNotification(notification.id)}
    >
      <div>
        <Typography className="notificationTitleListView">
          {notification.title}
        </Typography>
        <Typography className="notificationBodyListView">
          {notification.body}
        </Typography>
        <Typography className="notificationDateTimeListView">
          {DateHelper.getSuggestedTimeIntervalString(notification.createdAt, translation('NotificationsPage.at'))}
        </Typography>
      </div>
      <div className="iconContainerStyle">
        <ChevronRight className="notificationsPage-iconStyle" />
      </div>
    </ListItem>
  );
};

const NotificationsComponent = (props) => {
  const {
    history,
    user,
    translation,
    notifications,
    width,
    upliftHistory,
  } = props;

  useEffect(() => {
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);
  }, []);

  if (!user) {
    history.push(Routes.path.menuPage);
    return null;
  }

  checkForNotifications(user);

  const [notificationId, setNotificationId] = useState(null);

  const getSelectedMessage = notificationId
    ? notifications.find(note => note.id === notificationId)
    : '';

  const generateKey = i => (`key${i}`);

  const mutableNotifications = Immutable.asMutable(notifications, { deep: true });
  const getMessages = isMessageRead => mutableNotifications.filter(note => note.isRead === isMessageRead).sort(compareValues('id', 'desc'));
  const sortedNotifications = getMessages(MESSAGE_UNREAD).concat(getMessages(MESSAGE_READ));

  return (
    <div className="notificationsPage-pageContent">
      <PageTitle title={translation('NotificationsPage.title')} />
      <div className="notificationsPage-outerContainer">
        <List className="listContainer">
          {
            sortedNotifications.map((notification, i) => (
              <Notification
                {...props}
                notification={notification}
                index={i}
                setNotificationId={setNotificationId}
                key={generateKey(i)}
                selectedNoteId={notificationId}
              />
              ))
          }
        </List>
        {
          getSelectedMessage && isDesktopMode(width)
          && (
            <SelectedMessage
              {...props}
              message={getSelectedMessage}
            />
          )
        }
      </div>
    </div>
  );
};

NotificationsComponent.propTypes = {
  translation: func.isRequired,
  history: objectOf(any).isRequired,
  upliftHistory: func.isRequired,
  user: objectOf(any),
  notifications: arrayOf(objectOf(any)),
  width: string,
};

NotificationsComponent.defaultProps = {
  user: null,
  notifications: [],
  width: '',
};

Notification.propTypes = {
  translation: func.isRequired,
  getUserNotifications: func.isRequired,
  history: objectOf(any).isRequired,
  notification: objectOf(any).isRequired,
  setNotificationId: func.isRequired,
  updateNotification: func.isRequired,
  notifications: arrayOf(objectOf(any)),
  user: objectOf(any),
  width: string,
  selectedNoteId: number,
};

Notification.defaultProps = {
  user: null,
  notifications: [],
  width: '',
  selectedNoteId: null,
};

SelectedMessage.propTypes = {
  translation: func.isRequired,
  message: objectOf(any).isRequired,
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  updateNotification: (apiToken, notification, resourcePath) =>
    dispatch(Actions.updateNotification(apiToken, notification, resourcePath)),
  getUserNotifications: user =>
    dispatch(Actions.getUserNotifications(user.token, ['push_notifications', 'users', user.id])),
});

const NotificationsPage = compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(withWidth()(NotificationsComponent)));

export default NotificationsPage;
