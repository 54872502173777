import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinearProgress, Typography, Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import '../../css/subscriptionsPage/SubscriptionsPage.scss';
import { getSubscriptionsOffers, getUserSubscriptions, getLoading } from '../../selectors';
import SubscriptionTierCard from './SubscriptionTierCard';
import { getActiveSubscription, getAvailableSubscriptionOffers, getSortedSubscriptionOffers } from './utils/SubscriptionsPageUtils';
import * as Routes from '../../services/routes/Routes.json';
import { POSITIONED_ANCESTOR_CLASS_NAMES } from '../../events/Subscription/constants';
import useTrackParentHeight from '../../events/hooks/useTrackParentHeight';

let isSubscriptionsPageBlurred = false;

const NoAvailableTiers = ({ translation }) => (
  <div className="SubscriptionsPage-main">
    <Typography className="SubscriptionsPage-noTierText">
      {translation('SubscriptionsPage.noSubscriptions')}
    </Typography>
  </div>
);

const SubscriptionsPage = ({
  translation, subscriptionOffers, user, history, actions, userSubscriptions, loading, upliftHistory,
}) => {
  useEffect(() => {
    // Set Subscriptions Offers
    actions.getAllResources(null, ['subscriptions', '-', 'offers']);
    // Set User Subscriptions
    if (user) {
      actions.getAllResources(user.token, ['user-subscriptions']);
    }
  }, []);

  const { cardsParentRef, cardsParentHeight } = useTrackParentHeight();

  useEffect(() => {
    // Redirect not logged in or guest users to menu page
    if (!user) history.push(Routes.path.menuPage);
  }, [user]);

  useEffect(() => {
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);
  }, []);

  if (isEmpty(subscriptionOffers)) return <NoAvailableTiers translation={translation} />;

  const availableSubscriptionOffers = getAvailableSubscriptionOffers(subscriptionOffers);
  const sortedSubscriptions = getSortedSubscriptionOffers(availableSubscriptionOffers, userSubscriptions);

  window.onblur = () => { isSubscriptionsPageBlurred = true; };
  window.onfocus = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isSubscriptionsPageBlurred && location.pathname === '/subscriptions') {
      // eslint-disable-next-line no-restricted-globals
      location.reload(true);
    }
  };

  if (loading !== 0) return <LinearProgress />;

  return (
    <div className={POSITIONED_ANCESTOR_CLASS_NAMES.SUBSCRIPTIONS_PAGE_MAIN} ref={cardsParentRef}>
      <Grid container justifyContent="center" spacing={1}>
        {
          sortedSubscriptions.map((offer) => {
            const activeSubscription = getActiveSubscription(userSubscriptions, offer);
            return (
              <SubscriptionTierCard
                key={offer.id}
                userSubscription={activeSubscription}
                translation={translation}
                subscriptionOffer={offer}
                parentHeight={cardsParentHeight}
              />
            );
          })
        }
      </Grid>
    </div>
  );
};

NoAvailableTiers.propTypes = {
  translation: PropTypes.func.isRequired,
};

SubscriptionsPage.propTypes = {
  subscriptionOffers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  userSubscriptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loading: PropTypes.number.isRequired,
  upliftHistory: PropTypes.func.isRequired,
};

SubscriptionsPage.defaultProps = {
  user: null,
  subscriptionOffers: [],
  userSubscriptions: [],
};

const mapStateToProps = state => ({
  subscriptionOffers: getSubscriptionsOffers(state),
  userSubscriptions: getUserSubscriptions(state),
  loading: getLoading(state),
});

export default connect(mapStateToProps)(withRouter(SubscriptionsPage));
