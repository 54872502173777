import difference from 'lodash/difference';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Immutable from 'seamless-immutable';
import * as Locations from './Locations';
import * as Constants from '../constants/Constants';

const rejectHandler = reason => ({ status: 'rejected', reason });
const resolveHandler = value => ({ status: 'fulfilled', value });

function promiseAllSettled(promises) {
  const convertedPromises = promises.map(p => Promise.resolve(p).then(resolveHandler, rejectHandler));
  return Promise.all(convertedPromises);
}

const polyfillPromiseAllSettled = () => {
  if (Promise.allSettled) return;
  Promise.allSettled = promiseAllSettled;
};

export const getItemsInvalidatedByDeliveryOption = ({ currentOrder, deliveryOption, unavailableCategories }) => {
  const { items } = currentOrder;
  const invalidatedItems = {};
  if (!Array.isArray(items)) return invalidatedItems;

  items.forEach((item) => {
    const { id: orderItemId, productItem: { categoryId, name, id: itemId } } = item;
    if (!get(unavailableCategories, `${deliveryOption}.${categoryId}`)) return;

    const itemInfo = { itemId, name, categoryId };

    // Order items added while signed in have orderItemIds, if signed out use the productItem.id
    const lookupKey = orderItemId || itemId;
    invalidatedItems[lookupKey] = itemInfo;
  });

  return invalidatedItems;
};

export const filterOutInvalidItems = (items, invalidatedOrderItems) => {
  if (!invalidatedOrderItems || Object.keys(invalidatedOrderItems).length === 0 || !Array.isArray(items)) return items;
  return items.filter(orderItem => orderItem.id && !invalidatedOrderItems[orderItem.id]);
};

const unredeemPoints = async (actions, user, item, orderId) => {
  try {
    const updateResponse = await actions.updateOrderItem(user, item, orderId);
    if (updateResponse && updateResponse.error) {
      console.log('API call error', updateResponse.error);
    }
    return updateResponse;
  } catch (error) {
    console.log('API call error', error);
  }
};

const deleteOrderItem = async (orderItemId, actions, user, orderId) => {
  try {
    if (!orderItemId) return {};
    const deleteItemResponse = await actions.deleteOrderItem(user, Number(orderItemId), orderId);
    if (deleteItemResponse && deleteItemResponse.error) {
      console.log('API call error', deleteItemResponse.error);
    }
    return deleteItemResponse;
  } catch (error) {
    console.log('API call error', error);
  }
};

/**
 * When logged out, items added to orders are identified via their productItem.id.
 * When logged in, items added to orders are identified using their orderItem.id
 *
 * Without tracking this difference, you cannot remove items from the Redux store properly while logged out
 * @param {*} item
 * @param {*} user
 * @returns
 */
export const getItemId = (item, user) => (user ? get(item, 'id') : get(item, 'productItem.id'));

const getItemIdsOfSuccessResponses = (responses, user, arrayItemIdsForIterationOrder) => {
  const successfulIds = [];

  if (!user) {
    responses.forEach((response, ind) => {
      const hasError = get(response, 'value.error');
      const value = get(response, 'value');

      // When ItemUtils.removeItemsFromOrder decides an item should not be redeemed or deleted,
      // instead of running an action with a possible API update,
      // its Promise will resolve right away to an empty object
      const isNotAffected = response.status === 'fulfilled' && isEqual(value, {});
      if (response.status !== 'fulfilled' || hasError || isNotAffected) return;
      const id = arrayItemIdsForIterationOrder[ind];
      successfulIds.push(id);
    });
    return successfulIds;
  }

  responses.forEach((response, ind) => {
    const hasError = get(response, 'value.error');
    const isEmptyFulfilledValue = response.status === 'fulfilled' && isEmpty(get(response, 'value'));
    if (response.status !== 'fulfilled' || hasError || isEmptyFulfilledValue) return;
    const id = arrayItemIdsForIterationOrder[ind];
    successfulIds.push(id);
  });

  return successfulIds;
};

/**
 * Also unredeems any redeemed items
 *
 * Returns undeleted and still-redeemed item ids, so that call sites can handle these errors
 */
export const removeItemsFromOrder = async (invalidOrderItemsMap, currentOrder, actions, user) => {
  polyfillPromiseAllSettled();
  const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
  const mutableOrderItems = get(mutableOrder, 'items', []);

  // Capture the iteration order
  const orderItemsIdArray = mutableOrderItems.map(item => getItemId(item, user));
  const unredeemPointsPromises = mutableOrderItems.map(async (orderItem) => {
    const id = getItemId(orderItem, user);
    if (!orderItem || !invalidOrderItemsMap[id]) return {};
    const item = Immutable.asMutable(orderItem, { deep: true });
    if (item.redeemedPoints > 0) item.redeemedPoints = 0;

    const response = await unredeemPoints(actions, user, item, currentOrder.id);
    return response;
  });

  const responses = await Promise.allSettled(unredeemPointsPromises);
  const successfulUnredeemedItemsIds = getItemIdsOfSuccessResponses(responses, user, orderItemsIdArray);
  const invalidItemsIdArray = Object.keys(invalidOrderItemsMap).map(id => Number(id));
  const stillRedeemedItems = difference(invalidItemsIdArray, successfulUnredeemedItemsIds)

  const deleteOrderItemPromises = successfulUnredeemedItemsIds.map(async (orderItemId) => {
    const response = await deleteOrderItem(orderItemId, actions, user, currentOrder.id);
    return response;
  });

  const deleteResponses = await Promise.allSettled(deleteOrderItemPromises);
  const successfulDeletes = getItemIdsOfSuccessResponses(deleteResponses, user, successfulUnredeemedItemsIds);

  const undeletedItems = difference(invalidItemsIdArray, successfulDeletes);
  return { stillRedeemedItems, undeletedItems, successfulUnredeemedItemsIds };
};

/**
 * This fixes bugs related to when users log back in and reload unfinished orders with database ids,
 * switching delivery options to DELIVERY or CATERING requires an address on the order.
 *
 * Without an address, the API thinks an address is missing and returns an error instead of a new currentOrder object,
 * and the reducers will not change currentOrder with the new delivery option.
 * But the API will persist the delivery option change because UserResource.editOrderById
 * runs UserResource.setDeliveryInformation / setCateringInformation.
 *
 * The delivery options become out of sync between the front and back end, and you cannot add items
 * you should be able to add.
 */
export const shouldAddLastAddressToOrder = (currentOrder, deliveryOption, userAddresses) => {
  const orderNeedsAddress = Constants.usesAddresses.includes(deliveryOption) && !currentOrder.address;
  if (!Array.isArray(userAddresses)) return {};
  const lastAddress = Locations.getDefaultAddress(userAddresses);

  return { orderNeedsAddress, lastAddress };
};

export const hasInvalidItems = invalidItemsState => Object.keys(invalidItemsState).length > 0;

export const getNewStateForInvalidItems = ({
  deliveryOption,
  currentOrder,
  unavailableCategories,
  makeInvalidItemsUIStateFunction,
}) => {
  const getInvalidItemsArgs = { currentOrder, deliveryOption, unavailableCategories };
  const invalidatedOrderItems = getItemsInvalidatedByDeliveryOption(getInvalidItemsArgs);

  const newInvalidItemsUIStateArgs = { currentOrder, deliveryOption };
  const invalidItemState = makeInvalidItemsUIStateFunction(newInvalidItemsUIStateArgs);
  const newState = {
    ...(hasInvalidItems(invalidatedOrderItems) && invalidItemState),
    invalidatedOrderItems,
  };
  return newState;
};
