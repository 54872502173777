import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';

const CheckBoxComponent = (props) => {
  const {
    id,
    field,
    selectedValue,
    handleChange,
    formControlLabelStyle,
    disabled,
    labelStyle,
    containerStyle,
    component,
    checkboxContentStyle,
    optionDescriptionStyle,
  } = props;

  return (
    <div className={checkboxContentStyle}>
      <div className={containerStyle}>
        <FormControlLabel
          id={id}
          control={
            <Checkbox
              data-testid={id}
              checked={selectedValue}
              onChange={e => handleChange(e, field)}
              value={field.fieldKey || field.id.toString()}
              disabled={disabled}
            />
          }
          label={field.title}
          classes={{ label: labelStyle }}
          className={formControlLabelStyle}
        />
        {
          component
        }
      </div>
      {
        field.description
        && (
          <Typography className={optionDescriptionStyle}>
            {field.description}
          </Typography>
        )
      }
    </div>
  );
};

CheckBoxComponent.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedValue: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  labelStyle: PropTypes.string,
  formControlLabelStyle: PropTypes.string,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.string,
  component: PropTypes.objectOf(PropTypes.any),
  checkboxContentStyle: PropTypes.string,
  optionDescriptionStyle: PropTypes.string,
};

CheckBoxComponent.defaultProps = {
  selectedValue: false,
  labelStyle: '',
  formControlLabelStyle: '',
  disabled: false,
  containerStyle: '',
  component: null,
  checkboxContentStyle: '',
  optionDescriptionStyle: '',
};

export default CheckBoxComponent;
