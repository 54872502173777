import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

export default function loadingReducer(state = Immutable({ loading: 0, dialogLoading: 0 }), action) {
  switch (action.type) {
    case types.SET_IS_FETCHED_FEATURE_FLAGS_AVAILABLE:
      return Immutable.set(state, 'isFeatureFlagsAvailable', action.isAvailable);
    case types.LOGIN_USER_REQUEST:
    case types.SIGNUP_USER_REQUEST:
    case types.VERIFY_USER_REQUEST:
    case types.SEND_VERIFICATION_TOKEN_REQUEST:
    case types.RESET_PASSWORD_REQUEST:
    case types.UPDATE_USER_REQUESTED:
    case types.SEND_RESET_PASSWORD_TOKEN_REQUEST:
    case types.CHECK_ADDRESS_REQUESTED:
    case types.ADD_ADDRESS_REQUESTED:
    case types.CHECK_DESIRED_TIME:
    case types.GET_TIME_SUGGESTIONS:
      return Immutable.merge(state, { dialogLoading: state.dialogLoading + 1 });
    case types.GET_RESOURCE_REQUESTED:
    case types.GET_ALL_RESOURCES_REQUESTED:
    case types.UPDATE_RESOURCE_REQUESTED:
    case types.ADD_RESOURCE_REQUESTED:
    case types.DELETE_RESOURCE_REQUESTED:
    case types.GET_USER_NOTIFICATIONS_REQUEST:
    case types.UPDATE_NOTIFICATION_REQUEST:
      return Immutable.merge(state, { loading: state.loading + 1 });
    case types.ERROR:
    case types.LOGIN_USER_FAILURE:
    case types.LOGIN_USER_SUCCESS:
    case types.SIGNUP_USER_FAILURE:
    case types.SIGNUP_USER_SUCCESS:
    case types.VERIFY_USER_FAILURE:
    case types.VERIFY_USER_SUCCESS:
    case types.RESET_PASSWORD_FAILURE:
    case types.RESET_PASSWORD_SUCCESS:
    case types.UPDATE_USER_FAILURE:
    case types.UPDATE_USER_SUCCESS:
    case types.SEND_VERIFICATION_TOKEN_FAILURE:
    case types.SEND_VERIFICATION_TOKEN_SUCCESS:
    case types.SEND_RESET_PASSWORD_TOKEN_FAILURE:
    case types.SEND_RESET_PASSWORD_TOKEN_SUCCESS:
    case types.CHECK_ADDRESS_SUCCESS:
    case types.ADD_ADDRESS_SUCCESS:
    case types.ADD_ADDRESS_FAILURE:
    case types.CHECK_DESIRED_TIME_SUCCESS:
    case types.CHECK_DESIRED_TIME_FAILURE:
    case types.GET_TIME_SUGGESTIONS_SUCCESS:
    case types.GET_TIME_SUGGESTIONS_FAILURE:
      return Immutable.merge(state, { dialogLoading: state.dialogLoading - 1 });
    case types.GET_RESOURCE_FAILURE:
    case types.GET_RESOURCE_SUCCESS:
    case types.GET_ALL_RESOURCES_FAILURE:
    case types.GET_ALL_RESOURCES_SUCCESS:
    case types.UPDATE_RESOURCE_FAILURE:
    case types.UPDATE_RESOURCE_SUCCESS:
    case types.ADD_RESOURCE_FAILURE:
    case types.ADD_RESOURCE_SUCCESS:
    case types.DELETE_RESOURCE_FAILURE:
    case types.DELETE_RESOURCE_SUCCESS:
    case types.CREATE_ORDER_SUCCESS:
    case types.UPDATE_ORDER_SUCCESS:
    case types.RESOURCES_FOR_INNER_FUNCTION_SUCCESS:
    case types.CREATE_ORDER_ITEM_SUCCESS:
    case types.MAKE_PAYMENT_SUCCESS:
    case types.DELETE_ORDER_ITEM_SUCCESS:
    case types.UPDATE_ORDER_ITEM_SUCCESS:
    case types.GET_USER_NOTIFICATIONS_SUCCESS:
    case types.GET_USER_NOTIFICATIONS_FAILURE:
    case types.UPDATE_NOTIFICATION_SUCCESS:
    case types.UPDATE_NOTIFICATION_FAILURE:
      return Immutable.merge(state, { loading: state.loading - 1 });
    case types.LOGOUT_USER:
    default:
      return Immutable.merge(state, { loading: state.loading, dialogLoading: state.dialogLoading });
  }
}
