import { TOGGLE_COMPONENT_KEYS } from '../../constants/ToggleComponentKeys';
import versionInfo from '../../../config/version.json';

const EVENT_SOURCE = `/craver-web/${versionInfo.version}-${versionInfo.build}`;

const DATA_OBJ_EVENT_TYPES = {
  CLICK: 'click-interaction',
  VIEW: 'viewable-impression',
  NAVIGATION: 'navigation',
};

const PAGES_BY_ROUTES = {
  '/': 'menu',
  '/menu': 'menu',
  '/locations': 'locations',
  '/payment_method': 'manage-payment-methods',
  '/add_card': 'add-new-card',
  '/settings': 'profile-settings',
  '/orders': 'orders',
  '/order_status': 'order-status',
  '/notifications': 'notifications',
  '/account': 'account',
  '/subscriptions': 'subscriptions',
};

const DIALOG_NAMES = {
  GUEST_SIGN_IN: 'guest-sign-in-dialog',
  LOGIN: 'login-dialog',
  SIGNUP: 'signup-dialog',
  VERIFY: 'verify-email-dialog',
  RESET_PASSWORD: 'reset-password-dialog',
  REWARD: 'rewards-dialog',
  ORDER_FLOW: 'order-flow-dialog',
  PRODUCT_DIALOG: 'product-dialog',
  ORDER_LOCATION: 'order-location-dialog',
  DELIVERY_ADDRESS: 'delivery-address-dialog',
  INVALID_ORDER_ITEMS: 'invalid-order-items-dialog',
  DELIVERY_OPTION: 'delivery-option-dialog',
  TABLE_SELECTION: 'table-selection-dialog',
  CHOOSE_ASAP_OR_TIME: 'choose-asap-or-time-dialog',
  CHOOSE_TIME: 'choose-time-dialog',
  ORDER_THROTTLING: 'order-throttling-dialog',
};

const TOGGLE_COMPONENT_NAMES = {
  CHECKOUT: 'checkout-drawer',
  PAYMENT: 'payment-drawer',
};

const TOGGLE_COMPONENT_NAMES_BY_COMPONENT_KEYS = {
  [TOGGLE_COMPONENT_KEYS.CHECKOUT_DRAWER]: TOGGLE_COMPONENT_NAMES.CHECKOUT,
  [TOGGLE_COMPONENT_KEYS.PAYMENT_DRAWER]: TOGGLE_COMPONENT_NAMES.PAYMENT,
  [TOGGLE_COMPONENT_KEYS.ORDER_FLOW_DIALOG]: DIALOG_NAMES.ORDER_FLOW,
  [TOGGLE_COMPONENT_KEYS.PRODUCT_DIALOG]: DIALOG_NAMES.PRODUCT_DIALOG,
};

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  MOBILEWEB: 'mobile-web',
};

export {
  EVENT_SOURCE,
  DATA_OBJ_EVENT_TYPES,
  DIALOG_NAMES,
  TOGGLE_COMPONENT_NAMES,
  TOGGLE_COMPONENT_NAMES_BY_COMPONENT_KEYS,
  PAGES_BY_ROUTES,
  DEVICE_TYPES,
};
