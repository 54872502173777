/**
 * For Delivery or Catering.
 * @param {*} addresses
 */
export const getDefaultAddress = (addresses) => {
  if (!Array.isArray(addresses)) return null;
  const latestDeliveryAddressIndex = addresses.length - 1;
  return addresses[latestDeliveryAddressIndex];
};

/**
 * For Pickup or Dine In
 * @param {*} locations
 */
export const getDefaultLocation = locations => locations[0];
