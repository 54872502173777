import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Button from '../../core/components/Button';
import * as Routes from '../../../services/routes/Routes.json';
import * as Functions from '../../../services/functions/Functions';
import HoursComponent from './HoursComponent';
import styles from '../../../css/locationsPage/subComponents/MapComponent.scss';
import Config from '../../../../dist/config.json';

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.handleClickOrder = this.handleClickOrder.bind(this);
  }

  onMarkerClick(props, marker) {
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  // Events like 'onClick' do not fire within the map infoWindow due to how HTML is passed to Google
  // To fix this - the div with id 'order-button' is passed to the infoWindow, Button is rendered in
  // in onWindowOpen() function and the event is handled in handleClickOrder() function
  handleClickOrder() {
    // Redirect to menu page
    this.props.history.push(Routes.path.menuPage);
  }

  onWindowOpen(props) {
    const button = (
      <Button
        onClick={this.handleClickOrder}
        type="primary"
        text={props.translation('LocationComponent.orderHere')}
      />
    );

    if (props.location.hours) {
      ReactDOM.render(
        React.Children.only(button),
        document.getElementById('order-button'),
      );
    }
  }

  parseDeliveryStreetAddress = (location) => {
    return (
      <div>
        <Typography>
          {`${location.unitNumber} - ${location.streetAddress}`}
        </Typography>
      </div>
    );
  }

  render() {
    const {
      location, translation,
      containerStyle, // containerStyle requires position: relative, height: y
      mapStyle, // mapStyle requires width: x
    } = this.props;

    return (
      <div style={containerStyle}>
        <Map
          google={this.props.google}
          style={mapStyle}
          initialCenter={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          zoom={15}
        >
          <Marker
            onClick={this.onMarkerClick}
            name="Location"
            position={{
              lat: location.latitude,
              lng: location.longitude,
            }}
            icon={{
              url: Functions.getImageUrl('marker.png'),
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(40, 48),
            }}
          />
          {/* TODO: Fix info window to remove scrollbar */}
          {/* TODO: Use InfoWindow if there is one map and multiple locations */}
          {/* <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.windowHasClosed}
            visible={this.state.showingInfoWindow}
            onOpen={() => {
              this.onWindowOpen(this.props);
            }}
          >
            <div className={classes.infoWindow}>
              <h2>{location.name && location.name}</h2>
              <h2>{location.nickname && location.nickname}</h2>
              <div className={classes.row}>
                <div className={classes.infoWindowColumnOne}>
                  <p>
                    {location.address && Functions.parseAddress(location.address)}
                    {location.streetAddress && this.parseDeliveryStreetAddress(location)}
                  </p>
                  {
                    location.phone
                    && (
                      <div className={classes.infoWindowColumnOne}>
                        <p className={classes.boldStyle}>
                          <Icon className={classes.icon}>local_phone</Icon>
                          {location.phone}
                        </p>
                      </div>
                    )
                  }
                </div>
              </div>
              {
                location.hours
                && (
                  <div>
                    <div className={classes.row}>
                      <HoursComponent
                        hours={location.hours}
                        translation={translation}
                        textStyle={classes.infoWindow}
                      />
                    </div>
                    <div id="order-button" />
                  </div>
                )
              }
            </div>
          </InfoWindow> */}
        </Map>
      </div>
    );
  }
}

MapComponent.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  translation: PropTypes.func.isRequired,
  containerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  mapStyle: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(GoogleApiWrapper({
  apiKey: (Config.general.googleMapsApiKey),
})(MapComponent));
