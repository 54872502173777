/* eslint-disable no-console */
import get from 'lodash/get';
import { FeatureFlags } from '../../../src/services/functions/FeatureFlag';

export const shouldUseApplePay = (company, isAddNewCardPage) => {
  // Source: https://stackoverflow.com/a/57783606/11542028
  // eslint-disable-next-line no-undef
  const isSafari = (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) && !(navigator.userAgent.match('CriOS'));
  const applePayFlag = FeatureFlags.PaymentPage.useApplePay;
  const isSquare = get(company, 'paymentIntegration', 'NONE') === 'SQUARE';
  return isSafari && applePayFlag && !isAddNewCardPage && isSquare;
};

export const shouldUseGooglePay = (company, isAddNewCardPage) => {
  const googlePayFlag = FeatureFlags.PaymentPage.useGooglePay;
  const isSquare = get(company, 'paymentIntegration', 'NONE') === 'SQUARE';
  return googlePayFlag && !isAddNewCardPage && isSquare;
};
