import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Icon,
  Divider,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import PageTitle from '../core/components/PageTitle';
import TabBar from '../core/components/TabBar';
import * as Functions from '../../services/functions/Functions';
import * as Actions from '../../actions/Actions';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import * as Routes from '../../services/routes/Routes.json';
import { FeatureFlags } from '../../services/functions/FeatureFlag';
import { DELIVERY, PICKUP, deliveryOptionIcons, REORDER_CHECK_CASES } from '../../services/constants/Constants';
import { getCompany, getCurrentOrder, getProducts, getUser, getUserOrders, getUsers, getLocations, getLoading } from '../../selectors';
import DateHelper from '../../services/helpers/DateHelper';
import Button from '../../components/core/components/Button';
import '../../css/ordersPage/OrdersPage.scss';
import ReOrderDialog from './ReOrderDialog';
import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import ReorderATCEvent from '../../events/ATC/ReorderATCEvent';
import useTrackParentHeight from '../../events/hooks/useTrackParentHeight';
import useFindRowNumber from '../../events/hooks/useFindRowNumber';
import { POSITIONED_ANCESTOR_CLASS_NAMES } from '../../events/ATC/constants';
import getClickEventClientXY from '../../events/utils/getClickEventClientXY';

const orderTabsContent = [
  {
    tabName: 'UPCOMING_ORDERS',
    isUpcoming: true,
    noOrderMessage: 'OrdersPage.noUpcoming',
  },
  {
    tabName: 'COMPLETED_ORDERS',
    isUpcoming: false,
    noOrderMessage: 'OrdersPage.noRecent',
  },
];

const removeUnderscores = text => text && text.replace(/_/g, ' ');

const CardHeading = (props) => {
  const {
    company,
    translation,
    order,
    isUpcoming,
    isCurbside,
  } = props;

  return (
    <div>
      <div className="cardHeadingContainer">
        <div className="titleWithIconContainer">
          <Icon className="ordersPage-icon">{deliveryOptionIcons[order.deliveryOption]}</Icon>
          <Typography className="cardHeadingText">
            {isCurbside ? translation('OrdersPage.curbsidePickup') : removeUnderscores(order.deliveryOption)}
          </Typography>
        </div>
        <Typography className="cardHeadingText">
          {Functions.getFormattedPrice(order.totalPrice, company)}
        </Typography>
      </div>
      <div>
        <Typography className="orderNumber">
          {`#${order.id}`}
        </Typography>
      </div>
      {
        isUpcoming
        && (
          <div className="orderStatusContainer">
            <Typography className="orderStatus">
              {`${translation('OrdersPage.orderStatus')}:\u00A0`}
            </Typography>
            <Typography className="orderStatusType">
              {removeUnderscores(order.orderStatus)}
            </Typography>
          </div>
        )
      }
    </div>
  );
};

const DateTime = (props) => {
  const {
    translation,
    title,
    dateTime,
  } = props;

  return (
    <div className="dateTimeContainer">
      <div className="titleWithIconContainer">
        <Icon className="ordersPage-smallIcon">schedule</Icon>
        <Typography className="ordersPage-title">
          {`${title} ${translation('OrdersPage.time')}`}
        </Typography>
      </div>
      <Typography className="ordersPage-dateTime">
        {DateHelper.getSuggestedTimeIntervalString(dateTime, '-')}
      </Typography>
    </div>
  );
};

const OptionList = (props) => {
  const { item } = props;
  const groupedOptions = Functions.groupOptionsByCustomization(item);
  return (
    <div className="optionList">
      {
        [...groupedOptions].map((optionSet, i) => (
          <div className="options" key={Functions.generateKey(i)}>
            <Typography className="option">
              {`${optionSet[0]}: ${optionSet[1].join(', ')}`}
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

const OrderItems = (props) => {
  const {
    items,
    translation,
  } = props;
  return (
    <div>
      {
        items && items.map((item, i) => (
          <div>
            <Typography key={Functions.generateKey(i)} className="orderItem">
              {`${item.quantity}x ${item.productItem.name}`}
            </Typography>
            {
              item.options.length > 0
              && (
                <OptionList
                  item={item}
                />
              )
            }
            {
              item.note != null && item.note !== ''
              && (
                <div className="orderItemNoteContainer">
                  <Typography className="orderItemNote">
                    {`${translation('OrdersPage.note')}: ${item.note}`}
                  </Typography>
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  );
};

const Address = (props) => {
  const {
    title,
    address,
    streetAddress,
    titleIcon,
  } = props;

  return (
    <div className="addressContainer">
      <div className="titleWithIconContainer">
        <Icon className="ordersPage-smallIcon">{titleIcon}</Icon>
        <Typography className="ordersPage-title">
          {title}
        </Typography>
      </div>
      <Typography className="ordersPage-locationAddress">
        {streetAddress || address}
      </Typography>
    </div>
  );
};

const OrderCardButton = (props) => {
  const {
    buttonText,
    buttonIcon,
    addItemsToCart,
    order,
    isLoading,
  } = props;

  return (
    <div className="ordersPage-buttonContainer">
      <Button
        disabled={isLoading !== 0}
        type="primary"
        text={buttonText}
        icon={buttonIcon}
        className="orderButton"
        onClick={event => addItemsToCart(order, event)}
        keepclickeventonclick
      />
    </div>
  );
};

const CurbsideStepper = (props) => {
  const {
    currentStep,
  } = props;

  return (
    <Stepper alternativeLabel activeStep={currentStep} connector={<StepConnector />}>
      {
        FeatureFlags.ResourceConstants.statusIndicatorLabels.map((label, i) => (
          <Step key={Functions.generateKey(i)}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))
      }
    </Stepper>
  );
};

const CurbsideSection = (props) => {
  const {
    translation,
    order,
    user,
    updateCurbsideOrderStatus,
    locations,
  } = props;

  let currentStep;
  switch (order.orderStatus) {
    case 'RECEIVED':
      currentStep = 0;
      break;
    case 'PROCESSED':
      currentStep = 0;
      break;
    case 'READY':
      currentStep = 1;
      break;
    case 'ARRIVED_FOR_PICKUP':
      currentStep = 2;
      break;
    case 'OUT_FOR_DELIVERY':
      currentStep = 2;
      break;
    default:
      currentStep = 0;
  }

  const handleNotifyLocation = () => {
    const mutableOrder = Immutable.asMutable(order, { deep: true });
    mutableOrder.orderStatus = 'ARRIVED_FOR_PICKUP';
    updateCurbsideOrderStatus(user, mutableOrder);
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.location.reload(true);
    }, 400);
  };

  const customerHasNotifiedStore = orderStatus => FeatureFlags.ResourceConstants.curbsideOrderStatuses.indexOf(orderStatus)
      >= FeatureFlags.ResourceConstants.curbsideOrderStatuses.indexOf('ARRIVED_FOR_PICKUP');

  const orderHasReachedPickUpStage = orderStatus => FeatureFlags.ResourceConstants.curbsideOrderStatuses.indexOf(orderStatus)
      >= FeatureFlags.ResourceConstants.curbsideOrderStatuses.indexOf('READY');

  const storeIsNotified = customerHasNotifiedStore(order.orderStatus);

  const getCurbsideInstructions = (placedOrder) => {
    const currentLocation = locations.find(loc => loc.id === placedOrder.location.id);
    if (currentLocation && currentLocation.CURBSIDE_INSTRUCTIONS && currentLocation.CURBSIDE_INSTRUCTIONS.length !== 0) {
      return currentLocation.CURBSIDE_INSTRUCTIONS;
    }
    return translation('OrdersPage.curbsideInstructions');
  };

  return (
    <div className="curbsideContainer">
      <CurbsideStepper
        {...props}
        currentStep={currentStep}
      />
      <div className="instructionsSection">
        <Typography className="instructionsHeading">
          {`${translation('OrdersPage.curbsidePickup')} ${translation('OrdersPage.instructions')}`}
        </Typography>
        <Typography className="instructionsBody">
          {getCurbsideInstructions(order)}
        </Typography>
      </div>
      {
        orderHasReachedPickUpStage(order.orderStatus)
        && (
          <div className="notifyButtonContainer">
            <Button
              type={storeIsNotified ? 'tertiary' : 'primary'}
              text={storeIsNotified ? translation('OrdersPage.headingOut') : translation('OrdersPage.atTheStore')}
              icon={storeIsNotified ? 'thumb_up' : null}
              className="notifyButton"
              disabled={storeIsNotified}
              onClick={() => handleNotifyLocation()}
            />
          </div>
        )
      }
    </div>
  );
};

const Order = (props) => {
  const {
    order,
    translation,
    isUpcoming,
    actions,
    user,
    currentOrder,
    history,
    showReOrderDialog,
    onAddToCart,
    parentHeight,
  } = props;
  const { showTrackOrderButton } = FeatureFlags.OrdersPage.upcomingOrders;
  const isCurbside = order.isCurbsidePickUp;

  const cardRef = useRef(null);

  const getRowSlot = useFindRowNumber({
    childElement: cardRef.current,
    parentHeight,
    positionedAncestorElementClassName: POSITIONED_ANCESTOR_CLASS_NAMES.ORDERS_LIST_PARENT,
  });

  const checkReOrder = async (reOrderObject) => {
    const orderLocationId = get(reOrderObject, 'location.id');
    const orderDeliveryOption = get(reOrderObject, 'deliveryOption');
    const orderIsASAP = get(reOrderObject, 'isASAP');
    // Getting the formatted date, the API requires this to be in the 'yyyy-MM-ddTHH:mm:ssZ' format
    const parsedDate = DateHelper.parseEpochToDate(reOrderObject.desiredTime);
    const orderFormattedDesiredTime = parsedDate.toISOString();
    const queryParamString = `?locationId=${orderLocationId}&deliveryOption=${orderDeliveryOption}&isASAP=${orderIsASAP}&desiredTime=${orderFormattedDesiredTime}`;
    const reOrderCheckResult = await actions.getOfflineResource(user.token, ['users', user.id, 'orders', reOrderObject.id, 'reorder', `${queryParamString}`]);
    if (!reOrderCheckResult.error) return JSON.parse(reOrderCheckResult.data);
    return null;
  };

  const cloudEventBaseParams = useCloudEventsBaseParams();

  const onClickReOrderButton = async (prevOrder, clickEvent = {}) => {
    const reOrderObject = Functions.getReOrderObject(prevOrder, currentOrder);
    const cloudEvent = new ReorderATCEvent({
      ...cloudEventBaseParams,
      slot: getRowSlot(),
      reOrderId: prevOrder && prevOrder.id,
      ...getClickEventClientXY(clickEvent),
    });
    CloudEventsApi.sendCloudEvent({ cloudEvent, userToken: user && user.token });

    const reOrderCheckResult = await checkReOrder(reOrderObject);
    // We can either get 'ALL_AVAILABLE', 'NONE_AVAILABLE' or 'SOME_AVAILABLE';
    const reOrderItemStatus = Functions.getReorderItemStatus(currentOrder, reOrderCheckResult);
    // If all items are available, we add the items to cart as usual.
    if (reOrderItemStatus === REORDER_CHECK_CASES.ALL_AVAILABLE) {
      onAddToCart(reOrderObject);
      actions.updateOrder(user, { id: currentOrder.id, reorderId: reOrderObject.reorderId }, currentOrder.id);
    } else {
      showReOrderDialog(reOrderItemStatus, reOrderCheckResult);
    }
  };

  const onTrackOrder = () => {
    if (order.deliveryOption === DELIVERY && isUpcoming && order.deliveryTrackingUrl) {
      // eslint-disable-next-line no-undef
      window.open(order.deliveryTrackingUrl);
    }
  };

  const call = (phoneNum) => {
    // eslint-disable-next-line no-undef
    window.open(`tel:${phoneNum}`, '_self');
  };

  return (
    <Card className="ordersPage-card" ref={cardRef}>
      <CardContent>
        <CardHeading
          order={order}
          {...props}
          isUpcoming={isUpcoming}
          isCurbside={isCurbside}
        />
        <div>
          {
            order.isCurbsidePickUp && isUpcoming
            && (
              <div className="ordersPage-sectionContainer">
                <CurbsideSection
                  {...props}
                />
              </div>
            )
          }
          <div className="ordersPage-sectionContainer">
            <DateTime
              title={translation('OrdersPage.order')}
              dateTime={order.placedAt}
              {...props}
            />
            <DateTime
              title={removeUnderscores(order.deliveryOption)}
              dateTime={order.desiredTime}
              {...props}
            />
          </div>
          <Divider />
          <div className="ordersPage-sectionContainer">
            <OrderItems
              {...props}
              items={order.items}
            />
          </div>
          <Divider />
          <div className="addressSection">
            <div className="pickupAddressSection">
              <Address
                title={
                  order.deliveryOption === PICKUP
                    ? translation('OrdersPage.pickupLocation')
                    : translation('OrdersPage.locationPrepared')
                }
                address={order.location && order.location.address}
                deliveryOption={order.deliveryOption}
                {...props}
                titleIcon="restaurant_menu_icon"
              />
              {
                (isCurbside || order.deliveryOption === PICKUP)
                && (
                  <div className="callButtonContainer">
                    <Button
                      className="ordersPage-callButton"
                      onClick={() => call(order.location.phone)}
                      text={translation('OrdersPage.call')}
                    />
                  </div>
                )
              }
            </div>
            {
              order.deliveryOption === DELIVERY
              && (
                <Address
                  title={translation('OrdersPage.deliveryAddress')}
                  streetAddress={order.address.streetAddress}
                  deliveryOption={order.deliveryOption}
                  {...props}
                  titleIcon="directions_car"
                />
              )
            }
          </div>
        </div>
        {
          isUpcoming
            ? (showTrackOrderButton && order.deliveryOption === DELIVERY)
              && (
                <OrderCardButton

                  buttonText={translation('OrdersPage.trackOrder')}
                  addItemsToCart={() => onTrackOrder()}
                  {...props}
                />
            )
            : (
              <OrderCardButton
                buttonText={translation('OrdersPage.orderAgain')}
                buttonIcon="shopping_cart"
                addItemsToCart={onClickReOrderButton}
                {...props}
              />
            )
        }
      </CardContent>
    </Card>
  );
};

const OrdersList = (props) => {
  const {
    translation,
    orderList,
    noOrderMessage,
    isUpcoming,
    currentOrder,
    actions,
    user,
    history,
    onClickReOrderButton,
    onAddToCart,
    onReOrder,
    reOrderDialogVisible,
    reOrderCheckResult,
    setReOrderDialogVisible,
  } = props;

  const { cardsParentHeight, cardsParentRef } = useTrackParentHeight();

  return (
    <div>
      {
        orderList.length === 0
          ? (
            <Typography className="noOrders">
              {translation(noOrderMessage)}
            </Typography>
          )
          : (
            <div className={POSITIONED_ANCESTOR_CLASS_NAMES.ORDERS_LIST_PARENT} ref={cardsParentRef}>
              {
                orderList.map((order, i) => (
                  order.items && order.items.length > 0
                  && (
                    <Order
                      {...props}
                      order={order}
                      isUpcoming={isUpcoming}
                      key={Functions.generateKey(i)}
                      showReOrderDialog={onReOrder}
                      onAddToCart={onAddToCart}
                      parentHeight={cardsParentHeight}
                    />
                  )
                ))
              }
            </div>
          )
      }
      <ReOrderDialog
        open={reOrderDialogVisible}
        history={history}
        translation={translation}
        handleClick={onClickReOrderButton}
        reOrderCheckResult={reOrderCheckResult}
        handleCloseDialog={() => setReOrderDialogVisible(false)}
      />
    </div>
  );
};

// TO-DO: Remove inline styles
const PageHeader = (props) => {
  const { translation } = props;
  return (
    <div className="ordersPage-heading">
      <PageTitle
        title={translation('OrdersPage.recentOrders')}
        containerWidth="70%"
      />
    </div>
  );
};

const OrdersComponent = (props) => {
  const {
    loading,
    translation,
    orders,
    history,
    user,
    users,
    actions,
    products,
    currentOrder,
    upliftHistory,
  } = props;

  if (!user) history.push(Routes.path.menuPage);

  const [reOrderDialogVisible, setReOrderDialogVisible] = useState(false);
  const [reOrderCheckResult, setReOrderCheckResult] = useState(null);

  const onReOrder = (reOrderItemStatus, newReOrderCheckResult) => {
    setReOrderDialogVisible(reOrderItemStatus !== REORDER_CHECK_CASES.ALL_AVAILABLE);
    setReOrderCheckResult(newReOrderCheckResult);
  };

  useEffect(() => {
    if (!orders) Functions.getComponentResources(actions, user, products, users, loading);
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);
  }, []);

  const getOrderList = (upcoming) => {
    const upcomingOrders = [];
    const completedOrders = [];

    if (orders) {
      orders.forEach((order) => {
        if (['COMPLETED', 'CANCELED'].includes(order.orderStatus)) completedOrders.push(order);
        if (!(['COMPLETED', 'CANCELED', 'NEW'].includes(order.orderStatus))) upcomingOrders.push(order);
      });
    }

    if (upcoming) return upcomingOrders;
    return completedOrders;
  };

  const getTabs = () => {
    if (user && user.guestToken) {
      return (
        [{
          id: 'UPCOMING_ORDERS',
          label: translation('OrdersPage.upcoming'),
        }]
      );
    }
    return (
      [{
        id: 'UPCOMING_ORDERS',
        label: translation('OrdersPage.upcoming'),
      }, {
        id: 'COMPLETED_ORDERS',
        label: translation('OrdersPage.completed'),
      }]
    );
  };

  const [selectedTab, setSelectedTabId] = useState('UPCOMING_ORDERS');

  const setSelectedTab = (index) => {
    setSelectedTabId(index);
  };

  const onAddToCart = async (prevOrder, gotoMenu = true) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    const isReorder = true;
    // If mutableOrder.id is null, send null value to addToCart to create new order
    const orderId = mutableOrder.id;
    // If the current order doesn't have basic info, we'll use the one from prevOrder
    if (!Functions.checkBasicOrderInfo(mutableOrder)) {
      mutableOrder.deliveryOption = prevOrder.deliveryOption;
      mutableOrder.address = prevOrder.address;
      mutableOrder.location = prevOrder.location;
      actions.updateOrder(user, { ...mutableOrder, isASAP: true }, orderId);
    }

    const newestOrderId = await Functions.addToCart(
      actions,
      user,
      mutableOrder,
      prevOrder.items,
      orderId,
      history,
      isReorder,
      prevOrder.id,
      gotoMenu,
    );

    return newestOrderId;
  };

  const onClickReOrderButton = async (goToMenu) => {
    // Get full list of orders (upcoming and not)
    const orderList = getOrderList(true).concat(getOrderList(false));
    const prevOrder = orderList.find(order => order.id === reOrderCheckResult.reorderId) || {};
    const orderToRepeat = Functions.getReOrderObject(prevOrder, currentOrder);
    const mutableOrder = Immutable.asMutable(orderToRepeat, { deep: true });
    // Add available items to cart by removing the unavailable items
    // First map all unavailable item ids
    const unAvailableOrderItemIds = reOrderCheckResult.unavailableItems.map(unavailableItem => unavailableItem.orderItemId);
    // Now filter them out from the order to repeat's items
    const availableOrderItems = orderToRepeat.items.filter(orderItem => !unAvailableOrderItemIds.includes(orderItem.id));
    mutableOrder.items = availableOrderItems;
    mutableOrder.id = currentOrder.id;
    if (mutableOrder.items.length > 0) {
      const newestOrderId = await onAddToCart(mutableOrder, goToMenu, false);
      await Functions.refreshOrderPrice(actions, user, { id: newestOrderId });
    }
    if (goToMenu) {
      history.push(Routes.path.menuPage);
      return;
    }
    actions.toggleComponent('CheckoutDrawer');
    setReOrderDialogVisible(false);
  };

  return (
    loading !== 0
      ? <LinearProgress />
      : (
        <div className="ordersPage-main">
          <PageHeader
            {...props}
          />
          <TabBar
            translation={translation}
            selected={selectedTab}
            onClickSelected={value => setSelectedTab(value)}
            tabs={getTabs()}
          />
          {
            orderTabsContent.map((tab, i) => (
              <div key={Functions.generateKey(i)}>
                {
                  selectedTab === tab.tabName
                  && (
                    <OrdersList
                      {...props}
                      onReOrder={onReOrder}
                      reOrderDialogVisible={reOrderDialogVisible}
                      reOrderCheckResult={reOrderCheckResult}
                      setReOrderDialogVisible={setReOrderDialogVisible}
                      onAddToCart={onAddToCart}
                      onClickReOrderButton={onClickReOrderButton}
                      orderList={getOrderList(tab.isUpcoming)}
                      noOrderMessage={tab.noOrderMessage}
                      isUpcoming={tab.isUpcoming}
                    />
                  )
                }
              </div>
            ))
          }
        </div>
      )
  );
};

CardHeading.propTypes = {
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  translation: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any),
  isUpcoming: PropTypes.bool,
  isCurbside: PropTypes.bool,
};

CardHeading.defaultProps = {
  order: {},
  isUpcoming: false,
  isCurbside: false,
};

DateTime.propTypes = {
  translation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.number,
};

DateTime.defaultProps = {
  dateTime: 0,
};

OptionList.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

OptionList.defaultProps = {
  item: {},
};

OrderItems.propTypes = {
  translation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any),
};

OrderItems.defaultProps = {
  items: [],
};

Address.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  streetAddress: PropTypes.string,
  titleIcon: PropTypes.string,
};

Address.defaultProps = {
  address: '',
  streetAddress: '',
  titleIcon: '',
};

OrderCardButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string,
  addItemsToCart: PropTypes.func,
  order: PropTypes.objectOf(PropTypes.any),
};

OrderCardButton.defaultProps = {
  buttonIcon: '',
  addItemsToCart: null,
  order: {},
};

Order.propTypes = {
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  order: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
};

Order.defaultProps = {
  order: {},
  user: null,
};

OrdersList.propTypes = {
  translation: PropTypes.func.isRequired,
  orderList: PropTypes.arrayOf(PropTypes.object),
  noOrderMessage: PropTypes.string.isRequired,
  isUpcoming: PropTypes.bool.isRequired,
};

OrdersList.defaultProps = {
  orderList: [],
};

PageHeader.propTypes = {
  translation: PropTypes.func.isRequired,
};

OrdersComponent.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  upliftHistory: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  users: PropTypes.objectOf(PropTypes.any),
  currentOrder: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.object),
  orders: PropTypes.arrayOf(PropTypes.object),
};

OrdersComponent.defaultProps = {
  user: null,
  users: null,
  currentOrder: {},
  products: null,
  orders: null,
};

CurbsideStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

CurbsideSection.propTypes = {
  translation: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  updateCurbsideOrderStatus: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.object),
};

CurbsideSection.defaultProps = {
  order: null,
  user: null,
  updateCurbsideOrderStatus: null,
  locations: [],
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  user: getUser(state).user,
  orders: getUserOrders(state),
  company: getCompany(state),
  users: getUsers(state),
  products: getProducts(state),
  locations: getLocations(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
  updateCurbsideOrderStatus: (user, order) => dispatch(Actions.updateCurbsideOrderStatus(user, order)),
});

const OrdersPage = compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(OrdersComponent));

export default OrdersPage;

