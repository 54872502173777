import React, { useCallback } from 'react';
import {
  func,
  objectOf,
  arrayOf,
  any,
} from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import get from 'lodash/get';

import { getCurrentOrder, getUser, getProducts, getUserToken } from '../../../selectors';
import ProductBoxComponent from '../../menuPage/components/ProductBoxComponent';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';
import '../../../css/checkout/subComponents/UpsellCarrousel.scss';
import useCloudEventsBaseParams from '../../../events/hooks/useCloudEventsBaseParams';
import ClickCheckoutUpsellCarouselAddButton from '../../../events/ATC/ClickCheckoutUpsellCarouselAddButton';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';

const scrollToItem = (itemId) => {
  // eslint-disable-next-line no-undef
  const element = document.getElementById(`carrousel-${itemId}`);
  element.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
};

const UpsellCarrousel = (props) => {
  const {
    translation, currentOrder, setUpsell, upsellItems,
  } = props;

  let firstItemId;
  let lastItemId;
  const shouldShowScrollButtons = upsellItems.length >= 2;
  if (shouldShowScrollButtons) {
    firstItemId = get(upsellItems[0], 'id');
    lastItemId = get(upsellItems[upsellItems.length - 1], 'id');
  }

  const renderScrollIcon = (direction) => {
    if (!shouldShowScrollButtons) return null;
    return (
      <IconButton
        onClick={() => scrollToItem(direction === 'right' ? lastItemId : firstItemId)}
        className={`upsellCarrousel-scrollIcon-${direction}`}
      >
        {
          direction === 'right'
          ? <ChevronRightRoundedIcon />
          : <ChevronLeftRoundedIcon />
        }
      </IconButton>
    );
  };

  if (upsellItems.length === 0) return null;

  const cloudEventsBaseParams = useCloudEventsBaseParams();
  const userToken = useSelector(getUserToken);

  const handleClick = upsellItem => (clickEvent = {}) => {
    setUpsell(upsellItem);
    const cloudEvent = new ClickCheckoutUpsellCarouselAddButton({
      ...cloudEventsBaseParams,
      upsellId: upsellItem && upsellItem.id,
      itemId: get(upsellItem, 'product.id'),
      ...getClickEventClientXY(clickEvent),
    });

    CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
  };

  return (
    <div className="upsellCarrousel-container">
      <Typography className="upsellCarrousel-title">
        {translation('CheckoutDrawer.upsellTitle')}
      </Typography>
      <div className={`upsellCarrousel-carrousel ${!shouldShowScrollButtons && 'upsellCarrousel-justifyCenter'}`}>
        {renderScrollIcon('left')}
        {
          upsellItems.map(upsellItem => (
            <div
              id={`carrousel-${upsellItem.id}`}
              key={`carrousel-${upsellItem.id}`}
            >
              <ProductBoxComponent
                translation={translation}
                showButton
                upsell
                product={upsellItem.product}
                handleClick={handleClick(upsellItem)}
                currentOrderLocationId={(currentOrder && currentOrder.location) ? currentOrder.location.id : null}
              />
            </div>
          ))
        }
        {renderScrollIcon('right')}
      </div>
    </div>
  );
};

UpsellCarrousel.propTypes = {
  setUpsell: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  upsellItems: arrayOf(objectOf(any)).isRequired,
  translation: func.isRequired,
};

UpsellCarrousel.defaultProps = {
};

const mapStateToProps = state => ({
  userState: getUser(state),
  currentOrder: getCurrentOrder(state),
  products: getProducts(state),
});

export default connect(mapStateToProps)(UpsellCarrousel);

