import React, { useReducer, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import {
  Button as MUIButton,
  Typography,
  withWidth,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { defaultTo, get } from 'lodash';

/** Icon Imports */
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import WarningIcon from '@material-ui/icons/Warning';

import {
  getCurrentOrder,
  getCurrentUserAddresses,
  getDialogLoading,
  getLocations,
  getTimeSlot,
  getUnavailableCategoriesByDeliveryOption,
} from '../../../selectors';
import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import '../../../css/menuPage/components/OrderFlowDialog.scss';
import OrderLocationDialog from '../../checkout/subComponents/OrderLocationDialog';
import DeliveryAddressDialog from '../../checkout/subComponents/DeliveryAddressDialog';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';
import * as Functions from '../../../services/functions/Functions';
import CategoryService from '../../../services/helpers/Categories';
import * as ItemUtils from '../../../services/helpers/ItemUtils';
import OrderHelper from '../../../services/helpers/OrderHelper';
import HoursComponent from '../../locationsPage/subComponents/HoursComponent';
import {
  getNearestTimeSlot,
  getOrderTimeSlot,
} from '../../../services/helpers/OrderThrottling';

import {
  DELIVERY,
  DINE_IN,
  CATERING,
  PICKUP,
  StepDirection,
} from '../../../services/constants/Constants';
import DateHelper from '../../../services/helpers/DateHelper';
import InvalidOrderItemsDialog from '../../checkout/subComponents/InvalidOrderItemsDialog';
import DateTimeDropdown from './DateTimeDropdown';
import TableNumberDropdown from '../../core/components/TableNumberDropdown';
import { DIALOG_NAMES } from '../../../services/api/CloudEvents/constants';
import useCloudEventsBaseParams from '../../../events/hooks/useCloudEventsBaseParams';
import PageLoadEvent from '../../../events/Bounce/PageLoadEvent';
import PageExitEvent from '../../../events/Bounce/PageExitEvent';

const deliveryOptionArray = [
  {
    name: PICKUP,
    icon: 'person',
    flag: 'enablePickup',
  },
  {
    name: DELIVERY,
    icon: 'car',
    flag: 'enableDelivery',
  },
  {
    name: DINE_IN,
    icon: 'restaurant',
    flag: 'enableDineIn',
  },
  {
    name: CATERING,
    icon: 'room-service',
    flag: 'enableCatering',
  },
];

const STEP_NAMES = {
  deliveryOption: 'deliveryOption',
  invalidOrderItems: 'invalidOrderItems',
  location: 'location',
  tableSelection: 'tableSelection',
  desiredTime: 'desiredTime',
  chooseDateTime: 'chooseDateTime',
  timeSuggestion: 'timeSuggestion',
};

const orderFlowDialogSteps = {
  [STEP_NAMES.deliveryOption]: {
    showNextButton: false,
    showOnSingleDeliveryOption: false,
  },
  [STEP_NAMES.invalidOrderItems]: {
    showNextButton: true,
    showOnSingleDeliveryOption: false,
  },
  [STEP_NAMES.location]: {
    // This is not actually used, as the location dialog is another DialogView overlaid over the OrderFlowDialog
    showNextButton: false,
    showOnSingleDeliveryOption: true,
    // This is not actually used, as the location dialog is another DialogView overlaid over the OrderFlowDialog
  },
  [STEP_NAMES.tableSelection]: {
    showNextButton: true,
    showOnSingleDeliveryOption: true,
  },
  [STEP_NAMES.desiredTime]: {
    showNextButton: false,
    showOnSingleDeliveryOption: true,
  },
  [STEP_NAMES.chooseDateTime]: {
    showNextButton: true,
    showOnSingleDeliveryOption: true,
  },
  [STEP_NAMES.timeSuggestion]: {
    showNextButton: false,
    showOnSingleDeliveryOption: true,
  },
};

const STEP_NAMES_TO_DIALOG_NAMES = {
  [STEP_NAMES.deliveryOption]: DIALOG_NAMES.DELIVERY_OPTION,
  [STEP_NAMES.invalidOrderItems]: DIALOG_NAMES.INVALID_ORDER_ITEMS,
  // The location dialog could be OrderLocationDialog or DeliveryAddressDialog, handle page load and exit there
  [STEP_NAMES.location]: null,
  [STEP_NAMES.tableSelection]: DIALOG_NAMES.TABLE_SELECTION,
  [STEP_NAMES.desiredTime]: DIALOG_NAMES.CHOOSE_ASAP_OR_TIME,
  [STEP_NAMES.chooseDateTime]: DIALOG_NAMES.CHOOSE_TIME,
  [STEP_NAMES.timeSuggestion]: DIALOG_NAMES.ORDER_THROTTLING,
};

const handleChooseDateTimeStep = (currentOrder, value) => {
  const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
  mutableOrder.isASAP = false;
  mutableOrder.desiredTime = Functions.formatEpochToSeconds(value.desiredTime);
  return { mutableOrder, shouldMoveStep: true, numStepsToMove: 1 };
};

const getUISideEffectsForInvalidItems = ({ deliveryOption }) => ({
  attemptedDeliveryOptionChange: deliveryOption,
});

const getFlowSteps = (currentOrder) => {
  const deliveryOptions = Functions.availableDeliveryOptions();
  let allSteps = Object.keys(orderFlowDialogSteps);
  const currentOrderDeliveryOption = get(currentOrder, 'deliveryOption');
  const currentOrderTableNumbers = get(currentOrder, ['location', 'tableNumbers']);
  if (currentOrderDeliveryOption !== DINE_IN || !currentOrderTableNumbers) {
    allSteps = allSteps.filter(stepName => stepName !== 'tableSelection');
  }
  if (deliveryOptions.length === 1) {
    return allSteps.filter(stepName => (orderFlowDialogSteps[stepName] ? orderFlowDialogSteps[stepName].showOnSingleDeliveryOption : false));
  }
  return allSteps;
};

const initialState = {
  currentStepIndex: 0,
  selectedTime: new Date(),
  timeErrorMsg: null,
  forceShowNewAddressDialog: false,
  invalidatedOrderItems: {},
  attemptedDeliveryOptionChange: '',
  browserCoordinates: { latitude: 0, longitude: 0 },
  dateIndex: 0,
  timeIndex: 0,
};

const updateStateReducer = (prevState, nextState) => {
  const newState = { ...prevState, ...nextState };
  // Ensure that the step is not lower than 0
  if (newState.currentStepIndex < 0) {
    newState.endDate = 0;
  }
  return newState;
};

const OrderFlowDialog = (props) => {
  const [state, updateState] = useReducer(updateStateReducer, initialState);
  const flowSteps = getFlowSteps(props.currentOrder);
  const cloudEventBaseParams = useCloudEventsBaseParams();

  // eslint-disable-next-line no-undef
  if (navigator.geolocation) {
    useEffect(() => {
      // eslint-disable-next-line no-undef
      navigator.geolocation.getCurrentPosition((position) => {
        updateState({ browserCoordinates: { latitude: position.coords.latitude, longitude: position.coords.longitude } });
      });
    }, []);
  }

  const getCurrentStepName = () => flowSteps[state.currentStepIndex];

  // Rename these props to avoid ESLint warnings far below about variable names already used in the top-level scope
  const { user: latestUser, open: latestOpen } = props;
  const userToken = latestUser && latestUser.token;
  const prevStepIndexRef = useRef(null);

  // Purpose of the below useEffect:
  // 1. On open OrderFlowDialog, or when the current step changes, send a PageLoad event
  // 2. On unmount OrderFlowDialog, send a PageExit event with the current step
  // For many steps, OrderFlowDialog will unmount, even though it seems only its contents are changing. On testing,
  // the unmount is reliable for knowing when to fire PageExit, and the stepIndex change is reliable for firing PageLoad
  useEffect(() => {
    // Null dialogName if on location step, as it could be either OrderLocationDialog or DeliveryAddressDialog
    // Their PageLoad and PageExit are handled by DialogView
    const dialogName = STEP_NAMES_TO_DIALOG_NAMES[getCurrentStepName()];

    const sendPageLoad = () => {
      if (!dialogName) return;
      const cloudEvent = new PageLoadEvent({
        ...cloudEventBaseParams,
        componentNameAsPage: dialogName,
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    };

    const sendPageExit = (stepIndex) => {
      const exitedDialogName = STEP_NAMES_TO_DIALOG_NAMES[flowSteps[stepIndex]];
      if (!exitedDialogName) return;
      const cloudEvent = new PageExitEvent({
        ...cloudEventBaseParams,
        componentNameAsPage: exitedDialogName,
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    };

    if (prevStepIndexRef.current === null && latestOpen) {
      sendPageLoad();
    } else if (prevStepIndexRef.current !== null && prevStepIndexRef.current !== state.currentStepIndex) {
      sendPageLoad();
    }

    prevStepIndexRef.current = state.currentStepIndex;

    return () => {
      sendPageExit(state.currentStepIndex);
    };
  }, [latestOpen, state.currentStepIndex]);

  const getDeliveryOption = () => {
    const { currentOrder } = props;
    return currentOrder ? currentOrder.deliveryOption : '';
  };

  const isDeliveredOrder = () => OrderHelper.isDeliveredOrder(getDeliveryOption());

  const getSelectedTime = () => state.selectedTime.getTime();

  const getSelectedLocationOrAddressId = () => {
    const { currentOrder } = props;
    const locationOrAddress = OrderHelper.getUsedAddress(currentOrder);
    return locationOrAddress
      ? locationOrAddress.id
      : null;
  };

  const getDesiredTimeObj = currentOrderLocationId => ({
    deliveryOption: getDeliveryOption(),
    // getSelectedTime will give you the epoch milliseconds, so we need to divide by 1000 to get epoch seconds
    desiredTime: Math.floor(getSelectedTime() / 1000),
    locationId: currentOrderLocationId || getSelectedLocationOrAddressId(),
  });

  const getASAPTimeSuggestions = () => {
    const { actions, user, currentOrder } = props;
    if (!user) return;
    const currentOrderLocation = get(currentOrder, 'location', {});
    const minutesOffset = currentOrderLocation[isDeliveredOrder() ? 'deliveryTime' : 'pickupTime'];
    const throttledDesiredTime = Functions.addMinutesToEpoch(Date.now(), minutesOffset);
    const desiredTimeRequest = getDesiredTimeObj(get(currentOrder, 'location.id'), throttledDesiredTime);
    actions.getTimeSuggestions(user, desiredTimeRequest);
  };

  const moveStepCallback = (newStepIndex) => {
    const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
    const currentStepName = getCurrentStepName();
    if (currentStepName === 'desiredTime' && shouldUseOrderThrottling) getASAPTimeSuggestions();
    // if user is at the last step, close the dialog.
    if (newStepIndex > flowSteps.length - 1) {
      if (props.productId) {
        props.toggleProductDialog(props.productId);
      }
      const cloudEvent = new PageExitEvent({
        ...cloudEventBaseParams,
        componentNameAsPage: STEP_NAMES_TO_DIALOG_NAMES[currentStepName],
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
      props.handleClose(getCurrentStepName());
    }
  };

  useEffect(() => moveStepCallback(state.currentStepIndex), [state.currentStepIndex]);

  /**
   * Move backward or forward through the order flow
   * @param {*} numStepsToMove (positive if going forward, negative if going backwards)
   */
  const moveStep = (numStepsToMove) => {
    updateState({ currentStepIndex: state.currentStepIndex + numStepsToMove });
  };

  const getDistancesObject = (locations) => {
    const { currentOrder } = props;
    const { latitude, longitude } = state.browserCoordinates;

    let distances;
    if (currentOrder.deliveryOption === PICKUP || currentOrder.deliveryOption === DINE_IN) {
      distances = {};
      locations.forEach((location) => {
        distances[location.id] = Functions.getDistance(location.latitude, location.longitude, latitude, longitude);
      });
    }
    return distances;
  };

  const getCurrentStepInfo = () => {
    const currentStepName = getCurrentStepName();
    return currentStepName && orderFlowDialogSteps[currentStepName];
  };

  const hasValidTimeWindow = () => {
    const { currentOrder } = props;
    const desiredTime = getSelectedTime();
    return Functions.validateSelectedTime(currentOrder, desiredTime);
  };

  // eslint-disable-next-line react/sort-comp
  const hasTimeWindow = () => {
    const { currentOrder } = props;
    const desiredTime = getSelectedTime();
    // the '!!' is so that this is treated as a boolean
    return !!Functions.getTimeWindows(getDeliveryOption(), { ...currentOrder, desiredTime });
  };

  const getTimeErrorMessage = () => {
    const { translation } = props;
    let timeErrorMsgResult = null;
    if (!hasTimeWindow()) {
      timeErrorMsgResult = isDeliveredOrder()
        ? translation('CheckoutDrawer.orderTimeSelector.deliveryClosed')
        : translation('CheckoutDrawer.orderTimeSelector.pickupClosed');
    } else if (!hasValidTimeWindow()) {
      timeErrorMsgResult = translation('CheckoutDrawer.orderTimeSelector.timeError');
    }
    return timeErrorMsgResult;
  };

  const sortLocations = (locations, distances) => {
    if (!locations) return [];
    if (!distances) return locations;
    const sortedLocations = Immutable.asMutable(locations, { deep: true });
    sortedLocations.sort((a, b) => distances[a.id] - distances[b.id]);
    return sortedLocations;
  };

  const updateOrderDeliveryOption = (currentOrder, value) => {
    const { userAddresses } = props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.deliveryOption = value;

    const { orderNeedsAddress, lastAddress } = ItemUtils.shouldAddLastAddressToOrder(mutableOrder, value, userAddresses);
    if (orderNeedsAddress) mutableOrder.address = lastAddress;

    // Assumes that handleClickDeliveryOptionButton checked for invalid items already
    return { mutableOrder, moveHowManySteps: 2, shouldMoveStep: true };
  };

  const updateOrderForInvalidOrderItemsStep = (currentOrder, value) => {
    const { userAddresses } = props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.deliveryOption = value;

    const { orderNeedsAddress, lastAddress } = ItemUtils.shouldAddLastAddressToOrder(mutableOrder, value, userAddresses);
    if (orderNeedsAddress) mutableOrder.address = lastAddress;

    return { mutableOrder, shouldMoveStep: true };
  };

  // This is a special case, when order has an address but no location set.
  const updateOrderLocationFromAddress = async (address) => {
    const { actions, user, currentOrder } = props;
    if (!address || !user) return;
    try {
      const resourceRequest = await actions.getOfflineResource(user.token, ['users', user.id, 'addresses', address.id, 'location']);
      const location = JSON.parse(resourceRequest.data);
      if (location || location.id) {
        const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
        mutableOrder.location = location;
        actions.updateOrder(null, mutableOrder, currentOrder.id);
      }
    } catch (error) {
      console.log('API call error', error);
    }
  };

  const updateOrderForLocationStep = (currentOrder, value) => {
    const { user } = props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });

    if ([PICKUP, DINE_IN].includes(currentOrder.deliveryOption)) {
      mutableOrder.location = value;
    } else {
      mutableOrder.address = value;
      if (user && !currentOrder.location) {
        updateOrderLocationFromAddress(value);
      }
    }
    if (mutableOrder.deliveryOption === DINE_IN) {
      const orderLocation = get(mutableOrder, 'location');
      const tableNumbers = get(mutableOrder, 'location.tableNumbers');
      if (!tableNumbers || !orderLocation) return { mutableOrder, shouldMoveStep: true };
      // Make sure our order has a table number set.
      // If there's no table number, set the first one.
      if (!mutableOrder.tableNumber) mutableOrder.tableNumber = Functions.getFirstTableNumber(orderLocation);
      // If it already has one, check if the current table number is valid for the location.
      const selectedLocationTables = orderLocation.tableNumbers.split(',');
      if (!selectedLocationTables.includes(mutableOrder.tableNumber)) mutableOrder.tableNumber = Functions.getFirstTableNumber(orderLocation);
    }

    return { mutableOrder, shouldMoveStep: true };
  };

  const updateOrderForTableSelectionStep = (currentOrder, value) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.tableNumber = value;
    return { mutableOrder, shouldMoveStep: true };
  };

  const goToProductPage = () => {
    const { productId, toggleProductDialog } = props;
    toggleProductDialog(productId);
  };

  const handleDesiredTimeStep = (currentOrder, value) => {
    const { productId, toggleProductDialog } = props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });

    mutableOrder.isASAP = false;
    if (value.isASAP) {
      mutableOrder.isASAP = true;
      mutableOrder.desiredTime = null;
      if (productId) {
        toggleProductDialog(productId);
      }
      return { mutableOrder, moveHowManySteps: 2, shouldMoveStep: true };
    }
    return { mutableOrder, moveHowManySteps: 1, shouldMoveStep: true };
  };

  const stepUpdateOrderFunctions = {
    deliveryOption: updateOrderDeliveryOption,
    invalidOrderItems: updateOrderForInvalidOrderItemsStep,
    location: updateOrderForLocationStep,
    tableSelection: updateOrderForTableSelectionStep,
    desiredTime: handleDesiredTimeStep,
    chooseDateTime: handleChooseDateTimeStep,
    timeSuggestion: handleChooseDateTimeStep,
  };

  const followUpAfterRemoveInvalidItems = (currentStepName) => {
    if (currentStepName !== 'invalidOrderItems') return;

    if (state.attemptedDeliveryOptionChange) {
      updateState({ attemptedDeliveryOptionChange: '' });
    }
  };

  // Sometimes we want to update the order only in redux.
  const shouldUpdateOrderInAPI = (stepName, value, currentOrder) => {
    // If we're setting desiredTime, we always want to update in API
    if ((['desiredTime', 'chooseDateTime'].includes(stepName))) return true;
    // If our order already has an id, there's only one exception.
    // Which is if we change our delivery option.
    // See more on why here: https://aytech.atlassian.net/wiki/spaces/AYT/pages/1650425859/
    if (currentOrder.id && stepName === 'deliveryOption') {
      return (currentOrder.deliveryOption === value);
    }
    return false;
  };

  const updateOrderObject = (value) => {
    const {
      actions,
      currentOrder,
      user,
    } = props;

    const currentStepName = getCurrentStepName();

    const moveHowManySteps = currentStepName === 'deliveryOption' ? 2 : 1;

    const stepFunction = stepUpdateOrderFunctions[currentStepName];

    const { mutableOrder, shouldMoveStep } = stepFunction(currentOrder, value);

    if (shouldUpdateOrderInAPI(currentStepName, value, currentOrder)) {
      actions.updateOrder(user, mutableOrder, currentOrder.id);
      followUpAfterRemoveInvalidItems(currentStepName);
    } else {
      actions.updateOrder(null, mutableOrder, currentOrder.id);
    }
    if (shouldMoveStep) moveStep(moveHowManySteps);
  };

  const handleDateChange = (selectedTimeValue, selectedDateIndex, selectedTimeIndex) => {
    updateState({
      selectedTime: selectedTimeValue,
      timeErrorMsg: null,
      dateIndex: selectedDateIndex,
      timeIndex: selectedTimeIndex,
    });
  };

  const hasTimeError = () => {
    const {
      currentOrder,
      user,
    } = props;

    // Pass the check if there's no user and the order is for delivery
    if (!user && isDeliveredOrder() && !currentOrder.location) return true;

    return !hasTimeWindow() || !hasValidTimeWindow();
  };

  const setChooseTime = () => {
    const {
      currentOrder,
      storeLocations,
    } = props;
    const chosenTime = getNearestTimeSlot(
      new Date(),
      getOrderTimeSlot(OrderHelper.getLocationWithConfigurations(currentOrder, storeLocations)),
    );
    updateState({ selectedTime: chosenTime });
    moveStep(StepDirection.FORWARD);
  };

  const updateOrderLocation = (locationId) => {
    const { storeLocations, userAddresses, currentOrder } = props;
    if ([PICKUP, DINE_IN].includes(currentOrder.deliveryOption)) {
      const location = storeLocations.find(loc => loc.id === locationId);
      if (location) {
        updateOrderObject(location, 'location');
      }
    } else {
      // Handle new address
      if (!locationId) moveStep(StepDirection.FORWARD);
      // Handle existing addresses
      let address;
      if (locationId) address = userAddresses.find(addr => addr.id === locationId);
      if (address) {
        updateOrderObject(address, 'address');
      }
    }
  };

  const shouldRenderLocationDialog = () => {
    const { currentOrder, user } = props;
    return (
      (
        [PICKUP, DINE_IN].includes(currentOrder.deliveryOption)
        || ([DELIVERY, CATERING].includes(currentOrder.deliveryOption) && user)
      )
      && !state.forceShowNewAddressDialog
    );
  };

  // if order isASAP, API will handle time issues
  // if errors should be displayed on choose time step, we shouldn't continue
  // if there are order throttling suggestions, we should move step and not continue
  const shouldContinueWithTimeSelection = (isASAP) => {
    const {
      timeSlot: { showTimeSlotModal },
    } = props;

    // Don't handle ASAP case
    if (isASAP) return true;

    // Handle errors that are displayed on choose time page
    if (hasTimeError()) {
      const timeErrorMessage = getTimeErrorMessage();
      updateState({ timeErrorMsg: timeErrorMessage });
      return false;
    }

    // Handle order throttling case
    const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
    if (shouldUseOrderThrottling && showTimeSlotModal) {
      moveStep(StepDirection.FORWARD);
      return false;
    }

    return true;
  };

  const handleTimeChosen = async (isASAP = false) => {
    const {
      actions,
      user,
      productId,
      currentOrder,
    } = props;

    const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
    if (shouldUseOrderThrottling && !isASAP) {
      await actions.checkDesiredTime(user, getDesiredTimeObj(get(currentOrder, 'location.id')));
    }

    if (!shouldContinueWithTimeSelection(isASAP)) return;

    const desiredTime = isASAP
      ? null
      : getSelectedTime();
    const updatedValues = {
      desiredTime,
      isASAP,
    };
    updateOrderObject(updatedValues);
    if (productId) goToProductPage();
    props.handleClose();
  };

  const moveBack = () => {
    const currentStepName = getCurrentStepName();
    if (currentStepName === 'invalidOrderItems') updateState({ invalidatedOrderItems: {} });
    return moveStep(StepDirection.BACKWARD);
  };

  const handleAttemptToMoveForward = async () => {
    const { currentOrder, actions, user } = props;
    const currentStepName = getCurrentStepName();

    if (currentStepName !== 'invalidOrderItems') {
      moveStep(StepDirection.FORWARD);
      return;
    }

    const { undeletedItems } = await ItemUtils.removeItemsFromOrder(state.invalidatedOrderItems, currentOrder, actions, user);
    if (undeletedItems.length > 0) {
      moveStep(StepDirection.BACKWARD);
      return;
    }
    updateOrderObject(state.attemptedDeliveryOptionChange);
  };

  const handleTimeSuggestionClicked = (time) => {
    const updatedValues = {
      desiredTime: time,
      isASAP: false,
    };
    updateOrderObject(updatedValues);
    props.handleClose();
  };

  const getOnNextClickAction = () => {
    const currentStep = getCurrentStepName();
    return currentStep === 'chooseDateTime'
      ? handleTimeChosen()
      : handleAttemptToMoveForward();
  };

  const performUpdatesAfterCheckInvalidItems = (newDeliveryOption) => {
    // Shows the Invalid Items Dialog
    if (ItemUtils.hasInvalidItems(state.invalidatedOrderItems)) return moveStep(StepDirection.FORWARD);
    return updateOrderObject(newDeliveryOption);
  };

  const handleClickDeliveryOptionButton = newDeliveryOption => async () => {
    const { actions, apiToken, currentOrder } = props;
    // To check for invalid items, get updated unavailable delivery options on categories
    await CategoryService.fetchCategories(actions, apiToken);

    const { unavailableCategoriesByDeliveryOption: unavailableCategories } = props;
    const setInvalidItemsArgs = {
      deliveryOption: newDeliveryOption,
      currentOrder,
      unavailableCategories,
      makeInvalidItemsUIStateFunction: getUISideEffectsForInvalidItems,
    };

    const newState = ItemUtils.getNewStateForInvalidItems(setInvalidItemsArgs);
    updateState(newState);
    performUpdatesAfterCheckInvalidItems(newDeliveryOption);
  };

  /**
   * Goes back one extra step to skip past the invalidOrderItems step, which should only be rendered
   * after checking for invalid items
   */
  const goBackFromLocationToDeliveryOptionStep = () => {
    updateState({ forceShowNewAddressDialog: false });
    moveStep(StepDirection.BACKWARD * 2);
  };

  const getButtonId = (type) => {
    switch (type) {
      case DELIVERY:
        return 'deliveryOption';
      case PICKUP:
        return 'pickupOption';
      case CATERING:
        return 'cateringOption';
      default:
        return '';
    }
  };

  const renderIcons = (icon) => {
    const iconProps = {
      className: 'genericIcon',
    };
    const icons = {
      back: ArrowBackIcon,
      car: DriveEtaIcon,
      forward: ArrowForwardIcon,
      person: DirectionsWalkIcon,
      restaurant: RestaurantIcon,
      // room-service uses hyphens like this, others might
      'room-service': RoomServiceIcon,
      warning: WarningIcon,
    };
    if (!Object.keys(icons).includes(icon)) return null;
    const MappedIcon = icons[icon];
    return <MappedIcon {...iconProps} />;
  };

  const renderActionButtons = () => {
    const { translation } = props;
    const { currentStepIndex, timeErrorMsg } = state;
    const currentStepInfo = getCurrentStepInfo();
    const { showNextButton } = !!currentStepInfo && currentStepInfo;

    if (currentStepIndex === 0) {
      return (
        <div />
      );
    }

    const backButtonClass = showNextButton
      ? 'actionButtonBackWithNext'
      : 'orderFlowDialog-actionButtonBack';
    const nextButtonText = translation('NEXT');

    const nextButton = (showNextButton) && (
      <Button
        id="next"
        type="primary"
        onClick={() => getOnNextClickAction()}
        disabled={!!timeErrorMsg}
        overrideClass
        className="actionButtonNext"
      >
        {nextButtonText}
        {renderIcons('forward')}
      </Button>
    );

    return (
      <div className="orderFlowDialog-actionButtonContainer">
        <MUIButton
          className={backButtonClass}
          onClick={moveBack}
        >
          { renderIcons('back') }
          { translation('BACK') }
        </MUIButton>
        {nextButton}
      </div>
    );
  };

  const renderTimeEstimationText = () => {
    const {
      currentOrder,
      translation,
      storeLocations,
    } = props;
    const deliveryOption = get(currentOrder, 'deliveryOption');
    const location = get(currentOrder, 'location');
    if (!deliveryOption || !location) return '';
    const locationResource = storeLocations.find(loc => loc.id === location.id);
    const readyText = translation(`MenuPage.readyText.${deliveryOption}`);
    const minutesText = translation('CheckoutDrawer.orderTimeSelector.minutes');

    const firstNumberKey = isDeliveredOrder() ? 'deliveryTime' : 'pickupTime';
    const firstNumber = defaultTo(locationResource[firstNumberKey], '');
    const timeWindowKey = isDeliveredOrder() ? 'DELIVERY_WINDOW' : 'PICKUP_WINDOW';
    const timeWindow = locationResource[timeWindowKey];
    if (!timeWindow) return `${readyText} ${firstNumber} ${minutesText}`;
    const secondNumber = firstNumber + timeWindow;
    return `${readyText} ${firstNumber}-${secondNumber} ${minutesText}`;
  };

  const renderTimeSelector = () => {
    const {
      translation, currentOrder, loading,
    } = props;
    const { timeErrorMsg } = state;
    const orderLocation = get(currentOrder, 'location');
    const hours = get(currentOrder, 'location.hours');
    const { useUppercaseTitle, useASAPTimeEstimationText } = FeatureFlags.MenuPage.OrderFlowDialog.TimeSelector;
    const title = translation('MenuPage.timeSelectorTitle');

    if (loading !== 0) return <CircularProgress />;

    return (
      <div className="timeSelectorContent">
        <div className="orderFlowDialog-titleContainer">
          <Typography className="orderFlowDialog-dialogText">
            {useUppercaseTitle ? title.toUpperCase() : title}
          </Typography>
        </div>
        <div className="timeButtonContainer">
          {
            Functions.shouldShowTimePickerButton(currentOrder, 'ASAP')
            && (
              <Button
                id="asapPlease"
                type="primary"
                className="dateOptionButtonStyle"
                overrideClass
                onClick={() => handleTimeChosen(true)}
              >
                <Typography className="asapButtonTitle">
                  {translation('ASAP PLEASE')}
                </Typography>
                {
                  useASAPTimeEstimationText
                  && (
                    <Typography className="dateOptionSubtitle">
                      {renderTimeEstimationText()}
                    </Typography>
                  )
                }
              </Button>
            )
          }
          {
            Functions.shouldShowTimePickerButton(currentOrder, 'DatePicker')
            && (
              <Button
                id="selectDate"
                type="primary"
                className="dateOptionButtonStyle"
                overrideClass
                onClick={() => setChooseTime()}
              >
                <Typography className="asapButtonTitle">
                  {translation('SELECT DATE AND TIME')}
                </Typography>
              </Button>
            )
          }
        </div>
        {
          timeErrorMsg
          && (
            <div className="orderFlowDialog-titleContainer">
              <Typography>{timeErrorMsg}</Typography>
              {
                hours && (
                  <HoursComponent
                    hours={hours}
                    translation={translation}
                    // TO-DO: Remove inline styles
                    textcolorOverride="var(--text)"
                    location={orderLocation}
                    deliveryOption={currentOrder.deliveryOption}
                  />
                )
              }
            </div>
          )
        }
      </div>
    );
  };

  const renderChooseDateTime = () => {
    const {
      currentOrder,
      storeLocations,
      translation,
    } = props;
    const { timeErrorMsg, dateIndex, timeIndex } = state;

    const { useUppercaseTitle } = FeatureFlags.MenuPage.OrderFlowDialog.TimeSelector;
    const title = translation('MenuPage.timeSelectorTitle');

    const orderLocation = get(currentOrder, 'location');
    const hours = get(currentOrder, 'location.hours');
    const minuteInterval = getOrderTimeSlot(OrderHelper.getLocationWithConfigurations(currentOrder, storeLocations));

    return (
      <Fragment>
        <div className="orderFlowDialog-titleContainer">
          <Typography className="orderFlowDialog-largerDialogText">
            { useUppercaseTitle ? title.toUpperCase() : title }
          </Typography>
        </div>
        {
          timeErrorMsg
          && (
            <div className="orderFlowDialog-titleContainer">
              <Typography>{timeErrorMsg}</Typography>
              {
                hours && (
                  <HoursComponent
                    hours={hours}
                    translation={translation}
                    // TO-DO: Remove inline styles
                    textcolorOverride="var(--text)"
                    location={orderLocation}
                    deliveryOption={currentOrder.deliveryOption}
                  />
                )
              }
            </div>
          )
        }
        <DateTimeDropdown
          translation={translation}
          maxDate={Functions.getMaxDate()}
          minutesStep={minuteInterval}
          disablePast
          onChange={
            (selectedValue, selectedDateIndex, selectedTimeIndex) => handleDateChange(selectedValue, selectedDateIndex, selectedTimeIndex)
          }
          currentDateIndex={dateIndex}
          currentTimeIndex={timeIndex}
        />
      </Fragment>
    );
  };

  const renderTimeSuggestions = () => {
    const {
      dialogLoading,
      timeSlot: {
        suggestions: timeSlotSuggestions,
      },
      translation,
    } = props;

    if (dialogLoading !== 0) return <CircularProgress />;

    const TimeSuggestions = ({ times }) => times.map((time) => {
      const suggestionText = DateHelper.getSuggestedTimeIntervalString(time, translation('TimeIntervals.at'));

      return (
        <Button
          onClick={() => handleTimeSuggestionClicked(time)}
          styleOverride={{ margin: '5px' }}
          type="primary"
          text={suggestionText}
        />
      );
    });

    return (
      <div className="timeSuggestionsContent">
        { renderIcons('warning') }
        <div className="timeSuggestionsTitle">{ translation('TimeIntervals.modalTitle') }</div>
        <div className="timeSuggestionsDescription">{ translation('TimeIntervals.modalDescription') }</div>
        <TimeSuggestions times={timeSlotSuggestions} />
        <Button
          onClick={() => props.handleClose()}
          style={{ margin: '5px' }}
          text={translation('CANCEL')}
          type="secondary"
        />
      </div>
    );
  };

  const renderDeliveryOptionProductError = () => {
    const { translation, open, dialogLoading } = props;
    const { invalidatedOrderItems, attemptedDeliveryOptionChange } = state;

    return (
      <InvalidOrderItemsDialog
        invalidatedOrderItems={invalidatedOrderItems}
        deliveryOption={attemptedDeliveryOptionChange}
        open={open}
        onCloseDialog={() => props.handleClose()}
        translation={translation}
        dialogLoading={dialogLoading}
        handleChange={() => handleAttemptToMoveForward()}
        navigateBack={() => moveBack()}
      />
    );
  };

  const renderDeliveryOptionButtonLabel = (deliveryOption) => {
    const { translation } = props;
    const { icon, name } = deliveryOption;

    return (
      <div className="deliveryOptionButtonLabelContainer">
        { renderIcons(icon) }
        { translation(name) }
      </div>
    );
  };

  const renderDeliveryOptionButtons = () => (
    <div className="orderflowDialog-buttonContainer">
      {
          deliveryOptionArray.map((deliveryOption, i) => {
            if (FeatureFlags[deliveryOption.flag]) {
              return (
                <Button
                  id={getButtonId(deliveryOption)}
                  key={Functions.generateKey(i)}
                  className="deliveryOptionButtonStyle"
                  overrideClass
                  onClick={handleClickDeliveryOptionButton(deliveryOption.name)}
                >
                  {renderDeliveryOptionButtonLabel(deliveryOption) }
                </Button>
              );
            }
            return null;
          })
        }
    </div>
  );

  const renderDeliveryOptionStep = () => {
    const { translation } = props;
    return (
      <div className="deliveryOptionContainer">
        <div className="orderFlowDialog-titleContainer">
          <Typography className="orderFlowDialog-dialogText">
            {translation('MenuPage.deliveryOptionSelectorTitle')}
          </Typography>
        </div>
        {renderDeliveryOptionButtons()}
      </div>
    );
  };

  const renderLocationDialog = (allowDialogToClose = true) => {
    const {
      open,
      user,
      currentOrder,
      actions,
      translation,
      history,
      storeLocations,
      loading,
    } = props;

    let selectedDeliveryOption;
    let selectedLocationId;
    if (currentOrder) {
      selectedDeliveryOption = currentOrder.deliveryOption;
      selectedLocationId = getSelectedLocationOrAddressId();
    }

    const distances = getDistancesObject(storeLocations);
    const sortedLocations = sortLocations(storeLocations, distances);

    return (
      <OrderLocationDialog
        user={user}
        dialogOpen={open}
        onCloseDialog={() => props.handleClose()}
        deliveryOption={selectedDeliveryOption}
        selectedLocationId={selectedLocationId}
        actions={actions}
        translation={translation}
        history={history}
        storeLocations={sortedLocations}
        updateOrderLocation={locId => updateOrderLocation(locId)}
        closeAfterChange={false}
        onChangeDialog={() => updateState({ forceShowNewAddressDialog: true })}
        loading={loading}
        navigateBack={() => goBackFromLocationToDeliveryOptionStep()}
        allowDialogClose={allowDialogToClose}
      />
    );
  };

  const renderDeliveryAddressDialog = (allowDialogToClose = true) => {
    const {
      open,
      user,
      currentOrder,
      actions,
      translation,
    } = props;

    return (
      <DeliveryAddressDialog
        open={open}
        actions={actions}
        handleClose={() => props.handleClose()}
        closeAfterChange={false}
        translation={translation}
        currentOrderId={currentOrder.id}
        updateSelectedAddressId={
          (addressId) => {
            updateState({ forceShowNewAddressDialog: false });
            updateOrderLocation(addressId);
          }}
        navigateBack={() => goBackFromLocationToDeliveryOptionStep()}
        allowDialogClose={allowDialogToClose}
      />
    );
  };

  const renderTableSelectionStep = () => {
    const {
      currentOrder,
      translation,
    } = props;
    const tableNumbers = currentOrder.location ? Functions.getTableNumbers(currentOrder.location) : [];

    const { useUppercaseTitle } = FeatureFlags.MenuPage.OrderFlowDialog.TimeSelector;
    const title = translation('MenuPage.tableSelectorTitle');
    return (
      <Fragment>
        <div className="orderFlowDialog-titleContainer">
          <Typography className="orderFlowDialog-largerDialogText">
            { useUppercaseTitle ? title.toUpperCase() : title }
          </Typography>
        </div>
        <TableNumberDropdown
          value={currentOrder.tableNumber}
          onChange={(event) => { updateOrderObject(event.target.value); }}
          tableNumbers={tableNumbers}
          translation={translation}
          alignVertically
        />
      </Fragment>
    );
  };

  const showDialogForLocationStep = () => {
    const { currentOrder } = props;
    const allowDialogToClose = Functions.allowDialogClose(currentOrder) === true;

    if (shouldRenderLocationDialog()) {
      return renderLocationDialog(allowDialogToClose);
    }
    return renderDeliveryAddressDialog(allowDialogToClose);
  };

  const renderCurrentStepFunctions = {
    deliveryOption: renderDeliveryOptionStep,
    invalidOrderItems: renderDeliveryOptionProductError,
    tableSelection: renderTableSelectionStep,
    location: showDialogForLocationStep,
    desiredTime: renderTimeSelector,
    chooseDateTime: renderChooseDateTime,
    timeSuggestion: FeatureFlags.MenuPage.OrderFlowDialog.shouldUseOrderThrottling ? renderTimeSuggestions : null,
  };

  const renderCurrentStep = () => {
    const currentStep = getCurrentStepName();
    const renderFunction = renderCurrentStepFunctions[currentStep];
    return renderFunction ? renderFunction() : null;
  };

  const getProps = () => (
    {
      dialogTitle: {
        dialogTitleStyle: 'orderFlowDialog-dialogTitleStyle',
        dialogTitleText: '',
        dialogTitleSubheader: '',
      },
      dialogBodyContainerStyle: 'orderFlowDialog-dialogBodyContainerStyle',
      dialogContentStyle: 'orderFlowDialog-dialogContentStyle',
      dialogContent: renderCurrentStep(),
    }
  );

  const {
    open, dialogLoading, currentOrder,
  } = props;
  const stepProps = getProps();
  const {
    dialogContentStyle,
    dialogContent2,
    dialogTitle,
  } = stepProps;

  const allowDialogClose = Functions.allowDialogClose(currentOrder) === true;

  return (
    <DialogView
      dialogName={DIALOG_NAMES.ORDER_FLOW}
      open={open}
      titleAlignClose={false}
      handleClose={() => props.handleClose(getCurrentStepName())}
      disableBackdropClick={!allowDialogClose}
      disableEscapeKeyDown={!allowDialogClose}
      titleHasCloseBtn={allowDialogClose}
      hasDialogContent
      hasDialogContent2={false}
      hasDialogErrorContent={false}
      renderDialogContent={() => renderCurrentStep()}
      renderDialogContent2={() => dialogContent2}
      hasDialogActions
      renderActionBtn={() => renderActionButtons()}
      dialogCloseIconColor={dialogTitle.dialogTitleStyle.color}
      dialogBodyContainerStyle="orderFlowDialog-bodyContainer"
      dialogContentStyle={dialogContentStyle}
      loading={!!dialogLoading}
    />
  );
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  dialogLoading: getDialogLoading(state),
  storeLocations: getLocations(state),
  timeSlot: getTimeSlot(state),
  unavailableCategoriesByDeliveryOption: getUnavailableCategoriesByDeliveryOption(state),
  userAddresses: getCurrentUserAddresses(state),
});

OrderFlowDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  translation: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  storeLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  userAddresses: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/no-unused-prop-types
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogLoading: PropTypes.number,
  productId: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  loading: PropTypes.number.isRequired,
  unavailableCategoriesByDeliveryOption: PropTypes.objectOf(PropTypes.object),
  toggleProductDialog: PropTypes.func.isRequired,
  timeSlot: PropTypes.objectOf(PropTypes.any),
};

OrderFlowDialog.defaultProps = {
  user: null,
  productId: null,
  dialogLoading: 0,
  userAddresses: null,
  unavailableCategoriesByDeliveryOption: null,
  timeSlot: null,
};

export default connect(mapStateToProps)(withRouter(withWidth()(OrderFlowDialog)));
