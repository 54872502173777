/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { Typography, Divider } from '@material-ui/core';
import { getCurrentOrder } from '../../selectors';
import Button from '../core/components/Button';
import DialogView from '../core/components/DialogView';
import UnavailableItem from './subComponents/UnavailableItem';
import '../../css/ordersPage/ReOrderDialog.scss';
import * as Functions from '../../services/functions/Functions';
import { REORDER_CHECK_CASES } from '../../services/constants/Constants';
import * as Routes from '../../services/routes/Routes.json';


const ReOrderDialog = (props) => {
  const {
    translation, handleClick, reOrderCheckResult, history,
  } = props;

  const currentOrder = useSelector(state => getCurrentOrder(state));
  const reOrderItemStatus = Functions.getReorderItemStatus(currentOrder, reOrderCheckResult);
  const unavailableItems = get(reOrderCheckResult, 'unavailableItems', []);

  const getDialogContent = () => (
    <div className="reOrderDialog-dialogContentContainer">
      <Divider className="reOrderDialog-divider" />
      <div className="reOrderDialog-itemListContainer">
        {
          unavailableItems.map((unavailableItem, index) =>
            (<UnavailableItem
              key={Functions.generateKey(index)}
              reorderId={reOrderCheckResult.reorderId}
              translation={translation}
              unavailableItem={unavailableItem}
            />))
        }
      </div>
      <Divider className="reOrderDialog-divider" />
      <Typography className="unavailableText">
        {
          unavailableItems.length > 1
            ? translation('OrdersPage.ReOrderDialog.multipleUnavailable')
            : translation('OrdersPage.ReOrderDialog.oneUnavailable')
        }
      </Typography>
    </div>
  );

  const onClickPrimary = () => {
    if (reOrderItemStatus === REORDER_CHECK_CASES.NONE_AVAILABLE) {
      history.push(Routes.path.menuPage);
      return;
    }
    const goToMenu = true;
    handleClick(goToMenu);
  };

  const onClickSecondary = () => {
    const goToMenu = false;
    handleClick(goToMenu);
  };

  const getActionButtons = () => {
    const primaryButtonText = reOrderItemStatus === REORDER_CHECK_CASES.NONE_AVAILABLE
      ? translation('OrdersPage.ReOrderDialog.viewMenu')
      : translation('OrdersPage.ReOrderDialog.addMoreItems');
    return (
      <Fragment>
        {
          reOrderItemStatus === REORDER_CHECK_CASES.SOME_AVAILABLE
            && <Button
              fullWidth
              onClick={() => onClickSecondary()}
              type="secondary"
              text={translation('OrdersPage.ReOrderDialog.continueCheckout')}
            />
        }
        <Button
          type="primary"
          fullWidth
          onClick={() => onClickPrimary()}
          text={primaryButtonText}
        />
      </Fragment>
    );
  };

  return (
    <DialogView
      dialogTitleText={translation('OrdersPage.ReOrderDialog.title')}
      open={props.open}
      titleHasCloseBtn
      titleAlignClose
      handleClose={props.handleCloseDialog}
      disableEscapeKeyDown={false}
      dialogTitleStyle="reOrderDialog-dialogTitleStyle"
      dialogBodyContainerStyle="reOrderDialog-dialogBodyContainerStyle"
      dialogContentStyle="reOrderDialog-dialogContentStyle"
      hasDialogContent
      hasDialogContent2={false}
      renderDialogContent={() => getDialogContent()}
      hasDialogActions
      hasDialogErrorContent={false}
      actionBtnStyle="reOrderDialog-actionBtnStyle"
      renderActionBtn={() => getActionButtons()}
    />
  );
};

ReOrderDialog.propTypes = {
  reOrderCheckResult: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

ReOrderDialog.defaultProps = {
  reOrderCheckResult: null,
};

export default (ReOrderDialog);
