import { useCallback } from "react";

const getYPositionRelativeToAncestor = (element, ancestorClassName) => {
  let yPos = 0;
  let elementToScan = element;
  while (elementToScan && elementToScan.className !== ancestorClassName) {
    yPos += elementToScan.offsetTop;
    elementToScan = elementToScan.offsetParent;
  }
  return yPos;
};

// A positioned element is an element whose computed position value is either relative, absolute, fixed, or sticky. (In other words, it's anything except static.)
// https://devdocs.io/css/position
const useFindRowNumber = ({ parentHeight, childElement, positionedAncestorElementClassName }) => {
  return useCallback(() => {
    // Get Parent div total height
    // Get each single card’s height, positionY of top of card in the parent div, and half the height to get the centre
    // Divide the parent div into range sections that are equal by card height
    // See what range the centre of the card falls inside
    // Return the range number as a slot like L1, L2, L3, etc.

    if (!childElement || !parentHeight) return null;

    const cardHeight = childElement.offsetHeight;
    const cardPositionY = getYPositionRelativeToAncestor(childElement, positionedAncestorElementClassName);
    const cardCentre = cardPositionY + (cardHeight / 2);

    const rowRanges = [];
    let usedHeight = 0;
    while (usedHeight < parentHeight) {
      const totalHeightChecked = usedHeight + cardHeight;
      const rangeEnd = totalHeightChecked > parentHeight ? parentHeight : totalHeightChecked;
      const rowPositionYRange = [usedHeight, rangeEnd];
      rowRanges.push(rowPositionYRange);
      usedHeight += cardHeight;
    }

    let foundRow = null;
    rowRanges.forEach((range, index) => {
      if (cardCentre >= range[0] && cardCentre <= range[1]) {
        foundRow = index + 1;
      }
    });

    return foundRow ? `L${foundRow}` : null;
  }, [parentHeight, childElement]);
};

export default useFindRowNumber;
