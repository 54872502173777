import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrentOrder } from '../../../selectors';

import '../../../css/paymentPage/subComponents/ToastPaymentComponent.scss';

class ToastPaymentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    window.addEventListener('message', this.handleIframeTask);
    this.getHtmlContent();
  }

  getHtmlContent = async () => {
    const { actions, user } = this.props;
    const response = await actions.getOfflineResource(user.token, ['toast-payment', 'web']);
    // We 'manually' insert the HTML we got from API into our iframe.
    // eslint-disable-next-line no-undef
    const iframe = document.getElementById('toastWebForm');
    if (!iframe) return;
    const iframedoc = iframe.contentWindow.document || iframe.contentDocument;
    iframedoc.open();
    iframedoc.write(response.data);
    iframedoc.close();
  }

  handleIframeTask = (event) => {
    if (event && event.data && event.data.source === 'toastForm') {
      const eventResponseData = event.data.data;
      if (event.data.status !== 500 && eventResponseData) {
        // let the parent component handle a newly added card.
        if (this.props.handleAddCard) {
          this.props.handleAddCard(eventResponseData);
        }
      }
    }
  };

  render() {
    return (
      <div className="toastPaymentComponent-frameContainer">
        <iframe
          id="toastWebForm"
          title="toast"
          frameBorder="0"
          height="100%"
          width="100%"
        />
      </div>
    );
  }
}

ToastPaymentComponent.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAddCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)((ToastPaymentComponent));
