import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Icon, Divider } from '@material-ui/core';

import {
  groupDayTimeLimits,
  groupDateTimeLimits,
  getTime,
  isDarkTheme,
  timeLimitsValid,
  generateKey,
} from '../../../services/functions/Functions';
import '../../../css/menuPage/components/TimeLimitComponent.scss';

export const TimeLimitText = ({ timeLimit, useIcons, textClass }) => (
  <div className={useIcons ? 'dateTimeLimitGroupView' : 'dateTimeLimitGroupView-noicon'}>
    <div className={useIcons ? 'dateRangeView' : '.dateRangeView-noicon'}>
      {
          useIcons && <Icon className="timeLimitIcon">calendar_month</Icon>
        }
      <Typography className={textClass}>{`${timeLimit[0]}\u0020`}</Typography>
    </div>
    {
        useIcons && <Divider orientation="vertical" flexItem className="timeLimitRowDivider" />
      }
    <div className="timeRangesView">
      {
        timeLimit[1].map((range, i) => (
          (
            <div className="timeRangeRowView" key={generateKey(i)}>
              {
                useIcons && <Icon className="timeLimitIcon">schedule</Icon>
              }
              <Typography className={textClass}>{`${getTime(range.split(',')[0])} - ${getTime(range.split(',')[1])}`}</Typography>
            </div>
          )
        ))
      }
    </div>
  </div>
);

const TimeLimitComponent = ({ category, translation }) => {
  if (!category) return null;
  if (!category.timeLimits) return null;

  const { timeLimits } = category;
  if (!timeLimits[0]) return null;
  if (!timeLimitsValid(timeLimits)) return null;

  const groupedLimits = timeLimits[0].endDate != null
    ? groupDateTimeLimits(timeLimits)
    : groupDayTimeLimits(timeLimits);
  const testId = `timeLimitContainer-${category.id}`;
  const containerStyle = isDarkTheme() ? 'timeLimitContainer-dark' : 'timeLimitContainer';
  const titleStyle = isDarkTheme() ? 'timeLimitTitle-dark' : 'timeLimitTitle';
  const textClass = isDarkTheme() ? 'timeLimitComponentText-dark' : 'timeLimitComponentText';

  return (
    <div>
      <div className={containerStyle} data-testid={testId}>
        <Typography className={titleStyle}>{`${translation('MenuPage.timeLimit')}:`}</Typography>
        {
          [...groupedLimits].map((timeLimit, i) => <TimeLimitText timeLimit={timeLimit} textClass={textClass} key={generateKey(i)} />)
        }
      </div>
    </div>

  );
};

TimeLimitComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  category: PropTypes.objectOf(PropTypes.any),
};

TimeLimitComponent.defaultProps = {
  category: null,
};

TimeLimitText.propTypes = {
  timeLimit: PropTypes.arrayOf(PropTypes.any).isRequired,
  useIcons: PropTypes.bool,
  textClass: PropTypes.string,
};

TimeLimitText.defaultProps = {
  useIcons: true,
  textClass: null,
};

export default TimeLimitComponent;
