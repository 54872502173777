import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { PAGES, PLACEMENTS, SLOTS, TYPES, CONTROLS } from './constants';

export default class ReorderATCEvent extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    idSeam,
    sessionId,
    slot,
    reOrderId,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.REORDER_ATC,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: PAGES.ORDERS,
        slot: slot || SLOTS.L1,
        placement: PLACEMENTS.ORDERS_CAROUSEL,
        control: CONTROLS.ORDER_AGAIN_BUTTON,
      },
      position: getPositionObject({ clientX, clientY }),
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
      orderId: reOrderId,
    };
    this.setData(data);
  }
}
