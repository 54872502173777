import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

import Button from '../../core/components/Button';
import '../../../css/orderStatusPage/subComponents/LocationPrepComponent.scss';
import { parseAddress } from '../../../services/functions/Functions';

const LocationPrepComponent = (props) => {
  const {
    orderPlaced,
    translation,
  } = props;


  return (
    orderPlaced
      && (
      <div className="locationInfo">
        <Table className="locationPrepComponent-tableStyle ">
          <TableHead>
            <TableRow>
              <TableCell className="locationPrepComponent-placeHolderTableCellStyle" />
              <TableCell className="locationPrepComponent-headerTableLocation">
                {translation('OrderStatusPage.locationHeading')}
              </TableCell>
              <TableCell className="locationPrepComponent-headerTableCellStyle" />
              <TableCell className="locationPrepComponent-placeHolderTableCellStyle" />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              orderPlaced.location
              ? (
                <TableRow>
                  <TableCell className="locationPrepComponent-placeHolderTableCellStyle" />
                  <TableCell className="locationPrepComponent-tableCellDeliveryOption">
                    <Typography className="locationPrepComponent-locationName">
                      {(orderPlaced.location.name).toUpperCase()}
                    </Typography>
                    <Typography className="locationPrepComponent-locationAddress">
                      {parseAddress(orderPlaced.location.address)}
                    </Typography>
                  </TableCell>
                  <TableCell className="locationPrepComponent-tableCellAddress">
                    <a className="locationPrepComponent-telLink" href={`tel:${orderPlaced.location.phone}`}>
                      <Button
                        type="primary"
                        className="locationPrepComponent-callButton"
                        text={orderPlaced.location.phone}
                      />
                    </a>
                  </TableCell>
                  <TableCell className="locationPrepComponent-placeHolderTableCellStyle" />
                </TableRow>
              )
              : <TableRow />
            }
          </TableBody>
        </Table>
      </div>
      )
  );
};

LocationPrepComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  orderPlaced: PropTypes.objectOf(PropTypes.any),
};

LocationPrepComponent.defaultProps = {
  orderPlaced: null,
};

export default (LocationPrepComponent);
