import fjsh from 'fast-json-stable-hash';

export const requestsByKey = {};

const fetchRequest = (request, isPlain, noResponseBody) => fetch(request).then((response) => {
  if (response.status > 399) {
    if (response.status === 502) {
      return Promise.reject(new Error('Cannot process your request. Please try again in a few seconds.'));
    }
  }
  if (response.status === 204) {
    return (response.text()).then(data => ({ data, response }));
  }

  if (noResponseBody) return Promise.resolve({ data: null, response });

  return (!isPlain ? response.json() : response.text()).then(data => ({ data, response }));
})
  .then(({ data, response }) => {
    if (!response.ok) {
      const error = {
        message: !isPlain ? data.message : data,
        status: data.status,
      };
      return Promise.reject(error);
    }
    const body = {
      data,
      token: response.headers.get('api_token'),
    };
    return Promise.resolve(body);
  }).catch((error) => {
    if (error.message === 'Failed to fetch') {
      return Promise.reject(new Error('Server is not responding'));
    }
    return Promise.reject(error);
  });

export const hashRequestBody = (requestBody) => {
  if (requestBody) return fjsh.hash(requestBody);
  return '';
};

// Handle duplicate responses by storing them and returning them if they already exist in the requestsByKey object.
export const handleResponse = (request, requestBody, isPlain, noResponseBody) => {
  if (!['GET', 'POST', 'PUT', 'DELETE'].includes(request.method)) return fetchRequest(request, isPlain);
  // If there's no body to hash, then just return an empty string
  const bodyHash = hashRequestBody(requestBody);
  const key = `${request.method}${request.url}${bodyHash}`;
  if (!requestsByKey[key]) {
    const fetchPromise = fetchRequest(request, isPlain, noResponseBody).finally(() => delete requestsByKey[key]);
    requestsByKey[key] = fetchPromise;
  }
  return requestsByKey[key];
};

export default handleResponse;
