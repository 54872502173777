import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from '@material-ui/core';

import Button from '../../core/components/Button';
import DrawerNavToSubscriptionPage from '../../../events/Subscription/DrawerNavToSubscriptionPage';
import * as Routes from '../../../services/routes/Routes.json';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';
import '../../../css/core/components/AppDrawer.scss';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import NotificationIconComponent from './NotificationIconComponent';
import useCloudEventsBaseParams from '../../../events/hooks/useCloudEventsBaseParams';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';

const staticMenu = [
  {
    title: 'AppDrawer.staticMenu.menu',
    link: Routes.path.menuPage,
  },
  {
    title: 'AppDrawer.staticMenu.locations',
    link: Routes.path.locationsPage,
  },
];

const fullLoggedInMenu = [
  {
    title: 'AppDrawer.loggedInMenu.orderHistory',
    link: Routes.path.ordersPage,
    icon: 'history',
  },
  {
    title: 'AppDrawer.loggedInMenu.myRewards',
    link: 'rewards',
    icon: 'star',
  },
  {
    title: 'AppDrawer.loggedInMenu.notifications',
    link: Routes.path.notificationsPage,
    icon: 'notifications',
  },
  {
    title: 'AppDrawer.loggedInMenu.paymentMethods',
    link: Routes.path.paymentMethodPage,
    icon: 'payment',
  },
  {
    title: 'AppDrawer.loggedInMenu.settings',
    link: Routes.path.settingsPage,
    icon: 'settings',
  },
  {
    title: 'AppDrawer.loggedInMenu.subscriptions',
    link: Routes.path.subscriptionsPage,
    icon: 'local_activity',
  },
  {
    title: 'AppDrawer.loggedInMenu.account',
    link: Routes.path.accountPage,
    icon: 'person',
  },
  {
    title: 'AppDrawer.loggedInMenu.signOut',
    link: '',
    icon: 'input',
    signOut: 'signOut',
  },
];

const AppDrawer = ({
  translation,
  user,
  history,
  handleClickAuthAppDrawer,
  handleClickDrawerOptions,
  externalOptions,
  appDrawerOpen,
  onHandleDrawerClose,
}) => {
  const cloudEventsSubjectParams = useCloudEventsBaseParams();

  const getListItemIcon = (menuItemIcon) => {
    if (menuItemIcon === 'notifications') {
      return (
        <NotificationIconComponent
          iconStyleProps="appDrawer-iconStyle"
        />
      );
    }
    return (
      <ListItemIcon>
        <Icon className="appDrawer-iconStyle">{menuItemIcon}</Icon>
      </ListItemIcon>
    );
  };

  const handleClickOption = (route, clickEvent = {}) => {
    onHandleDrawerClose();
    if (route === Routes.path.subscriptionsPage) {
      const userToken = user && user.token;
      const cloudEvent = new DrawerNavToSubscriptionPage({
        ...cloudEventsSubjectParams,
        route: history && history.location && history.location.pathname,
        ...getClickEventClientXY(clickEvent),
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }

    if (route === 'rewards') {
      handleClickDrawerOptions('REWARDS');
    } else {
      history.push(route);
    }
  };

  // Get the option list for logged in users.
  const getLoggedInMenu = () => {
    let loggedInOptions = (FeatureFlags.CoreView.HeaderComponent.hidePaymentOptions)
      ? fullLoggedInMenu.filter(menu => menu.link !== Routes.path.paymentMethodPage)
      : fullLoggedInMenu;

    if (FeatureFlags.disableRewards) loggedInOptions = loggedInOptions.filter(menu => menu.link !== Routes.path.rewardsPage);
    loggedInOptions = FeatureFlags.enableAccountPage
      ? loggedInOptions.filter(menu => menu.link !== Routes.path.settingsPage)
      : loggedInOptions.filter(menu => menu.link !== Routes.path.accountPage);

    if (user && user.guestToken) loggedInOptions = loggedInOptions.filter(menu => menu.link === Routes.path.ordersPage);
    if (!FeatureFlags.enableSubscription) loggedInOptions = loggedInOptions.filter(menu => menu.link !== Routes.path.subscriptionsPage);
    return loggedInOptions;
  };

  const loggedInMenu = getLoggedInMenu();
  const { useSignupButtonPrimary } = FeatureFlags.CoreView.AppDrawerComponent;
  const allOptions = (FeatureFlags.CoreView.HeaderComponent.showExtraLinksAppDrawer)
    ? staticMenu.concat(externalOptions) : staticMenu;


  const staticMenuList = (
    <List className="staticMenu">
      {
        allOptions.map(menuItem => (
          menuItem.isExternal
          ? (
            <ListItem
              button
              key={menuItem.title}
              component="a"
              href={menuItem.href}
              target={menuItem.target}
            >
              <ListItemText
                primary={translation(menuItem.title)}
                classes={{ primary: 'staticMenuText' }}
              />
            </ListItem>
          )
          : (
            <ListItem
              button
              key={menuItem.title}
              onClick={() => handleClickOption(menuItem.link)}
            >
              <ListItemText
                primary={translation(menuItem.title)}
                classes={{ primary: 'staticMenuText' }}
              />
            </ListItem>
          )
        ))
      }
    </List>
  );

  const variableMenuList = (
    <List className="variableMenu">
      {
        user
          ? (
            loggedInMenu.map(menuItem => (
              <ListItem
                button
                key={menuItem.title}
                onClick={
                  menuItem.signOut
                    ? () => handleClickAuthAppDrawer(user)
                    : event => handleClickOption(menuItem.link, event)
                }
              >
                {getListItemIcon(menuItem.icon)}
                <ListItemText primary={translation(menuItem.title)} />
              </ListItem>
            ))
          )
          : (
            <div className="loggedOut">
              <Button
                fullWidth
                type={useSignupButtonPrimary ? 'primary' : 'secondary'}
                onClick={() => handleClickAuthAppDrawer(user)}
                text={translation('AppDrawer.loggedOut.signIn')}
              />
              <Typography className="notMember">
                {`${translation('AppDrawer.loggedOut.notAMember')}?`}
              </Typography>
              <Typography
                className="createAccount"
                onClick={() => handleClickDrawerOptions(translation('AppDrawer.loggedOut.createAccount'))}
              >
                {translation('AppDrawer.loggedOut.createAccount')}
              </Typography>
            </div>
          )
      }
    </List>
  );

  return (
    <SwipeableDrawer
      open={appDrawerOpen}
      onOpen={() => {}}
      onClose={onHandleDrawerClose}
    >
      <div className="appDrawer">
        {staticMenuList}
        {variableMenuList}
      </div>
    </SwipeableDrawer>
  );
};

AppDrawer.propTypes = {
  translation: PropTypes.func.isRequired,
  externalOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClickAuthAppDrawer: PropTypes.func.isRequired,
  handleClickDrawerOptions: PropTypes.func.isRequired,
  onHandleDrawerClose: PropTypes.func.isRequired,
  appDrawerOpen: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

AppDrawer.defaultProps = {
  user: null,
};

export default (AppDrawer);
