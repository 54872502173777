import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Icon,
  IconButton,
} from '@material-ui/core';
import Immutable from 'seamless-immutable';

import Button from '../../core/components/Button';
import ReadMore from '../../core/components/ReadMore';
import '../../../css/menuPage/components/ProductBoxComponent.scss';
import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import { productIcons } from '../../../services/constants/Constants';
import FeaturePizzaIcon from '../../../assets/images/feature@3x.png';
import { getCurrencySymbol, productIsOutOfStock, getImageUrl } from '../../../services/functions/Functions';

class ProductBoxComponent extends Component {
  componentDidMount = () => {
    const { product, getMaxBoxHeight } = this.props;
    // eslint-disable-next-line no-undef
    const productDiv = document.getElementById(`productBox-${product.id}`);
    if (!productDiv) return;
    getMaxBoxHeight(productDiv.offsetHeight);
  }

  getLowestPrice = (items) => {
    const mutableItems = Immutable.asMutable(items, { deep: true });
    const sortedItems = mutableItems.sort((a, b) => a.price - b.price);
    return sortedItems[0].price.toFixed(2);
  }

  getPriceText = (productItems) => {
    const { translation } = this.props;
    if (productItems.length === 1 && productItems[0].price === 0) {
      return '';
    } else if (productItems.length > 1) {
      return `${getCurrencySymbol()}${this.getLowestPrice(productItems)} ${translation('ProductBoxComponent.andUp')}`;
    } else if (productItems.length > 0 && productItems[0] != null) {
      return `${getCurrencySymbol()}${productItems[0].price.toFixed(2)}`;
    }
    return '';
  }

  parseDescription = (description) => {
    const { translation } = this.props;
    const desc = description && description.replace(translation('ProductBoxComponent.vegetarian'), '').trim();
    return desc;
  }

  renderOutOfStockOverlay = () => {
    const { translation } = this.props;
    // TO-DO: Remove inline styles
    return (
      <div className="oufOfStockOverlay">
        <IconButton
          aria-label="warning"
        >
          <Icon
            color="error"
            fontSize="large"
          >
            warning
          </Icon>
        </IconButton>
        <Typography className="productBox-outOfStockText">
          {translation('ProductBoxComponent.outOfStock')}
        </Typography>
      </div>
    );
  }

  render() {
    const {
      translation, product, handleClick, currentOrderLocationId, showButton, upsell,
    } = this.props;
    const {
      showProductImage, showProductDescription, useRoundImage, useTransparentBackground,
    } = FeatureFlags.ProductBoxComponent;
    const iconList = get(product, 'icons');
    let cardClassName = showProductDescription ? 'cardWithDescription' : 'cardNoDescription';
    if (useTransparentBackground) cardClassName = `${cardClassName} transparentBackground`;
    if (upsell) cardClassName = `${cardClassName} upsell`;
    let headerClassName = 'productBox-header';
    if (upsell) headerClassName = 'productBox-upsellHeader';
    let mediaClassName = useRoundImage ? 'media-round' : 'media';
    if (upsell) mediaClassName = 'upsell-media';
    return (
      <div className="productBox-productContainer" >
        {
          FeatureFlags.ProductBoxComponent.featureProduct.show
          && <img src={FeaturePizzaIcon} alt="Feature Pizza" className="featurePizzaIcon" />
        }
        <Card
          id={`productBox-${product.id}`}
          className={cardClassName}
          onClick={(event) => {
            if (!productIsOutOfStock(product, currentOrderLocationId)) handleClick(product.id, event);
          }}
        >
          {productIsOutOfStock(product, currentOrderLocationId) && this.renderOutOfStockOverlay()}
          <div className="cardHeaderContainer">
            {
              showProductImage
                && (
                  <CardMedia
                    className={mediaClassName}
                    component="img"
                    src={
                      product.images && product.images.length > 0
                        ? product.images[0].src
                        : getImageUrl('image-placeholder.png')
                    }
                  />
                )
            }
            <CardHeader
              title={
                <div className="productBox-titleContainer">
                  {FeatureFlags.ProductBoxComponent.useUppercaseProductName ? product.name.toUpperCase() : product.name}
                  <div className="iconsContainer">
                    {
                      productIcons.map((pIcon) => {
                        if (iconList && iconList.includes(pIcon.key)) {
                          return (
                            <img
                              key={pIcon.key}
                              src={getImageUrl(`productIcons/${pIcon.key}.png`)}
                              className="productBox-productIcon"
                              alt={translation(pIcon.translation)}
                              title={translation(pIcon.translation)}
                            />
                          );
                        }
                        return null;
                      })
                    }
                  </div>
                </div>
            }
              subheader={this.getPriceText(product.items)}
              classes={{
              content: headerClassName,
              title: 'productBox-titleText',
              subheader: 'productBox-subheader',
            }}
            />
          </div>
          {
              showProductDescription && !upsell
              && (
                <CardContent classes={{ root: 'removeTopPadding' }}>
                  <ReadMore
                    // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
                    charLimit={FeatureFlags.ResourceConstants.descriptionCharacterLimit}
                    textClass="productBox-description"
                    text={this.parseDescription(product.description)}
                    translation={translation}
                  />
                </CardContent>
              )
          }
          {
            showButton
            && (
              <Button
                type="primary"
                className="productBox-addToCartButton"
                overrideClass
                text={translation('ProductFlowDialog.orderNowText')}
              />
            )
          }
        </Card>
      </div>
    );
  }
}

ProductBoxComponent.propTypes = {
  showButton: PropTypes.bool,
  translation: PropTypes.func.isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func.isRequired,
  getMaxBoxHeight: PropTypes.func,
  currentOrderLocationId: PropTypes.number,
  upsell: PropTypes.bool,
};

ProductBoxComponent.defaultProps = {
  getMaxBoxHeight: () => {},
  currentOrderLocationId: null,
  showButton: false,
  upsell: false,
};

export default (ProductBoxComponent);
