import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';
import * as ItemUtils from '../services/helpers/ItemUtils';

const initialState = Immutable({
  currentOrder: {},
  timeSlot: {
    showTimeSlotModal: false,
    suggestions: [],
  },
});

const calculateNewOrderPrice = (
  currentOrderPrice,
  newOrderItemPrice,
  currentOrderItemPrice = 0,
) => ((currentOrderPrice - currentOrderItemPrice) + newOrderItemPrice);

const calculateNewOrderTax = (
  currentOrderTax,
  newOrderItemPrice,
  newOrderItemTax,
  currentOrderItemPrice = 0,
  currentOrderItemTax = 0,
) => ((currentOrderTax - ((currentOrderItemPrice * currentOrderItemTax) / 100))
+ ((newOrderItemPrice * newOrderItemTax) / 100));

const calculateNewOrderTotalPrice = (
  currentOrderPrice,
  newOrderItemPrice,
  currentOrderItemPrice = 0,
  currentOrderTax,
  newOrderTax,
) => ((currentOrderPrice - currentOrderItemPrice - currentOrderTax) + newOrderTax + newOrderItemPrice);

export default function OrderReducer(state = initialState, action) {
  let newState;
  let currentOrder;
  let mutableOrder;
  let newOrderItemPrice; // orderItem.price * orderItem.quantity
  let currentOrderItemPrice; // orderItem.price * orderItem.quantity
  let currentOrderItemTax; // orderItem.tax: tax percentage (e.g. 5)

  const currentOrderPrice = Immutable.getIn(state, ['currentOrder', 'price']) || 0;
  const currentOrderTotalPrice = Immutable.getIn(state, ['currentOrder', 'totalPrice']) || 0;
  const currentOrderTax = Immutable.getIn(state, ['currentOrder', 'tax']) || 0;

  switch (action.type) {
    case types.CREATE_ORDER_SUCCESS:
    case types.UPDATE_ORDER_SUCCESS:
      newState = Immutable.update(
        state,
        'currentOrder',
        order => (
          !order
            ? [action.order]
            : order // currentOrder is null/undefined before the order is first created
        ),
      );
      currentOrder = Immutable.getIn(newState, ['currentOrder']) || {};
      if (action.order && action.order.isASAP !== undefined) {
        // isASAP can be null, true or false. Null when createOrder without setting
        // isASAP. T/F when we select ASAP or select a time.
        // Only update properties that have changed in the order
        // as indicated in the API response. If directly set state to
        // action.order, we will lose the JsonIgnore properties indicated
        // in the Order.java entity that are currently in the Redux state.
        return Immutable.set(
          newState,
          'currentOrder',
          Object.assign(
            {},
            currentOrder,
            action.order,
          ),
        );
      }
      return Immutable.set(
        newState,
        'currentOrder',
        Object.assign({}, currentOrder, action.order),
      );
    case types.CREATE_ORDER_ITEM_SUCCESS:
      newState = Immutable.updateIn(
        state,
        ['currentOrder', 'items'],
        items => (
          !items
            ? [action.orderItem]
            : items.concat(action.orderItem)
        ),
      );
      // Calculate the new order item's total price (taking into account the quantity)
      newOrderItemPrice = action.orderItem.price * action.orderItem.quantity;
      // Fetch newest copy of currentOrder with updated state for currentOrder.items
      mutableOrder = Immutable.asMutable(newState.currentOrder, { deep: true });
      // Update currentOrder.price
      mutableOrder.price = calculateNewOrderPrice(
        currentOrderPrice,
        newOrderItemPrice,
      );
      // Update currentOrder.tax
      mutableOrder.tax = calculateNewOrderTax(
        currentOrderTax,
        newOrderItemPrice,
        action.orderItem.tax,
      );
      // Update currentOrder.totalPrice
      mutableOrder.totalPrice = calculateNewOrderTotalPrice(
        currentOrderTotalPrice,
        newOrderItemPrice,
        currentOrderItemPrice,
        currentOrderTax,
        mutableOrder.tax,
      );
      return Immutable.set(
        newState,
        'currentOrder',
        Object.assign({}, mutableOrder),
      );
    case types.UPDATE_ORDER_ITEM_SUCCESS: {
      const { user } = action;
      newState = Immutable.updateIn(
        state,
        ['currentOrder', 'items'],
        items => (
          items.map((item) => {
            const itemId = ItemUtils.getItemId(item, user);
            if (itemId === action.orderItemId) {
              currentOrderItemPrice = item.price * item.quantity;
              currentOrderItemTax = item.tax;
              return action.orderItem;
            }
            return item;
          })
        ),
      );
      // Calculate the order item's updated total price (taking into account the quantity)
      newOrderItemPrice = action.orderItem.price * action.orderItem.quantity;
      // Also include item's redeemed amount
      newOrderItemPrice -= action.orderItem.totalRedeemedAmount;
      // Fetch newest copy of currentOrder with updated state for currentOrder.items
      mutableOrder = Immutable.asMutable(newState.currentOrder, { deep: true });
      // Update currentOrder.price
      mutableOrder.price = calculateNewOrderPrice(
        currentOrderPrice,
        newOrderItemPrice,
        currentOrderItemPrice,
      );
      // Update currentOrder.tax
      mutableOrder.tax = calculateNewOrderTax(
        currentOrderTax,
        newOrderItemPrice,
        action.orderItem.tax,
        currentOrderItemPrice,
        currentOrderItemTax,
      );
      // Update currentOrder.totalPrice
      mutableOrder.totalPrice = calculateNewOrderTotalPrice(
        currentOrderTotalPrice,
        newOrderItemPrice,
        currentOrderItemPrice,
        currentOrderTax,
        mutableOrder.tax,
      );
      return Immutable.set(
        newState,
        'currentOrder',
        Object.assign({}, mutableOrder),
      );
    }
    case types.DELETE_ORDER_ITEM_SUCCESS: {
      const { user, orderResponse = {} } = action;
      newState = Immutable.updateIn(
        state,
        ['currentOrder', 'items'],
        items => (
          items.filter((item) => {
            const itemId = ItemUtils.getItemId(item, user);
            return itemId !== action.orderItemId;
          })
        ),
      );
      // Fetch newest copy of currentOrder with updated state for currentOrder.items
      mutableOrder = Immutable.asMutable(newState.currentOrder, { deep: true });
      // Order item quantity can be partially redeemed. When removed from cart,
      // all the quantities should be removed. Therefore, need to pass in price
      // and tax, otherwise order reducer would need to figure out which items
      // were redeemed and calculate the correct prices using
      // Functions.calculatePrice (which requires products as an argument)
      const isEmptyOrder = mutableOrder.items.length === 0;
      mutableOrder.totalPrice = isEmptyOrder ? 0 : orderResponse.totalPrice;
      mutableOrder.price = isEmptyOrder ? 0 : orderResponse.price;
      mutableOrder.totalTip = isEmptyOrder ? 0 : orderResponse.totalTip;
      mutableOrder.tipAmount = isEmptyOrder ? 0 : orderResponse.tipAmount;
      mutableOrder.tax = orderResponse.tax;
      return Immutable.set(
        newState,
        'currentOrder',
        Object.assign({}, mutableOrder),
      );
    }
    case types.MAKE_PAYMENT_SUCCESS:
      return Immutable.update(
        state,
        'currentOrder',
        () => {}, // Delete currentOrder
      );
    case types.LOGOUT_USER:
      if (action.eventSource === 'verifyCloseIcon') return state;
      return Immutable.merge(state, { ...initialState });
    case types.GET_TIME_SUGGESTIONS_SUCCESS:
      return Immutable.updateIn(state, ['timeSlot', 'suggestions'], () => action.suggestions);
    case types.CHECK_DESIRED_TIME_SUCCESS:
      return Immutable.updateIn(state, ['timeSlot', 'showTimeSlotModal'], () => false);
    case types.CHECK_DESIRED_TIME_FAILURE:
      return Immutable.updateIn(state, ['timeSlot', 'showTimeSlotModal'], () => true);
    default:
      return state;
  }
}
