import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Typography,
  Icon,
  Badge,
} from '@material-ui/core';
import {
  func,
  objectOf,
  any,
} from 'prop-types';

import * as Actions from '../../../actions/Actions';
import { getCurrentOrder } from '../../../selectors';
import '../../../css/menuPage/components/FloatingCartButton.scss';
import { getCurrencySymbol, getOrderPrice } from '../../../services/functions/Functions';

const FloatingCartButton = (props) => {
  const { currentOrder, toggleCheckoutDrawer } = props;
  const itemsQuantity = get(currentOrder, 'items.length', 0);
  const orderPrice = getOrderPrice(currentOrder);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      id="floatingCart"
      role="button"
      className="floatingCartButton-container"
      onClick={() => toggleCheckoutDrawer()}
      tabIndex={0}
    >
      <Badge overlap="rectangular" badgeContent={itemsQuantity} color="primary" classes={{ badge: 'floatingCartButton-cartBadge' }} >
        <Icon className="floatingCartButton-cartIcon">shopping_cart</Icon>
      </Badge>
      <Typography className="checkoutTextStyles">
        {`${getCurrencySymbol()}${orderPrice.toFixed(2)}`}
      </Typography>
    </div>
  );
};

FloatingCartButton.propTypes = {
  currentOrder: objectOf(any).isRequired,
  toggleCheckoutDrawer: func.isRequired,
};

FloatingCartButton.defaultProps = {
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

const mapDispatchToProps = dispatch => ({
  toggleCheckoutDrawer: () => dispatch(Actions.toggleComponent('CheckoutDrawer')),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloatingCartButton);
