const TYPES = {
  CLICK_CART_PAY_NOW_BUTTON: 'ca.craver.web.click_cart_pay_now_button.v1',
  CLICK_APPLY_COUPON_BUTTON: 'ca.craver.web.click_apply_coupon_button.v1',
};

const PAGES = {
  CHECKOUT_DRAWER: 'checkout-drawer',
};

const SLOTS = {
  FOOTER: 'footer',
  L5: 'L5',
};

const PLACEMENTS = {
  CART_CALL_TO_ACTION_PLACEMENT: 'cart-call-to-action-placement',
  USE_COUPON_CODE_PLACEMENT: 'use-coupon-code-placement',
};

const CONTROLS = {
  CART_PAY_NOW_BUTTON: 'cart-pay-now-button',
  APPLY_COUPON_BUTTON: 'apply-coupon-button',
};

export {
  TYPES,
  PAGES,
  SLOTS,
  PLACEMENTS,
  CONTROLS,
};
