import React, { Fragment } from 'react';
import omit from 'lodash/omit';
import {
  Button as MUIButton,
  Icon,
  CircularProgress,
} from '@material-ui/core';
import {
  bool,
  func,
  node,
  objectOf,
  oneOf,
  string,
  any,
} from 'prop-types';

import '../../../css/core/components/Button.scss';

const getButtonIcon = (buttonIcon) => {
  if (!buttonIcon) return null;
  return <Icon className="button-buttonIcon">{buttonIcon}</Icon>;
};

const getUsedContent = ({
  buttonChildren, buttonloading, buttonText, buttonIcon,
}) => {
  if (buttonloading) return (<CircularProgress className="button-circularProgress" />);
  if (buttonChildren) return (buttonChildren);
  return (
    <Fragment>
      {getButtonIcon(buttonIcon)}
      { buttonText }
    </Fragment>
  );
};

const Button = (props) => {
  const {
    children,
    fullWidth,
    hasBorderColor,
    hasBorderRadius,
    icon,
    onClick,
    keepclickeventonclick,
    type,
    text,
    disabled,
    overrideClass,
    buttonloading,
  } = props;

  const getButtonClass = () => {
    const typeStyle = `button-${type}`;
    const buttonStyle = `
      ${typeStyle}
      ${disabled || buttonloading ? 'button-disabled' : ''}
      ${fullWidth ? 'button-fullWidth' : ''}
      ${hasBorderRadius ? 'button-borderRadius' : ''}
      ${hasBorderColor ? 'button-borderStyle' : ''}
    `;
    return buttonStyle;
  };

  const onButtonClick = (event) => {
    // If the Button is inside a Form, it will refresh the page unexpectedly, because of js form submission
    event.preventDefault();
    if (keepclickeventonclick && typeof onClick === 'function') {
      onClick(event);
      return;
    }
    if (onClick !== null) onClick();
  };

  const usedContent = getUsedContent({
    buttonChildren: children, buttonloading, buttonText: text, buttonIcon: icon,
  });

  const isButtonDisabled = () => {
    if (disabled || buttonloading) return true;
    return false;
  };

  const muiButtonProps = omit(props, ['keepclickeventonclick', 'buttonloading', 'overrideClass', 'styleOverride', 'hasBorderColor', 'hasBorderRadius', 'id', 'testId']);
  const buttonProps = {
    ...muiButtonProps,
    disabled: isButtonDisabled(),
    type: 'button',
    className: getButtonClass(),
    onClick: onButtonClick,
    style: props.styleOverride,
    'data-testid': props.testId,
  };

  if (overrideClass) {
    buttonProps.className = `${buttonProps.className} ${props.className}`;
  }

  return (
    <MUIButton
      {...buttonProps}
    >
      { usedContent }
    </MUIButton>
  );
};

Button.defaultProps = {
  children: null,
  type: 'primary',
  variant: null,
  onClick: null,
  hasBorderColor: true,
  hasBorderRadius: true,
  icon: null,
  selected: false,
  text: '',
  fullWidth: false,
  overrideClass: false,
  testId: null,
  styleOverride: null,
  className: null,
  disabled: false,
  keepclickeventonclick: false,
  buttonloading: false,
};

Button.propTypes = {
  disabled: bool,
  className: string,
  styleOverride: objectOf(any),
  testId: string,
  children: node,
  variant: oneOf(['contained', 'outlined', 'text']),
  selected: bool,
  type: oneOf(['primary', 'secondary', 'tertiary']),
  onClick: func,
  hasBorderRadius: bool,
  hasBorderColor: bool,
  icon: string,
  text: string,
  fullWidth: bool,
  overrideClass: bool,
  keepclickeventonclick: bool,
  buttonloading: bool,
};

export default Button;
