import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const initialState = Immutable({
  styles: {},
  isFetchedRemoteStylesAvailable: false,
});

export default function remoteStylesReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_REMOTE_STYLES_SUCCESS:
      return { ...state, styles: action.updatedRemoteStyles };
    case types.SET_IS_FETCHED_REMOTE_STYLES_AVAILABLE:
      return { ...state, isFetchedRemoteStylesAvailable: action.isAvailable };
    default:
      return state;
  }
}
