import React from 'react';
import { Typography } from '@material-ui/core';
import {
  string,
  func,
} from 'prop-types';

import '../../../css/core/components/LinkText.scss';


const LinkText = (props) => {
  const {
    onClick, className, text, style, id,
  } = props;
  return (
    <Typography
      id={id}
      onClick={onClick}
      className={className || 'linkText'}
      style={style}
    >
      {text}
    </Typography>
  );
};

LinkText.propTypes = {
  text: string.isRequired,
  className: string,
  onClick: func.isRequired,
  style: string,
  id: string,
};

LinkText.defaultProps = {
  className: null,
  style: null,
  id: null,
};

export default LinkText;
