import { TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from '../core/components/Button';
import DialogView from '../core/components/DialogView';

import '../../css/authentication/VerifyDialog.scss';
import { DIALOG_NAMES } from '../../services/api/CloudEvents/constants';
import { DIALOG_CONSTANTS, DIALOG_EVENT_SOURCES } from '../core/DialogConstants';

const textFieldInputs = [
  {
    id: 'verificationToken1', autoFocus: true, position: 1,
  },
  {
    id: 'verificationToken2', autoFocus: false, position: 2,
  },
  {
    id: 'verificationToken3', autoFocus: false, position: 3,
  },
  {
    id: 'verificationToken4', autoFocus: false, position: 4,
  },
];

const initialState = {
  verificationToken1: '',
  verificationToken2: '',
  verificationToken3: '',
  verificationToken4: '',
};

class VerifyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getProps = () => {
    const {
      translation, user, userEmail,
    } = this.props;
    const email = user ? user.email : userEmail;

    return (
      {
        dialogTitle: {
          dialogTitleStyle: 'verifyDialog-dialogTitleStyl',
          dialogTitleStyleVariant: 'h2',
          dialogTitleText: '',
          dialogTitleSubheader: '',
        },
        dialogBodyContainerStyle: 'verifyDialog-dialogBodyContainerStyle',
        dialogContentStyle: 'verifyDialog-dialogContentStyle',
        dialogContent: (
          <div className="verifyDialog-dialogContentContainer">
            <Typography>
              {`${translation('VerifyDialog.dialogContent.text1')} ${email}.`}
            </Typography>
            <Typography>
              {translation('VerifyDialog.dialogContent.text2')}
            </Typography>
            <div className="verifyDialog-textFieldContainer">
              {
                textFieldInputs.map(fieldInput => (
                  <TextField
                    key={fieldInput.id}
                    autoFocus={fieldInput.autoFocus}
                    className="verifyDialog-textFieldStyle"
                    inputProps={{
                      className: 'verifyDialog-inputStyle',
                      maxLength: 1,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      inputMode: 'numeric',
                    }}
                    inputRef={input => this.setInputRef(input, fieldInput.position)}
                    id={fieldInput.id}
                    value={this.state[fieldInput.id]}
                    onChange={e => this.handleTextFieldChange(e, fieldInput.id)}
                  />
                ))
              }
            </div>
          </div>
        ),
        dialogContent2: (
          <div className="verifyDialog-dialogContent2">
            <Typography>
              <a href="#" className="verifyDialog-dialogContent2Link" onClick={this.handleResendToken}>
                {translation('VerifyDialog.dialogContent2.newToken')}
              </a>
            </Typography>
          </div>
        ),
        dialogActions: {
          actionButtons: (
            <Button
              id="verifyAccount"
              fullWidth
              className="verifyDialog-verifyButton"
              onClick={this.handleClickVerify}
              disabled={this.disableButton()}
              text={translation('VerifyDialog.dialogActions.verifyAccount')}
            />
          ),
        },
      });
  };

  setInputRef = (input, position) => {
    switch (position) {
      case 2:
        this.token2Input = input;
        break;
      case 3:
        this.token3Input = input;
        break;
      case 4:
        this.token4Input = input;
        break;
      default:
        this.token1Input = input;
        break;
    }
  }

  handleResendToken = async () => {
    const { actions, user, userEmail } = this.props;
    const userObj = { email: userEmail || user.email };
    actions.sendVerificationToken(userObj, ['users', 'token']);
  };

  handleClickVerify = async () => {
    const {
      verificationToken1, verificationToken2, verificationToken3, verificationToken4,
    } = this.state;
    const verificationToken = `${verificationToken1}${verificationToken2}${verificationToken3}${verificationToken4}`;
    const {
      actions, user, userEmail, currentOrder, sendUserToCheckout,
    } = this.props;
    const userObj = { email: userEmail || user.email, token: verificationToken };

    try {
      const response = await actions.verifyUser(userObj, ['users', 'verify']);

      if (!response.error) {
        const { loggedInUser } = response;
        // Check if the user has something in the cart
        if (currentOrder.items && loggedInUser) {
          await actions.createOrder(loggedInUser, currentOrder, currentOrder.price);
        }
        this.setState(initialState);
        sendUserToCheckout();
        this.handleClose({ eventSource: 'verifySuccess' });
      }
    } catch (error) {
      console.log('API call error', error);
    }
  };

  handleClose = (closeDialogOptions = { eventSource: DIALOG_EVENT_SOURCES.CLOSE_ICON }) => {
    const defaultCloseOptions = { dialog: DIALOG_CONSTANTS.VERIFY };
    const finalCloseOptions = Object.assign(defaultCloseOptions, closeDialogOptions);
    this.setState(initialState);
    this.props.handleClose(finalCloseOptions);
  }

  disableButton = () => {
    const {
      verificationToken1, verificationToken2, verificationToken3, verificationToken4,
    } = this.state;
    return !verificationToken1 || !verificationToken2 || !verificationToken3 || !verificationToken4;
  };

  handleTextFieldChange = (e, fieldName) => {
    if (!this.state[fieldName]) {
      switch (fieldName) {
        case 'verificationToken1':
          this.token2Input.focus();
          break;
        case 'verificationToken2':
          this.token3Input.focus();
          break;
        case 'verificationToken3':
          this.token4Input.focus();
          break;
        default:
          break;
      }
    }

    this.setState({
      [fieldName]: e.target.value,
      errorText: '',
    });
  };

  render() {
    const { open } = this.props;
    const stepPros = this.getProps();
    const {
      dialogTitle,
      dialogContent,
      dialogContent2,
      dialogActions,
      dialogBodyContainerStyle,
      dialogContentStyle,
    } = stepPros;

    return (
      <DialogView
        dialogName={DIALOG_NAMES.VERIFY}
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableEscapeKeyDown={false}
        dialogTitleStyle={dialogTitle.dialogTitleStyle}
        dialogBodyContainerStyle={dialogBodyContainerStyle}
        dialogContentStyle={dialogContentStyle}
        titleHasCloseBtn
        dialogTitleText={dialogTitle.dialogTitleText}
        hasDialogContent
        hasDialogContent2
        hasDialogErrorContent={false}
        renderDialogContent={() => dialogContent}
        renderDialogContent2={() => dialogContent2}
        hasDialogActions
        actionBtnStyle="verifyDialog-actionBtnStyle"
        renderActionBtn={() => dialogActions.actionButtons}
        dialogCloseIconColor="var(--text)"
      />
    );
  }
}

VerifyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  userEmail: PropTypes.string,
  sendUserToCheckout: PropTypes.func.isRequired,
};

VerifyDialog.defaultProps = {
  user: null,
  userEmail: '',
};

export default (VerifyDialog);
