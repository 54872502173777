const TYPES = {
  NAVIGATE_TO_SUBSCRIPTION_PAGE: 'ca.craver.web.navigate_to_subscriptions_page.v1',
  CLICK_SHOW_MORE: 'ca.craver.web.click_show_more_subscription_features.v1',
  CLICK_SUBSCRIBE: 'ca.craver.web.click_subscribe.v1',
  CLICK_MANAGE_SUBSCRIPTION: 'ca.craver.web.click_manage_subscription.v1',
};

const PAGES = {
  MAIN_SCREEN: 'main-screen',
  SUBSCRIPTIONS: 'subscriptions',
};

const SLOTS = {
  HEADER_ROW: 'header-row',
  L1: 'L1',
  DRAWER_SLOT: 'drawer-slot',
};

const PLACEMENTS = {
  DRAWER: 'drawer',
  HEADER_NAV_BAR: 'header-nav-bar',
  OPEN_SUBSCRIPTIONS_PAGE_BUTTON: 'open-subscriptions-page-button',
  SUBSCRIPTIONS_CAROUSEL: 'subscription-card-carousel',
};

const CONTROLS = {
  SUBSCRIPTIONS_BUTTON: 'subscriptions-button',
  SHOW_MORE_TEXT: 'show-more-text',
  SUBSCRIBE_BUTTON: 'subscribe-button',
  MANAGE_SUBSCRIPTION_BUTTON: 'manage-subscription-button',
};

const POSITIONED_ANCESTOR_CLASS_NAMES = {
  SUBSCRIPTIONS_PAGE_MAIN: 'SubscriptionsPage-main',
};

export {
  TYPES,
  PAGES,
  SLOTS,
  PLACEMENTS,
  CONTROLS,
  POSITIONED_ANCESTOR_CLASS_NAMES,
};
