import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { getPage } from '../../services/api/CloudEvents/getPageByRoute';
import { TYPES } from './constants';

export default class PageExitEvent extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    idSeam,
    sessionId,
    route,
    componentNameAsPage,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.PAGE_EXITED,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: getPage({ route, componentNameAsPage }),
      },
      eventType: DATA_OBJ_EVENT_TYPES.NAVIGATION,
    };
    this.setData(data);
  }
}
