import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

import * as Functions from '../../services/functions/Functions';
import { getResources, getCurrentOrder } from '../../selectors';
import ThankYouComponent from './subComponents/ThankYouComponent';
import StatusComponent from './subComponents/StatusComponent';
import LocationPrepComponent from './subComponents/LocationPrepComponent';
import '../../css/orderStatusPage/OrderStatusPage.scss';
import * as Routes from '../../services/routes/Routes.json';

class OrderStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderPlaced: {},
    };
  }

  async componentDidMount() {
    const {
      actions, user, history, upliftHistory,
    } = this.props;
    if (typeof upliftHistory === 'function' && history) upliftHistory(history);

    if (user) {
      try {
        const response = await actions.getAllResources(user.token, ['users', user.id, 'orders']);
        if (!response.error && response.response.length > 0) {
          const order = Functions.getNewestOrder(response.response);
          this.setState({ orderPlaced: order });
        }
      } catch (error) {
        console.log('There is an API error', error);
      }
    } else {
      // Redirect users that are not logged in to menu page
      history.push(Routes.path.menuPage);
    }
  }

  componentDidUpdate() {
    const { user, history } = this.props;
    if (!user) {
      history.push(Routes.path.menuPage);
    }
  }

  render() {
    const {
      translation,
      user,
      loading,
    } = this.props;
    const { orderPlaced } = this.state;
    return (
      loading !== 0
        ? <LinearProgress />
        // TODO: redirect if user null or undefined
        : user && (
          <div className="orderStatusPage-main">
            <div className="orderStatusPage-content">
              <ThankYouComponent
                user={user}
                translation={translation}
                orderPlaced={orderPlaced}
              />
              <div className="orderStatusPage-info">
                <StatusComponent
                  translation={translation}
                  orderPlaced={orderPlaced}
                />
                <LocationPrepComponent
                  translation={translation}
                  orderPlaced={orderPlaced}
                />
              </div>
            </div>
          </div>
        )
    );
  }
}

OrderStatusPage.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  upliftHistory: PropTypes.func.isRequired,
};

OrderStatusPage.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  resources: getResources(state),
  currentOrder: getCurrentOrder(state),
});

export default (withRouter(connect(mapStateToProps)(OrderStatusPage)));
