import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';


const useTrackParentHeight = () => {
  const cardsParentRef = useRef(null);
  const [cardsParentHeight, setCardsParentHeight] = useState(null);

  useLayoutEffect(() => {
    if (cardsParentRef.current) {
      setCardsParentHeight(cardsParentRef.current.scrollHeight);
    }
  }, [cardsParentRef.current]);

  useEffect(() => {
    const handleResize = () => {
      if (cardsParentRef.current) {
        setCardsParentHeight(cardsParentRef.current.scrollHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    cardsParentRef,
    cardsParentHeight,
    setCardsParentHeight,
  };
};

export default useTrackParentHeight;
