/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { TextField } from '@material-ui/core';
import { func } from 'prop-types';
import NumberFormat from 'react-number-format';

import Button from '../../core/components/Button';
import { getUser } from '../../../selectors';
import '../../../css/paymentPage/subComponents/CraverGiftCardForm.scss';


const CustomCardInput = (props) => {
  const {
    inputRef, onChange, value, className, id, placeholder, onBlur,
  } = props;
  return (
    <NumberFormat
      id={id}
      placeholder={placeholder}
      className={className}
      getInputRef={inputRef}
      onBlur={onBlur}
      value={value}
      onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
    />
  );
};

const CraverGiftCardForm = ({
  actions, translation, user, handleAddCard,
}) => {
  const [cardNumber, setCardNumber] = useState('');

  const saveCard = async () => {
    const paymentMethod = {
      cardNumber,
      cardType: 'GIFT_CARD',
    };
    await actions.addResource(
      user.token, paymentMethod,
      'users', user.id,
      'payment_options',
    ).then((response) => {
      if (!get(response, 'error')) {
        setCardNumber('');
        if (handleAddCard) handleAddCard();
        actions.getAllResources(user.token, ['users', user.id, 'payment_options']);
      }
    });
  };

  return (
    <form className="craverGiftCardForm-cardForm" noValidate autoComplete="off">
      <TextField
        className="craverGiftCardForm-fullWidthInput"
        id="card-number-input"
        variant="outlined"
        placeholder={translation('NewCardComponent.newCard.cardDetails.giftCardNumber')}
        onChange={event => setCardNumber(event.target.value)}
        value={cardNumber}
        InputProps={{
          inputComponent: CustomCardInput,
        }}
      />
      <Button
        disabled={!cardNumber}
        type="primary"
        fullWidth
        onClick={() => saveCard()}
        text={translation('save')}
      />
    </form>
  );
};

CraverGiftCardForm.propTypes = {
  translation: func.isRequired,
};

CraverGiftCardForm.defaultProps = {
};

const mapStateToProps = state => ({
  user: getUser(state).user,
});

export default connect(mapStateToProps)(CraverGiftCardForm);
