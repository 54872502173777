import React, { useEffect, useRef, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import {
  tabSplitThreshold,
  TAB_CHAR_LIMIT,
} from '../../../services/constants/Constants';
import * as Functions from '../../../services/functions/Functions';

import '../../../css/core/components/TabBar.scss';

const getInitialState = selected => ({
  value: selected,
  menuListTabs: [],
  anchorEl: null,
  runDivideTabs: false,
});

const stateReducer = (state, stateToMerge) => {
  return { ...state, ...stateToMerge };
};

const groupTabs = (tabs) => {
  const splittedTabs = [];
  const threshold = tabSplitThreshold;
  if (tabs.length > threshold) {
    const folds = Math.ceil((tabs.length) / threshold);
    for (let i = 0; i < folds; i += 1) {
      const tab = tabs.slice(threshold * i, (threshold * i) + threshold);
      splittedTabs.push(tab);
    }
  } else {
    splittedTabs.push(tabs);
  }
  return splittedTabs;
};

const useHandleChange = ({ onClickSelected, updateState }) => useCallback((event, value) => {
  updateState({ value });
  onClickSelected(value);
}, [onClickSelected, updateState]);

const getTruncatedLabel = label => ((label.length > TAB_CHAR_LIMIT)
  ? `${label.substr(0, TAB_CHAR_LIMIT - 1)}...`
  : label);

const TabBar = ({
  isCentered, onClickSelected, selected, stickToTop, tabs, type, width,
}) => {
  const initialState = getInitialState(selected);
  const [state, updateState] = useReducer(stateReducer, initialState);

  const divideTabs = () => {
    const allTabs = groupTabs(tabs);
    const menuListTabs = [];

    allTabs.forEach((tabGroup) => {
      menuListTabs.push(...tabGroup);
    });
    updateState({ menuListTabs });
  };

  useEffect(() => {
    if (tabs.length > tabSplitThreshold) divideTabs();
  }, []);

  const prevSelected = useRef(selected);
  const prevTabs = useRef(tabs);

  useEffect(() => {
    if (selected !== prevSelected.current) {
      updateState({ value: selected, runDivideTabs: true });
    }
    if (tabs.length !== prevTabs.current.length) {
      divideTabs();
    }
    prevSelected.current = selected;
    prevTabs.current = tabs;
  }, [selected, tabs]);

  if (state.runDivideTabs) {
    divideTabs();
    updateState({ runDivideTabs: false });
  }

  const handleChange = useHandleChange({ onClickSelected, updateState });

  const isDesktop = Functions.isDesktopMode(width);
  let tabsClass = stickToTop ? 'tabBar-sticky' : 'tabBar-root';
  if (isCentered) tabsClass += ' tabBar-centered';

  return (
    <div className={tabsClass}>
      {
        isDesktop
        ? (
          <Tabs
            value={state.value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{ root: 'tabBar-moreButton' }}
            className="tabBar-tabs"
            variant="scrollable"
          >
            {
              tabs.map(tab => (
                <Tab
                  id={`${type}${tab.id}`}
                  classes={{ selected: 'tabBar-selected' }}
                  className="tabBar-tab"
                  key={tab.id}
                  label={getTruncatedLabel(tab.label)}
                  value={tab.id}
                />
              ))
            }
          </Tabs>
        )
        : (
          <Tabs
            value={state.value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            classes={{ root: 'tabBar-moreButton' }}
            className="tabBar-tabs"
          >
            {
              tabs.map(tab => (
                <Tab
                  id={`${type}${tab.id}`}
                  classes={{ selected: 'tabBar-selected' }}
                  className="tabBar-tab"
                  key={tab.id}
                  label={getTruncatedLabel(tab.label)}
                  value={tab.id}
                />
              ))
            }
          </Tabs>
        )
      }
    </div>
  );
};

TabBar.propTypes = {
  stickToTop: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickSelected: PropTypes.func.isRequired,
  selected: PropTypes.number,
  width: PropTypes.string,
  type: PropTypes.string,
  isCentered: PropTypes.bool,
};

TabBar.defaultProps = {
  stickToTop: false,
  selected: 0,
  width: null,
  type: '',
  isCentered: false,
};

export default (withWidth()(TabBar));
