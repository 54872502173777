import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Hidden } from '@material-ui/core';

import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import '../../../css/orderStatusPage/subComponents/ThankYouComponent.scss';
import { getImageUrl } from '../../../services/functions/Functions';

/* eslint-env browser */

const ThankYouComponent = (props) => {
  const {
    orderPlaced,
    translation,
    user,
  } = props;

  const logoSource = FeatureFlags.OrderStatusPage.ThankYouComponent.usePrimaryLogo
    ? getImageUrl('primary_logo.svg')
    : getImageUrl('logoContrast.svg');

  const logo = {
    src: logoSource,
    alt: `${window.companyName} logo`,
  };

  return (
    <div className="thankYouBox">
      <Hidden smDown>
        <div className="thankYouComponent-logo">
          <img src={logo.src} alt={logo.alt} className="logoImage" />
        </div>
      </Hidden>
      <div>
        <div className="greeting">
          <Typography className="thankYou">
            {`${translation('OrderStatusPage.thankYou')} `}
            {
              FeatureFlags.OrderStatusPage.ThankYouComponent.useUppercaseUserName
                ? user.name.toUpperCase().split(' ')[0]
                : user.name.split(' ')[0]
            }!
          </Typography>
        </div>
        <div>
          <Typography className="currentStatus">
            {translation('OrderStatusPage.orderPlaced')}
          </Typography>
          <Typography className="receipt">
            {`${translation('OrderStatusPage.receipt')} `}{user.email}
          </Typography>
          <Typography className="thankYouComponent-orderNum">
            {`${translation('OrderStatusPage.orderNum')}: `}{orderPlaced.id}
          </Typography>
        </div>
      </div>
    </div>
  );
};

ThankYouComponent.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  translation: PropTypes.func.isRequired,
  orderPlaced: PropTypes.objectOf(PropTypes.any),
};

ThankYouComponent.defaultProps = {
  user: null,
  orderPlaced: null,
};

export default (ThankYouComponent);
