/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { TextField } from '@material-ui/core';
import { func } from 'prop-types';
import NumberFormat from 'react-number-format';

import Button from './Button';
import { getUser } from '../../../selectors';
import { validateCreditCard } from '../../../services/functions/Functions';
import '../../../css/core/components/CraverCreditCardForm.scss';

// Return styles object when testing

const validatedExpiryDate = (value, translation) => {
  if (value.length < 7) return translation('NewCardComponent.invalidErrorMessages.expiryDate.nonDigits');
  const month = value.slice(0, 2);
  if (month > 12 || month < 1) {
    return translation('NewCardComponent.invalidErrorMessages.expiryDate.invalidMonth');
  }
  const year = value.slice(3);
  const expiryDate = new Date(`${year}-${month}-01`);
  const currentDate = new Date();
  if (expiryDate < currentDate) {
    return translation('NewCardComponent.invalidErrorMessages.expiryDate.expired');
  }
  return '';
};

const validateField = (fieldKey, value, validationState, setValidationState, translation) => {
  // Validation Tests
  const validPhoneNumber = /^\(\d{3}\) \d{3}-\d{4}$/;
  const validCvc = /^\d{3,4}$/;

  let fieldInvalidMsg = '';
  switch (fieldKey) {
    case 'cardNumber':
      fieldInvalidMsg = validateCreditCard(value) ? '' : translation('NewCardComponent.invalidErrorMessages.cardNumber');
      break;
    case 'cvc':
      fieldInvalidMsg = value.match(validCvc) ? '' : translation('NewCardComponent.invalidErrorMessages.cvc');
      break;
    case 'expiryDate':
      fieldInvalidMsg = validatedExpiryDate(value, translation);
      break;
    case 'phoneNumber':
      fieldInvalidMsg = value.match(validPhoneNumber) ? '' : translation('NewCardComponent.invalidErrorMessages.phoneNumber');
      break;
    default:
      return; // Terminate function so that no extra fields are appended to the state
  }
  const newValidationState = {
    ...validationState,
    [`${fieldKey}`]: fieldInvalidMsg,
  };


  setValidationState(newValidationState);
};

const CustomExpDateInput = (props) => {
  const {
    inputRef, onChange, className, id, placeholder, onBlur,
  } = props;

  // https://jinno.io/app/24?source=react-number-format
  const limit = (val, max) => {
    let result = val;
    if (result.length === 1 && result[0] > max[0]) {
      result = `0${result}`;
    }
    if (result.length === 2) {
      if (Number(result) === 0) {
        result = '01';
        // this can happen when user paste number
      } else if (result > max) {
        result = max;
      }
    }
    return result;
  };

  const cardExpiry = (val) => {
    const month = limit(val.substring(0, 2), '12');
    const date = limit(val.substring(2, 6), '9999');

    return month + (date.length ? `/${date}` : '');
  };

  return (
    <NumberFormat
      format={cardExpiry}
      id={id}
      placeholder={placeholder}
      className={className}
      getInputRef={inputRef}
      onBlur={onBlur}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
    />
  );
};

const CustomCardInput = (props) => {
  const {
    inputRef, onChange, className, id, placeholder, onBlur,
  } = props;
  return (
    <NumberFormat
      format="#### #### #### ####"
      id={id}
      placeholder={placeholder}
      className={className}
      getInputRef={inputRef}
      onBlur={onBlur}
      onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
    />
  );
};

const CustomCvcInput = ({
  inputRef, onChange, className, id, placeholder, onBlur,
}) => (
  <NumberFormat
    allowLeadingZeros
    id={id}
    placeholder={placeholder}
    className={className}
    getInputRef={inputRef}
    onBlur={onBlur}
    onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
  />
);

const CraverCreditCardForm = ({
  actions, translation, user, handleAddCard, onPressCancel,
}) => {
  const [name, setName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validationState, setValidationState] = useState({});

  const saveCard = async () => {
    const paymentMethod = {
      name,
      cardNumber,
      cvc,
      expiryMonth: expiryDate.slice(0, 2),
      expiryYear: expiryDate.slice(3),
      streetNumber: '',
      streetName: '',
      phoneNumber,
    };
    await actions.addResource(
      user.token, paymentMethod,
      'users', user.id,
      'payment_options',
    ).then((response) => {
      if (!get(response, 'error')) {
        if (handleAddCard) handleAddCard();
        actions.getAllResources(user.token, ['users', user.id, 'payment_options']);
      }
    });
  };

  const hasOneInvalidField = Object.keys(validationState).find(key => validationState[key] !== '');
  const hasAllRequiredFields = !([cardNumber, cvc, expiryDate].includes(''));

  // TO-DO: remove inline styles
  const cancelButtonStyles = {
    width: '25%',
    'margin-right': '15px',
  };

  const saveCardButtonStyles = {
    width: '25%',
  };

  return (
    <form className="craverCreditCardForm-cardForm" noValidate autoComplete="off">
      <TextField
        className="craverCreditCardForm-fullWidthInput"
        id="name-input"
        variant="outlined"
        placeholder={translation('NewCardComponent.newCard.name')}
        onChange={event => setName(event.target.value)}
      />
      <TextField
        className="craverCreditCardForm-fullWidthInput"
        id="card-number-input"
        variant="outlined"
        error={!isEmpty(validationState.cardNumber)}
        placeholder={translation('NewCardComponent.newCard.cardDetails.cardNumber')}
        onChange={event => setCardNumber(event.target.value)}
        InputProps={{
          inputComponent: CustomCardInput,
          inputProps: {
            onBlur: event => validateField('cardNumber', event.target.value, validationState, setValidationState, translation),
          },
        }}
        helperText={validationState.cardNumber}
      />
      <TextField
        className="craverCreditCardForm-fullWidthInput"
        id="phone-number-input"
        variant="outlined"
        error={!isEmpty(validationState.phoneNumber)}
        placeholder={translation('NewCardComponent.newCard.phoneNumber')}
        onChange={event => setPhoneNumber(event.target.value)}
        InputProps={{
          onBlur: event => validateField('phoneNumber', event.target.value, validationState, setValidationState, translation),
        }}
        helperText={validationState.phoneNumber}
      />
      <div className="craverCreditCardForm-dividedColumn">
        <TextField
          className="craverCreditCardForm-halfWidthInput"
          id="expiry-date-input"
          variant="outlined"
          error={!isEmpty(validationState.expiryDate)}
          helperText={validationState.expiryDate}
          placeholder={translation('NewCardComponent.newCard.cardDetails.expiryDate')}
          onChange={event => setExpiryDate(event.target.value)}
          InputProps={{
            inputComponent: CustomExpDateInput,
            onBlur: event => validateField('expiryDate', event.target.value, validationState, setValidationState, translation),
          }}
        />
        <TextField
          className="craverCreditCardForm-halfWidthInput"
          id="cvc-input"
          variant="outlined"
          error={!isEmpty(validationState.cvc)}
          helperText={validationState.cvc}
          placeholder={translation('NewCardComponent.newCard.cardDetails.cvc')}
          onChange={event => setCvc(event.target.value)}
          InputProps={{
            inputComponent: CustomCvcInput,
            onBlur: event => validateField('cvc', event.target.value, validationState, setValidationState, translation),
          }}
        />
      </div>
      <div className="craverCreditCardForm-halfWidthEnd">
        <Button
          type="secondary"
          styleOverride={cancelButtonStyles}
          onClick={onPressCancel}
          text={translation('CANCEL')}
        />
        <Button
          disabled={hasOneInvalidField || !hasAllRequiredFields}
          type="primary"
          styleOverride={saveCardButtonStyles}
          onClick={() => saveCard()}
          text={translation('save')}
        />
      </div>
    </form>
  );
};

CraverCreditCardForm.propTypes = {
  translation: func.isRequired,
};

CraverCreditCardForm.defaultProps = {
};

const mapStateToProps = state => ({
  user: getUser(state).user,
});

const EnhancedCraverCreditCardForm = compose(connect(mapStateToProps))(CraverCreditCardForm);
export default EnhancedCraverCreditCardForm;
