/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
import styleVariablesJSON from '../css/styleVariables.json';

export const getRemoteJSON = async (url) => {
  const config = { method: 'GET' };
  const response = await fetch(url, config);
  if (!response.ok) throw new Error(response.statusText);
  const data = response.json(); // get JSON from the response
  return data; // returns a promise, which resolves to this data value
};

const jsonToCssVariables = (json) => {
  let css = '';
  for (const key in json) {
    if (key && key !== 'styleOverrides') {
      css += `--${key}: ${json[key]};`;
    }
  }
  return css;
};

const createCssRules = (jsonValues) => {
  let cssRule = '';
  if (typeof jsonValues === 'string') return jsonValues;
  for (const key in jsonValues) {
    cssRule += `${key}: ${jsonValues[key]};`;
  }
  return cssRule;
};

const setStyleOverrides = (jsonOverrides) => {
  for (const key in jsonOverrides) {
    const cssRuleList = `${key}{${createCssRules(jsonOverrides[key])}}`;
    document.styleSheets[document.styleSheets.length - 1].insertRule(cssRuleList, 0);
  }
};

export const setCssVariables = (json) => {
  const cssVariables = jsonToCssVariables(json);
  document.documentElement.style.cssText = cssVariables;
  if (json.styleOverrides) setStyleOverrides(json.styleOverrides);
};

export const jsonToConstants = (json) => {
  const COLORS = {};
  for (const key in json) {
    COLORS[key] = json[key];
  }
  return COLORS;
};

export default jsonToConstants(styleVariablesJSON);
