import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
} from '@material-ui/core';

const RadioGroupComponent = (props) => {
  const {
    options,
    selectedValue,
    handleChange,
    formControlStyle,
    radioGroupStyle,
    labelStyle,
    disabledLabelStyle,
  } = props;

  return (
    <FormControl component="fieldset" className={formControlStyle}>
      <RadioGroup
        value={selectedValue}
        onChange={handleChange}
        className={radioGroupStyle}
      >
        {
          options.map(option => (
            <div key={option.key}>
              <FormControlLabel
                data-testid={`radioOption-${option.value}`}
                id={`radioOption${option.value}`}
                value={option.value}
                control={<Radio />}
                label={option.label}
                classes={{ label: labelStyle, disabled: disabledLabelStyle }}
                className={option.style}
                disabled={option.outOfStockOption}
              />
              {
                option.description
                && (
                  <Typography className={option.descriptionStyle}>
                    {option.description}
                  </Typography>
                )
              }
            </div>
          ))
        }
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  formControlStyle: PropTypes.string,
  radioGroupStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  disabledLabelStyle: PropTypes.string,
};

RadioGroupComponent.defaultProps = {
  selectedValue: '',
  formControlStyle: '',
  radioGroupStyle: '',
  labelStyle: '',
  disabledLabelStyle: '',
};

export default RadioGroupComponent;
