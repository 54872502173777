import Immutable from 'seamless-immutable';
import isString from 'lodash/isString';

import * as types from '../actions/ActionTypes';

const sanitizeMsg = (actionMsg) => {
  // Sanitize action message to make sure a string is added to this reducer's state
  if (actionMsg) {
    return isString(actionMsg) ? actionMsg : isString(actionMsg.message) ? actionMsg.message : '';
  }
  return '';
};

export default function MessageReducer(state = Immutable({ errors: '', success: '', serverDown: false }), action) {
  let serverIsDown = false;
  if (action.error && sanitizeMsg(action.error) === 'Server is not responding') {
    serverIsDown = true;
  }
  switch (action.type) {
    case types.ERROR:
    case types.LOGIN_USER_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
    case types.VERIFY_USER_FAILURE:
    case types.SIGNUP_USER_FAILURE:
    case types.GET_RESOURCE_FAILURE:
    case types.SEND_VERIFICATION_TOKEN_FAILURE:
    case types.SEND_RESET_PASSWORD_TOKEN_FAILURE:
    case types.DELETE_RESOURCE_FAILURE:
    case types.SET_ERROR_SNACKBAR_MESSAGE:
      return Immutable.merge(state, { errors: sanitizeMsg(action.error), success: '', serverDown: serverIsDown });
    case types.VERIFY_USER_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.SEND_VERIFICATION_TOKEN_SUCCESS:
    case types.SEND_RESET_PASSWORD_TOKEN_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return Immutable.merge(state, { errors: '', success: sanitizeMsg(action.successMsg), serverDown: false });
    case types.CLEAR_MESSAGE:
      return Immutable.merge(state, { errors: '', success: '' });
    default:
      return Immutable.merge(state, { errors: sanitizeMsg(action.error) });
  }
}
