import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const TextComponent = (props) => {
  const { title, style } = props;

  return (
    <Typography style={style}>
      {title}
    </Typography>
  );
};

TextComponent.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};

TextComponent.defaultProps = {
  style: {},
};

export default TextComponent;
