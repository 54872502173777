/**
 * Custom base theme for material-ui components.
 */
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const customizedThemes = themes => ({
  // zIndex was set by default by MaterialUI to 1000 to 1500.
  // This breaks some third party components
  // that use zIndex 1000 inside a modal, which had zIndex of 1300.
  zIndex: {
    mobileStepper: 100,
    speedDial: 105,
    appBar: 110,
    drawer: 120,
    modal: 130,
    snackbar: 140,
    tooltip: 150,
  },
  palette: themes.palette,
  typography: {
    fontFamily: themes.typography.fontFamily,
    [breakpoints.down('sm')]: {
      fontSize: themes.typography.md.fontSize,
    },
    body1: {
      [breakpoints.down('sm')]: {
        fontSize: themes.typography.body1.md.fontSize,
      },
    },
    title: themes.title,
    caption: {
      [breakpoints.down('sm')]: {
        fontSize: themes.typography.caption.md.fontSize,
      },
    },
  },
  overrides: {
    MuiButton: {
      label: themes.overrides.MuiButton.label,
      root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        height: themes.overrides.MuiButton.root.height,
        width: themes.overrides.MuiButton.root.width,
        fontSize: themes.overrides.MuiButton.root.fontSize,
        borderRadius: themes.overrides.MuiButton.root.borderRadius,
        [breakpoints.down('sm')]: {
          fontSize: themes.overrides.MuiButton.root.md.fontSize,
          width: themes.overrides.MuiButton.root.md.width,
          height: themes.overrides.MuiButton.root.md.height,
        },
        color: themes.overrides.MuiButton.root.color,
        fontFamily: themes.overrides.MuiButton.root.fontFamily,
        '&.Mui-disabled': themes.overrides.MuiButton.root.disabled,
        '&:hover': {
          '&.Mui-disabled': themes.overrides.MuiButton.root.disabled,
        },
      },
      contained: {
        boxShadow: themes.overrides.MuiButton.contained.boxShadow,
      },
      textPrimary: themes.overrides.MuiButton.textPrimary,
    },
    MuiCardHeader: {
      root: {
        ...themes.overrides.MuiCardHeader.root,
        [breakpoints.down('md')]: themes.overrides.MuiCardHeader.md,
      },
    },
    MuiDialogContent: themes.overrides.MuiDialogContent,
    MuiTabs: themes.overrides.MuiTabs,
    MuiTab: themes.overrides.MuiTab,
    MuiTabScrollButton: themes.overrides.MuiTabScrollButton,
    MuiPaper: themes.overrides.MuiPaper,
    MuiMenu: themes.overrides.MuiMenu,
    MuiNotchedOutline: themes.overrides.MuiNotchedOutline,
    MuiDialog: themes.overrides.MuiDialog,
    MuiList: themes.overrides.MuiList,
    MuiListItem: themes.overrides.MuiListItem,
    MuiListItemIcon: themes.overrides.MuiListItemIcon,
    MuiListItemText: themes.overrides.MuiListItemText,
    MuiAppBar: themes.overrides.MuiAppBar,
    MuiTypography: themes.overrides.MuiTypography,
    MuiGrid: themes.overrides.MuiGrid,
    MuiIcon: {
      root: {
        [breakpoints.down('sm')]: {
          fontSize: themes.overrides.MuiIcon.root.md.fontSize,
        },
        color: themes.overrides.MuiIcon.root.color,
      },
    },
    MuiIconButton: {
      ...themes.overrides.MuiIconButton,
      root: {
        '&.Mui-disabled': themes.overrides.MuiIconButton.root['Mui-disabled'],
        '&:hover, &.Mui-focusVisible': themes.overrides.MuiIconButton.root['&:hover, &.Mui-focusVisible'],
      },
    },
    MuiRadio: themes.overrides.MuiRadio,
    MuiSvgIcon: {
      root: {
        [breakpoints.down('sm')]: {
          fontSize: themes.overrides.MuiSvgIcon.root.md.fontSize,
        },
      },
    },
    MuiCheckbox: themes.overrides.MuiCheckbox,
    MuiInputLabel: themes.overrides.MuiInputLabel,
    MuiInputBase: { ...themes.overrides.MuiInputBase, root: { '&.Mui-disabled': themes.overrides.MuiInputBase.root['Mui-disabled'] } },
    MuiOutlinedInput: themes.overrides.MuiOutlinedInput,
    MuiTableCell: themes.overrides.MuiTableCell,
    MuiBadge: themes.overrides.MuiBadge,
    MuiFormHelperText: themes.overrides.MuiFormHelperText,
    MuiSelect: themes.overrides.MuiSelect,
    MuiFormControlLabel: {
      ...themes.overrides.MuiFormControlLabel,
      label: { '&.Mui-disabled': themes.overrides.MuiFormControlLabel.label['Mui-disabled'] },
    },
    PrivateTabScrollButton: themes.overrides.PrivateTabScrollButton,
    PrivateTabIndicator: themes.overrides.PrivateTabIndicator,
    MuiStepLabel: {
      label: {
        '&.MuiStepLabel-completed': themes.overrides.MuiStepLabel.completed,
        '&.MuiStepLabel-active': themes.overrides.MuiStepLabel.active,
        color: themes.overrides.MuiStepLabel.color,
        fontSize: themes.overrides.MuiStepLabel.fontSize,
      },
    },
    MuiStepConnector: {
      completed: {
        backgroundColor: themes.overrides.MuiStepConnector.completed.backgroundColor,
        height: themes.overrides.MuiStepConnector.completed.height,
      },
      active: {
        backgroundColor: themes.overrides.MuiStepConnector.active.backgroundColor,
        eight: themes.overrides.MuiStepConnector.active.height,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: themes.overrides.MuiDivider.root.backgroundColor,
      },
    },
  },
});

export default customizedThemes;
