import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, LinearProgress, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import get from 'lodash/get';

import { shouldUseApplePay, shouldUseGooglePay } from '../../services/functions/PaymentFunctions';
import * as Config from '../../../dist/config.json';
import { getCurrentUserPaymentOptions, getCurrentOrder, getCompany, getLocations } from '../../selectors';
import PaymentMethodComponent from './subComponents/PaymentMethodComponent';
import Button from '../core/components/Button';
import { FeatureFlags } from '../../services/functions/FeatureFlag';
import Routes from '../../services/routes/Routes.json';
import MasterIcon from '../../assets/images/master.svg';
import VisaIcon from '../../assets/images/visa.svg';
import AmexIcon from '../../assets/images/american-express-1.png';
import DeleteDialog from '..//paymentPage/components/DeleteDialog';

import '../../css/paymentMethodPage/PaymentMethodPage.scss';

class PaymentMethodPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentOptionToDelete: null,
      deleteDialogOpen: false,
    };
  }
  async componentDidMount() {
    const {
      actions, user, history, company,
    } = this.props;

    if (user && user.guestToken) {
      history.push(Routes.path.menuPage);
    }
    if (user) {
      try {
        await actions.getAllResources(user.token, ['users', user.id, 'payment_options']);
      } catch (error) {
        console.log('API call error', error);
      }
    } else {
      history.push(Routes.path.menuPage);
    }

    let googlePayCompany;
    if (!company) {
      const response = await actions.getAllResources(null, ['companies']);
      googlePayCompany = response.response;
    } else {
      googlePayCompany = company;
    }
    if (shouldUseGooglePay(googlePayCompany, false)) {
      try {
        await this.initializeSquare();
      } catch (e) {
        console.error('Initializing Card failed', e);
      }
    }
  }

  onClickDelete = (payOpt) => {
    this.setState({ paymentOptionToDelete: payOpt, deleteDialogOpen: true });
  };

  initializeDigitalPay = async (squarePayments) => {
    const { company, currentOrder } = this.props;
    const countryCode = get(company, 'countryCode') || 'US';
    const currencyCode = get(currentOrder, 'location.currencyCode') || 'USD';
    const orderTotal = 0;
    const paymentRequest = squarePayments.paymentRequest({
      countryCode,
      currencyCode,
      total: {
        amount: orderTotal.toString(),
        label: 'Total',
      },
    });

    const digitalPay = await squarePayments.googlePay(paymentRequest);
    await digitalPay.attach('#google-pay-button-payment-method', { buttonSizeMode: 'fill', buttonType: 'long' });
  }

  initializeSquare = async () => {
    const { currentOrder, locations } = this.props;
    const appId = Config.general.squareAppId;
    const locationId = currentOrder.location ? get(currentOrder, 'location.squareId') : locations[0].squareId;
    const payments = window.Square.payments(appId, locationId);
    await this.initializeDigitalPay(payments);
  }

  addNewCard = () => {
    const { history } = this.props;
    history.push(Routes.path.addNewCardPage);
  }

  shouldRenderPaymentOptions = () => {
    const { company, paymentOptions } = this.props;
    return shouldUseApplePay(company, false) || shouldUseGooglePay(company, false) || paymentOptions.length > 0;
  }

  deletePaymentOption = () => {
    const { user, actions } = this.props;
    const { paymentOptionToDelete } = this.state;
    if (user) {
      actions.deleteResource(user, ['users', user.id, 'payment_options', paymentOptionToDelete.id]);
      this.setState({ paymentOptionToDelete: null, deleteDialogOpen: false });
    }
  };

  renderApplePay = () => {
    const appleButtonStyle = {
      display: 'inline-block',
      WebkitAppearance: '-apple-pay-button',
      ApplePayButtonType: 'plain',
      ApplePayButtonStyle: 'black',
      width: '100%',
      margin: '0 0 0 0',
      height: '100%',
    };
    return (
      <Grid
        item
        xs={12}
        sm={6}
        className="paymentMethodPage-gridItem"
      >
        <button
          style={appleButtonStyle}
          id="apple-pay-button"
        />
      </Grid>
    );
  }

  renderGooglePay = () => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        className="paymentMethodPage-gridItem"
      >
        <div
          id="google-pay-button-payment-method"
          role="button"
          tabIndex={0}
          aria-label="google pay button"
          className="paymentMethodPage-googlePayContainer"
        />
      </Grid>
    );
  }

  renderNoPaymentOptions = () => {
    const { onPressAddCard, translation } = this.props;
    return (
      <div className="paymentMethodPage-noPaymentOptionsCardContainer">
        <Card className="paymentMethodPage-noPaymentOptionsCard">
          <CardContent className="paymentMethodPage-noPaymentOptionsCardContent">
            <Typography className="paymentMethodPage-noPaymentOptionsText">{translation('PaymentMethodPage.noPaymentOptions')}</Typography>
            <div className="paymentMethodPage-cardMediaContainer">
              <CardMedia
                className="paymentMethodPage-cardMedia"
                component="img"
                image={VisaIcon}
              />
              <CardMedia
                className="paymentMethodPage-cardMedia"
                component="img"
                image={MasterIcon}
              />
              <CardMedia
                className="paymentMethodPage-cardMedia"
                component="img"
                image={AmexIcon}
              />
            </div>
            <div className="paymentMethodPage-cardButton">
              <Button
                type="primary"
                text={translation('PaymentMethodPage.addNewCard')}
                onClick={onPressAddCard}
                classes="paymentMethodPage-addNewCardButton"
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  render() {
    const {
      paymentOptions, loading, translation, user, actions, company, onPressAddCard,
    } = this.props;
    const { deleteDialogOpen, paymentOptionToDelete } = this.state;
    return FeatureFlags.PaymentMethodPage.show && (
      <div>
        {
          loading !== 0
            ? <LinearProgress />
            : (
              <div className="paymentMethodPage-main">
                <div className="paymentMethodPage-paymentMethodsContainer">
                  {
                    this.shouldRenderPaymentOptions()
                      ? (
                        <div>
                          <Grid container spacing={3}>
                            {
                              shouldUseApplePay(company, false)
                              && this.renderApplePay()
                            }
                            {
                              shouldUseGooglePay(company, false)
                              && this.renderGooglePay()
                            }
                            {
                              paymentOptions && paymentOptions.map(paymentOption =>
                              (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className="paymentMethodPage-gridItem"
                                  key={paymentOption.id}
                                >
                                  <PaymentMethodComponent
                                    paymentOption={paymentOption}
                                    user={user}
                                    translation={translation}
                                    actions={actions}
                                    onClickDelete={this.onClickDelete}
                                  />
                                </Grid>
                              ))
                            }
                          </Grid>
                          <div className="paymentMethodPage-buttonContainer">
                            <Button
                              type="primary"
                              text={translation('PaymentMethodPage.addNewCard')}
                              onClick={onPressAddCard}
                              classes="paymentMethodPage-addNewCardButton"
                            />
                          </div>
                        </div>
                      ) : this.renderNoPaymentOptions()
                  }
                </div>
                {
                  deleteDialogOpen
                  && (
                    <DeleteDialog
                      open={deleteDialogOpen}
                      translation={translation}
                      cardType={paymentOptionToDelete && paymentOptionToDelete.cardType}
                      cardName={paymentOptionToDelete && paymentOptionToDelete.cardNumber}
                      handleClick={this.deletePaymentOption}
                      handleCloseDeleteDialog={() => this.setState({ deleteDialogOpen: false })}
                    />
                  )
                }
              </div>
            )
        }
      </div>
    );
  }
}

PaymentMethodPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  paymentOptions: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  company: PropTypes.objectOf(PropTypes.any),
  onPressAddCard: PropTypes.func.isRequired,
};

PaymentMethodPage.defaultProps = {
  user: null,
  paymentOptions: [],
  company: null,
};

const mapStateToProps = state => ({
  paymentOptions: getCurrentUserPaymentOptions(state),
  currentOrder: getCurrentOrder(state),
  company: getCompany(state),
  locations: getLocations(state),
});

export default connect(mapStateToProps, null)(withRouter(PaymentMethodPage));
