import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Icon, IconButton } from '@material-ui/core';

import { TimeLimitText } from '../../menuPage/components/TimeLimitComponent';
import { groupDayTimeLimits, groupDateTimeLimits, generateKey } from '../../../services/functions/Functions';

import '../../../css/product/subComponents/TimeLimitErrorComponent.scss';

const TimeLimitErrorComponent = ({ timeLimits, translation, onClickClose }) => {
  if (!timeLimits[0]) return null;

  const groupedLimits = timeLimits[0].endDate != null
    ? groupDateTimeLimits(timeLimits)
    : groupDayTimeLimits(timeLimits);
  return (
    <div className="timeLimitError-container" data-testid="timeLimitError">
      <div className="timeLimitError-iconSection">
        <Icon className="timeLimitError-errorIcon">error</Icon>
      </div>
      <div className="timeLimitError-contentSection">
        <Typography className="timeLimitError-titleText">
          {translation('ProductFlowDialog.timeLimits.timeLimitSubtitle')}
        </Typography>
        <div className="timeLimitError-timeLimitView">
          {
            [...groupedLimits].map((timeLimit, i) => <TimeLimitText textClass="timeLimitError-text" timeLimit={timeLimit} useIcons={false} key={generateKey(i)} />)
          }
        </div>
        <Typography className="timeLimitError-text">
          {translation('ProductFlowDialog.timeLimits.timeLimitSubtitleBefore')}
        </Typography>
      </div>
      <div className="timeLimitError-closeSection">
        <IconButton onClick={onClickClose} data-testid="timeLimitError-close" className="timeLimitError-text" >
          <Icon>close</Icon>
        </IconButton>
      </div>
    </div>
  );
};

TimeLimitErrorComponent.propTypes = {
  translation: PropTypes.func.isRequired,
  timeLimits: PropTypes.arrayOf(PropTypes.any),
  onClickClose: PropTypes.func.isRequired,
};

TimeLimitErrorComponent.defaultProps = {
  timeLimits: null,
};

export default TimeLimitErrorComponent;
