import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Icon, IconButton } from '@material-ui/core';
import useCloudEventsBaseParams from '../../../events/hooks/useCloudEventsBaseParams';
import PageLoadEvent from '../../../events/Bounce/PageLoadEvent';
import PageExitEvent from '../../../events/Bounce/PageExitEvent';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';

import * as Functions from '../../../services/functions/Functions';

import '../../../css/core/components/DialogView.scss';
import { getUserToken } from '../../../selectors';
import { DIALOG_NAMES } from '../../../services/api/CloudEvents/constants';

const DialogViewTitle = ({
  handleClose, titleHasCloseBtn, dialogTitleText, dialogTitleSubheader2, dialogTitleStyle,
  dialogTitleStyleVariant,
  dialogTitleSubheader2Style, dialogCloseIconColor, titleAlignClose, hasHeaderImage,
}) => {
  const dialogTitleRootStyle = dialogTitleStyle || 'dialogView-dialogTitleRootBase';
  if (hasHeaderImage) {
    return (
      <IconButton
        onClick={handleClose}
        className="dialogView-floatingCloseIconButton"
      >
        <Icon>
          close
        </Icon>
      </IconButton>
    );
  }

  return (
    <DialogTitle className={dialogTitleRootStyle}>
      {
        titleHasCloseBtn
        && (
          <div
            className="dialogView-dialogHeaderContainer"
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <div className="dialogView-dialogHeaderTitleContainer">
              {
                titleAlignClose
                && (
                  <Typography
                    gutterBottom
                    className={dialogTitleStyle}
                    variant={dialogTitleStyleVariant}
                  >
                    {dialogTitleText}
                  </Typography>
                )
              }
            </div>
            <IconButton
              onClick={handleClose}
            >
              <Icon
                style={{ color: dialogCloseIconColor }}
              >
                close
              </Icon>
            </IconButton>
          </div>
        )
      }
      <div>
        {
          !titleAlignClose
          && (
            <Typography
              gutterBottom
              className={dialogTitleStyle}
              variant={dialogTitleStyleVariant}
            >
              {dialogTitleText}
            </Typography>
          )
        }
        {
          dialogTitleSubheader2
            ? (
              <Typography gutterBottom className={dialogTitleSubheader2Style}>
                {dialogTitleSubheader2}
              </Typography>
            )
            : null
        }
      </div>
    </DialogTitle>
  );
};

const DialogView = ({
  dialogName, handleClose, hasDialogActions, titleHasCloseBtn, renderActionBtn, actionBtnStyle, hasDialogContent,
  hasDialogContent2, hasDialogErrorContent, renderDialogContent, renderDialogContent2,
  renderDialogErrorContent, dialogBodyContainerStyle, dialogContentStyle, dialogErrorContentStyle,
  disableBackdropClick, disableEscapeKeyDown, dialogTitleSubheader2, dialogTitleStyle, dialogTitleStyleVariant,
  dialogPaperStyle, loading, width, open, dialogTitleText, dialogTitleSubheader2Style, dialogCloseIconColor,
  titleAlignClose, dialogHeaderStyle, hasHeaderImage,
}) => {
  /*
  * Notes about the props
  *
  * dialogPaperStyle = style for the Paper which determines the dimensions of the DialogView
  */
  const isTablet = Functions.isTabletMode(width);

  const cloudEventsBaseParams = useCloudEventsBaseParams();
  const userToken = useSelector(getUserToken);

  const prevOpen = useRef(open);
  useEffect(() => {
    if (!dialogName) return;
    const eventCommonParams = {
      ...cloudEventsBaseParams,
      componentNameAsPage: dialogName,
    };
    // The OrderFlowDialog encompasses a lot of different sub dialogs, we want to know the real bounce point inside that
    if (dialogName === DIALOG_NAMES.ORDER_FLOW) return;

    const isConditionallyRenderedDialog = [
      DIALOG_NAMES.DELIVERY_ADDRESS,
      DIALOG_NAMES.ORDER_LOCATION,
      DIALOG_NAMES.PRODUCT_DIALOG,
    ].includes(dialogName);

    // These dialogs are conditionally rendered, rather than always existing but open depending on the open prop.
    // So they need to fire PageExit on unmount.
    if (isConditionallyRenderedDialog) {
      if (open) {
        const cloudEvent = new PageLoadEvent(eventCommonParams);
        CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
      }
      // eslint-disable-next-line consistent-return
      return () => {
        const cloudEvent = new PageExitEvent(eventCommonParams);
        CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
      };
    }

    // This handles the rest of the dialogs that always exist, like SignUp, Login, Verify, Reset, GuestSignIn, Rewards, Product.
    if (!prevOpen.current && open) {
      const cloudEvent = new PageLoadEvent(eventCommonParams);
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    } else if (prevOpen.current && !open) {
      const cloudEvent = new PageExitEvent(eventCommonParams);
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }

    prevOpen.current = open;
  }, [open, dialogName]);

  const onDialogClose = (event, reason) => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      // Do nothing if disableBackdropClick is enabled.
      return;
    }

    handleClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={onDialogClose}
      scroll="paper"
      maxWidth={false}
      className="dialogView-dialog"
      aria-labelledby="form-dialog-title"
      // TO-DO: Remove inline styles
      PaperProps={{
        style: dialogPaperStyle || (
          isTablet ? {
            width: '99vw',
            maxWidth: '720px',
            height: 'auto',
            overflowX: 'hidden',
          } : {
              width: '620px',
          }
          ),
      }}
    >
      <DialogViewTitle
        handleClose={onDialogClose}
        titleHasCloseBtn={titleHasCloseBtn}
        dialogTitleText={dialogTitleText}
        dialogTitleSubheader2={dialogTitleSubheader2}
        dialogTitleStyle={dialogTitleStyle}
        dialogTitleStyleVariant={dialogTitleStyleVariant}
        dialogTitleSubheader2Style={dialogTitleSubheader2Style}
        dialogCloseIconColor={dialogCloseIconColor}
        titleAlignClose={titleAlignClose}
        dialogHeaderStyle={dialogHeaderStyle}
        hasHeaderImage={hasHeaderImage}
      />
      <div className={classNames('dialogView-dialog', dialogBodyContainerStyle)}>
        {
          hasDialogErrorContent
          && (
            <DialogContent className={dialogErrorContentStyle}>
              {
                loading
                  ? (
                    <CircularProgress
                      className="dialogView-circularProgress"
                    />
                  )
                  : renderDialogErrorContent()
              }
            </DialogContent>
          )
        }
        {
          hasDialogContent
          && (
            <DialogContent className={dialogContentStyle}>
              {
                !loading && renderDialogContent()
              }
            </DialogContent>
          )
        }
        {
          hasDialogActions
          && (
            <DialogActions className={actionBtnStyle}>
              {
                loading
                  ? (
                    <CircularProgress
                      className="dialogView-circularProgress"
                    />
                  )
                  : renderActionBtn()
              }
            </DialogActions>
          )
        }
        {
          hasDialogContent2
          && (
            <DialogContent className={dialogContentStyle}>
              {
                loading
                  ? (
                    <CircularProgress
                      className="dialogView-circularProgress"
                    />
                  )
                  : renderDialogContent2()
              }
            </DialogContent>
          )
        }
      </div>
    </Dialog>
  );
};

DialogViewTitle.propTypes = {
  handleClose: PropTypes.func,
  hasHeaderImage: PropTypes.bool,
  dialogTitleText: PropTypes.string,
  dialogTitleSubheader2: PropTypes.string,
  dialogTitleStyle: PropTypes.string,
  dialogTitleStyleVariant: PropTypes.string,
  dialogTitleSubheader2Style: PropTypes.string,
  dialogCloseIconColor: PropTypes.string,
  titleAlignClose: PropTypes.bool,
  titleHasCloseBtn: PropTypes.bool,
};

DialogViewTitle.defaultProps = {
  handleClose: null,
  dialogTitleText: '',
  dialogTitleSubheader2: '',
  dialogTitleStyle: '',
  dialogTitleStyleVariant: 'inherit',
  dialogTitleSubheader2Style: '',
  dialogCloseIconColor: '',
  titleAlignClose: false,
  titleHasCloseBtn: false,
  hasHeaderImage: false,
};


DialogView.propTypes = {
  dialogName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool.isRequired,
  titleHasCloseBtn: PropTypes.bool.isRequired,
  handleClose: (props, propName) => {
    if ((!props.disableEscapeKeyDown || !props.disableEscapeKeyDown || props.titleHasCloseBtn)
     && (props[propName] === undefined || typeof (props[propName]) !== 'function')) {
      return new Error('Please provide a handleClose function!');
    }
    return null;
  },
  loading: PropTypes.bool,
  hasDialogActions: PropTypes.bool.isRequired,
  renderActionBtn: (props, propName) => {
    if ((props.hasDialogActions && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderActionBtn function!');
    }
    return null;
  },
  actionBtnStyle: PropTypes.string,
  hasDialogContent: PropTypes.bool.isRequired,
  renderDialogContent: (props, propName) => {
    if ((props.hasDialogContent && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderDialogContent function!');
    }
    return null;
  },
  hasDialogContent2: PropTypes.bool.isRequired,
  renderDialogContent2: (props, propName) => {
    if ((props.hasDialogContent2 && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderDialogContent2 function!');
    }
    return null;
  },
  hasDialogErrorContent: PropTypes.bool,
  renderDialogErrorContent: (props, propName) => {
    if ((props.hasDialogErrorContent && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderDialogErrorContent function!');
    } return null;
  },
  hasHeaderImage: PropTypes.bool,
  dialogBodyContainerStyle: PropTypes.string,
  dialogContentStyle: PropTypes.string,
  dialogErrorContentStyle: PropTypes.string,
  dialogTitleText: PropTypes.string,
  dialogTitleSubheader2: PropTypes.string,
  dialogTitleStyle: PropTypes.string,
  dialogTitleStyleVariant: PropTypes.string,
  dialogTitleSubheader2Style: PropTypes.string,
  dialogCloseIconColor: PropTypes.string,
  titleAlignClose: PropTypes.bool,
  dialogHeaderStyle: PropTypes.string,
  dialogPaperStyle: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.string,
};

DialogView.defaultProps = {
  dialogName: '',
  disableBackdropClick: false,
  loading: null,
  handleClose: null,
  renderActionBtn: null,
  actionBtnStyle: '',
  renderDialogContent: null,
  renderDialogContent2: null,
  renderDialogErrorContent: null,
  dialogBodyContainerStyle: '',
  dialogContentStyle: '',
  dialogErrorContentStyle: '',
  dialogTitleText: '',
  dialogTitleSubheader2: '',
  dialogTitleStyle: '',
  dialogTitleStyleVariant: 'inherit',
  dialogTitleSubheader2Style: '',
  dialogCloseIconColor: '',
  titleAlignClose: false,
  dialogHeaderStyle: '',
  dialogPaperStyle: null,
  hasHeaderImage: false,
  width: null,
  hasDialogErrorContent: false,
};

export default (withWidth()(DialogView));
