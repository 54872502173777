import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const initialState = Immutable({
  isFeatureFlagsAvailable: false,
});

export default function FeatureFlagReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_IS_FETCHED_FEATURE_FLAGS_AVAILABLE:
      return { ...state, isFeatureFlagsAvailable: action.isAvailable };
    default:
      return state;
  }
}
