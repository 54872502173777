export const KEYS = {
  MENU: 'menu',
  LOCATIONS: 'locations',
  ORDERS: 'orders',
  REWARDS: 'rewards',
  SUBSCRIPTIONS: 'subscriptions',
  ACCOUNT: 'account',
  SETTINGS: 'settings',
};

export default {
  headerLinks: [
    {
      id: 1,
      title: 'CoreView.header.option1.title',
      src: '',
      key: KEYS.MENU,
      alt: 'CoreView.header.option1.alt',
      icon: 'import_contacts',
    },
    {
      id: 2,
      title: 'CoreView.header.option2.title',
      src: '',
      key: KEYS.LOCATIONS,
      alt: 'CoreView.header.option2.alt',
      icon: 'location_on',
    }
  ],
  headerLinksExtra: [
    {
      id: 3,
      title: 'CoreView.header.option3.title',
      src: '',
      key: KEYS.ORDERS,
      alt: 'CoreView.header.option2.alt',
      icon: 'restore',
    },
    {
      id: 4,
      title: 'CoreView.header.option4.title',
      src: '',
      key: KEYS.REWARDS,
      alt: 'CoreView.header.option2.alt',
      icon: 'star',
    },
    {
      id: 5,
      title: 'CoreView.header.option5.title',
      src: '',
      key: KEYS.SUBSCRIPTIONS,
      alt: 'CoreView.header.option2.alt',
      icon: 'subscriptions',
    },
    {
      id: 6,
      title: 'CoreView.header.option6.title',
      src: '',
      key: KEYS.ACCOUNT,
      alt: 'CoreView.header.option2.alt',
      icon: 'settings',
    },
    {
      id: 7,
      title: 'CoreView.header.option7.title',
      src: '',
      key: KEYS.SETTINGS,
      alt: 'CoreView.header.option2.alt',
      icon: 'settings',
    },
  ],
  submMenuLinks: [
    {
      id: 3,
      title: 'CoreView.headerSubMenu.option1.title',
      src: '',
      key: 'orders',
      alt: 'CoreView.headerSubMenu.option1.alt',
      icon: 'restore',
    },
    {
      id: 4,
      title: 'CoreView.headerSubMenu.option2.title',
      src: '',
      key: 'rewards',
      alt: 'CoreView.headerSubMenu.option2.alt',
      icon: 'star',
    },
    {
      id: 5,
      title: 'CoreView.headerSubMenu.option3.title',
      src: '',
      key: 'payment_method',
      alt: 'CoreView.headerSubMenu.option3.alt',
      icon: 'payment',
    },
    {
      id: 6,
      title: 'CoreView.headerSubMenu.option4.title',
      src: '',
      key: 'settings',
      alt: 'CoreView.headerSubMenu.option4.alt',
      icon: 'settings',
    },
  ],
};
