import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import get from 'lodash/get';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import { FeatureFlags } from '../../../../src/services/functions/FeatureFlag';
import { getAutocompleteInfoFromPlace } from '../../../../src/services/functions/Functions';
import { getDialogLoading, getCurrentOrder, getLoading, getUser } from '../../../selectors';
import AutoCompleteAddress from './AutoCompleteAddress';

import '../../../css/checkout/subComponents/DeliveryAddressDialog.scss';
import { DIALOG_NAMES } from '../../../services/api/CloudEvents/constants';

const dialogContentFields = [
  {
    key: 'nickname',
    title: 'DeliveryAddressDialog.dialogContent.nickname',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'streetAddress',
    title: 'DeliveryAddressDialog.dialogContent.streetAddress',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'city',
    title: 'DeliveryAddressDialog.dialogContent.city',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'unit',
    title: 'DeliveryAddressDialog.dialogContent.unit',
    fieldWidth: '100%',
    details: [
      'DeliveryAddressDialog.dialogContent.addressDetails.apt',
      'DeliveryAddressDialog.dialogContent.addressDetails.suite',
      'DeliveryAddressDialog.dialogContent.addressDetails.floor',
      'DeliveryAddressDialog.dialogContent.addressDetails.room',
    ],
    isRequired: false,
  },
  {
    key: 'postalCode',
    title: 'DeliveryAddressDialog.dialogContent.postalCode',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'buzzNumber',
    title: 'DeliveryAddressDialog.dialogContent.buzzNumber',
    fieldWidth: '100%',
    isRequired: false,
  },
];

const updateStateReducer = (prevState, nextState) => {
  const newState = { ...prevState, ...nextState };
  // TO-DO: Run some validations here ...
  return newState;
};

const initialState = {
  nickname: '',
  streetAddress: '',
  unit: '',
  city: '',
  postalCode: '',
  buzzNumber: '',
  browserCoordinates: null,
  nicknameLeftBlank: false,
  streetAddressLeftBlank: false,
  cityLeftBlank: false,
  postalCodeLeftBlank: false,
};

const DeliveryAddressDialog = (props) => {
  const {
    actions,
    currentOrderId,
    handleClose,
    updateSelectedAddressId,
    closeAfterChange,
    translation,
    navigateBack,
    width,
    open,
    allowDialogClose,
  } = props;

  const user = get(useSelector(getUser), 'user');
  const dialogLoading = useSelector(getDialogLoading);
  const loading = useSelector(getLoading);
  const currentOrder = useSelector(getCurrentOrder);
  const [state, updateState] = useReducer(updateStateReducer, initialState);

  const handleChange = (fieldKey, event) => {
    const { value } = event.target;
    updateState({
      [fieldKey]: value,
    });
  };

  // Check for empty field or field with only blank spaces
  const isEmptyTextField = (text) => {
    const filteredText = text !== undefined ? text.replace(/\s/g, '') : '';
    return filteredText === '';
  };

  const handleBlur = (field, inputText) => {
    if (isEmptyTextField(inputText)) updateState({ [`${field}LeftBlank`]: true });
  };

  const getBrowserLocation = () => {
    // eslint-disable-next-line no-undef
    const navGeoLocation = navigator.geolocation;
    if (navGeoLocation) {
      navGeoLocation.getCurrentPosition(
        (position) => {
          updateState({
            browserCoordinates: {
              lat: position.coords.latitude,
              long: position.coords.longitude,
            },
          });
        },
        () => {
          console.log('Could not get browser geolocation, using default coordinates');
        },
      );
    }
  };

  useEffect(() => { getBrowserLocation(); }, []);

  const getTextFieldInnerInputProps = () => {
    const innerInputClasses = {
      root: '.deliveryAddressDialog-textFieldInnerInputRoot',
    };
    return {
      classes: innerInputClasses,
    };
  };

  const renderHelperText = (isRequired) => {
    const { hideTextFieldHelperTextOnMobile } = FeatureFlags.CheckoutDrawer.DeliveryAddressDialog;
    if (isRequired) {
      if (hideTextFieldHelperTextOnMobile && isWidthDown('sm', width)) return '';
      return translation('DeliveryAddressDialog.dialogContent.required');
    }
    // Render white text so all fields appear with the same margins
    return ' ';
  };

  const setAddress = (place) => {
    const { streetAddress, city, postalCode } = getAutocompleteInfoFromPlace(place);
    updateState({ streetAddress, city, postalCode });
  };

  const renderTextField = (prop, isSelect, useFieldTitle) => {
    let fieldTitle = translation(prop.title);

    if (fieldTitle === translation('DeliveryAddressDialog.dialogContent.postalCode')
      && FeatureFlags.CheckoutDrawer.DeliveryAddressDialog.provinceOrStateDropdown.state) {
      fieldTitle = translation('DeliveryAddressDialog.dialogContent.zipCode');
    }

    if (prop.details) {
      const details = prop.details.map(detail => translation(detail));
      fieldTitle = Array.join(details, '/');
    }

    if (prop.optionalText) {
      fieldTitle = `${fieldTitle} (${translation(prop.optionalText)})`;
    }

    if (prop.key === 'streetAddress') {
      return (
        <div className="deliveryAddressDialog-textFieldStyle" style={{ width: prop.fieldWidth }}>
          <AutoCompleteAddress
            id={prop.key}
            key={prop.key}
            select={isSelect}
            addressValue={state.streetAddress}
            label={useFieldTitle ? fieldTitle : translation(prop.title)}
            placeholder={useFieldTitle ? fieldTitle : translation(prop.title)}
            onPlaceSelected={place => setAddress(place)}
            className="deliveryAddressDialog-textFieldStyle"
            variant="outlined"
            FormHelperTextProps={{ className: 'deliveryAddressDialog-textFieldHelperText' }}
            helperText={renderHelperText(prop.isRequired)}
            InputProps={getTextFieldInnerInputProps()}
            emptyAddress={state.streetAddressLeftBlank}
            onAddressBlur={handleBlur}
            checkText={isEmptyTextField}
          />
        </div>
      );
    }

    return (
      <TextField
        id={prop.key}
        key={prop.key}
        select={isSelect}
        value={state[`${prop.key}`]}
        label={useFieldTitle ? fieldTitle : translation(prop.title)}
        placeholder={useFieldTitle ? fieldTitle : translation(prop.title)}
        onChange={event => handleChange(prop.key, event)}
        className="deliveryAddressDialog-textFieldStyle"
        style={{ width: prop.fieldWidth }}
        variant="outlined"
        helperText={renderHelperText(prop.isRequired, width)}
        InputProps={getTextFieldInnerInputProps()}
        FormHelperTextProps={{ className: 'deliveryAddressDialog-textFieldHelperText' }}
        error={prop.isRequired && isEmptyTextField(state[`${prop.key}`]) && state[`${prop.key}LeftBlank`]}
        onBlur={() => { handleBlur(prop.key, state[`${prop.key}`]); }}
      />
    );
  };

  const getDialogContent = () => {
    if (loading !== 0) return <CircularProgress />;
    return (
      <div className="deliveryAddressDialog-dialogContentContainer">
        <div className="deliveryAddressDialog-dialogContentTextContainer">
          <Typography className="deliveryAddressDialog-dialogContentText">
            {translation('DeliveryAddressDialog.dialogContent.text')}
          </Typography>
        </div>
        {
          dialogContentFields.map(field => (
              renderTextField(field, false, true)
            ))
        }
      </div>
    );
  };

  const disableButton = () => {
    const {
      nickname,
      streetAddress,
      city,
      postalCode,
    } = state;
    // unit can be null if it's a house and buzz number is optional
    return (dialogLoading !== 0) || !nickname || !streetAddress || !city || !postalCode;
  };

  const handleClickAdd = async () => {
    const {
      nickname,
      streetAddress,
      unit,
      city,
      postalCode,
      buzzNumber,
    } = state;
    const addressObj = {
      nickname,
      street_address: streetAddress,
      unit_number: unit,
      city,
      postal_code: postalCode,
      buzzer_number: buzzNumber,
    };
    try {
      let addAddressResponse;
      if (user) {
        addAddressResponse = await actions.addAddress(user.token, addressObj, 'users', user.id, 'addresses');
        if (!addAddressResponse || addAddressResponse.error) {
          return;
        }
        await actions.getAllResources(
          user.token,
          ['users', user.id, 'addresses'],
        );
      } else {
        addAddressResponse = addressObj;
      }
      const updatedOrder = {
        ...currentOrder,
        address: addAddressResponse,
      };
      let updateOrderResponse;
      if (!user) {
        const addressChecked = await actions.checkAddress(user, addAddressResponse, ['locations', 'checkAddress']);
        if (addressChecked.location) updatedOrder.location = addressChecked.location;
        if (addressChecked.error) throw addressChecked.error;
      }
      if (!currentOrderId) {
        updateOrderResponse = await actions.updateOrder(null, updatedOrder, currentOrderId);
      } else {
        updateOrderResponse = await actions.updateOrder(user, updatedOrder, currentOrderId);
      }
      if (user && currentOrderId && (!updateOrderResponse || updateOrderResponse.error)) return;
      updateSelectedAddressId(user ? addAddressResponse.id : null);
      if (closeAfterChange) handleClose();
    } catch (error) {
      console.log('API call error', error);
    }
  };

  const getActionButton = () => {
    const isDisabled = disableButton();
    const isDeliveryOnly = FeatureFlags.enableDelivery && !FeatureFlags.enableDineIn && !FeatureFlags.enablePickup;

    return (
      <div className={
        isDeliveryOnly
          ? 'deliveryAddressDialog-single-actionButtonContainer'
          : 'deliveryAddressDialog-actionButtonContainer'
        }
      >
        {
          !isDeliveryOnly
          && (
            <Button
              variant="text"
              overrideClass
              type="primary"
              className="deliveryAddressDialog-backButtonStyle"
              onClick={navigateBack}
              startIcon={<ArrowBackIcon className="deliveryAddressDialog-backArrowIcon" />}
              text={translation('BACK')}
            />
          )
        }
        <Button
          type="primary"
          onClick={handleClickAdd}
          disabled={isDisabled}
          overrideClass
          className="deliveryAddressDialog-addButtonOverride"
        >
          {
            dialogLoading !== 0
            ? <CircularProgress />
            : `${translation('DeliveryAddressDialog.addButton')}`
          }
        </Button>
      </div>
    );
  };

  return (
    <DialogView
      dialogName={DIALOG_NAMES.DELIVERY_ADDRESS}
      open={open}
      titleAlignClose={false}
      handleClose={handleClose}
      disableBackdropClick={!allowDialogClose}
      disableEscapeKeyDown={!allowDialogClose}
      dialogTitleStyle="deliveryAddressDialog-dialogTitleStyle"
      dialogTitleStyleVariant="h2"
      dialogBodyContainerStyle="deliveryAddressDialog-dialogBodyContainerStyle"
      dialogContentStyle="deliveryAddressDialog-dialogContentStyle"
      titleHasCloseBtn={allowDialogClose}
      hasDialogContent
      hasDialogContent2={false}
      hasDialogErrorContent={false}
      renderDialogContent={getDialogContent}
      hasDialogActions
      actionBtnStyle="deliveryAddressDialog-actionBtnStyle"
      renderActionBtn={getActionButton}
      dialogCloseIconColor="var(--text)"
    />
  );
};

DeliveryAddressDialog.propTypes = {
  updateSelectedAddressId: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  currentOrderId: PropTypes.number,
  closeAfterChange: PropTypes.bool,
  allowDialogClose: PropTypes.bool,
  width: PropTypes.string,
};

DeliveryAddressDialog.defaultProps = {
  closeAfterChange: true,
  currentOrderId: null,
  allowDialogClose: true,
  width: null,
};

export default (withWidth()(DeliveryAddressDialog));
