import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const initialState = Immutable({
  showPaymentDrawer: false,
  showCheckoutDrawer: false,
  showOrderFlowDialog: false,
  showProductDialog: false,
});

const acceptedActionsMap = {
  [types.HIDE_COMPONENT]: flag => ({ [flag]: false }),
  [types.SHOW_COMPONENT]: flag => ({ [flag]: true }),
  [types.TOGGLE_COMPONENT_VISIBILITY]: (flag, currentValue) => ({ [flag]: !currentValue }),
};

const acceptedActions = Object.keys(acceptedActionsMap);

export default function ComponentReducer(state = initialState, action) {
  if (!acceptedActions.includes(action.type)) return state;
  const { componentFlag } = action;
  const currentFlag = state[componentFlag];
  const updatedValues = acceptedActionsMap[action.type](componentFlag, currentFlag);
  return Immutable.merge(state, updatedValues);
}
