import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Typography, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';

import { getDialogLoading, getLoading } from '../../selectors';
import Button from '../core/components/Button';
import { invertMonthDict } from '../../services/constants/Constants';
import { DIALOG_CONSTANTS, DIALOG_EVENT_SOURCES } from '../core/DialogConstants';
import DialogView from '../core/components/DialogView';
import TextBoxComponent from '../core/components/TextBoxComponent';
import TextComponent from '../core/components/TextComponent';
import { FeatureFlags } from '../../../src/services/functions/FeatureFlag';
import { isValidEmail } from '../../services/functions/Functions';

import '../../css/authentication/SignUpDialog.scss';
import { DIALOG_NAMES } from '../../services/api/CloudEvents/constants';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  error: '',
  birthMonth: '',
  birthDay: '',
};
const signUpFields = [
  {
    autoFocus: true,
    type: 'TextBox',
    title: 'First Name',
    fieldKey: 'firstName',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Last Name',
    fieldKey: 'lastName',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'E-mail',
    fieldKey: 'email',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Password',
    fieldKey: 'password',
    fieldType: 'password',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Phone Number',
    fieldKey: 'phoneNumber',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'Text',
    title: 'We might need your phone number to confirm your order',
    fieldKey: 'phoneNumberText',
  },
];

const birthDateFields = [
  {
    autoFocus: false,
    type: 'Text',
    title: 'birthDayText',
    fieldKey: 'birthDayText',
  },
  {
    autoFocus: true,
    type: 'DayMonth',
    title: 'Birth Date',
    fieldKey: 'birthDate',
    fieldType: '',
  },
];

// TODO: Terms of use page
class SignUpDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getProps = () => {
    const { translation } = this.props;
    const formFields = (FeatureFlags.SignupDialog.useBirthDate) ? signUpFields.concat(birthDateFields) : signUpFields;
    return (
      {
        dialogTitle: {
          dialogTitleStyle: 'signUpDialog-dialogTitleStyle',
          dialogTitleStyleVariant: 'h2',
          dialogTitleText: '',
          dialogTitleSubheader: '',
          dialogTitleSubheaderImageAlt: translation('CoreView.header.logoAlt'),
          subHeaderImageContainerStyle: 'signUpDialog-subHeaderImageContainerStyle',
          subHeaderImageStyle: 'signUpDialog-subHeaderImageStyle',
        },
        dialogBodyContainerStyle: 'signUpDialog-dialogBodyContainerStyle',
        dialogContentStyle: 'signUpDialog-dialogContentStyle',
        dialogContent: (
          <form className="signUpDialog-formContainer" noValidate autoComplete="off">
            {
              formFields.map(field => (this.renderFormField[field.type](field)))
            }
          </form>
        ),
        dialogContent2: (
          <div className="signUpDialog-dialogContent2">
            <Typography>
              <a target="_blank" rel="noopener noreferrer" href="https://craverapp.com/terms-and-conditions/" className="signUpDialog-dialogContent2Link">{translation('SignUpDialog.dialogContent2.termsOfUse')}</a>
            </Typography>
          </div>
        ),
        dialogActions: {
          actionBtnStyle: 'signUpDialog-actionBtnStyle',
          actionButtons: (
            <Button
              id="createAccount"
              fullWidth
              type="primary"
              onClick={this.handleCreateAccount}
              disabled={this.disableButton()}
              text={translation('SignUpDialog.dialogActions.createAccount')}
            />
          ),
        },
      });
  };

  handleChange = (field, selectedItem) => {
    this.setState({
      [field.fieldKey]: selectedItem,
    });
  };

  handleCreateAccount = async () => {
    const { actions, translation } = this.props;
    const {
      firstName, lastName, email, password, phoneNumber, birthDay, birthMonth,
    } = this.state;

    // Only check email on front-end, API will handle phone number validation
    if (!isValidEmail(email)) {
      actions.setErrorSnackbarMessage(translation('SignUpDialog.errorTexts.invalidEmail'));
      return;
    }

    const userObj = {
      name: `${firstName} ${lastName}`,
      email,
      password,
      telephone: phoneNumber,
      birthDay,
      birthMonth,
    };

    const response = await actions.signUpUser(userObj);

    if (!response.error) {
      this.handleClose({ dialog: DIALOG_CONSTANTS.SIGNUP, eventSource: DIALOG_EVENT_SOURCES.SIGNUP_SUCCESS });
    }
  };

  handleClose = (dialogObj = { dialog: DIALOG_CONSTANTS.SIGNUP, eventSource: DIALOG_EVENT_SOURCES.CLOSE_ICON }) => {
    this.setState(initialState);
    this.props.handleClose(dialogObj);
  }

  disableButton = () => {
    const {
      firstName, lastName, email, password, phoneNumber,
    } = this.state;
    const { dialogLoading, loading } = this.props;
    const requiredFieldsEmpty = isEmpty(firstName && lastName && email && password && phoneNumber);
    return (requiredFieldsEmpty || dialogLoading !== 0 || loading !== 0);
  };

  renderDayMonthPicker = () => {
    const { translation } = this.props;
    const birthMonthText = translation('SignUpDialog.fieldTitles.birthMonth');
    const birthDayText = translation('SignUpDialog.fieldTitles.birthDay');
    return (
      <div className="signUpDialog-dayMonthPicker">
        <FormControl className="signUpDialog-picker">
          <InputLabel id="monthDropDown">{birthMonthText}</InputLabel>
          <Select
            labelId="monthDropDown"
            id="monthDropDown"
            value={this.state.birthMonth}
            onChange={event => this.handleChange({ fieldKey: 'birthMonth' }, event.target.value)}
          >
            {
              Object.keys(invertMonthDict).map(month =>
                (<MenuItem value={month}>
                  {translation(`months.${invertMonthDict[month]}`)}
                </MenuItem>))
            }
          </Select>
        </FormControl>
        <FormControl className="signUpDialog-picker">
          <InputLabel id="dayDropdown">{birthDayText}</InputLabel>
          <Select
            labelId="dayDropdown"
            id="dayDropdown"
            value={this.state.birthDay}
            onChange={event => this.handleChange({ fieldKey: 'birthDay' }, event.target.value)}
          >
            {
              [...Array(31).keys()].map(index =>
                (<MenuItem value={index + 1}>
                  {index + 1}
                </MenuItem>))
            }
          </Select>
        </FormControl>
      </div>
    );
  }

  renderText = (field) => {
    const { translation } = this.props;
    return (
      <TextComponent
        key={field.fieldKey}
        title={translation(`SignUpDialog.fieldTitles.${field.fieldKey}`)}
        style={{
          marginTop: '24px',
          fontSize: '14px',
          fontStyle: 'italic',
        }}
      />
    );
  }

  renderTextBox = field => (
    <TextBoxComponent
      key={field.fieldKey}
      {...field}
      field={field}
      selectedValue={this.state[`${field.fieldKey}`]}
      handleChange={
         (fieldItem, selectedItems) => this.handleChange(fieldItem, selectedItems)
       }
      fieldAutoFocus={field.autoFocus}
    />
  )

  renderFormField = {
    TextBox: this.renderTextBox,
    Text: this.renderText,
    DayMonth: this.renderDayMonthPicker,
  }
  render() {
    const { open } = this.props;
    const stepPros = this.getProps();
    const {
      dialogTitle,
      dialogContent,
      dialogContent2,
      dialogActions,
      dialogBodyContainerStyle,
      dialogContentStyle,
    } = stepPros;

    return (
      <DialogView
        dialogName={DIALOG_NAMES.SIGNUP}
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableEscapeKeyDown={false}
        dialogTitleStyle={dialogTitle.dialogTitleStyle}
        dialogTitleStyleVariant={dialogTitle.dialogTitleStyleVariant}
        subHeaderImageContainerStyle={dialogTitle.subHeaderImageContainerStyle}
        subHeaderImageStyle={dialogTitle.subHeaderImageStyle}
        dialogBodyContainerStyle={dialogBodyContainerStyle}
        dialogContentStyle={dialogContentStyle}
        titleHasCloseBtn
        dialogTitleText={dialogTitle.dialogTitleText}
        dialogTitleSubheaderImage={dialogTitle.dialogTitleSubheaderImage}
        dialogTitleSubheaderImageAlt={dialogTitle.dialogTitleSubheaderImageAlt}
        hasDialogContent
        hasDialogContent2
        hasDialogErrorContent={false}
        renderDialogContent={() => dialogContent}
        renderDialogContent2={() => dialogContent2}
        hasDialogActions
        actionBtnStyle={dialogActions.actionBtnStyle}
        renderActionBtn={() => dialogActions.actionButtons}
        dialogCloseIconColor="var(--text)"
      />
    );
  }
}

const mapStateToProps = state => ({
  dialogLoading: getDialogLoading(state),
  loading: getLoading(state),
});

SignUpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogLoading: PropTypes.number.isRequired,
  loading: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  translation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SignUpDialog);
