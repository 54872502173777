import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import VizSensor from 'react-visibility-sensor';
import {
  LinearProgress,
  Typography,
  withWidth,
  Divider,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Immutable from 'seamless-immutable';
import union from 'lodash/union';
import flattenDeep from 'lodash/flattenDeep';
import LazyLoad from 'react-lazyload';

import { productIcons, SCROLL_TO_TOP_OFFSET } from '../../services/constants/Constants';
import '../../css/menuPage/MenuPage.scss';
import ProductBoxComponent from './components/ProductBoxComponent';
import OrderFlowDialog from './components/OrderFlowDialog';
import OrderInfoBanner from './components/OrderInfoBanner';
import ProductFlowDialog from '../product/ProductFlowDialog';
import FloatingCartButton from './components/FloatingCartButton';
import ScrollToTop from '../core/components/ScrollToTop';

import AnnouncementBanner from '../core/components/AnnouncementBanner';
import TimeLimitComponent from './components/TimeLimitComponent';
import TabBar from '../core/components/TabBar';
import { getCloudEventSessionId, getCompany, getCurrentOrder, getIsOrderFlowDialogVisible } from '../../selectors';
import * as Actions from '../../actions/Actions';
import {
  checkBasicOrderInfo,
  compareValues,
  getAvailableCategories,
  getAvailableProducts,
  allowDialogClose,
  getImageUrl,
  generateKey,
  getEmergencyAnnouncement,
} from '../../services/functions/Functions';
import { FeatureFlags } from '../../../src/services/functions/FeatureFlag';
import * as ResponsiveStyles from '../../../jsonStyles/components/menuPage/MenuPage.style.json';
import PageExitEvent from '../../events/Bounce/PageExitEvent';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';

const MenuItemProducts = (props) => {
  const [maxBoxHeight, setMaxBoxHeight] = useState(0);
  const {
    menuItemProducts,
    translation,
    currentOrder,
    goToProduct,
  } = props;

  const getMaxBoxHeight = (boxHeight) => {
    if (boxHeight > maxBoxHeight) {
      setMaxBoxHeight(boxHeight);
      const cardElements = document.querySelectorAll('.cardWithDescription');
      cardElements.forEach((cardElement) => {
        cardElement.style.minHeight = `${boxHeight}px`;
      });
    }
  };

  const { productBoxGrid } = FeatureFlags.ResourceConstants;

  return menuItemProducts.map(product => (
    <Grid
      item
      {...productBoxGrid}
      key={product.id}
      id={`product-${product.id}`}
    >
      <ProductBoxComponent
        getMaxBoxHeight={getMaxBoxHeight}
        maxBoxHeight={maxBoxHeight}
        translation={translation}
        product={product}
        handleClick={
            prodId => goToProduct(prodId)
          }
        currentOrderLocationId={(currentOrder && currentOrder.location) ? currentOrder.location.id : null}
        showButton={FeatureFlags.ProductBoxComponent.showOrderNowButton}
      />
    </Grid>
  ));
};

class MenuPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategoryId: props.categories
        ? this.getCategoriesTabs().length > 0 && this.getCategoriesTabs()[0].id
        : null,
      productId: null,
      showProductDialog: false,
      isBannerVisible: true,
    };
  }

  async componentDidMount() {
    const {
      user,
      actions,
      categories,
      currentOrder,
      currentCategoryId,
      upliftHistory,
      history,
    } = this.props;
    // eslint-disable-next-line no-undef
    const apiToken = user ? user.token : window.companyApiKey;

    if (typeof upliftHistory === 'function' && history) upliftHistory(history);

    try {
      await actions.getAllResources(apiToken, ['products']);
      const response = await actions.getAllResources(apiToken, ['categories']);

      if (response.response && response.response[0]) {
        const responseCategories = response.response;
        const sortedCategories = responseCategories.sort(compareValues('position', 'asc'));
        this.setState({ selectedCategoryId: sortedCategories[0].id });
      }
    } catch (error) {
      console.log('API call error', error);
    }
    if (categories && categories.length > 0) this.setState({ selectedCategoryId: this.getCategoriesTabs()[0].id });
    if (!currentOrder) this.createEmptyOrder();
    if (currentCategoryId) this.setState({ selectedCategoryId: currentCategoryId });
    if (!allowDialogClose(currentOrder)) actions.showComponent('OrderFlowDialog');
  }

  // If user selects an invalid product, undo selection.
  componentDidUpdate(prevProps) {
    if (prevProps.currentOrder && prevProps.currentOrder !== this.props.currentOrder) {
      const availableCategoriesForOrder = this.getCategoriesTabs();
      const isValidCategory = this.checkIsValidCategorySelection(availableCategoriesForOrder);
      if (!isValidCategory) {
        this.setState({
          productId: null,
          selectedCategoryId: this.props.categories
            ? availableCategoriesForOrder.length > 0 && availableCategoriesForOrder[0].id
            : 0,
        });
      }
    }
  }

  onSelectCategory(categoryId) {
    this[`ref-${categoryId}`].scrollIntoView();
    // Wait until scroll is complete
    setTimeout(() => {
      this.setState({ selectedCategoryId: categoryId });
    }, 300);
  }

  // Generate list of all relevant icons for the page
  getIconList = products => union(flattenDeep(products.map(prod => prod.icons)));

  getProductsFromCategory(categoryId) {
    const { products, categories, currentOrder } = this.props;
    if (!products || !categories) return [];
    let availableProducts = products.filter(product => product.category.id === categoryId);
    availableProducts = getAvailableProducts(availableProducts, currentOrder);

    return availableProducts.filter(product => !product.isDisabled && !product.isDeleted);
  }

  getCategoriesTabs() {
    const { categories, currentOrder } = this.props;
    if (!categories) return [];
    const mutableCategories = Immutable.asMutable(categories, { deep: true });
    let availableCategoriesForOrder = getAvailableCategories(mutableCategories, currentOrder);
    availableCategoriesForOrder = availableCategoriesForOrder.sort(compareValues('position', 'asc'));
    return availableCategoriesForOrder.map(category => ({ id: category.id, label: category.name }));
  }

  /**
   * Return rendered time limits if specified for the category
   * @param {*} categoryId the id of the selected category
   */
  getTimeLimits = (categoryId) => {
    const { categories, translation } = this.props;
    const selectedCategory = categories.find(cat => cat.id === categoryId);

    return <TimeLimitComponent category={selectedCategory} translation={translation} />;
  }

  getSelectedCategoryDetails = (selectedCategoryId, property) => {
    const { categories } = this.props;
    const selectedCategory = categories && categories.find(cat => cat.id === selectedCategoryId);
    const shouldReturnProperty = (
      selectedCategory && (
        property !== 'description'
        || (property === 'description' && FeatureFlags.MenuPage.showCategoryDescription)
      )
    );
    return shouldReturnProperty && (selectedCategory[property] || '');
  }

  getProductList = (selectedCategoryId) => {
    const filteredProducts = this.getProductsFromCategory(selectedCategoryId);
    const mutableProducts = Immutable.asMutable(filteredProducts, { deep: true });
    // Sort products by position
    return mutableProducts.sort((a, b) => a.position - b.position);
  }

  getFullList = () => {
    const { products } = this.props;
    if (!products) return [];
    const availableCategoriesForOrder = this.getCategoriesTabs();
    return availableCategoriesForOrder.map(category => ({
      categoryId: category.id,
      products: this.getProductList(category.id),
    }));
  }

  getCloudEventBaseParams = () => {
    const {
      user, company, currentOrder, sessionId,
    } = this.props;

    return {
      merchantId: company && company.id,
      userId: user && user.id,
      orderId: currentOrder && currentOrder.id,
      sessionId,
    };
  }

  handleClickBannerLink = () => {
    const { actions } = this.props;
    actions.toggleComponent('OrderFlowDialog');
  }

  toggleProductDialog = (prodId) => {
    const { showProductDialog } = this.state;
    const { currentOrder, actions } = this.props;

    if (FeatureFlags.MenuPage.showNewFlow && !checkBasicOrderInfo(currentOrder)) {
      actions.toggleComponent('OrderFlowDialog');
      this.setState({ productId: prodId });
    } else {
      this.setState({ showProductDialog: !showProductDialog, productId: prodId });
    }
  }

  goToProduct = (prodId) => {
    const { currentOrder, actions } = this.props;

    if (FeatureFlags.MenuPage.showNewFlow && !checkBasicOrderInfo(currentOrder)) {
      actions.toggleComponent('OrderFlowDialog');
      this.setState({ productId: prodId });
    } else {
      this.toggleProductDialog(prodId);
    }
  }

  toggleOrderDialog() {
    const { actions } = this.props;
    actions.toggleComponent('OrderFlowDialog');
  }

  /**
   * Sends PageExited events for many sub-dialogs in OrderFlowDialog, from clicking outside the dialog, or close button
   * Only sends the event if the dialogName is given, providing greater fine-grained control using call sites
   * @param {*} dialogName
   */
  hideOrderDialog(dialogName) {
    const { actions, user } = this.props;
    if (dialogName) {
      const userToken = user && user.token;
      const cloudEventBaseParams = this.getCloudEventBaseParams();
      const cloudEvent = new PageExitEvent({
        ...cloudEventBaseParams,
        componentNameAsPage: dialogName,
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }
    actions.hideComponent('OrderFlowDialog');
  }

  checkIsValidCategorySelection(availableCategoriesForOrder) {
    const { products, categories } = this.props;
    const { productId, selectedCategoryId } = this.state;
    if (productId) {
      const product = products.find(prod => prod.id === productId);
      if (!product) return false;
    } else if (selectedCategoryId) {
      const category = categories.find(cat => cat.id === selectedCategoryId);
      if (!category) return false;
      return (availableCategoriesForOrder.some(cat => cat.id === selectedCategoryId));
    }
    return true;
  }

  createEmptyOrder() {
    const { actions } = this.props;
    actions.createOrder(null, {
    });
  }

  renderLegend = (products) => {
    const { translation } = this.props;
    const iconList = this.getIconList(products);
    if (!iconList) return null;
    const iconObjects = productIcons.filter(icon => iconList.includes(icon.key));
    if (!iconObjects) return null;
    return (
      <div className="legendContainer">
        {
          iconObjects.map(prodIcon => (
            <div key={prodIcon.key} className="legend">
              <img
                src={getImageUrl(`productIcons/${prodIcon.key}.png`)}
                alt={translation(prodIcon.translation)}
                className="menuPage-productIcon"
              />
              <Typography className="legendText">
                {`- ${translation(prodIcon.translation)}`}
              </Typography>
            </div>
            ))
        }
      </div>
    );
  }

  render() {
    const {
      loading,
      translation,
      user,
      actions,
      currentOrder,
      history,
      stateVisibleOrderFlowDialog,
    } = this.props;
    const {
      selectedCategoryId, productId, showProductDialog, isBannerVisible,
    } = this.state;
    const fullList = this.getFullList();
    // Sort products by position
    const showTabBarMenu = selectedCategoryId && FeatureFlags.MenuPage && FeatureFlags.MenuPage.showTabBarMenu;
    const allowDialogToClose = allowDialogClose(currentOrder) === true;
    return (
      <div>
        {
          (stateVisibleOrderFlowDialog || !allowDialogToClose)
          && (
            <OrderFlowDialog
              open={stateVisibleOrderFlowDialog || !allowDialogToClose}
              user={user}
              actions={actions}
              handleClose={() => this.hideOrderDialog()}
              translation={translation}
              currentOrderId={get(currentOrder, 'id')}
              history={history}
              productId={productId}
              loading={loading}
              toggleProductDialog={this.toggleProductDialog}
            />
          )
        }
        {
          showProductDialog
          && (
            <ProductFlowDialog
              open={showProductDialog}
              user={user}
              actions={actions}
              handleClose={this.toggleProductDialog}
              translation={translation}
              history={history}
              loading={loading}
              productId={productId}
            />
          )
        }
        {
          <div className="outerContent">
            <div className="banners">
              {
                FeatureFlags.MenuPage.showBannner
                  && (
                    <VizSensor
                      onChange={(isVisible) => {
                        this.setState({ isBannerVisible: isVisible });
                      }}
                    >
                      <OrderInfoBanner
                        translation={translation}
                        handleClick={() => this.handleClickBannerLink()}
                      />
                    </VizSensor>
                  )
              }
              {
                showTabBarMenu
                  && (
                    <TabBar
                      isCentered
                      type="category"
                      stickToTop={!isBannerVisible}
                      selected={selectedCategoryId}
                      onClickSelected={value => this.onSelectCategory(value)}
                      tabs={this.getCategoriesTabs()}
                      translation={translation}
                    />
                  )
              }
              {
                FeatureFlags.AnnouncementBanner.show
                && (
                  <AnnouncementBanner
                    openMessageSnackbar
                    actions={actions}
                    message={getEmergencyAnnouncement(currentOrder)}
                  />
                )
              }
            </div>
            {
              FeatureFlags.MenuPage.showMenuDescription
                && (
                  <Typography className="summary">
                    {translation('MenuPage.description')}
                  </Typography>
                )
            }
            {
              loading !== 0 && (
                <LinearProgress />
              )
            }
            <div className="menuPage-pageContent">
              {
                fullList.map((menuItem, i) => {
                  const menuItemProducts = menuItem.products || [];
                  let categoryTitle = this.getSelectedCategoryDetails(menuItem.categoryId, 'name');
                  if (FeatureFlags.MenuPage.useUppercaseCategoryName) categoryTitle = categoryTitle.toUpperCase();
                  return (
                    <div
                      // eslint-disable-next-line no-return-assign
                      ref={ref => this[`ref-${menuItem.categoryId}`] = ref}
                      key={generateKey(i)}
                    >
                      <LazyLoad
                        offset={100}
                      >
                        <Fragment>
                          <div className="categoryTitleContainer">
                            <Typography className="categoryTitle">
                              {categoryTitle}
                            </Typography>
                            <Divider className="categoryDivider" />
                            <Typography className="categoryDescription">
                              {this.getSelectedCategoryDetails(menuItem.categoryId, 'description')}
                            </Typography>
                            {this.getTimeLimits(menuItem.categoryId)}
                          </div>
                          <VizSensor
                            partialVisibility
                            offset={{ top: 300, bottom: 300 }}
                            onChange={(isVisible) => {
                              if (isVisible) this.setState({ selectedCategoryId: menuItem.categoryId });
                            }}
                          >
                            <Grid
                              container
                              className="gridContainer"
                              {...ResponsiveStyles.gridContainer}
                            >
                              <MenuItemProducts
                                menuItemProducts={menuItemProducts}
                                translation={translation}
                                currentOrder={currentOrder}
                                toggleProductDialog={this.toggleProductDialog}
                                handleClickOpenProductPage={this.handleClickOpenProductPage}
                                goToProduct={this.goToProduct}
                              />
                            </Grid>
                          </VizSensor>
                        </Fragment>
                      </LazyLoad>
                      {this.renderLegend(menuItemProducts)}
                    </div>
                  );
                })
              }
            </div>
          </div>
        }
        {
          !isBannerVisible
          && (
            <div className="floatingButtonContainer">
              <FloatingCartButton />
              <ScrollToTop showBelow={SCROLL_TO_TOP_OFFSET} />
            </div>
          )
        }
      </div>
    );
  }
}

MenuPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  categories: PropTypes.arrayOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  currentCategoryId: PropTypes.number,
  width: PropTypes.string,
  upliftHistory: PropTypes.func.isRequired,
  company: PropTypes.objectOf(PropTypes.any),
  sessionId: PropTypes.string,
  stateVisibleOrderFlowDialog: PropTypes.bool,
};

MenuPage.defaultProps = {
  user: null,
  categories: undefined,
  currentCategoryId: null,
  width: '',
  company: null,
  sessionId: null,
  stateVisibleOrderFlowDialog: false,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  stateVisibleOrderFlowDialog: getIsOrderFlowDialogVisible(state),
  company: getCompany(state),
  sessionId: getCloudEventSessionId(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
});

export default connect(mapStateToProps, mapDispatchToProps)((withRouter(withWidth()(MenuPage))));
