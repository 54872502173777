import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  endOfDay,
  differenceInCalendarDays,
  addMinutes,
  startOfDay,
  getMinutes,
  eachMinuteOfInterval,
  addMilliseconds,
  min,
  max,
  startOfMinute,
} from 'date-fns';
import get from 'lodash/get';
import addDays from 'date-fns/addDays';
import range from 'lodash/range';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  instanceOf,
  func,
  objectOf,
  any,
  number,
} from 'prop-types';

import { getPrepTime } from '../../../services/functions/Functions';
import { getCurrentOrder } from '../../../selectors';
import { FeatureFlags } from '../../../services/functions/FeatureFlag';
import '../../../css/menuPage/components/DateTimeDropdown.scss';

// Offset to include the maxDate itself
const OFFSET = 1;

// Returns the first time option available, approximated to either an exact hour,
// or past the ammount of minutes selected with timeOptionInterval.
// For example: If TimeSelector minutes = 30 ; 3:45 -> 4:00 or 3:15 -> 3:30.
// Another example: If timeOptionInterval = 15 ; 3:40 -> 3:45 or 3:05 -> 3:15
export const getFirstTimeOption = (order, timeOptionInterval) => {
  const prepTime = getPrepTime(order);
  const firstOptionDate = addMinutes(new Date(), prepTime);
  const dateOptionsMinutes = getMinutes(firstOptionDate);
  // Get correct interval time to round up/down to, i.e. if current time is 5:02, timeOptionInterval is 10 and prep is 7, 5:10 is first time slot
  // if current time is 5:04, timeOptionInterval is 10 and prep time is 7, 5:20 is first time slot
  let timeToRoundTo = Math.ceil(dateOptionsMinutes / timeOptionInterval) * timeOptionInterval;
  if (dateOptionsMinutes === timeToRoundTo) timeToRoundTo = Math.floor(dateOptionsMinutes / timeOptionInterval) * timeOptionInterval;

  if (dateOptionsMinutes >= timeOptionInterval) return addMinutes(firstOptionDate, (timeToRoundTo - dateOptionsMinutes));
  return addMinutes(firstOptionDate, (timeOptionInterval - dateOptionsMinutes));
};

export const dateOptions = (maxDate, translation) => {
  const todayDateTime = new Date();
  const numberOfDays = differenceInCalendarDays(maxDate, todayDateTime) + OFFSET;
  return range(numberOfDays).map((dayIndex) => {
    const DateOption = addDays(todayDateTime, dayIndex);
    let dayOptionText = DateOption.toLocaleDateString([], { weekday: 'long', month: 'long', day: '2-digit' });
    if (dayIndex === 0) dayOptionText = `${translation('today')}, ${dayOptionText}`;
    return (
      <MenuItem key={dayIndex} value={dayIndex} date={DateOption}>
        {dayOptionText}
      </MenuItem>
    );
  });
};

export const timeOptions = (selectedDateIndex, firstDateOption, timeOptionInterval) => {
  // timeOptionInterval is the amount of minutes we'll be adding to each selectable interval.
  //    for example: 12:00, 12:15, 12:30 is 15 minutes ; 12:30, 1:00, 1:30 is 30 minutes.
  // firstDateOption will consistently be passed as the first upcoming time that aligns with timeOptionInterval
  //    e.x. 13:46 will get bumped up to 14:00 if the timeOptionInterval is 30 minutes and that will be passed as firstDateOption
  const { maxDateOffset } = FeatureFlags.ResourceConstants;
  const selectedDate = addDays(firstDateOption, selectedDateIndex);
  const maxDate = addMilliseconds(startOfMinute(firstDateOption), maxDateOffset);

  const minutesInterval = { start: max([firstDateOption, startOfDay(selectedDate)]), end: min([maxDate, endOfDay(selectedDate)]) };
  return eachMinuteOfInterval(minutesInterval, { step: timeOptionInterval }).map((minuteDate, minuteIndex) => {
    const hourText = minuteDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
      <MenuItem key={minuteDate} value={minuteIndex} date={minuteDate}>
        {hourText}
      </MenuItem>
    );
  });
};

const DateTimeDropdown = (props) => {
  const {
    maxDate, translation, currentOrder, onChange,
    currentDateIndex, currentTimeIndex,
  } = props;

  const [selectedTimeIndex, setSelectedTimeIndex] = useState(currentTimeIndex);
  const [selectedDateIndex, setSelectedDateIndex] = useState(currentDateIndex);

  // Time option interval comes from the "timeSelectorMinutes" by default.
  // UNLESS the order location has them set up using the `configurations` table
  const orderLocationTimeSlot = get(currentOrder, 'location.ORDER_TIME_SLOT');
  // TO-DO: Replace this with a proper ResourceConstant instance when we have an API solution for storing this.
  const timeOptionInterval = orderLocationTimeSlot || FeatureFlags.ResourceConstants.timeSelectorMinutes;

  // We will later use this first option in our other functions.
  const firstDateOption = getFirstTimeOption(currentOrder, timeOptionInterval);

  const updateSelectedTime = () => {
    let selectedDay = addDays(firstDateOption, selectedDateIndex);
    if (selectedDateIndex !== 0) selectedDay = startOfDay(selectedDay);
    const selectedTime = addMinutes(selectedDay, timeOptionInterval * selectedTimeIndex);
    onChange(selectedTime, selectedDateIndex, selectedTimeIndex);
  };

  const dayTimeOptions = timeOptions(selectedDateIndex, firstDateOption, timeOptionInterval);

  useEffect(() => {
    updateSelectedTime();
  }, [selectedDateIndex, selectedTimeIndex]);

  return (
    <div className="dateTimeDropdown-dropdownContainer">
      <FormControl variant="outlined" className="dateTimeDropdown-formControl">
        <Select
          labelId="date-select-outlined-label"
          id="date-select-outlined"
          value={selectedDateIndex}
          onChange={event => setSelectedDateIndex(event.target.value)}
          data-testid="date-select"
        >
          {dateOptions(maxDate, translation)}
        </Select>
      </FormControl>
      {
        dayTimeOptions
          ? (
            <FormControl variant="outlined" className="dateTimeDropdown-formControl">
              <Select
                labelId="time-select-outlined-label"
                id="time-select-outlined"
                value={selectedTimeIndex}
                onChange={event => setSelectedTimeIndex(event.target.value)}
                data-testid="time-select"
              >
                {dayTimeOptions}
              </Select>
            </FormControl>
          )
          : (
            <div className="dayErrorContainer">
              <Typography className="dayErrorText">
                {translation('CheckoutDrawer.orderTimeSelector.selectedDayError')}
              </Typography>
            </div>
          )
      }
    </div>
  );
};

DateTimeDropdown.defaultProps = {
  maxDate: null,
};

DateTimeDropdown.propTypes = {
  onChange: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  maxDate: instanceOf(Date),
  translation: func.isRequired,
  currentDateIndex: number.isRequired,
  currentTimeIndex: number.isRequired,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(DateTimeDropdown);
