import React, { useContext, useState } from 'react';
import { func, object, objectOf } from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import get from 'lodash/get';
import Button from '../../../core/components/Button';
import CssVariablesContext from '../../../../css/cssVariablesContext';
import '../../../../css/paymentPage/subComponents/StripeNewCardField.scss';
import ResourcesAPI from '../../../../services/api/ResourcesApi';

const SUCCEEDED = 'succeeded';

const StripeNewCardField = ({
  actions,
  handleAddCard,
  translation,
  user,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const cssVariables = useContext(CssVariablesContext);
  const [cardEntryError, setCardEntryError] = useState(null);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const getStripeClientSecret = async () => {
    try {
      const paymentOptionRequest = await ResourcesAPI.addResource(user.token, null, ['users', 'payment-options-request']);
      if (!paymentOptionRequest || paymentOptionRequest.error) {
        const errorMessage = get(paymentOptionRequest, 'error.message', '');
        actions.setErrorSnackbarMessage(errorMessage);
        setIsSubmitButtonLoading(false);
        return null;
      } else if (!paymentOptionRequest.error) {
        const clientSecret = paymentOptionRequest.data.requestToken;
        return clientSecret;
      }
    } catch (e) {
      const { message } = e;
      actions.setErrorSnackbarMessage(message);
      setIsSubmitButtonLoading(false);
    }
    return null;
  };

  const savePaymentOptionToCraver = async (keyId) => {
    const cardInfo = { keyId };
    try {
      const addCardResponse = await actions.addResource(user.token, cardInfo, 'users', user.id, 'payment-options');
      if (addCardResponse.error) actions.setErrorSnackbarMessage(addCardResponse.error.message);
      if (!addCardResponse.error && addCardResponse.id) handleAddCard(addCardResponse);
      setIsSubmitButtonLoading(false);
    } catch (e) {
      const { message } = e;
      actions.setErrorSnackbarMessage(message);
      setIsSubmitButtonLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitButtonLoading(true);

    // Stripe.js hasn't yet loaded.
    if (!stripe || !elements) {
      setIsSubmitButtonLoading(false);
      return;
    }
    try {
      const clientSecret = await getStripeClientSecret();
      if (!clientSecret) return;

      const confirmSetupIntentResult = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement('card'),
        },
      });

      if (confirmSetupIntentResult.error) {
        actions.setErrorSnackbarMessage(confirmSetupIntentResult.error.message);
        setIsSubmitButtonLoading(false);
        return;
      }
      if (confirmSetupIntentResult.setupIntent.status === SUCCEEDED) {
        const paymentMethodObjOrIdString = confirmSetupIntentResult.setupIntent.payment_method;
        const paymentMethodId = typeof paymentMethodObjOrIdString === 'string' ? paymentMethodObjOrIdString : paymentMethodObjOrIdString.id;
        await savePaymentOptionToCraver(paymentMethodId);
      }
    } catch (e) {
      const { message } = e;
      actions.setErrorSnackbarMessage(message);
      setIsSubmitButtonLoading(false);
    }
  };

  const handleChange = (event) => {
    const errorMessage = get(event, 'error.message');
    if (errorMessage !== cardEntryError) setCardEntryError(errorMessage);
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: '14px',
        color: cssVariables['input-text-color'],
      },
      invalid: {
        color: cssVariables['error-text-color'],
      },
    },
  };

  return (
    <React.Fragment>
      <section
        className="StripeCardElementContainer"
      >
        <CardElement
          options={cardStyle}
          id="card-element"
          onChange={handleChange}
        />
      </section>
      {
        !!cardEntryError
        && (
          <section className="StripeCardElement-cardErrorContainer" role="alert">
            <p className="StripeCardElement-cardError">{cardEntryError}</p>
          </section>
        )
      }
      <section className="StripeCardElement-submitButton">
        <Button onClick={handleSubmit} disabled={isSubmitButtonLoading}>
          {
            isSubmitButtonLoading
              ? <CircularProgress size={20} />
              : translation('StripeNewCardForm.addCardButton')
          }
        </Button>
      </section>
    </React.Fragment>
  );
};

StripeNewCardField.propTypes = {
  user: object,
  actions: objectOf(func),
  handleAddCard: func,
  translation: func,
};

StripeNewCardField.defaultProps = {
  user: {},
  actions: {
    addResource: () => {},
    setErrorSnackbarMessage: () => {},
  },
  handleAddCard: () => {},
  translation: () => {},
};

export default StripeNewCardField;
