import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { CONTROLS, PAGES, PLACEMENTS, SLOTS, TYPES } from './constants';

export default class ClickSquareApplePayButtonEvent extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    idSeam,
    sessionId,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId,
    };
    super(
      EVENT_SOURCE,
      TYPES.CLICK_SQUARE_APPLE_PAY_BUTTON,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: PAGES.PAYMENT_DRAWER,
        slot: SLOTS.L2,
        placement: PLACEMENTS.SQUARE_PAYMENT_CARD_INFO_ENTRY_PLACEMENT,
        control: CONTROLS.SQUARE_APPLE_PAY_BUTTON,
      },
      position: getPositionObject({ clientX, clientY }),
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
    };
    this.setData(data);
  }
}
