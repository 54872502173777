import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, withWidth, TextField } from '@material-ui/core';
import {
  func,
  objectOf,
  bool,
  number,
} from 'prop-types';
import { getDialogLoading } from '../../selectors';
import DialogView from '../core/components/DialogView';
import Button from '../core/components/Button';

import '../../css/authentication/GuestSignInDialog.scss';
import { DIALOG_NAMES } from '../../services/api/CloudEvents/constants';
import { DIALOG_CONSTANTS } from '../core/DialogConstants';

const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  error: '',
  validation: {
    email: '',
  },
};

const guestFields = [
  {
    title: 'guestSignInDialog.firstName',
    fieldKey: 'firstName',
    fieldWidth: '100%',
  },
  {
    title: 'guestSignInDialog.lastName',
    fieldKey: 'lastName',
    fieldWidth: '100%',
  },
  {
    title: 'guestSignInDialog.email',
    fieldKey: 'email',
    fieldWidth: '100%',
  },
  {
    title: 'guestSignInDialog.phoneNumber',
    fieldKey: 'phoneNumber',
    fieldWidth: '100%',
  },
];
class GuestSignInDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getDialogContent = () => {
    const { translation } = this.props;
    const isDisabled = this.disableButton();

    return (
      <form
        noValidate
        className="guestSignInDialog-guestForm"
      >
        <div className="guestSignInDialog-fieldsContainer">
          {guestFields.map(field => this.renderTextField(field))}
        </div>
        <div className="guestSignInDialog-textContainer">
          <Typography className="guestSignInDialog-confirmText">
            {translation('guestSignInDialog.phoneNumberConfirmText')}
          </Typography>
        </div>
        <div>
          <Button
            id="guestCheckout"
            type="primary"
            onClick={() => this.handleContinue()}
            fullWidth
            text={translation('guestSignInDialog.continue')}
            disabled={isDisabled}
          />
        </div>
        <div className="guestSignInDialog-linkContainer">
          <Typography>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://craverapp.com/terms-and-conditions/"
              className="guestSignInDialog-linkStyle"
            >
              {translation('guestSignInDialog.termsOfUse')}
            </a>
          </Typography>
        </div>
      </form>
    );
  };

  getTextFieldInnerInputProps = () => {
    const innerInputClasses = {
      root: {
        backgroundColor: 'var(--input-background-color)',
      },
    };
    return {
      classes: innerInputClasses,
    };
  }

  handleChange = (fieldKey, event) => {
    const { target } = event;
    this.validateField(fieldKey, target.value);
    this.setState({
      [fieldKey]: target.value,
    });
  }

  // Check for empty field or field with only blank spaces
  isEmptyTextField = (text) => {
    const filteredText = text !== undefined ? text.replace(/\s/g, '') : '';
    return filteredText === '';
  }

  handleBlur = (field, inputText) => {
    if (this.isEmptyTextField(inputText)) this.setState({ [`${field}LeftBlank`]: true });
  }

  handleContinue = async () => {
    const { actions } = this.props;
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
    } = this.state;
    // eslint-disable-next-line no-undef, no-restricted-globals
    const guestToken = self.crypto.randomUUID();

    const userObj = {
      name: `${firstName} ${lastName}`,
      email,
      telephone: phoneNumber,
      guestToken,
    };

    const response = await actions.signUpUser(userObj);

    if (!response.error) {
      actions.hideComponent('CheckoutDrawer');
      this.handleClose({ eventSource: 'guestSignInSuccess' });
    }
  };

  handleClose = (closeDialogOptions) => {
    const defaultOptions = { dialog: DIALOG_CONSTANTS.GUEST_SIGN_IN };
    const mergedOptions = { ...defaultOptions, ...closeDialogOptions };
    this.props.handleClose(mergedOptions);
  }

  validateField = (fieldKey, value) => {
    const { translation } = this.props;
    const { validation } = this.state;
    let fieldInvalidMsg = '';

    if (fieldKey === 'email') {
      fieldInvalidMsg = value.match(/^\S+@\S+\.\S+/) ? '' : translation('guestSignInDialog.invalidEmail');
    }

    this.setState({
      validation: {
        ...validation,
        [`${fieldKey}`]: fieldInvalidMsg,
      },
    });
  }

  disableButton = () => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      validation,
    } = this.state;
    const { dialogLoading } = this.props;
    return (dialogLoading !== 0) || !firstName || !lastName || !phoneNumber || !email || validation.email !== '';
  }

  renderTextField = (prop) => {
    const {
      translation,
    } = this.props;
    const { validation } = this.state;

    return (
      <TextField
        id={prop.fieldKey}
        key={prop.fieldKey}
        select={false}
        value={this.state[`${prop.fieldKey}`]}
        label={translation(prop.title)}
        placeholder={translation(prop.title)}
        onChange={event => this.handleChange(prop.fieldKey, event)}
        className="guestSignInDialog-textFieldStyle"
        style={{ width: prop.fieldWidth }}
        variant="outlined"
        InputProps={this.getTextFieldInnerInputProps()}
        FormHelperTextProps={{ className: 'guestSignInDialog-textFieldHelperText' }}
        error={
          (this.isEmptyTextField(this.state[`${prop.fieldKey}`]) && this.state[`${prop.fieldKey}LeftBlank`])
          || !!validation[`${prop.fieldKey}`]
        }
        helperText={validation[`${prop.fieldKey}`]}
        onBlur={() => { this.handleBlur(prop.fieldKey, this.state[`${prop.fieldKey}`]); }}
      />
    );
  }

  render() {
    const { open } = this.props;

    return (
      <DialogView
        dialogName={DIALOG_NAMES.GUEST_SIGN_IN}
        open={open}
        handleClose={() => this.handleClose()}
        titleAlignClose={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle="guestSignInDialog-dialogTitleStyle"
        dialogTitleStyleVariant="h2"
        titleHasCloseBtn
        hasDialogActions={false}
        hasDialogContent
        hasDialogContent2={false}
        renderDialogContent={() => this.getDialogContent()}
        hasDialogErrorContent={false}
        dialogCloseIconColor="var(--color-primary)"
        dialogBodyContainerStyle="guestSignInDialog-dialogBodyContainerStyle "
        dialogContentStyle="guestSignInDialog-dialogContentStyle"
        // TO-DO: Remove inline styles
        dialogPaperStyle={{
          width: '600px',
        }}
      />
    );
  }
}

GuestSignInDialog.propTypes = {
  translation: func.isRequired,
  open: bool.isRequired,
  actions: objectOf(func).isRequired,
  handleClose: func.isRequired,
  dialogLoading: number,
};

GuestSignInDialog.defaultProps = {
  dialogLoading: 0,
};

const mapStateToProps = state => ({
  dialogLoading: getDialogLoading(state),
});

export default connect(mapStateToProps)(withWidth()(GuestSignInDialog));
