import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, IconButton, Icon, Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getCurrentOrder } from '../../../selectors';
import { getCurrencySymbol, getOrderPrice } from '../../../services/functions/Functions';
import '../../../css/core/components/ShoppingCart.scss';

const useHandleClick = ({ user, onHandleClick, onHandleOpenCheckoutDrawer }) => useCallback(() => {
  if (!user) {
    const updateCoreViewState = {
      cartIconWasPressed: true,
    };
    onHandleClick(updateCoreViewState);
    return;
  }
  onHandleOpenCheckoutDrawer();
}, [user, onHandleClick, onHandleOpenCheckoutDrawer]);


const ShoppingCart = ({
  onHandleClick, onHandleOpenCheckoutDrawer, user,
}) => {
  const currentOrder = useSelector(getCurrentOrder);

  const handleClick = useHandleClick({ onHandleClick, user, onHandleOpenCheckoutDrawer });

  const orderPrice = getOrderPrice(currentOrder);
  let itemsQuantity = 0;

  if (currentOrder) {
    itemsQuantity = currentOrder.items ? currentOrder.items.length : 0;
  }

  return (
    <div id="headerCart" className="shoppingCart-shoppingCartContainer">
      <IconButton
        className="shoppingCart-redirectLink"
        onClick={handleClick}
      >
        <div className="shoppingCart-shoppingCartLabel">
          <Badge overlap="rectangular" badgeContent={itemsQuantity} color="secondary" >
            <Icon className="shoppingCart-checkoutIcon">shopping_cart</Icon>
          </Badge>
          <div className="shoppingCart-checkoutContainer">
            <Typography variant="h5" className="shoppingCart-checkoutText">
              {`${getCurrencySymbol()}${orderPrice.toFixed(2)}`}
            </Typography>
          </div>
        </div>
      </IconButton>
    </div>
  );
};

ShoppingCart.propTypes = {
  onHandleClick: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  onHandleOpenCheckoutDrawer: PropTypes.func.isRequired,
};

ShoppingCart.defaultProps = {
  user: null,
};

export default withRouter(ShoppingCart);
