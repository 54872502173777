import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@material-ui/core';
import {
  func,
  objectOf,
  any,
  bool,
  arrayOf,
} from 'prop-types';

import LinkText from './LinkText';
import Button from './Button';
import { getFormattedPrice, getProductFamily, generateKey } from '../../../services/functions/Functions';
import { getCurrentOrder, getCompany, getProducts, getUser } from '../../../selectors';
import * as Actions from '../../../actions/Actions';

import '../../../css/core/components/OrderItemList.scss';

const CustomizationList = (props) => {
  const { translation, options } = props;
  if (isEmpty(options)) return null;
  const addOnsText = translation('addOns');
  return (
    <Typography className="orderItemList-optionListText">
      {`${addOnsText}:`}
      {
        options.map(option => (
            ` ${option.quantity}x ${option.name}`
        ))
      }
    </Typography>
  );
};

const RedeemedPrice = (props) => {
  const {
    enableEdit,
    orderItem,
    company,
    translation,
    handleUnRedeem,
  } = props;
  const { totalPrice, totalRedeemedAmount } = orderItem;
  const redeemedPrice = totalPrice - totalRedeemedAmount;
  return (
    <div className="orderItemList-priceWithButton">
      <div className="orderItemList-priceContainer">
        <Typography
          className="orderItemList-crossedOutPriceText"
        >
          {getFormattedPrice(totalPrice, company)}
        </Typography>
        <Typography
          className="orderItemList-priceText"
        >
          {getFormattedPrice(redeemedPrice, company)}
        </Typography>
      </div>
      {
        enableEdit && (
          <LinkText
            className="orderItemList-linkText"
            onClick={() => handleUnRedeem(orderItem)}
            text={translation('OrderItemComponent.unRedeem')}
          />
        )
      }
    </div>
  );
};

const ItemPrice = (props) => {
  const {
    orderItem,
    company,
    isCurrentItemRedeemed,
  } = props;
  if (isCurrentItemRedeemed) return <RedeemedPrice {...props} />;
  // Total price is the item's price, times quantity.
  const { totalPrice } = orderItem;
  return (
    <Typography
      className="orderItemList-priceText"
    >
      {getFormattedPrice(totalPrice, company)}
    </Typography>
  );
};

const RedeemButton = (props) => {
  const {
    userPoints,
    handleRedeem,
    orderItem,
    translation,
    enableEdit
  } = props;
  if (!enableEdit) return null;
  const { redeemedPoints, quantity } = orderItem;
  const productItemPoints = get(orderItem, 'productItem.points', 0);

  const redeemedQuantity = productItemPoints ? redeemedPoints / productItemPoints : 0;

  // For the item to be redeemable, three conditions are needed:
  // 1) The product item has associated points
  // 2) The user currently has equal or more of said points
  // 3) The item hasn't been redeemed more times than what's in the cart (i.e: Can't redeem 3 times if the user only has 2x of said product)
  const redeemableItem = productItemPoints > 0
    && userPoints >= productItemPoints
    && redeemedQuantity < quantity;

  // If the item is not redeemable we can exit early
  if (!redeemableItem) return null;
  const redeemText = translation('OrderItemComponent.redeem');
  const pointsText = translation('RewardDialog.points');
  const buttonText = `${redeemText} ${productItemPoints} ${pointsText}`;

  return (
    <Button
      className="orderItemList-redeemButton"
      type="primary"
      onClick={() => handleRedeem(orderItem)}
      icon="info"
      text={buttonText}
      overrideClass
    />
  );
};

const OrderItem = (props) => {
  const {
    orderItem,
    handleEdit,
    handleRemove,
    redeemedItemsIds,
    products,
    translation,
    enableEdit,
    user,
  } = props;
  if (!orderItem) return null;
  const { productItem, quantity, options } = orderItem;
  const product = getProductFamily(products, productItem.id);
  const productItemValue = ((get(product, 'items.length') === 1) ? null : productItem.attribute_value);
  const isCurrentItemRedeemed = !isEmpty(redeemedItemsIds) && redeemedItemsIds.includes(orderItem.id);
  const showRedeemButton = enableEdit && user && !user.guestToken;

  return (
    <div className="orderItemList-itemContainer">
      <div className="orderItemList-itemTitle">
        <div>
          <Typography className="orderItemList-productName">
            {`${quantity}x ${get(product, 'name', '')}`}
          </Typography>
          <Typography className="orderItemList-productItemValue">
            {productItemValue}
          </Typography>
        </div>
        <div className="orderItemList-priceAndRedeem">
          <ItemPrice {...props} isCurrentItemRedeemed={isCurrentItemRedeemed} />
          {
            showRedeemButton && (
              <RedeemButton {...props} isCurrentItemRedeemed={isCurrentItemRedeemed} />
            )
          }
        </div>
      </div>
      <div className="orderItemList-customizationList">
        <CustomizationList {...props} options={options} />
      </div>
      <div className="orderItemList-noteContainer">
        {
          orderItem.note != null && orderItem.note !== ''
          && (
            <Typography className="orderItemList-note">
              {`${translation('CheckoutDrawer.orderItem.note')}: ${orderItem.note}`}
            </Typography>
          )
        }
      </div>
      {
        enableEdit && (
          <div className="orderItemList-itemLinks">
            <LinkText
              id="editCartItem"
              className="orderItemList-linkText"
              onClick={() => handleEdit(orderItem)}
              text={translation('EDIT')}
            />
            <LinkText
              id="removeCartItem"
              className="orderItemList-linkText"
              onClick={() => handleRemove(orderItem)}
              text={translation('REMOVE')}
            />
          </div>
        )
      }
    </div>
  );
};

const OrderItemList = (props) => {
  const {
    currentOrder,
    orderItems,
    user,
    actions,
    isBelowMinimum,
  } = props;
  if (!orderItems) return null;

  const redeemedItems = orderItems.filter(({ redeemedPoints }) => redeemedPoints);
  const redeemedItemsIds = redeemedItems.map(({ id }) => id);

  const [userPoints, setUserPoints] = useState(get(user, 'points', 0));

  const productItemPoints = orderItem => get(orderItem, 'productItem.points', 0);

  const redeemPoints = (orderItem) => {
    setUserPoints(userPoints - productItemPoints(orderItem));
    actions.updateOrderItemsRedeemedPoints(user, currentOrder, orderItem, productItemPoints(orderItem));
  };

  const unRedeemPoints = (orderItem) => {
    setUserPoints(userPoints + productItemPoints(orderItem));
    actions.updateOrderItemsRedeemedPoints(user, currentOrder, orderItem, 0);
  };

  return (
    <div className={isBelowMinimum ? 'orderItemList-itemListTopMargin' : 'orderItemList-itemList'}>
      {
        orderItems.map((orderItem, i) => (
          <OrderItem
            {...props}
            key={generateKey(i)}
            orderItem={orderItem}
            redeemedItemsIds={redeemedItemsIds}
            handleRedeem={redeemPoints}
            handleUnRedeem={unRedeemPoints}
            userPoints={userPoints}
          />
        ))
      }
    </div>
  );
};

OrderItem.propTypes = {
  products: objectOf(any).isRequired,
  translation: func.isRequired,
  user: objectOf(any).isRequired,
  orderItem: objectOf(any),
  handleEdit: func,
  handleRemove: func,
  enableEdit: func,
  redeemedItemsIds: arrayOf(any),
};

OrderItem.defaultProps = {
  orderItem: null,
  handleEdit: null,
  handleRemove: null,
  enableEdit: null,
  redeemedItemsIds: null,
};

OrderItemList.propTypes = {
  translation: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  enableEdit: bool,
  isBelowMinimum: bool,
  orderItems: arrayOf(any),
  user: objectOf(any).isRequired,
};

OrderItemList.defaultProps = {
  enableEdit: true,
  isBelowMinimum: false,
  orderItems: [],
};

const mapStateToProps = state => ({
  user: getUser(state).user,
  company: getCompany(state),
  currentOrder: getCurrentOrder(state),
  products: getProducts(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
});

const EnhancedOrderItemList = compose(connect(mapStateToProps, mapDispatchToProps))(OrderItemList);
export default EnhancedOrderItemList;
