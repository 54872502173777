/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from 'react';
import { Typography, Link } from '@material-ui/core';
import {
  func,
  number,
  string,
} from 'prop-types';
import truncate from 'lodash/truncate';

import '../../../css/core/components/ReadMore.scss';

const ReadMore = (props) => {
  const {
    text,
    textClass,
    translation,
    charLimit,
  } = props;

  const showToggle = !!text && text.length >= charLimit;
  const [hidden, setHidden] = useState(showToggle);
  const linkText = hidden ? translation('showMore') : translation('showLess');
  const truncatedText = hidden ? truncate(text, { length: charLimit }) : text;

  const onClickLink = (event) => {
    event.stopPropagation();
    setHidden(!hidden);
  };

  return (
    <div>
      <Typography
        display="inline"
        className={textClass}
      >
        <Fragment>
          {truncatedText}
          {
            showToggle
              && (
                <Link
                  variant="body2"
                  onClick={onClickLink}
                  className="linkText"
                >
                  {linkText}
                </Link>
            )
          }
        </Fragment>
      </Typography>
    </div>
  );
};

ReadMore.defaultProps = {
  charLimit: 99,
  text: '',
  textClass: '',
};

ReadMore.propTypes = {
  charLimit: number,
  text: string,
  textClass: string,
  translation: func.isRequired,
};

export default ReadMore;
