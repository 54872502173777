import { configureStore } from '@reduxjs/toolkit';
import { browserHistory } from 'react-router';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { routerMiddleware } from 'react-router-redux';
import RootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['loading', 'dialogLoading', 'cloudEvents'],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const setupStore = (preloadedState = {}) => configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(routerMiddleware(browserHistory)),
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});

export const store = setupStore();

export const persistor = persistStore(store);
