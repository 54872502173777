import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Chip, Typography, Grid } from '@material-ui/core';
import get from 'lodash/get';

import { getUser } from '../../selectors';
import Button from '../core/components/Button';
import * as CloudEventsApi from '../../services/api/CloudEvents/CloudEventsApi';
import { getCurrencySymbol } from '../../services/functions/Functions';
import '../../css/subscriptionsPage/SubscriptionTierCard.scss';
import SubscriptionFeaturesList from './SubscriptionFeaturesList';
import { manageSubscription, subscribe, getSubscriptionFrequency, getRenewalDate } from './utils/SubscriptionsPageUtils';
import useCloudEventsBaseParams from '../../events/hooks/useCloudEventsBaseParams';
import ClickManageSubscriptionEvent from '../../events/Subscription/ClickManageSubscriptionEvent';
import ClickSubscribeEvent from '../../events/Subscription/ClickSubscribeEvent';
import useFindRowNumber from '../../events/hooks/useFindRowNumber';
import { POSITIONED_ANCESTOR_CLASS_NAMES } from '../../events/Subscription/constants';
import getClickEventClientXY from '../../events/utils/getClickEventClientXY';

const SubscriptionTierCard = ({
  subscriptionOffer, translation, userSubscription, user, parentHeight,
}) => {
  const [buttonloading, setButtonLoading] = useState(false);

  // Button setup
  const buttonText = userSubscription ? translation('SubscriptionsPage.manage') : translation('SubscriptionsPage.subscribe');
  const buttonType = userSubscription ? 'secondary' : 'primary';
  const renewalDate = userSubscription ? getRenewalDate(userSubscription.endDate) : '';

  // Subscription Data
  const tierPrice = get(subscriptionOffer, 'price', 0);
  const tierPriceText = `${getCurrencySymbol()}${tierPrice.toFixed(2)}`;
  const tierInterval = getSubscriptionFrequency(subscriptionOffer, translation);
  const tierImage = get(subscriptionOffer, 'image', '');

  const cardRef = React.useRef(null);
  const cloudEventSubjectParams = useCloudEventsBaseParams();
  const getRowSlot = useFindRowNumber({
    parentHeight,
    childElement: cardRef.current,
    positionedAncestorElementClassName: POSITIONED_ANCESTOR_CLASS_NAMES.SUBSCRIPTIONS_PAGE_MAIN,
  });

  const handleButtonClick = (clickEvent = {}) => {
    setButtonLoading(true);
    const subscriptionId = get(subscriptionOffer, 'subscriptionId');
    const eventSubjectParams = { ...cloudEventSubjectParams, subscriptionId, slot: getRowSlot() };
    const positionParams = getClickEventClientXY(clickEvent);
    const userToken = user && user.token;
    if (userSubscription) {
      const cloudEvent = new ClickManageSubscriptionEvent({
        ...eventSubjectParams,
        ...positionParams,
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
      manageSubscription(user, () => setButtonLoading(false));
    } else {
      const cloudEvent = new ClickSubscribeEvent({
        ...eventSubjectParams,
        ...positionParams,
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
      subscribe(subscriptionOffer, user, () => {
        // wait 3 second before enabling the button again, this is to
        // account for the delay between the server response and being redirected.
        setTimeout(() => setButtonLoading(false), 3000);
      });
    }
  };

  const cardClass = userSubscription ? 'SubscriptionTierCard-activeCard' : 'SubscriptionTierCard-card';

  return (
    <Grid
      item
      xs={12}
      md={3}
      className={cardClass}
      data-testid={`subscriptionTierCard-${subscriptionOffer.subscriptionId}`}
      ref={cardRef}
    >
      {
        tierImage && (
          <div className="SubscriptionTierCard-imageContainer">
            <img className="SubscriptionTierCard-image" src={tierImage} alt={`tier-${subscriptionOffer.name}`} />
          </div>
        )
      }
      <div className="SubscriptionTierCard-container">
        {
          userSubscription && (
            <div className="SubscriptionTierCard-subscribedContainer">
              <Chip className="SubscriptionTierCard-chip" label={translation('SubscriptionsPage.subscribed')} />
              <Typography className="SubscriptionTierCard-activeSubscription">
                {`${translation('SubscriptionsPage.renews')} ${renewalDate}`}
              </Typography>
            </div>
          )
        }
        <Typography className="SubscriptionTierCard-title">{subscriptionOffer.name}</Typography>
        <Typography className="SubscriptionTierCard-description">{subscriptionOffer.description}</Typography>
        <div className="SubscriptionTierCard-priceContainer">
          <Typography className="SubscriptionTierCard-price">{tierPriceText}</Typography>
          <div className="SubscriptionTierCard-intervalContainer">
            <Typography className="SubscriptionTierCard-intervalText">{translation('SubscriptionsPage.intervalPrefix')}</Typography>
            <Typography className="SubscriptionTierCard-intervalText">{tierInterval}</Typography>
          </div>
        </div>
        <SubscriptionFeaturesList
          translation={translation}
          features={subscriptionOffer.features}
          subscriptionId={subscriptionOffer.subscriptionId}
          getRowSlot={getRowSlot}
        />
      </div>
      <Button
        buttonloading={buttonloading}
        keepclickeventonclick
        type={buttonType}
        onClick={handleButtonClick}
        fullWidth
        text={buttonText}
      />
    </Grid>
  );
};

SubscriptionTierCard.propTypes = {
  user: PropTypes.object.isRequired,
  userSubscription: PropTypes.objectOf(PropTypes.any),
  translation: PropTypes.func.isRequired,
  subscriptionOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  parentHeight: PropTypes.number,
};

SubscriptionTierCard.defaultProps = {
  userSubscription: null,
  parentHeight: null,
};

const mapStateToProps = state => ({
  user: getUser(state).user,
});

export default connect(mapStateToProps)(SubscriptionTierCard);
