/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withWidth from '@material-ui/core/withWidth';
import { Icon, Typography } from '@material-ui/core';
import '../../../css/menuPage/components/OrderInfoBanner.scss';
import {
  checkBasicOrderInfo,
  isDesktopMode,
  renderDesiredTime,
} from '../../../services/functions/Functions';
import { getCurrentOrder } from '../../../selectors';
import { PICKUP, DELIVERY, DINE_IN, CATERING } from '../../../services/constants/Constants';

const getOptionIcon = (props) => {
  const { currentOrder } = props;
  const { deliveryOption } = currentOrder;

  const icons = {};
  icons[PICKUP] = 'directions_walk';
  icons[DELIVERY] = 'directions_car';
  icons[CATERING] = 'directions_car';
  icons[DINE_IN] = 'directions_walk';

  return deliveryOption
    ? (
      <Icon className="orderInfoBanner-infoIcon">
        {icons[currentOrder.deliveryOption]}
      </Icon>
    )
    : null;
};

const renderAddressOrLocation = (props) => {
  const { currentOrder, translation } = props;

  let addressOrLocation = '';
  const isDelivery = [DELIVERY, CATERING].includes(currentOrder.deliveryOption);

  // We will only assign a value if the order
  // has location for pickup or address for delivery.
  if ((isDelivery && currentOrder.address) || (!isDelivery && currentOrder.location)) {
    addressOrLocation = isDelivery
      ? currentOrder.address.streetAddress
      : currentOrder.location.name;
  }

  if (currentOrder.deliveryOption === DINE_IN && currentOrder.tableNumber) addressOrLocation += ` ${translation('OrderStatusPage.tableNumber')}: ${currentOrder.tableNumber}`;

  return (
    <Typography className="orderInfoBanner-infoText">{addressOrLocation}</Typography>
  );
};

const renderEmptyOrder = (props) => {
  const {
    handleClick,
    translation,
  } = props;

  return (
    <div className="infoContainer">
      <Typography className="orderInfoBanner-infoText ">{translation('MenuPage.orderInfoBanner.infoTextEmpty')}</Typography>
      <Typography
        id="selectHere"
        onClick={() => handleClick()}
        className="orderInfoBanner-linkText"
      >
        {translation('MenuPage.orderInfoBanner.linkTextEmpty')}
      </Typography>
    </div>
  );
};

const renderOrder = (props) => {
  const {
    currentOrder,
    handleClick,
    isDesktop,
    translation,
  } = props;

  return (
    <div className="infoContainer">
      <div className="infoSubContainer">
        <div className="infoSubContainerItem">
          { getOptionIcon(props) }
          <Typography className="orderInfoBanner-infoText">{translation(currentOrder.deliveryOption)}</Typography>
          { renderAddressOrLocation(props) }
        </div>
        { isDesktop && <Typography className="orderInfoBanner-infoText">|</Typography> }
        <div className="infoSubContainerItem">
          <Icon className="orderInfoBanner-infoIcon">access_time</Icon>
          { renderDesiredTime(props) }
        </div>
      </div>
      <Typography
        onClick={() => handleClick()}
        className="orderInfoBanner-linkText"
        id="editOrder"
      >
        { translation('MenuPage.orderInfoBanner.linkTextDefault') }
      </Typography>
    </div>
  );
};

const OrderInfoBanner = (props) => {
  const {
    currentOrder,
    width,
  } = props;
  const hasAllInfo = checkBasicOrderInfo(currentOrder);
  const isDesktop = isDesktopMode(width);

  return (
    <div className="orderInfoBanner-container">
      {
        hasAllInfo
        ? renderOrder({ ...props, isDesktop, width })
        : renderEmptyOrder(props)
      }
    </div>
  );
};

OrderInfoBanner.propTypes = {
  translation: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(withWidth()(OrderInfoBanner));
