import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Typography } from '@material-ui/core';

import '../../../css/core/components/TableNumberDropdown.scss';

const MENU_PROPS = ({
  style: {
    list: {
      'padding-right': '1vw',
    },
  },
});

/**
 * Turning this into another function component like <AllTableNumbers> leads to an issue
 * with refs that is not easily solveable.
 */
const renderAllTableNumbers = (tableNumbers) => {
  if (!tableNumbers || tableNumbers.length === 0) return null;
  return tableNumbers.map(tableNumber => <MenuItem key={tableNumber} value={tableNumber}>{tableNumber}</MenuItem>);
};

const TableNumberDropdown = ({
  alignVertically, onChange, tableNumbers, translation, value,
}) => {
  const tableNumberTitle = translation('CheckoutDrawer.orderLocationSelector.tableNumber');
  let containerClass = 'tableNumberDropdown-titleAndDropdownContainer';
  if (alignVertically) containerClass += ' tableNumberDropdown-alignVertically';
  return (
    <div className={containerClass}>
      <div className="tableNumberDropdown-labelContainer">
        <Typography className="tableNumberDropdown-labelTitleText">{tableNumberTitle}</Typography>
      </div>
      <div className="tableNumberDropdown-dropdownContainer">
        <FormControl variant="outlined" classes={{ root: 'tableNumberDropdown-formControl' }}>
          <Select
            labelId="tableNumberDropdown"
            id="tableNumberDropdown"
            value={value}
            onChange={onChange}
            classes={{ root: 'tableNumberDropdown-select' }}
            MenuProps={MENU_PROPS}
          >
            {renderAllTableNumbers(tableNumbers)}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

TableNumberDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  tableNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  alignVertically: PropTypes.bool,
};

TableNumberDropdown.defaultProps = {
  alignVertically: false,
};

export default TableNumberDropdown;
