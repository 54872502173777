const TYPES = {
  PRODUCT_DIALOG_ATC: 'ca.craver.web.product_dialog.add_to_cart.v1',
  CLICK_CHECKOUT_UPSELL_CAROUSEL_ADD_BUTTON: 'ca.craver.web.click_checkout_upsell_carousel_add_button.v1',
  REORDER_ATC: 'ca.craver.web.reorder_atc.v1',
  INCREMENT_ITEM_QUANTITY: 'ca.craver.web.increment_item_quantity.v1',
  DECREMENT_ITEM_QUANTITY: 'ca.craver.web.decrement_item_quantity.v1',
};

const PAGES = {
  PRODUCT_DIALOG: 'product-dialog',
  CHECKOUT_DRAWER: 'checkout-drawer',
  ORDERS: 'orders',
};

const SLOTS = {
  L1: 'L1',
  L2: 'L2',
  FOOTER: 'footer',
};

const PLACEMENTS = {
  PRODUCT_CALL_TO_ACTION_PLACEMENT: 'product-call-to-action-placement',
  ORDERS_CAROUSEL: 'orders-carousel',
  QUANTITY_SELECTOR: 'quantity-selector',
  UPSELL_PRODUCT_CAROUSEL: 'upsell-product-carousel',
};

const CONTROLS = {
  ADD_TO_CART_BUTTON: 'add-to-cart-button',
  ORDER_AGAIN_BUTTON: 'order-again-button',
  UPSELL_ORDER_NOW_BUTTON: 'upsell-order-now-button',
  INCREMENT_QUANTITY_BUTTON: 'increment-quantity-button',
  DECREMENT_QUANTITY_BUTTON: 'decrement-quantity-button',
};

const POSITIONED_ANCESTOR_CLASS_NAMES = {
  ORDERS_LIST_PARENT: 'orders',
};

export {
  TYPES,
  PAGES,
  SLOTS,
  PLACEMENTS,
  CONTROLS,
  POSITIONED_ANCESTOR_CLASS_NAMES,
};
