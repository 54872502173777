import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { func, object, objectOf } from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeNewCardField from './StripeNewCardField';
import ResourcesAPI from '../../../../services/api/ResourcesApi';

const StripeNewCardForm = (props) => {
  const { user } = props;
  const [stripeObj, setStripeObj] = useState(null);

  useEffect(() => {
    const initStripeObjWithPublishableKey = async () => {
      const getStripePublishableKeyResponse = await ResourcesAPI.getResource(user.token, ['stripe', 'publishable-api-key']);
      const publishableApiKey = get(getStripePublishableKeyResponse, 'data.publishableApiKey');
      if (publishableApiKey) {
        const stripeObject = await loadStripe(publishableApiKey);
        setStripeObj(stripeObject);
      }
    };

    initStripeObjWithPublishableKey();
  }, []);

  if (!stripeObj) return null;

  return (
    <Elements
      stripe={stripeObj}
    >
      {
        stripeObj
        && (
          <StripeNewCardField {...props} />
        )
      }
    </Elements>
  );
};

StripeNewCardForm.propTypes = {
  translation: func,
  user: object,
  actions: objectOf(func),
  handleAddCard: func,
};

StripeNewCardForm.defaultProps = {
  user: {},
  actions: {
    addResource: () => {},
    setErrorSnackbarMessage: () => {},
  },
  handleAddCard: () => {},
  translation: () => {},
};

export default StripeNewCardForm;
