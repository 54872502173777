/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
/* eslint-disable prefer-template */

/* eslint no-console: off */
import get from 'lodash/get';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MuiThemeProvider, StylesProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'babel-polyfill';
import { persistor, store } from './config/configureStore';
import App from './components/App';
import customizedThemes from './css/customMuiStyles';
import './i18n/i18n';
import versionInfo from './config/version.json';
import './colors.css';

import ResourcesAPI from './services/api/ResourcesApi';
import themes from './css/muiTheme';
import { setCssVariables, getRemoteJSON } from './utils/index';
import * as localStyles from './css/styleVariables.json';
import CssVariablesContext from './css/cssVariablesContext';

const async = require('async');

const isDevelopment = () => window.location.href.startsWith('http://localhost');

Sentry.init({
  dist: versionInfo.version,
  dsn: 'https://69ef4501d2f14466be97005c4412a69f@o104974.ingest.sentry.io/1819074',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  release: 'craver-web@' + versionInfo.version,
  enabled: !isDevelopment(),
});

Sentry.setTags({
  companyName: window.companyName,
  version: versionInfo.version,
});

const WebFont = require('webfontloader');

WebFont.load({
  custom: {
    families: ['Museo 300', 'Museo 500', 'Museo 700'],
    urls: [window.font_host + '/fonts.css'],
  },
});

const trimmedVersionNumber = versionInfo.version;

const renderApp = (styleBundle) => {
  const styledMuiTheme = themes(styleBundle);
  const customizedMuiTheme = customizedThemes(styledMuiTheme);
  let theme = createTheme(customizedMuiTheme);
  theme = responsiveFontSizes(theme);
  const container = document.getElementById('app');
  const root = createRoot(container);
  root.render(<Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <CssVariablesContext.Provider value={styleBundle}>
            <App />
          </CssVariablesContext.Provider>
        </PersistGate>
      </StylesProvider>
    </MuiThemeProvider>
  </Provider>);
};

async.waterfall([
  (callback) => {
    const path = ['assets', 'web', 'style', 'json', `?v=${trimmedVersionNumber}`];
    ResourcesAPI.getAllResources(null, path)
      .then(res => callback(null, res))
      .catch(err => callback(err));
  },
  (res, callback) => {
    const pathToStyleFile = get(res, 'data.href');
    getRemoteJSON(pathToStyleFile)
      .then(styleJson => callback(null, styleJson))
      .catch(err => callback(err));
  },
], (err, remoteStyles) => {
  if (err) {
    console.log('unable to download remote style: ', err);
  }
  const remoteOrLocalStyles = !remoteStyles ? localStyles : remoteStyles;
  setCssVariables(remoteOrLocalStyles);
  renderApp(remoteOrLocalStyles);
});
