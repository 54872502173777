import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import * as CloudEventsApi from '../../../services/api/CloudEvents/CloudEventsApi';
import DecrementQuantityEvent from '../../../events/ATC/DecrementQuantityEvent';
import IncrementQuantityEvent from '../../../events/ATC/IncrementQuantityEvent';
import getClickEventClientXY from '../../../events/utils/getClickEventClientXY';

class AddSubtractToggle extends Component {
  onRemoveItem = (clickEvent = {}) => {
    const {
      id, handleChange, quantity, trackEvents, eventParams, userToken,
    } = this.props;
    if (trackEvents) {
      const cloudEvent = new DecrementQuantityEvent({
        ...eventParams,
        ...getClickEventClientXY(clickEvent),
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }
    handleChange(id, quantity - 1);
  }

  onAddItem = (clickEvent = {}) => {
    const {
      id, handleChange, quantity, trackEvents, eventParams, userToken,
    } = this.props;
    if (trackEvents) {
      const cloudEvent = new IncrementQuantityEvent({
        ...eventParams,
        ...getClickEventClientXY(clickEvent),
      });
      CloudEventsApi.sendCloudEvent({ cloudEvent, userToken });
    }
    handleChange(id, quantity + 1);
  }

  render() {
    const {
      max,
      containerStyle,
      iconStyle,
      quantity,
      iconDisabledStyle,
      textStyle,
      forceDisable,
      useCircleIcons,
      id,
    } = this.props;

    const maxButttonDisabled = (max && quantity >= max) || forceDisable;
    const UsedAddIcon = useCircleIcons ? AddCircle : AddIcon;
    const UsedRemoveIcon = useCircleIcons ? RemoveCircle : RemoveIcon;

    return (
      <div className={containerStyle}>
        <IconButton
          data-testid={`subtractItemQuantity-${id}`}
          id="subtractItemQuantity"
          onClick={this.onRemoveItem}
          disabled={quantity <= 1}
        >
          <UsedRemoveIcon className={quantity > 1 ? iconStyle : iconDisabledStyle} />
        </IconButton>
        <Typography className={textStyle}>
          {quantity}
        </Typography>
        <IconButton
          data-testid={`addItemQuantity-${id}`}
          id="addItemQuantity"
          onClick={this.onAddItem}
          disabled={maxButttonDisabled}
        >
          <UsedAddIcon className={maxButttonDisabled ? iconDisabledStyle : iconStyle} />
        </IconButton>
      </div>
    );
  }
}

AddSubtractToggle.propTypes = {
  id: PropTypes.number.isRequired,
  max: PropTypes.number,
  containerStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  iconDisabledStyle: PropTypes.string,
  textStyle: PropTypes.string,
  forceDisable: PropTypes.bool,
  useCircleIcons: PropTypes.bool,
  trackEvents: PropTypes.bool,
  eventParams: PropTypes.objectOf(PropTypes.any),
  userToken: PropTypes.string,
};

AddSubtractToggle.defaultProps = {
  max: null,
  containerStyle: '',
  iconStyle: '',
  iconDisabledStyle: '',
  textStyle: '',
  quantity: 1,
  forceDisable: false,
  useCircleIcons: true,
  trackEvents: false,
  eventParams: {},
  userToken: null,
};

export default AddSubtractToggle;
