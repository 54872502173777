import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import '../../../css/core/components/MessageSnackbar.scss';

// From https://dev.to/mcavaliere/comparing-previous-useeffect-values-in-react-2o4g
function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const MessageSnackbar = ({
  openMessageSnackbar, errorMsg, successMsg, actions, translation,
}) => {
  const [open, setOpen] = useState(openMessageSnackbar);

  const previousErrorMsg = usePreviousValue(errorMsg);
  const previousSuccessMsg = usePreviousValue(successMsg);
  const previousOpenMsgSnackbar = usePreviousValue(openMessageSnackbar);

  useEffect(() => {
    const isMsgDiff = (previousErrorMsg !== errorMsg) || (previousSuccessMsg !== successMsg);
    const isOpenDiff = openMessageSnackbar !== previousOpenMsgSnackbar;

    if (isOpenDiff || isMsgDiff) setOpen(openMessageSnackbar);
  }, [openMessageSnackbar, errorMsg, successMsg]);

  const getSnackBarRootClass = () => {
    if (errorMsg) return 'messageSnackbar-errorSnackbarContentRoot';
    if (successMsg) return 'messageSnackbar-successSnackbarContentRoot';
    return '';
  };

  const getErrorOrSuccessIcon = () => {
    if (errorMsg) return <ErrorOutlineIcon aria-label={translation('MessageSnackbar.warningIconAriaLabel')} />;
    if (successMsg) return <CheckCircleOutlinedIcon aria-label={translation('MessageSnackbar.successIconAriaLabel')} />;
    return null;
  };

  const handleClose = () => {
    setOpen(false);
    actions.clearMessage();
  };

  const renderIconAndMessage = () => (
    <div className="messageSnackbar-iconAndMessageContainer">
      <div className="messageSnackbar-iconContainer">
        {getErrorOrSuccessIcon()}
      </div>
      <div className="messageSnackbar-messageContainer">
        <span id="message-id">
          {errorMsg || successMsg}
        </span>
      </div>
    </div>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={null}
      onClose={handleClose}
      ContentProps={{
        classes: {
          root: getSnackBarRootClass(),
        },
      }}
      message={renderIconAndMessage()}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className="messageSnackbar-iconButton"
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>,
      ]}
    />
  );
};

MessageSnackbar.propTypes = {
  actions: PropTypes.object.isRequired,
  openMessageSnackbar: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  translation: PropTypes.func.isRequired,
};

MessageSnackbar.defaultProps = {
  errorMsg: '',
  successMsg: '',
};

export default MessageSnackbar;
