import CloudEvent from '../../services/api/CloudEvents/CloudEvent';
import { DATA_OBJ_EVENT_TYPES, EVENT_SOURCE } from '../../services/api/CloudEvents/constants';
import { getPositionObject, getSubject } from '../../services/api/CloudEvents/CloudEventsApi';
import { CONTROLS, PLACEMENTS, SLOTS, TYPES } from './constants';
import getPageByRoute from '../../services/api/CloudEvents/getPageByRoute';

export default class DrawerNavToSubscriptionPage extends CloudEvent {
  constructor({
    userId,
    orderId,
    merchantId,
    itemId,
    idSeam,
    sessionId,
    route,
    clientX,
    clientY,
  }) {
    const subjectParams = {
      userId, orderId, merchantId, itemId,
    };
    super(
      EVENT_SOURCE,
      TYPES.NAVIGATE_TO_SUBSCRIPTION_PAGE,
      getSubject(subjectParams),
      idSeam,
    );
    const data = {
      sessionID: sessionId,
      uxContext: {
        page: getPageByRoute(route),
        slot: SLOTS.DRAWER_SLOT,
        placement: PLACEMENTS.DRAWER,
        control: CONTROLS.SUBSCRIPTIONS_BUTTON,
      },
      position: getPositionObject({ clientX, clientY }),
      eventType: DATA_OBJ_EVENT_TYPES.CLICK,
    };
    this.setData(data);
  }
}
