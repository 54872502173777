import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography, IconButton } from '@material-ui/core';

import '../../../css/core/components/AnnouncementBanner.scss';

const AnnouncementBanner = ({ actions, message, openMessageSnackbar }) => {
  const [open, setOpen] = useState(openMessageSnackbar);

  const handleClose = () => {
    setOpen(false);
    actions.clearMessage();
  };

  const isMessageEmpty = () => {
    if (message.title === '' && message.message === '') return true;
    return false;
  };

  if (isMessageEmpty() || !open) return null;

  return (
    <div data-testid="announcement-banner" className="AnnouncementBanner-container">
      <div className="AnnouncementBanner-messageContainer">
        <Icon className="AnnouncementBanner-warningIcon">info_icon</Icon>
        <Typography className="AnnouncementBanner-messageText">
          {`${message.title} | ${message.message}`}
        </Typography>
      </div>
      <IconButton
        key="close"
        aria-label="close"
        className="AnnouncementBanner-iconButton"
        onClick={handleClose}
      >
        <Icon>close</Icon>
      </IconButton>
    </div>
  );
};

AnnouncementBanner.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  openMessageSnackbar: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any),
};

AnnouncementBanner.defaultProps = {
  message: {},
};

export default AnnouncementBanner;
