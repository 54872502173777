import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import '../../../css/paymentPage/components/DeleteDialog.scss';

class DeleteDialog extends Component {
  getDialogContent = () => {
    const {
      translation,
      cardType,
      cardName,
    } = this.props;
    return (
      <div className="deleteDialog-dialogContentContainer">
        <WarningIcon className="deleteDialog-dialogImage" />
        <Typography className="emphasizedText">
          {translation('DeleteDialog.title')}
        </Typography>
        <Typography className="deleteDialog-normalText">
          {`${translation('DeleteDialog.content')} ${cardType}: "${cardName}"`}
        </Typography>
      </div>
    );
  };

  getActionButton = () => {
    const { translation, handleClick } = this.props;
    return (
      <Button
        type="primary"
        className="deleteButton"
        overrideClass
        onClick={handleClick}
        text={translation('DeleteDialog.button')}
      />
    );
  };

  render() {
    const {
      open,
      handleCloseDeleteDialog,
    } = this.props;

    return (
      <DialogView
        open={open}
        titleHasCloseBtn
        titleAlignClose={false}
        handleClose={handleCloseDeleteDialog}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle="deleteDialog-dialogTitleStyle"
        dialogBodyContainerStyle="deleteDialog-dialogBodyContainerStyle"
        dialogContentStyle="deleteDialog-dialogContentStyle"
        hasDialogContent
        hasDialogContent2={false}
        renderDialogContent={() => this.getDialogContent()}
        hasDialogActions
        actionBtnStyle="deleteDialog-actionBtnStyle"
        renderActionBtn={() => this.getActionButton()}
        // TO-DO: remove inline styles
        dialogCloseIconColor="#000000"
        dialogPaperStyle={{ width: '367px' }}
      />
    );
  }
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleCloseDeleteDialog: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  cardType: PropTypes.string.isRequired,
  cardName: PropTypes.string,
};

DeleteDialog.defaultProps = {
  cardName: '',
};

export default (DeleteDialog);
