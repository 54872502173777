const getClickEventClientXY = (event) => {
  // pageX and Y are the absolute coordinates that take into account the page scroll position
  if (!event) return { clientX: 0, clientY: 0 };
  return {
    clientX: event.pageX,
    clientY: event.pageY,
  };
};

export default getClickEventClientXY;
