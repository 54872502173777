import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import {
  CATERING,
  DELIVERY,
  usesAddresses,
} from '../constants/Constants';
import * as Functions from '../functions/Functions';

class OrderHelper {
  static calculateIfBelowMinimum = (props) => {
    const {
      currentOrder,
      deliveryOption,
    } = props;
    if (!OrderHelper.isDeliveredOrder(deliveryOption)) return false;
    const zoneMinimum = OrderHelper.getZoneProperty(currentOrder, deliveryOption, 'minimum');
    const belowMinimumFee = OrderHelper.getZoneProperty(currentOrder, deliveryOption, 'belowMinimumFee');
    const subTotalWithoutRedeem = OrderHelper.calculateSubTotalWithoutRedeem(props);
    // If belowMinimumFee is null, then the zone minimum must be met to place an order
    return subTotalWithoutRedeem < zoneMinimum && !belowMinimumFee;
  }

  static calculateSubTotalWithoutRedeem = ({ currentOrder, products, company }) => {
    if (!currentOrder || !currentOrder.items || !products) return 0;
    return reduce(currentOrder.items, (subTotal, item) => {
      const itemTotal = Functions.calculatePrice([item], products, null, company);
      return itemTotal + subTotal;
    }, 0);
  }

  static getLocationWithConfigurations = (currentOrder, locations) => (
    locations && currentOrder && currentOrder.location
      ? locations.filter(location => location.id === currentOrder.location.id)[0]
      : {}
  );

  static getUsedAddress = (currentOrder) => {
    return usesAddresses.includes(currentOrder.deliveryOption)
      ? currentOrder.address
      : currentOrder.location;
  }

  static getZone = (currentOrder, deliveryOption) => {
    const [
      cateringZone,
      deliveryZone,
    ] = OrderHelper.getZones(currentOrder);
    return OrderHelper.isDelivery(deliveryOption)
      ? deliveryZone
      : cateringZone;
  }

  static getZoneProperty = (currentOrder, deliveryOption, propKey) => {
    const zone = OrderHelper.getZone(currentOrder, deliveryOption);
    return zone[propKey];
  }

  static getZones = (currentOrder) => {
    const {
      cateringZone = {},
      deliveryZone = {},
    } = currentOrder;
    return [cateringZone, deliveryZone];
  }

  static isCatering = deliveryOption => deliveryOption === CATERING;

  static isDeliveredOrder = deliveryOption => [CATERING, DELIVERY].includes(deliveryOption);

  static isDelivery = deliveryOption => deliveryOption === DELIVERY;

  static shouldDisableMakePayment = (props) => {
    const {
      currentOrder,
      disable,
    } = props;

    const isBelowMinimum = OrderHelper.calculateIfBelowMinimum(props);

    return isEmpty(currentOrder.items) || isBelowMinimum || disable;
  }
}

export default OrderHelper;
